
/*-------- 21. Sidebar style ---------*/

.shop-sidebar-padding-right {
    padding-right: 35px;
    @media #{$xx-layout} {
        padding-right: 0px;
    }
    @media #{$xl-layout} {
        padding-right: 0px;
    }
    @media #{$lg-layout} {
        padding-right: 0px;
    }
    @media #{$md-layout} {
        padding-right: 0px;
        margin-top: 80px;
    }
    @media #{$xs-layout} {
        padding-right: 0px;
        margin-top: 60px;
    }
}

.shop-sidebar-padding-left {
    padding-left: 35px;
    @media #{$xx-layout} {
        padding-left: 0px;
    }
    @media #{$xl-layout} {
        padding-left: 0px;
    }
    @media #{$lg-layout} {
        padding-left: 0px;
    }
    @media #{$md-layout} {
        padding-left: 0px;
        margin-top: 80px;
    }
    @media #{$xs-layout} {
        padding-left: 0px;
        margin-top: 60px;
    }
}
.sidebar-widget {
    h4 {
        &.pro-sidebar-title {
            font-size: 18px;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin: 0;
            font-weight: 500;
            @media #{$xx-layout} {
                letter-spacing: 1px;
            }
            @media #{$xl-layout} {
                letter-spacing: 0px;
            }
            @media #{$lg-layout} {
                letter-spacing: 0px;
                font-size: 20px;
            }
        }
    }
    .sidebar-widget-categories {
        .widget-categories-active {
            > li {
                display: block;
                position: relative;
                border-bottom: 1px solid #ebebeb;
                padding: 14px 0;
                &:last-child {
                   border: none;
                }
                &.menu-item-has-children {
                    .menu-expand {
                        top: -2px;
                        left: 94%;
                        width: 30px;
                        position: absolute;
                        height: 100%;
                        text-align: center;
                        cursor: pointer;
                        i {
                            display: block;
                            position: relative;
                            width: 12px;
                            margin-top: 25px;
                            border-bottom: 1px solid #6D6D6D;
                            transition: all 250ms ease-out;
                            &::before {
                                top: 0;
                                width: 100%;
                                content: "";
                                display: block;
                                position: absolute;
                                transform: rotate(90deg);
                                border-bottom: 1px solid #6D6D6D;
                            }
                        }
                    }
                    &.active > .menu-expand i::before{
                        transform: rotate(0);
                    }
                }
                a {
                    font-size: 16px;
                    text-transform: capitalize;
                    position: relative;
                    display: inline-block;
                    color: #6D6D6D;
                }
                ul {
                    padding-left: 18px;
                    padding-top: 15px;
                    li {
                        border-bottom: 1px solid #ebebeb;
                        padding: 8px 0;
                        &:last-child {
                           border-bottom: none;
                        }
                        a {
                            font-size: 16px;
                            color: #6d6d6d;
                            &:hover {
                                color: $theme-color-yellow;
                            }
                        }
                    }
                }
                &:hover {
                    > a {
                        color: $theme-color-yellow;
                    }
                }
            }
        }
        
    }
    .price-filter{
        #slider-range {
            background: #eee none repeat scroll 0 0;
            border: medium none;
            border-radius: 50px;
            height: 5px;
            margin-bottom: 24px;
            margin-left: auto;
            span {
                transition: all .0s ease 0s;
            }
            .ui-slider-range {
                position: absolute;
                display: block;
                border: 0;
                background: $theme-color-yellow;
                border: none;
                transition: all .0s ease 0s;
            }
            .ui-slider-handle {
                background: #fff none repeat scroll 0 0;
                border: medium none;
                border-radius: 50%;
                height: 18px;
                margin-left: 0;
                width: 18px;
                box-shadow: 0 1px 4px 0 rgba(0,0,0,0.4);
                top: -7px;
                cursor: ew-resize;
            }
        }
        .price-slider-amount {
            display: flex;
            justify-content: space-between;
            @media #{$lg-layout} {
                display: block;
            }
            .label-input {
                display: flex;
                align-items: center;
                @media #{$lg-layout} {
                    margin: 0 0 7px;
                }
                span {
                    font-size: 14px;
                    color: #181818 !important;
                    margin-right: 5px;
                }
                input {
                    background: transparent;
                    padding: 0;
                    border: none;
                    font-size: 14px;
                    font-weight: 400;
                    width: 60%;
                    height: auto;
                    color: #181818 !important;
                }
            }
            button {
                background-color: transparent;
                padding: 7px 37px 6px;
                border: 2px solid #181818;
                font-size: 12px;
                font-weight: 400;
                order: 2;
                color: #181818;
                transition: all 0.3s;
                text-transform: uppercase;
                display: inline-block;
                &:hover {
                    background-color: $theme-color-yellow;
                    color: #fff;
                    border: 2px solid $theme-color-yellow;
                }
            }
        }
    }
    .sidebar-widget-list-wrap {
        ul {
            li {
                display: block;
                margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0;
                }
                .sidebar-widget-list {
                    display: flex;
                    align-items: center;
                    .widget-list-checkbox {
                        position: relative;
                        width: 15px;
                        height: 15px;
                        margin-right: 10px;
                        input {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                            z-index: 999;
                            width: 15px;
                            height: 15px;
                            &:checked ~ .checkmark {
                                background-color: $theme-color-yellow;
                                border: 1px solid $theme-color-yellow;
                            }
                            &:checked ~ .checkmark::after {
                                display: block;
                            }
                        }
                        .checkmark {
                            position: absolute;
                            top: 0px;
                            left: 0;
                            height: 15px;
                            width: 15px;
                            background-color: #fff;
                            border: 1px solid #979797;
                            border-radius: 0px;
                            &:before {
                                font-family: LaStudioIcons;
                                position: absolute;
                                left: 2px;
                                font-size: 10px;
                                margin-top: 1px;
                                color: #fff;
                                content: '\ea20';
                                line-height: 1;
                            }
                        }
                    }
                    .widget-list-content {
                        a {
                            color: #181818;
                            &:hover {
                                color: $theme-color-yellow;
                            }
                        }
                        span {
                            color: #181818;
                        }
                    }
                    &:hover {
                        .widget-list-checkbox {
                            .checkmark {
                                background-color: $theme-color-yellow;
                                border: 1px solid $theme-color-yellow;
                                &:before {
                                    font-family: LaStudioIcons;
                                    position: absolute;
                                    left: 2px;
                                    font-size: 10px;
                                    margin-top: 1px;
                                    color: #fff;
                                    content: '\ea20';
                                    line-height: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .sidebar-widge-tag {
        ul {
            li {
                display: inline-block;
                margin: 0 6px 10px 0;
                a {
                    display: inline-block;
                    border: 1px solid #ebebeb;
                    color: #181818;
                    padding: 6px 15px;
                    &:hover {
                        color: $white;
                        background-color: #181818;
                        border: 1px solid #181818;
                    }
                }
            }
        }
    }
    &.sidebar-subscribe-form {
        form {
            position: relative;
            input {
                border: 1px solid #181818;
                color: #181818;
                height: 65px;
                padding: 2px 60px 2px 20px;
                background-color: transparent;
                @media #{$lg-layout} {
                    padding: 2px 50px 2px 10px;
                }
            }
            a {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 20px;
                display: inline-block;
                line-height: 1;
                color: #181818;
                &:hover {
                    color: $theme-color-yellow;
                }
                i {
                    font-size: 24px;
                }
            }
        }
    }
    .sidebar-widget-categories-2 {
        ul {
            li {
                display: block;
                margin: 0 0 20px;
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    color: #211e1c;
                    &:hover {
                        color: $theme-color-yellow;
                    }
                }
                span {
                    float: right;
                    color: #6d6d6d;
                }
            }
        }
    }
    .sidebar-post-wrap {
        .single-sidebar-post {
            display: flex;
            border-bottom: 1px solid #ebebeb;
            padding-bottom: 25px;
            margin-bottom: 25px;
            &:last-child {
                border-bottom: none;
                padding-bottom: 0px;
                margin-bottom: 0px;
            }
            .sidebar-post-img {
                flex: 0 0 80px;
                margin-right: 20px;
                a {
                    display: block;
                    img {
                        width: 100%;
                    }
                }
            }
            .sidebar-post-content {
                h4 {
                    font-size: 16px;
                    line-height: 22px;
                    margin: 0 0 3px;
                    a {
                        color: #181818;
                        &:hover {
                            color: $theme-color-yellow;
                        }
                    }
                }
                span {
                    display: block;
                    font-size: 12px;
                    color: #666;
                }
            }
        }
    }
    .archives-wrap {
        select {
            height: 50px;
            border: 1px solid #ebebeb;
            color: #181818;
            padding: 2px 25px 2px 15px;
        }
    }
    .sidebar-widge-tag-2 {
        a {
            display: inline-block;
            color: #181818;
            margin: 0 0 8px;
            &:hover {
                color: $theme-color-yellow;
            }
        }
    }
}

.sidebar-widge-color {
    ul {
        li {
            display: inline-block;
            margin-right: 18px;
            @media #{$lg-layout} {
                margin-right: 5px;
            }
            &:last-child {
                margin-right: 0;
            }
            a {
                display: inline-block;
                position: relative;
                &:hover {
                    &:after {
                        content: '\ea20';
                        font-family: LaStudioIcons;
                        position: absolute;
                        top: 12px;
                        font-size: 16px;
                        line-height: 1;
                        left: 7px;
                        color: #fff;
                    }
                }
                .swatch-anchor {
                    width: 30px;
                    height: 30px;
                    border-radius: 100%;
                    font-size: 0;
                    display: inline-block;
                    position: relative;
                    &::before {
                        content: attr(title);
                        position: absolute;
                        bottom: 80%;
                        text-indent: 0;
                        background: #000;
                        padding: 4px 10px 6px;
                        line-height: 1;
                        border-radius: 4px;
                        margin-bottom: 10px;
                        min-width: 50px;
                        color: #fff;
                        left: 50%;
                        white-space: pre;
                        text-align: center;
                        text-transform: none;
                        transform: translate(-50%, 0);
                        visibility: hidden;
                        opacity: 0;
                        transition: all 250ms ease-out;
                        font-size: 12px;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        left: 50%;
                        bottom: 80%;
                        border-width: 4px 4px 0 4px;
                        border-style: solid;
                        border-color: #000 transparent transparent transparent;
                        margin-bottom: 6px;
                        margin-left: -4px;
                        visibility: hidden;
                        opacity: 0;
                        transition: all 250ms ease-out;
                    }
                    &:hover::before {
                        bottom: 100%;
                        opacity: 1;
                        visibility: visible;
                    }
                    &:hover::after {
                        bottom: 100%;
                        opacity: 1;
                        visibility: visible;
                    }
                    &:hover {

                    }
                    &.blue {
                        background-color: #5a7eba;
                    }
                    &.gray {
                        background-color: #d8d8d8;
                    }
                    &.pink {
                        background-color: #e8856f;
                    }
                }
            }
        }
    }
}

@media #{$xs-layout} {
    .shop-area {
        &.pt-115 {
            padding-top: 55px;
        }
        &.pb-110 {
            padding-bottom: 50px;
        }
    }
}

