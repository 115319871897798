/*-----------------------------------------------------------------------------------
    
    Template Name: Lauriel - Multipurpose eCommerce HTML Template
    Version: 1.2
    
-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
    1. Template default CSS
    2. Animation CSS
    3. Header style
    4. Slider style
    5. Banner style
    6. Section title style
    7. Product style
    8. Testimonial style
    9. Video style
    10. Blog style
    11. Footer style
    12. Instafeed style
    13. Others style
    14. Newsletter style
    15. Services style
    16. Brand logo style
    17. Subscribe style
    18. About us style
    19. Contact us style
    20. Breadcrumb style
    21. Sidebar style
    22. Shop style
    23. Faq style
    24. Product details style
    25. Order tracking style
    26. Cart style
    27. Checkout style
    28. Compare style
    29. My account style
    30. Login register style
    31. Wishlist style
    
-----------------------------------------------------------------------------------*/
@import 'variabls';
@import 'common';
@import 'animation';
@import 'header';
@import 'slider';
@import 'banner';
@import 'section-title';
@import 'product';
@import 'testimonial';
@import 'video';
@import 'blog';
@import 'footer';
@import 'instafeed';
@import 'others';
@import 'newsletter';
@import 'services';
@import 'brand-logo';
@import 'subscribe';
@import 'about-us';
@import 'contact-us';
@import 'breadcrumb';
@import 'sidebar';
@import 'shop';
@import 'faq'; 
@import 'product-details'; 
@import 'order-tracking'; 
@import 'cart'; 
@import 'checkout'; 
@import 'compare'; 
@import 'my-account'; 
@import 'login-register'; 
@import 'wishlist'; 






