
/*-------- 18. About us style ---------*/


.about-us-area {
    position: relative;
    @media #{$xs-layout} {
        &.pt-135 {
            padding-top: 55px;
        }
    }
}

.about-us-img {
    height: 100%;
    padding: 0;
    position: absolute;
    top: 0;
    width: 50%;
    z-index: 9;
    @media #{$md-layout} {
        position: relative;
        width: 100%;
    }
    @media #{$xs-layout} {
        position: relative;
        width: 100%;
    }
    > img {
        height: 100%;
        transition: all 0.3s linear 0s;
        will-change: transform, top;
        z-index: 9;
        position: relative;
        width: 100%;
        object-fit: cover;
    }
    .about-us-position-img {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 9;
        padding: 10px 10px;
        transform: translateY(-50%);
        img {
            width: 304px;
            @media #{$lg-layout} {
                width: 200px;
            }
            @media #{$xs-layout} {
                width: 120px;
            }
        }
    }
}

.about-us-content {
    padding: 170px 145px 174px 145px;
    @media #{$xx-layout} {
        padding: 100px 100px 104px 100px;
    }
    @media #{$xl-layout} {
        padding: 80px 50px 84px 50px;
    }
    @media #{$lg-layout} {
        padding: 50px 20px 60px 20px;
    }
    @media #{$md-layout} {
        padding: 50px 50px 70px 50px;
    }
    @media #{$xs-layout} {
        padding: 50px 20px 60px 20px;
    }
    h2 {
        font-size: 80px;
        font-weight: 500;
        line-height: 1.2em;
        letter-spacing: -3px;
        font-family: $noto;
        margin: 0;
        @media #{$xl-layout} {
            font-size: 70px;
            letter-spacing: -2px;
        }
        @media #{$lg-layout} {
            font-size: 55px;
            letter-spacing: -1px;
            line-height: 1.1em;
        }
        @media #{$md-layout} {
            font-size: 60px;
            letter-spacing: 0px;
            line-height: 1.1em;
        }
        @media #{$xs-layout} {
            font-size: 35px;
            letter-spacing: 0px;
            line-height: 1.1em;
        }
    }
    P {
        font-size: 18px;
        line-height: 36px;
        color: #6D6D6D;
        margin: 48px 0 33px;
        @media #{$lg-layout} {
            font-size: 16px;
            line-height: 34px;
            margin: 28px 0 23px;
        }
        @media #{$md-layout} {
            margin: 28px 0 23px;
        }
        @media #{$xs-layout} {
            font-size: 15px;
            line-height: 30px;
            margin: 28px 0 23px;
        }
    }
}
.team-wrap {
    .team-img {
        margin: 0 0 25px;
        transition: all .3s ease 0s;
        @media #{$md-layout} {
            margin: 0 0 15px;
        }
        @media #{$xs-layout} {
            margin: 0 0 10px;
        }
        img {
            max-width: 100%;
        }
        &:hover {
            transform: translateY(-8px);
        }
    }
    .team-content {
        h3 {
            font-size: 24px;
            font-family: $playfair;
            margin: 0 0 8px;
            @media #{$xs-layout} {
                font-size: 20px;
                margin: 0 0 5px;
            }
        }
        span {
            font-size: 16px;
            color: #444;
            display: block;
            font-family: $playfair;
        }
    }
}

.about-us-content-2 {
    padding: 240px 0 330px 15px;
    @media #{$xx-layout} {
        padding: 180px 0 270px 15px;
    }
    @media #{$xl-layout} {
        padding: 180px 0 270px 15px;
    }
    @media #{$lg-layout} {
        padding: 110px 0 200px 15px;
    }
    @media #{$md-layout} {
        padding: 100px 0 200px 15px;
    }
    @media #{$xs-layout} {
        padding: 80px 0 160px 10px;
    }
    > h2 {
        color: $white;
        font-family: $montserrat;
        font-size: 26px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 15px;
        display: block;
        @media #{$xs-layout} {
            font-size: 22px;
            letter-spacing: 7px;
        }
    }
    h1 {
        color: $white;
        font-family: $montserrat;
        font-size: 100px;
        font-weight: 900;
        text-transform: uppercase;
        font-style: italic;
        line-height: 1.2em;
        letter-spacing: 3px;
        margin: 25px 0 46px;
        @media #{$xx-layout} {
            font-size: 70px;
        }
        @media #{$xl-layout} {
            font-size: 60px;
        }
        @media #{$lg-layout} {
            font-size: 50px;
        }
        @media #{$md-layout} {
            font-size: 40px;
            margin: 20px 0 30px;
        }
        @media #{$xs-layout} {
            font-size: 22px;
            line-height: 1.2em;
            margin: 12px 0 20px;
        }
    }
}

.about-us-content-3 {
    padding: 348px 0 358px 16px;
    @media #{$xx-layout} {
        padding: 248px 0 258px 16px;
    }
    @media #{$xl-layout} {
        padding: 248px 0 258px 16px;
    }
    @media #{$lg-layout} {
        padding: 148px 0 158px 16px;
    }
    @media #{$md-layout} {
        padding: 170px 0 170px 16px;
    }
    @media #{$xs-layout} {
        padding: 80px 0 85px 0px;
    }
    h3 {
        color: $white;
        font-family: $montserrat;
        font-size: 46px;
        font-weight: 900;
        text-transform: uppercase;
        font-style: italic;
        letter-spacing: 2px;
        margin: 0;
        @media #{$xs-layout} {
            font-size: 22px;
            letter-spacing: 1px;
        }
    }
    h1 {
        color: $white;
        font-family: $montserrat;
        font-size: 150px;
        font-weight: 900;
        text-transform: uppercase;
        font-style: italic;
        line-height: 115px;
        letter-spacing: 4px;
        margin: 28px 0 48px;
        @media #{$lg-layout} {
            font-size: 140px;
        }
        @media #{$md-layout} {
            font-size: 106px;
            margin: 15px 0 35px;
        }
        @media #{$xs-layout} {
            font-size: 50px;
            letter-spacing: 4px;
            margin: 15px 0 30px;
            line-height: 40px;
        }
    }
}
.about-us-content-4 {
    p {
        color: #6d6d6d;
        font-size: 17px;
        line-height: 34px;
        margin: 0 auto 23px;
        width: 59%;
        @media #{$lg-layout} {
            width: 95%;
        }
        @media #{$md-layout} {
            width: 98%;
            margin: 0 auto 15px;
        }
        @media #{$xs-layout} {
            font-size: 15px;
            width: 95%;
            margin: 0 auto 15px;
            line-height: 29px;
        }
    }
}

.btn-style-12 {
    position: relative;
    &:before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 1px;
        width: 100%;
        background-color: #D8D8D8;
        z-index: 8;
    }
    a {
        display: inline-block;
        font-size: 18px;
        color: #181818;
        background-color: $white;
        padding: 0 20px;
        position: relative;
        z-index: 9;
        &:hover {
            color: $theme-color-pink;
        }
    }
    @media #{$lg-layout} {
        &.mt-85 {
            margin-top: 50px;
        }
    }
    @media #{$md-layout} {
        &.mt-85 {
            margin-top: 40px;
        }
    }
    @media #{$xs-layout} {
        &.mt-85 {
            margin-top: 20px;
        }
    }
}

.about-us-ptb {
    padding: 120px 0 120px;
    @media #{$md-layout} {
        padding: 80px 0 80px;
    }
    @media #{$xs-layout} {
        padding: 60px 0 60px;
    }
}

.about-us-content-5 {
    @media #{$md-layout} {
        margin-bottom: 50px;
    }
    @media #{$xs-layout} {
        margin-bottom: 30px;
    }
    h2 {
        font-size: 34px;
        letter-spacing: 2px;
        margin: 0 0 25px;
        text-transform: uppercase;
        @media #{$md-layout} {
            margin: 0 0 15px;
        }
        @media #{$xs-layout} {
            margin: 0 0 10px;
            font-size: 28px;
            letter-spacing: 1px;
        }
    }
    p {
        font-size: 18px;
        line-height: 36px;
        width: 85%;
        @media #{$lg-layout} {
            font-size: 17px;
            line-height: 35px;
            width: 100%;
        }
        @media #{$md-layout} {
            font-size: 17px;
            line-height: 35px;
            width: 100%;
        }
        @media #{$xs-layout} {
            font-size: 15px;
            line-height: 29px;
            width: 100%;
        }
    }
}

.about-video {
    img {
        width: 100%;
    }
    .about-video-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        text-align: center;
        a {
            display: inline-block;
            svg {
                color: #fff;
            }
        }
    }
}

.team-wrap-2 {
    position: relative;
    .team-img-2 {
        overflow: hidden;
        a {
            display: block;
            img {
                width: 100%;
                transform: scale(1);
                transition: all .35s ease 0s;
            }
        }
    }
    .team-content-2 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        transition: all .35s ease 0s;
        z-index: 9;
        h4 {
            margin: 0;
            font-size: 20px;
            color: $white;
            transform: translate(0,50px);
            transition: all .35s ease 0s;
            @media #{$lg-layout} {
                font-size: 18px;
            }
            @media #{$xs-layout} {
                font-size: 18px;
            }
            a {
                color: $white;
                &:hover {
                    color: $theme-color-yellow;
                }
            }
        }
        span {
            display: block;
            color: #fff;
            font-size: 14px;
            font-style: italic;
            font-family: $playfair;
            transform: translate(0,60px);
            transition: all .45s ease 0s;
            margin: 8px 0 0;
            letter-spacing: 1px;
            @media #{$xs-layout} {
                letter-spacing: 0px;
            }
        }
    }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
        background: #000;
        transition: all .35s ease 0s;
        pointer-events: none;
    }
    &:hover {
        &:before {
            opacity: .5;
        }
        .team-img-2 {
            a {
                img {
                    transform: scale(1.1);
                }
            }
        }
        .team-content-2 {
            opacity: 1;
            visibility: visible;
            h4 {
                transform: translate(0,0px);
            }
            span {
                transform: translate(0,0px);
            }
        }
    }
}

@media #{$md-layout} {
    .team-area {
        &.pb-90 {
            padding-bottom: 50px;
        }
    }
}

@media #{$xs-layout} {
    .team-area {
        &.pt-120 {
            padding-top: 60px;
        }
        &.pb-90 {
            padding-bottom: 30px;
        }
    }
}




