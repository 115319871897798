
/*-------- 15. Services style ---------*/

.service-wrap {
    .service-icon {
        i {
            color: #EAB700;
            font-size: 30px;
        }
        &.service-icon-red {
            i {
                color: $theme-color-red;
            }
        }
    }
    h3 {
        font-size: 16px;
        font-weight: 600;
        margin: 6px 0 9px;
    }
    p {
        font-size: 14px;
        color: #6D6D6D;
        line-height: 2em;
        margin: 0 auto;
        width: 87%;
        @media #{$xx-layout} {
            width: 98%;
        }
        @media #{$xl-layout} {
            width: 90%;
        }
        @media #{$lg-layout} {
            width: 100%;
        }
        @media #{$md-layout} {
            width: 73%;
        }
        @media #{$xs-layout} {
            width: 100%;
        }
    }
}

.service-wrap-2 {
    .service-icon-2 {
        i {
            font-size: 46px;
            @media #{$lg-layout} {
                font-size: 40px;
            }
        }
        &.font-size-dec {
            i {
                font-size: 30px;
            }
        }
        &.icon-roted {
            i {
                transform: rotateY(190deg);
                display: inline-block;
            }
        }
    }
    h3 {
        font-size: 18px;
        font-weight: 500;
        font-family: $noto;
        margin: 12px 0 13px;
        &.playfair {
            font-family: $playfair;
            font-weight: 400;
        }
    }
    p {
        color: #777;
        line-height: 25px;
        width: 80%;
        margin: 0 auto;
        @media #{$xx-layout} {
            width: 98%;
        }
        @media #{$xl-layout} {
            width: 100%;
        }
        @media #{$lg-layout} {
            width: 100%;
        }
        @media #{$md-layout} {
            width: 100%;
        }
        @media #{$xs-layout} {
            width: 100%;
        }
    }
}

.service-3-mrg {
	margin: 0 30px;
    @media #{$xs-layout} {
        margin: 0 0px;
    }
}

.service-wrap-3 {
    .service-3-icon {
        i {
            display: inline-block;
            color: #181818;
            font-size: 33px;
            width: 80px;
            height: 80px;
            line-height: 80px;
            border-radius: 100%;
            border: 1px solid #818a91;
            transition: all .3s ease 0s;
            i {
                line-height: 80px;
            }
            @media #{$lg-layout} {
                font-size: 30px;
                width: 75px;
                height: 75px;
                line-height: 75px;
            }
            @media #{$md-layout} {
                font-size: 30px;
                width: 80px;
                height: 80px;
                line-height: 80px;
            }
            @media #{$xs-layout} {
                font-size: 25px;
                width: 70px;
                height: 70px;
                line-height: 70px;
            }
        }
    }
    h3 {
        font-size: 20px;
        font-weight: 500;
        margin: 30px 0 10px;
        @media #{$md-layout} {
            margin: 20px 0 10px;
        }
        @media #{$xs-layout} {
            margin: 20px 0 10px;
        }
    }
    p {
        font-size: 16px;
        line-height: 28px; 
        width: 90%;
        margin: 0 auto;
        @media #{$xl-layout} {
            width: 100%; 
            font-size: 15px;
        }
        @media #{$lg-layout} {
            width: 100%; 
            font-size: 15px;
        }
        @media #{$md-layout} {
            font-size: 15px;
            line-height: 28px;
            width: 100%;
        }
        @media #{$xs-layout} {
            font-size: 15px;
            line-height: 28px;
            width: 100%;
        }
    }
    &:hover {
        .service-3-icon {
            i {
                color: #fff;
                border: 1px solid #181818;
                background-color: #181818;
            }
        }
    }
}

@media #{$md-layout} {
    .service-area {
        &.pb-75 {
            padding-bottom: 40px;
        }
        &.pb-85 {
            padding-bottom: 50px;
        }
    }
}
@media #{$xs-layout} {
    .service-area {
        &.pt-115 {
            padding-top: 60px;
        }
        &.pt-110 {
            padding-top: 55px;
        }
        &.pb-80 {
            padding-bottom: 40px;
        }
        &.pb-85 {
            padding-bottom: 25px;
        }
        &.pb-75 {
            padding-bottom: 20px;
        }
    }
}



