
/*-------- 11. Footer style ---------*/


.footer-column {
	position: relative;
	padding-left: 15px;
	padding-right: 15px;
    width: 100%;
    &.footer-width-30 {
        flex: 0 0 25%;
        max-width: 25%;
        @media #{$lg-layout} {
            flex: 0 0 25%;
            max-width: 25%;
        }
        @media #{$md-layout} {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    &.footer-width-14 {
        flex: 0 0 15%;
        max-width: 15%;
        @media #{$lg-layout} {
            flex: 0 0 14%;
            max-width: 14%;
        }
        @media #{$md-layout} {
            flex: 0 0 25%;
            max-width: 25%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width: 100%;
        }
        @media #{$sm-layout} {
            flex: 0 0 26%;
            max-width: 26%;
        }
    }
    &.footer-width-16 {
        flex: 0 0 18%;
        max-width: 18%;
        @media #{$lg-layout} {
            flex: 0 0 18%;
            max-width: 18%;
        }
        @media #{$md-layout} {
            flex: 0 0 25%;
            max-width: 25%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width: 100%;
        }
        @media #{$sm-layout} {
            flex: 0 0 37%;
            max-width: 37%;
        }
    }
    &.footer-width-15 {
        flex: 0 0 17%;
        max-width: 17%;
        @media #{$md-layout} {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width: 100%;
        }
        @media #{$sm-layout} {
            flex: 0 0 37%;
            max-width: 37%;
        }
    }
    &.footer-width-25 {
        flex: 0 0 25%;
        max-width: 25%;
        @media #{$lg-layout} {
            flex: 0 0 26%;
            max-width: 26%;
        }
        @media #{$md-layout} {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    &.footer-width-25-2 {
        flex: 0 0 21%;
        max-width: 21%;
        @media #{$xx-layout} {
            flex: 0 0 23%;
            max-width: 23%;
        }
        @media #{$xl-layout} {
            flex: 0 0 23%;
            max-width: 23%;
        }
        @media #{$lg-layout} {
            flex: 0 0 30%;
            max-width: 30%;
        }
        @media #{$md-layout} {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width: 100%;
        }
        @media #{$sm-layout} {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    &.footer-width-13 {
        flex: 0 0 15%;
        max-width: 15%;
        @media #{$lg-layout} {
            flex: 0 0 25%;
            max-width: 25%;
        }
        @media #{$md-layout} {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width: 100%;
        }
        @media #{$sm-layout} {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    &.footer-width-14-2 {
        flex: 0 0 16%;
        max-width: 16%;
        @media #{$lg-layout} {
            flex: 0 0 25%;
            max-width: 25%;
        }
        @media #{$md-layout} {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width: 100%;
        }
        @media #{$sm-layout} {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    &.footer-width-18 {
        flex: 0 0 20%;
        max-width: 20%;
        @media #{$xx-layout} {
            flex: 0 0 18%;
            max-width: 18%;
        }
        @media #{$xl-layout} {
            flex: 0 0 18%;
            max-width: 18%;
        }
        @media #{$lg-layout} {
            flex: 0 0 20%;
            max-width: 20%;
        }
        @media #{$md-layout} {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width: 100%;
        }
        @media #{$sm-layout} {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    &.footer-width-28 {
        flex: 0 0 28%;
        max-width: 28%;
        @media #{$lg-layout} {
            flex: 0 0 100%;
            max-width: 100%;
        }
        @media #{$md-layout} {
            flex: 0 0 100%;
            max-width: 100%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    &.footer-width-30-2 {
        flex: 0 0 30%;
        max-width: 30%;
        @media #{$md-layout} {
            flex: 0 0 33.333%;
            max-width: 33.333%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width: 100%;
        }
        @media #{$xs-layout} {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    &.footer-width-14-3 {
        flex: 0 0 14%;
        max-width: 14%;
        @media #{$md-layout} {
            flex: 0 0 33.333%;
            max-width: 33.333%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width: 100%;
        }
        @media #{$sm-layout} {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    &.footer-width-16-2 {
        flex: 0 0 16%;
        max-width: 16%;
        @media #{$md-layout} {
            flex: 0 0 33.333%;
            max-width: 33.333%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width: 100%;
        }
        @media #{$sm-layout} {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    &.footer-width-15-2 {
        flex: 0 0 15%;
        max-width: 15%;
        @media #{$md-layout} {
            flex: 0 0 33.333%;
            max-width: 33.333%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width: 100%;
        }
        @media #{$sm-layout} {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    &.footer-width-25-3 {
        flex: 0 0 25%;
        max-width: 25%;
        @media #{$md-layout} {
            flex: 0 0 66%;
            max-width: 66%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width: 100%;
        }
        @media #{$sm-layout} {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    &.footer-width-28-2 {
        flex: 0 0 28%;
        max-width: 28%; 
        @media #{$md-layout} {
            flex: 0 0 33.333%;
            max-width:33.333%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width:100%;
        }
        @media #{$sm-layout} {
            flex: 0 0 50%;
            max-width:50%;
        }
    }
    &.footer-width-16-3 {
        flex: 0 0 16%;
        max-width: 16%;
        @media #{$md-layout} {
            flex: 0 0 33.333%;
            max-width: 33.333%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width:100%;
        }
        @media #{$sm-layout} {
            flex: 0 0 50%;
            max-width:50%;
        }
    }
    &.footer-width-20 {
        flex: 0 0 20%;
        max-width: 20%;
        @media #{$md-layout} {
            flex: 0 0 33.333%;
            max-width: 33.333%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width:100%;
        }
        @media #{$sm-layout} {
            flex: 0 0 50%;
            max-width:50%;
        }
    }
    &.footer-width-17 {
        flex: 0 0 17%;
        max-width: 17%;
        @media #{$md-layout} {
            flex: 0 0 33.333%;
            max-width: 33.333%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width:100%;
        }
        @media #{$sm-layout} {
            flex: 0 0 50%;
            max-width:50%;
        }
    }
    &.footer-width-18-2 {
        flex: 0 0 18%;
        max-width: 18%;
        @media #{$md-layout} {
            flex: 0 0 66%;
            max-width: 66%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width:100%;
        }
        @media #{$sm-layout} {
            flex: 0 0 50%;
            max-width:50%;
        }
    }
}

.logo-width-2 {
    a {
        display: block;
        img {
            width: 136px;
        }
    }
}

.footer-about {
    margin-right: 80px;
    @media #{$xx-layout} {
        margin-right: 50px;
    }
    @media #{$xl-layout} {
        margin-right: 50px;
    }
    @media #{$lg-layout} {
        margin-right: 0px;
    }
    @media #{$md-layout} {
        margin-right: 0px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
    }
    p {
        color: #A6A6A6;
        font-size: 18px;
        line-height: 1.8em;
        font-family: $playfair;
        margin: 24px 0 24px;
    }
}

.footer-widget {
    .footer-title {
        margin: 0 0 35px;
        @media #{$xs-layout} {
            margin: 0 0 25px;
        }
        h3 {
            color: $white;
            margin: 0;
            line-height: 1;
            font-size: 18px;
            @media #{$lg-layout} {
                font-size: 16px;
            }
            @media #{$md-layout} {
                font-size: 17px;
            }
        }
    }
    .footer-title-2 {
        margin-bottom: 30px;
        @media #{$lg-layout} {
            margin-bottom: 20px;
        }
        h3 {
            font-size: 16px;
            font-weight: 600;
            color: #000;
            margin: 0;
        }
    }
    .footer-title-3 {
        margin-bottom: 30px;
        @media #{$xs-layout} {
            margin-bottom: 15px;
        }
        h3 {
            font-size: 14px;
            font-weight: bold;
            color: $white;
            text-transform: uppercase;
            margin: 0;
            letter-spacing: .5px;
        }
    }
    .footer-title-4 {
        margin-bottom: 22px;
        h3 {
            font-size: 18px;
            font-weight: 900;
            line-height: 1.4em;
            font-family: $noto;
            @media #{$xs-layout} {
                font-size: 15px;
            }
            @media #{$sm-layout} {
                font-size: 18px;
            }
        }
    }
    .footer-title-5 {
        margin-bottom: 22px;
        @media #{$xs-layout} {
            margin-bottom: 15px;
        }
        h3 {
            font-size: 18px;
            font-weight: 900;
            line-height: 1.4em;
            font-family: $playfair;
        }
    }
    .footer-title-6 {
        margin-bottom: 30px;
        @media #{$xs-layout} {
            margin-bottom: 20px;
        }
        h3 {
            color: $white;
            font-family: $montserrat;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1.4em;
            margin: 0;
            @media #{$md-layout} {
                font-size: 13px;
            }
        }
    }
    .footer-title-7 {
        margin-bottom: 20px;
        h3 {
            font-family: $pacifico;
            font-size: 24px;
            line-height: 1.4em;
            margin: 0;
            @media #{$esm-layout} {
                font-size: 21px;
            }
        }
    }
    .footer-title-8 {
        margin-bottom: 30px;
        h3 {
            font-family: $playfair;
            font-size: 16px;
            font-weight: bold;
            margin: 0;
        }
    }
    .footer-list {
        ul {
            li {
                padding-bottom: 15px;
                @media #{$xs-layout} {
                    padding-bottom: 10px;
                }
                &:last-child {
                    padding-bottom: 0;
                }
                a {
                    color: #B4B4B4;
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }
    .footer-list-2 {
        ul {
            li {
                padding-bottom: 14px;
                color: #6D6D6D;
                display: flex;
                @media #{$xs-layout} {
                    padding-bottom: 10px;
                }
                &:last-child {
                    padding-bottom: 0;
                }
                a {
                    color: #6D6D6D;
                    display: flex;
                    i {
                        font-size: 22px;
                        position: relative;
                        top: 4px;
                        margin-right: 20px;
                        transition: all .3s ease 0s;
                        display: none;
                    }
                    &:hover {
                        color: #181818;
                        i {
                            color: $theme-color-yellow-2;
                        }
                        i {
                            &.green {
                                color: $theme-color-green;
                            }
                        }
                    }
                }
                i {
                    font-size: 22px;
                    margin-right: 20px;
                    display: none;
                }
            }
        }
        &.footer-contect {
            ul {
                li {
                    padding-bottom: 22px;
                    &:last-child {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
    .footer-list-3 {
        ul {
            li {
                padding-bottom: 13px;
                display: block;
                color: #6D6D6D;
                font-size: 14px;
                @media #{$md-layout} {
                    padding-bottom: 10px;
                }
                @media #{$xs-layout} {
                    padding-bottom: 10px; 
                }
                &:last-child {
                    padding-bottom: 0;
                }
                a {
                    color: #6D6D6D;
                    font-size: 14px;
                    display: block;
                    &:hover {
                        color: #181818;
                    }
                }
            }
        }
    }
}

.contact-page-map {
    #contact-map {
        height: 255px;
    }
}

.subscribe-form {
    form {
        .mc-form {
            position: relative;
            input {
                background-color: transparent;
                border: 1px solid rgba(255,255,255,.2);
                height: 50px;
                color: #fff;
                padding: 2px 112px 2px 15px;
                &.black {
                    border: none;
                    background-color: #363636;
                }
                &.gray {
                    border: none;
                    background-color: rgba(83,83,83,.5);
                }
                &::-moz-input-placeholder {
                    color: #fff;
                    opacity: 1;
                }
                &::-webkit-input-placeholder {
                    color: #fff;
                    opacity: 1;
                }
            }
            .mc-news {
                display: none;
            }
            .clear {
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
                input {
                    width: auto;
                    font-size: 16px;
                    font-weight: bold;
                    color: #fff;
                    border: none;
                    padding: 0px;
                }
            }
        }
    }
}

.copyright {
    p {
        color: #ddd;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        a {
            color: #ddd;
            &:hover {
                color: #fff;
            }
        }
    }
}
.copyright-2 {
    p {
        color: #848484;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        a {
            color: #848484;
            &:hover {
                color: $theme-color-yellow-2;
            }
        }
    }
}

.footer-two-area {
    display: block;
    width: 100%;
    overflow: hidden;
}

.footer-about-2 {
    margin-top: 50px;
    @media #{$xs-layout} {
        margin-top: 0px;
    }
}

.footer-about-4 {
    p {
        line-height: 1.8em;
        margin: 23px 0 0px;
        @media #{$lg-layout} {
            width: 90%
        }
        @media #{$md-layout} {
            width: 80%
        }
    }
}

.footer-logo-2 {
    a {
        img {
            width: 230px;
            @media #{$xx-layout} {
                width: 210px;
            }
            @media #{$xl-layout} {
                width: 210px;
            }
            @media #{$lg-layout} {
                width: 210px;
            }
            @media #{$md-layout} {
                width: 200px;
            }
            @media #{$xs-layout} {
                width: 220px;
            }
        }
    }
}
.footer-logo-3 {
    a {
        img {
            width: 188px;
            @media #{$xs-layout} {
                width: 150px;
            }
        }
    }
}

.social-icon-style-3 {
    a {
        font-size: 16px;
        width: 34px;
        height: 34px;
        line-height: 34px;
        border-radius: 100%;
        display: inline-block;
        border: 1px solid #E1E1E1;
        text-align: center;
        color: #777;
        margin-right: 13px;
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            color: #1B1A1A;
        }
    }
}

.footer-leather-logo {
    a {
        display: inline-block;
        img {
            width: 165px;
        }
    }
}

.footer-logo {
    p {
        color: #6D6D6D;
        font-size: 18px;
        font-weight: 500;
        font-family: $noto;
        margin: 16px 0 0;
    }
}
.footer-cosmetic-logo {
    margin-top: 60px;
    @media #{$md-layout} {
        margin-top: 0px;
    }
    @media #{$xs-layout} {
        margin-top: 0px;
    }
    a {
        display: inline-block;
        img {
            width: 188px;
        }
    }
    p {
        color: #6D6D6D;
        font-size: 18px;
        font-family: $playfair;
        margin: 8px 0 0;
    }
}

.footer-bottom-ptb-1 {
    padding: 15px 0;
}

@media #{$md-layout} {
    .footer-top {
        &.pb-65 {
            padding-bottom: 25px;
        }
        &.pb-70 {
            padding-bottom: 30px;
        }
    }
}

@media #{$xs-layout} {
    .footer-area {
        &.pt-100 {
            padding-top: 60px;
        }
        &.pt-80 {
            padding-top: 60px;
        }
        &.pt-75 {
            padding-top: 60px;
        }
    }
    .footer-top {
        &.pb-65 {
            padding-bottom: 10px;
        }
        &.pb-70 {
            padding-bottom: 15px;
        }
        &.pb-35 {
            padding-bottom: 15px;
        }
        &.pb-40 {
            padding-bottom: 20px;
        }
    }
}


