/*-------- 20. Breadcrumb style ---------*/

.breadcrumb-ptb-1 {
	padding: 51px 0 55px;
    @media #{$xs-layout} {
        padding: 41px 0 45px;
    }
}
.breadcrumb-ptb-2 {
	padding: 135px 0 135px;
    @media #{$xs-layout} {
        padding: 41px 0 45px; 
    }
}

.breadcrumb-ptb-3 {
	padding: 235px 0 160px;
    @media #{$xx-layout} {
        padding: 200px 0 125px;
    }
    @media #{$xl-layout} {
        padding: 200px 0 125px;
    }
    @media #{$lg-layout} {
        padding: 175px 0 100px;
    }
    @media #{$md-layout} {
        padding: 155px 0 100px;
    }
    @media #{$xs-layout} {
        padding: 105px 0 52px;
    }
}

.breadcrumb-bg-1 {
	background-image: url(../../assets/images/bg/breadcrumb-bg-1.jpg);
	background-repeat: repeat;
	background-position: left top;
	background-size: cover;
	background-color: transparent;
}

.breadcrumb-bg-2 {
	background-image: url(../../assets/images/product/shop-collection-8.jpg);
	background-repeat: repeat;
	background-position: left top;
	background-size: cover;
	background-color: transparent;
}

.breadcrumb-content {
    position: relative;
    z-index: 9;
    .breadcrumb-title {
        margin: 0 0 10px;
        @media #{$xs-layout} {
            margin: 0 0 5px;
        }
        h2 {
            font-size: 32px;
            letter-spacing: 2px;
            color: #181818;
            margin: 0;
            text-transform: uppercase;
            @media #{$xs-layout} {
                font-size: 24px;
                letter-spacing: 1;
            }
        }
    }
    ul {
        li {
            display: inline-block;
            margin: 0 18px 0 0;
            text-transform: capitalize;
            color: #1a1a1a;
            position: relative;
            font-size: 16px;
            &:last-child {
                margin: 0;
            }
            &::before {
                position: absolute;
                width: 13px;
                height: 1px;
                background-color: #6d6d6d;
                content: "";
                right: -18px;
                top: 13px;
                z-index: 99;
                transform: rotate(106deg);
            }
            &:last-child::before {
                display: none;
            }
            a {
                text-transform: capitalize;
                color: #6d6d6d;
                &:hover {
                    color: $theme-color-yellow;
                }
            }
        }
    }
    &.breadcrumb-content-white {
        .breadcrumb-title {
            h2 {
                color: #fff;
            }
        }
        ul {
            li {
                color: #fff;
                &::before {
                    background-color: #fff;
                }
                a {
                    color: #fff;
                    &:hover {
                        color: $theme-color-yellow;
                    }
                }
            }
        }
    }
}

