
/*-------- 14. Newsletter style ---------*/


.b-modal.__b-popup1__ {
	background-color: #333 !important;
	opacity: .9 !important;
}
.newletter-popup {
	width: 100%;
    background: #fff none repeat scroll 0 0;
    left: 0;
    margin: auto;
    position: fixed !important;
    right: 0;
    top: 22% !important;
    width: 770px;
    display: none;
    @media #{$xx-layout} {
        top: 15% !important;
    }
    @media #{$xl-layout} {
        top: 15% !important;
    }
    @media #{$lg-layout} {
        width: 800px;
        top: 15% !important;
    }
    @media #{$md-layout} {
        width: 700px;
        top: 15% !important;
    }
    @media #{$xs-layout} {
        width: 290px;
        top: 10% !important;
        height: 300px !important;
        overflow-y: auto;
    }
    @media #{$sm-layout} {
        width: 400px;
        height: 400px !important;
        overflow-y: auto;
    }
    > #popup2 {
        position: absolute;
        right: 20px;
        top: 20px;
        color: #181818;
        font-size: 24px;
        cursor: pointer;
        transition: all 250ms ease-out;
        @media #{$xs-layout} {
            left: 20px;
            right: auto;
            top: 20px;
            font-size: 18px;
            color: #fff;
        }
        &:hover {
            transform: rotate(90deg);
            color: $theme-color-yellow;
        }
    }
    .popup-wrapper-all {
        display: flex;
        overflow: hidden;
        @media #{$xs-layout} {
            display: block;
        }
        .popup-subscribe-content {
            flex: 0 0 65%;
            padding: 65px 50px 30px 50px;
            @media #{$lg-layout} {
                padding: 50px 30px 33px 30px;
            }
            @media #{$md-layout} {
                padding: 20px 30px 20px 30px;
            }
            @media #{$xs-layout} {
                padding: 15px 15px 15px 15px;
                flex: 0 0 100%;
            }
            h3 {
                font-size: 24px;
                color: #181818;
                font-weight: 600;
                margin: 0;
                line-height: 1.2;
                @media #{$xs-layout} {
                    font-size: 18px;
                }
            }
            p {
                font-size: 14px;
                color: #181818;
                margin: 20px 0 40px;
                line-height: 1.375em;
                @media #{$xs-layout} {
                    margin: 12px 0 12px;
                }
            }
            .subscribe-newletter-popup {
                form {
                    .mc-form-2 {
                        input {
                            background-color: transparent;
                            border: none;
                            padding: 2px 0;
                            border-bottom: 2px solid #D8D8D8;
                            color: #6D6D6D;
                        }
                    }
                    .mc-news-2 {
                        display: none;
                    }
                    .clear-2 {
                        margin: 20px 0 0;
                        input {
                            background-color: #181818;
                            color: #fff;
                            border: none;
                            height: auto;
                            padding: 13px 10px 17px;
                            &:hover {
                                background-color: $theme-color-yellow;
                            }
                        }
                    }
                }
            }
            .dont-show {
                display: flex;
                align-items: center;
                input {
                    width: auto;
                    height: auto;
                }
                label {
                    font-size: 14px;
                    color: #6D6D6D;
                    margin: 0 0 0 10px;
                }
            }
        }
        .popup-image {
            flex: 0 0 35%;
            @media #{$xs-layout} {
                display: block;
                flex: 0 0 100%;
            }
            @media #{$sm-layout} {
                display: block;
            }
            img {
                width: 100%;
            }
        }
    }
}

