
/*-------- 3. Header style ---------*/


.header-small-device {
    display: none;
    @media #{$md-layout} {
        display: block;
    }
    @media #{$xs-layout} {
        display: block;
    }
    &.small-device-ptb-1 {
        padding: 20px 0 0;
    }
    &.small-device-ptb-2 {
        padding: 30px 0 30px;
    }
    &.small-device-ptb-3 {
        padding: 20px 0 20px;
    }
    .small-device-categori-wrap {
        padding: 20px 0 0;
        @media #{$xs-layout} {
            .header-offer-cart-wrap {
                padding: 0px 0 20px;
                @media #{$sm-layout} {
                    padding: 12px 0;
                    justify-content: flex-end;
                }
            }
        }
    }
}

.header-large-device {
    display: block;
    @media #{$md-layout} {
        display: none;
    }
    @media #{$xs-layout} {
        display: none;
    }
}

.header-ptb-1 {
    padding: 50px 0;
    @media #{$xs-layout} {
        padding: 30px 0;
    }
}

.logo-width-1 , 
.mobile-logo-width {
    a {
        display: inline-block;
        img {
            width: 150px;
            @media #{$md-layout} {
                width: 120px;
            }
            @media #{$xs-layout} {
                width: 100px;
            }
        }
    }
}

.logo-width-4 {
    a {
        display: inline-block;
        img {
            width: 162px;
            @media #{$lg-layout} {
                width: 130px;
            }
            @media #{$xs-layout} {
                width: 120px;
            }
        }
    }
}

.logo-width-3 {
    a {
        display: inline-block;
        img {
            width: 300px;
            @media #{$xx-layout} {
                width: 180px;
            }
            @media #{$xl-layout} {
                width: 180px;
            }
            @media #{$lg-layout} {
                width: 150px;
            }
        }
    }
}

.header-action-wrap {
    &.header-action-flex {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: -5px;
        @media #{$md-layout} {
            margin-bottom: -8px;
        }
        @media #{$xs-layout} {
            margin-bottom: -8px;
        }
    }
    &.header-action-mrg-1 {
        .same-style {
            margin-left: 40px;
            @media #{$lg-layout} {
                margin-left: 25px;
            }
            @media #{$xs-layout} {
                margin-left: 23px;
            }
            &:first-child {
                margin-left: 0;
            }
        }
    }
    &.header-action-mrg-2 {
        .same-style {
            margin-left: 10px;
            &:first-child {
                margin-left: 0;
            }
        }
    }
    .same-style {
        > a {
            color: #fff;
            font-size: 20px;
            display: inline-block;
            position: relative;
            &:hover {
                color: $theme-color-yellow;
                &.pink {
                    color: $theme-color-pink;
                }
                &.green {
                    color: $theme-color-green;
                }
                &.green-2 {
                    color: $theme-color-green-2;
                }
                &.yellow {
                    color: $theme-color-yellow;
                }
            }
            @media #{$md-layout} {
                &.same-style-hvr-red {
                    &:hover {
                        color: $theme-color-red;
                    }
                }
            }
            @media #{$xs-layout} {
                &.same-style-hvr-red {
                    &:hover {
                        color: $theme-color-red;
                    }
                }
            }
            span {
                position: absolute;
                top: -14px;
                font-size: 10px;
                width: 18px;
                height: 18px;
                display: inline-block;
                line-height: 18px;
                color: #fff;
                text-align: center;
                right: -6px;
                border-radius: 10px;
                &.yellow {
                    background-color: $theme-color-yellow;
                }
                &.red {
                    background-color: $theme-color-red;
                }
                &.pink {
                    background-color: $theme-color-pink;
                }
                &.green {
                    background-color: $theme-color-green;
                }
            }
        }
        &.same-style-black {
            > a {
                color: #181818;
                @media #{$md-layout} {
                    &:hover {
                        color: $theme-color-yellow;
                    }
                    &.same-style-hvr-red {
                        &:hover {
                            color: $theme-color-red;
                        }
                    }
                    &.same-style-hvr-pink {
                        &:hover {
                            color: $theme-color-pink;
                        }
                    }
                    &.same-style-hvr-green {
                        &:hover {
                            color: $theme-color-green;
                        }
                    }
                }
                @media #{$xs-layout} {
                    &:hover {
                        color: $theme-color-yellow;
                    }
                    &.same-style-hvr-red {
                        &:hover {
                            color: $theme-color-red;
                        }
                    }
                    &.same-style-hvr-pink {
                        &:hover {
                            color: $theme-color-pink;
                        }
                    }
                    &.same-style-hvr-green {
                        &:hover {
                            color: $theme-color-green;
                        }
                    }
                }
            }
        }
        &.main-menu-icon {
            > a {
                font-size: 26px;
            }
        }
        &.same-style-border {
            > a {
                border: 2px solid rgba(255,255,255,.3);
                padding: 16px 13px 13px;
                &.red {
                    color: $white;
                    &:hover {
                        color: $theme-color-red;
                    }
                }
            }
        }
    }
}

.search-style-1 {
    form {
        .form-search-1 {
            position: relative;
            input {
                font-size: 20px;
                color: $white;
                border: none;
                background-color: transparent;
                height: 30px;
                padding: 2px 50px 2px 0;
                width: 400px;
                &::-moz-input-placeholder {
                    color: $white !important;
                    opacity: 1 !important;
                }
                &::-webkit-input-placeholder {
                    color: $white !important;
                    opacity: 1 !important;
                }
            }
            button {
                border: none;
                background-color: transparent;
                font-size: 20px;
                color: $white;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                padding: 0;
                transition: all .3s ease 0s;
                &:hover {
                    color: $theme-color-yellow;
                }
            }
        }
    }
}

.main-wrapper {
    .body-overlay {
        background-color: #232324;
        height: 100%;
        left: 0;
        opacity: 0;
        position: fixed;
        top: 0;
        transition: all 0.5s ease-in-out 0s;
        visibility: hidden;
        width: 100%;
        z-index: 9999;
    }
    &.overlay-active {
        .body-overlay {
            opacity: .7;
            visibility: visible;
            z-index: 9999;
        }
    }
} 

.main-wrapper-2 {
    .body-overlay-2 {
        background-color: #232324;
        height: 100%;
        left: 0;
        opacity: 0;
        position: fixed;
        top: 0;
        transition: all 400ms ease-out;
        visibility: hidden;
        width: 100%;
        z-index: 9999;
    }
    &.overlay-active-2 {
        .body-overlay-2 {
            opacity: .7;
            visibility: visible;
            z-index: 9999;
        }
    }
} 

.sidebar-cart-active {
	position: fixed;
	top: 0;
	width: 420px;
	padding: 104px 50px 50px;
	min-height: 100vh;
	bottom: 0;
	z-index: 9999;
	right: 0;
	visibility: hidden;
	opacity: 0;
	transition: all 250ms ease-in;
	-moz-transition: all 250ms ease-in;
	transform: translate(200px,0);
	box-shadow: 0 0 87px 0 rgba(0,0,0,.09);
    background-color: #fff;
    &.inside {
        visibility: visible;
        opacity: 1;
        transform: translate(0,0);
    }
    @media #{$md-layout} {
        width: 350px;
        padding: 104px 30px 50px;
    }
    @media #{$xs-layout} {
        padding: 60px 15px 50px;
        width: 280px;
    }
    .sidebar-cart-all {
        .cart-close {
            position: absolute;
            top: 48px;
            right: 50px;
            font-size: 36px;
            line-height: 30px;
            color: #6D6D6D;
            transition: all 250ms ease-out;
            @media #{$md-layout} {
                right: 30px;
                font-size: 25px;
            }
            @media #{$xs-layout} {
                top: 20px;
                right: 17px;
                font-size: 23px;
            }
            &:hover {
                transform: rotate(90deg);
                color: $theme-color-yellow;
            }
        }
        .cart-content {
            > h3 {
                font-size: 18px;
                font-weight: 600;
                margin: 0 0 35px;
            }
            ul {
                margin: 0 -30px 0 0;
                padding: 0 30px 0 0;
                max-height: 310px;
                max-height: calc(100vh - 430px);
                overflow: auto;
                @media #{$md-layout} {
                    max-height: calc(100vh - 365px);
                }
                @media #{$xs-layout} {
                    max-height: calc(100vh - 360px);
                }
                li {
                    display: flex;
                    margin-bottom: 30px;
                    .cart-img {
                        flex: 0 0 70px;
                        a {
                            img {
                                width: 100%;
                                border: 1px solid rgba(0, 0, 0, 0.1);
                            }
                        }
                    }
                    .cart-title {
                        margin-left: 20px;
                        h4 {
                            font-size: 15px;
                            margin: 0 0 8px;
                            a {
                                color: #211E1C;
                                &:hover {
                                    color: $theme-color-yellow;
                                }
                            }
                        }
                        span {
                            font-size: 15px;
                            color: #6D6D6D;
                        }
                    }
                    .cart-delete {
                        display: flex;
                        flex-grow: 100;
                        justify-content: flex-end;
                        a {
                            display: block;
                            font-size: 16px;
                            height: 20px;
                            line-height: 17px;
                            overflow: hidden;
                            width: 20px;
                            text-align: right;
                        }
                    }
                }
            }
            .cart-total {
                display: block;
                width: 100%;
                margin: 16px 0 26px;
                h4 {
                    font-size: 16px;
                    margin: 0;
                    font-weight: 600;
                    color: #6D6D6D;
                    span {
                        font-size: 15px;
                        color: #6D6D6D;
                        float: right;
                        font-weight: 400;
                    }
                }
            }
            .cart-checkout-btn {
                display: block;
                width: 100%;
                a {
                    width: 100%;
                    display: block;
                    margin: 10px 0 0;
                    text-align: center;
                    line-height: 20px;
                    padding: 19px 20px 20px;
                    background-color: #181818;
                    color: #fff;
                    text-transform: capitalize;
                    font-size: 16px;
                    &:hover {
                        background-color: $theme-color-yellow;
                    }
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

.welcome-content {
    p {
        color: #181818;
        font-size: 15px;
        line-height: 1;
        @media #{$lg-layout} {
            font-size: 14px;
        }
    }
}

.header-top-right {
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
}

.same-style-2 {
    display: flex;
    align-items: center;
    margin-right: 30px;
    @media #{$lg-layout} {
        margin-right: 15px;
    }
    &:last-child {
        margin-right: 0;
    }
    a {
        color: #181818;
        font-size: 15px;
        i {
            position: relative;
            margin-right: 5px;
            top: 2px;
        }
        &:hover {
            color: $theme-color-yellow;
        }
    }
    &.same-style-2-red {
       a {
            &:hover {
                color: $theme-color-red;
            }
        } 
    }
}

.header-language-wrap , .header-currency-wrap {
    position: relative;
    .language-dropdown , .currency-dropdown {
        background: #fff none repeat scroll 0 0;
        position: absolute;
        left: 50%;
        top: 112%;
        width: 120px;
        z-index: 9999;
        box-shadow: 0 3px 14px 2px rgba(0,0,0,.1);
        transform: translateX(-50%);
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s ease 0s;
        text-align: center;
        ul {
            padding: 10px 0px;
            li {
                display: block;
                a {
                    display: block;
                    padding: 10px 5px;
                    color: #181818;
                    font-size: 14px;
                    line-height: 1;
                    &:hover {
                        color: $theme-color-yellow;
                    }
                }
            }
        }
        &.show {
            opacity: 1;
            visibility: visible;
        }
        &.lang-bg-black {
            background-color: #181818;
            ul {
                li {
                    a {
                        color: $white;
                        &:hover {
                            color: $theme-color-red;
                        }
                    }
                }
            }
        }
    }
}

.header-top-ptb-1 {
    padding: 16px 0 18px;
}
.header-search-categori-mrg {
    margin: 0 -30px;
    @media #{$xx-layout} {
        margin: 0 0px 0 -200px;
    }
    @media #{$xl-layout} {
        margin: 0 0px 0 -200px;
    }
    @media #{$lg-layout} {
        margin: 0 -20px 0 12px;
    }
}

.header-search-categori-wrap {
    form {
        display: flex;
        position: relative;
        border: 2px solid rgba(24,24,24,.1);
        input {
            &.search-field {
                order: -1;
                color: #181818;
                font-size: 15px;
                background-color: transparent;
                font-weight: 600;
                border: none;
                height: 60px;
                padding: 2px 20px;
                @media #{$lg-layout} {
                    width: 47%;
                    padding: 2px 10px;
                }
            }
        }
        select {
            height: 60px;
            border: none;
            margin-right: 56px;
            width: 150px;
            padding-right: 25px;
            padding-left: 20px;
            -moz-appearance: none;
            -webkit-appearance: none;
            color: #181818;
            cursor: pointer;
            display: block;
            @media #{$lg-layout} {
                padding-left: 10px;
                margin-right: 45px;
            }
        }
        button {
            &.search-button {
                position: absolute;
                right: 25px;
                top: 53%;
                transform: translateY(-50%);
                border: none;
                background-color: transparent;
                padding: 0;
                font-size: 22px;
                color: #181818;
                transition: all .3s ease 0s;
                &:hover {
                    color: $theme-color-yellow;
                }
            }
        }
    }
}

.header-support-wrap {
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
    .header-single-support {
        display: flex;
        align-items: center;
        margin-left: 40px;
        &:first-child {
            margin-left: 0;
        }
        .header-support-icon {
            margin-right: 10px;
            a {
                color: #1a1a1a;
                font-size: 30px;
                display: block;
            }
        }
        .header-support-content {
            span {
                color: #6D6D6D;
                line-height: 1;
                display: block;
            }
            h3 {
                color: #1A1A1A;
                font-size: 20px;
                margin: 5px 0 0;
                font-weight: 600;
                line-height: 20px;
                @media #{$lg-layout} {
                    font-size: 17px;
                }
                a {
                    color: #1A1A1A;
                }
            }
        }
        &.red {
            &:hover {
                .header-support-icon {
                    a {
                        color: $theme-color-red;
                    }
                }
                .header-support-content {
                    h3 {
                        a {
                            color: $theme-color-red;
                        }
                    }
                }
            }
        }
        &:hover {
            .header-support-icon {
                a {
                    color: $theme-color-yellow-2;
                }
            }
            .header-support-content {
                h3 {
                    a {
                        color: $theme-color-yellow-2;
                    }
                }
            }
        }
    }
}

.header-middle-ptb {
    padding: 42px 0;
}
.main-categori-menu-wrap {
	display: flex;
	flex-wrap: wrap;
}
.main-categori {
    position: relative;
    > a {
        background-color: #181818;
        color: #fff;
        height: 100%;
        padding: 0 40px 0 35px;
        display: inline-block;
        font-size: 20px;
        font-weight: 600;
        display: flex;
        align-items: center;
        @media #{$lg-layout} {
            padding: 0 30px 0 25px;
            font-size: 16px;
        }
        @media #{$md-layout} {
            font-weight: 500;
            font-size: 18px;
            color: #181818;
            background-color: transparent;
        }
        @media #{$xs-layout} {
            font-weight: 400;
            font-size: 16px;
            color: #181818;
            background-color: transparent;
        }
        i {
            position: relative;
            margin-right: 15px;
            @media #{$xs-layout} {
                margin-right: 5px;
            }
        }
        &.small-device-categori {
            padding: 0px 0px 20px;
            justify-content: flex-start;
            @media #{$xs-layout} {
                padding: 0px 0px 20px;
            }
        }
        &.categori-active1-icon {
            &.open {
                i {
                    &:before {
                        content: "\ea3a";
                    }
                }
            }
        }
        &.categori-active2-icon {
            &.open {
                i {
                    &:before {
                        content: "\ea30";
                    }
                }
            }
        }
    }
    .main-categori-dropdown {
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 9;
        background-color: #181818;
        box-shadow: 0 1px 19.8px .2px rgba(12,31,46,.15);
        padding: 10px 0 10px;
        transition: margin .1s linear .3s,visibility .1s linear .3s,opacity .1s linear .3s;
        z-index: 99;
        &.categori-dropdown-visible-1 {
            margin-top: 20px;
            visibility: hidden;
            opacity: 0;
            &.open {
                margin-top: 0px;
                visibility: visible;
                opacity: 1;
            }
        }
        &.categori-dropdown-visible-2 {
            visibility: visible;
            opacity: 1;
            margin-top: 0px;
            min-height: 742px;
            @media #{$xx-layout} {
                min-height: 642px;
            }
            @media #{$xl-layout} {
                min-height: 579px;
            }
            @media #{$lg-layout} {
                min-height: 529px;
            }
            &.open {
                margin-top: 20px;
                visibility: hidden;
                opacity: 0;
            }
        }
        ul {
            li {
                a {
                    color: #bfbfbf;
                    display: block;
                    font-size: 16px;
                    padding: 14px 35px 14px 35px;
                    @media #{$lg-layout} {
                        padding: 12px 35px 12px 35px;
                    }
                    @media #{$xs-layout} {
                        padding: 12px 15px 12px 15px;
                        font-size: 15px;
                    }
                    &:hover {
                        color: $white;
                    }
                }
            }
        }
    }
    @media #{$xl-layout} {
        &.mr-45 {
            margin-right: 20px;
        }
    }
    @media #{$lg-layout} {
        &.mr-45 {
            margin-right: 15px;
        }
    }
    @media #{$xs-layout} {
        &.mr-45 {
            margin-right: 0px;
        }
    }
}

.main-categori-position {
    position: absolute;
    top: 0;
    left: 140px;
    @media #{$xx-layout} {
        left: 60px;
    }
    @media #{$xl-layout} {
        left: 15px;
    }
    @media #{$lg-layout} {
        left: 30px;
    }
    @media #{$md-layout} {
        display: none;
    }
    @media #{$xs-layout} {
        display: none;
    }
    > a {
        background-color: $theme-color-red;
        padding: 22px 40px 25px 35px;
    }
}

.main-menu {
    > nav {
        > ul {
            > li {
                display: inline-block;
                position: relative;
                &.position-static {
                    position: static;
                }
                > a {
                    display: inline-block;
                    font-size: 18px;
                    color: #181818;
                    @media #{$lg-layout} {
                        font-size: 16px;
                    }
                    i {
                        font-size: 10px;
                        margin-left: 5px;
                        @media #{$lg-layout} {
                            font-size: 8px;
                            margin-left: 2px;
                        }
                    }
                    &.active {
                        color: $theme-color-red;
                    }
                }
                &:hover {
                    > a {
                        color: $theme-color-red;
                    }
                }
            }
        }
    }
    &.main-menu-padding-1 {
        > nav {
            > ul {
                > li {
                    padding-right: 25px;
                    @media #{$xl-layout} {
                        padding-right: 18px;
                    }
                    @media #{$lg-layout} {
                        padding-right: 12px;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
    }
    &.main-menu-padding-3 {
        > nav {
            > ul {
                > li {
                    padding-right: 28px;
                    @media #{$xl-layout} {
                        padding-right: 18px;
                    }
                    @media #{$lg-layout} {
                        padding-right: 12px;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
    }
    &.main-menu-lh-1 {
        > nav {
            > ul {
                > li {
                    > a {
                        line-height: 70px;
                    }
                }
            }
        }
    }
    &.main-menu-lh-3 {
        > nav {
            > ul {
                > li {
                    > a {
                        line-height: 144px;
                    }
                }
            }
        }
    }
    &.main-menu-lh-4 {
        > nav {
            > ul {
                > li {
                    > a {
                        line-height: 140px;
                    }
                }
            }
        }
    }
    &.main-menu-color-change {
        > nav {
            > ul {
                > li {
                    > a {
                        color: #1a1a1a;
                    }
                }
            }
        }
    }
    &.main-menu-white {
        > nav {
            > ul {
                > li {
                    > a {
                        color: $white;
                        &.active {
                            color: $theme-color-red;
                        }
                    }
                    &:hover {
                        > a {
                            color: $theme-color-red;
                        }
                    }
                }
            }
        }
    }
    &.main-menu-hover-green {
        > nav {
            > ul {
                > li {
                    > a {
                        &.active {
                            color: $theme-color-green;
                        }
                    }
                    &:hover {
                        > a {
                            color: $theme-color-green;
                        }
                    }
                }
            }
        }
    }
    &.main-menu-hover-green-2 {
        > nav {
            > ul {
                > li {
                    > a {
                        &.active {
                            color: $theme-color-green-2;
                        }
                    }
                    &:hover {
                        > a {
                            color: $theme-color-green-2;
                        }
                    }
                }
            }
        }
    }
    &.main-menu-hover-yellow {
        > nav {
            > ul {
                > li {
                    > a {
                        &.active {
                            color: $theme-color-yellow;
                        }
                    }
                    &:hover {
                        > a {
                            color: $theme-color-yellow;
                        }
                    }
                }
            }
        }
    }
    &.main-menu-mrg-1 {
        margin-left: 355px;
        @media #{$xl-layout} {
            margin-left: 200px;
        }
        @media #{$lg-layout} {
            margin-left: 0px;
        }
    }
}

.main-menu-dropdown-style {
    > nav {
        > ul {
            > li {
                > ul {
                    box-shadow: 0 1px 24px 0 rgba(0,0,0,0.09);
                    background-color: #181818;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    transition: margin .1s linear .3s,visibility .1s linear .3s,opacity .1s linear .3s;
                    opacity: 0;
                    visibility: hidden;
                    margin-top: 20px;
                    z-index: 999;
                    &.sub-menu-width {
                        width: 250px;
                        padding: 30px 0 34px;
                        text-align: left;
                        li {
                            display: block;
                            padding: 0 15px 20px 30px;
                            position: relative;
                            &:last-child {
                                padding-bottom: 0;
                            }
                            a {
                                display: block;
                                color: #fff;
                                i {
                                    float: right;
                                    font-size: 10px;
                                    color: #999;
                                    position: relative;
                                    top: 6px;
                                }
                            }
                            ul.lavel-menu {
                                padding: 30px 0 34px;
                                width: 250px;
                                transition: all 250ms ease-out;
                                box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
                                background-color: #181818;
                                position: absolute;
                                top: -17px;
                                left: 100%;
                                margin: 0;
                                visibility: hidden;
                                opacity: 0;
                            }
                            &:hover {
                                > a {
                                    color: $theme-color-yellow;
                                    text-decoration: underline;
                                }
                            }
                            &:hover > ul.lavel-menu {
                                visibility: visible;
                                opacity: 1;
                            }
                        }
                    }
                    &.mega-menu-style-1 {
                        width: 100%;
                        > li {
                            > ul {
                                width: 1170px;
                                margin: auto;
                                padding-top: 80px;
                                padding-bottom: 40px;
                                display: flex;
                                flex-wrap: wrap;
                                @media #{$lg-layout} {
                                    width: 950px;
                                    padding-top: 45px;
                                }
                                li {
                                    padding: 15px;
                                    flex: 0 0 25%;
                                    max-width: 25%;
                                    a {
                                        display: block;
                                        text-align: left;
                                        overflow: hidden;
                                        padding: 10px 0;
                                        img {
                                            width: 100%;
                                            transition: all .3s;
                                        }
                                        span {
                                            display: block;
                                            font-weight: 600;
                                            color: $white;
                                            font-size: 14px;
                                            margin: 20px 0 0;
                                            position: relative;
                                            &::before {
                                                content: '\ea6b';
                                                font-family: LaStudioIcons;
                                                padding-right: 5px;
                                                position: absolute;
                                                left: -20px;
                                                top: 0;
                                                transition: all .3s;
                                            }
                                        }
                                        &:hover {
                                            img {
                                                transform: translateY(-10px);
                                            }
                                            span {
                                                padding-left: 20px;
                                                &::before {
                                                    left: 0px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.mega-menu-style-2 {
                        width: 100%;
                        > li {
                            > ul {
                                width: 1600px;
                                margin: auto;
                                padding: 53px 0 40px;
                                display: flex;
                                flex-wrap: wrap;
                                @media #{$xx-layout} {
                                    width: 1300px;
                                }
                                @media #{$xl-layout} {
                                    width: 1170px;
                                }
                                @media #{$lg-layout} {
                                    width: 950px;
                                    padding: 40px 0 40px;
                                }
                                > li {
                                    padding: 15px;
                                    flex: 0 0 33.333%;
                                    max-width: 33.333%;
                                    text-align: left;
                                    > a {
                                        font-size: 18px;
                                        font-weight: 600;
                                        color: $white;
                                        display: block;
                                    }
                                    ul {
                                        display: flex;
                                        flex-wrap: wrap;
                                        margin: 23px 0 0;
                                        li {
                                            flex: 0 0 50%;
                                            max-width: 50%;
                                            display: inline-block;
                                            padding: 0 0 17px;
                                            &:last-child {
                                                padding: 0 0 0;
                                            }
                                            a {
                                                color: $white;
                                                font-size: 12px;
                                                &:hover {
                                                    color: $theme-color-yellow;
                                                    text-decoration: underline;
                                                }
                                            }
                                        }
                                    }
                                    .menu-banner-wrap {
                                        margin: 23px 0 0;
                                        .menu-banner-1-wrap {
                                            position: relative;
                                            margin-bottom: 20px;
                                            display: inline-block;
                                            a {
                                                display: block;
                                                position: relative;
                                                img {
                                                    max-width: 100%;
                                                }
                                            }
                                            .menu-banner-content-1 {
                                                position: absolute;
                                                top: 50%;
                                                transform: translateY(-50%);
                                                left: 20px;
                                                span {
                                                    display: block;
                                                    font-size: 15px;
                                                    font-style: italic;
                                                    color: #181818;
                                                }
                                                h4 {
                                                    font-size: 22px;
                                                    font-weight: 600;
                                                    color: #E41515;
                                                    margin: 3px 0 10px;
                                                    @media #{$lg-layout} {
                                                        font-size: 18px;
                                                    }
                                                }
                                                a {
                                                    display: inline-block;
                                                    font-size: 12px;
                                                    color: #181818;
                                                    line-height: 1;
                                                    border: 1px solid #979797;
                                                    padding: 10px 20px 12px;
                                                    &:hover {
                                                        background-color: #E41515;
                                                        border: 1px solid #E41515;
                                                        color: $white;
                                                    }
                                                }
                                            }
                                            
                                        }
                                        .menu-banner-2-wrap {
                                            position: relative;
                                            margin-bottom: 20px;
                                            display: inline-block;
                                            a {
                                                display: block;
                                                img {
                                                    max-width: 100%;
                                                }
                                            }
                                            .menu-banner-content-2 {
                                                position: absolute;
                                                top: 50%;
                                                transform: translateY(-50%);
                                                right: 36px;
                                                span {
                                                    display: block;
                                                    font-size: 13px;
                                                    color: #181818;
                                                    text-transform: uppercase;
                                                }
                                                h4 {
                                                    font-size: 24px;
                                                    font-weight: bold;
                                                    color: $theme-color-yellow;
                                                    margin: 1px 0 18px;
                                                    font-style: italic;
                                                    @media #{$lg-layout} {
                                                        font-size: 17px;
                                                        margin: 1px 0 10px;
                                                    }
                                                }
                                                a {
                                                    display: inline-block;
                                                    font-size: 12px;
                                                    color: #181818;
                                                    line-height: 1;
                                                    border: 1px solid #979797;
                                                    padding: 10px 20px 12px;
                                                    &:hover {
                                                        background-color: #E41515;
                                                        border: 1px solid #E41515;
                                                        color: $white;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &:hover {
                    &:hover > ul {
                        opacity: 1;
                        visibility: visible;
                        margin-top: 0px;
                    }
                }
            }
        }
    }
    &.main-menu-hover-green {
        > nav {
            > ul {
                > li {
                    > ul {
                        &.sub-menu-width {
                            li {
                                &:hover {
                                    > a {
                                        color: $theme-color-green;
                                    }
                                }
                            }
                        }
                        &.mega-menu-style-2 {
                            > li {
                                > ul {
                                    > li {
                                        ul {
                                            li {
                                                a {
                                                    &:hover {
                                                        color: $theme-color-green;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.main-menu-hover-pink {
        > nav {
            > ul {
                > li {
                    > ul {
                        &.sub-menu-width {
                            li {
                                &:hover {
                                    > a {
                                        color: $theme-color-pink;
                                    }
                                }
                            }
                        }
                        &.mega-menu-style-2 {
                            > li {
                                > ul {
                                    > li {
                                        ul {
                                            li {
                                                a {
                                                    &:hover {
                                                        color: $theme-color-pink;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.main-menu-hover-red {
        > nav {
            > ul {
                > li {
                    > ul {
                        &.sub-menu-width {
                            li {
                                &:hover {
                                    > a {
                                        color: $theme-color-red;
                                    }
                                }
                            }
                        }
                        &.mega-menu-style-2 {
                            > li {
                                > ul {
                                    > li {
                                        ul {
                                            li {
                                                a {
                                                    &:hover {
                                                        color: $theme-color-red;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}



.header-offer-cart-wrap {
	display: flex;
	justify-content: flex-end;
    align-items: center;
    height: 100%;
    @media #{$md-layout} {
        padding: 0 0 18px;
    }
    @media #{$xs-layout} {
        justify-content: center;
    }
    .same-style-3 {
        margin-left: 22px;
        @media #{$lg-layout} {
            margin-left: 10px;
        }
        &:first-child {
            margin-left: 0;
        }
        > a {
            font-size: 20px;
            color: #181818;
            font-weight: 600;
            display: flex;
            align-items: center;
            position: relative;
            @media #{$xx-layout} {
                font-size: 18px;
            }
            @media #{$xl-layout} {
                font-size: 16px;
            }
            @media #{$lg-layout} {
                font-size: 14px;
            }
            @media #{$md-layout} {
                font-size: 18px;
            }
            @media #{$xs-layout} {
                font-size: 15px;
            }
            i {
                font-size: 26px;
                margin-right: 10px;
                @media #{$xx-layout} {
                    font-size: 24px;
                }
                @media #{$xl-layout} {
                    font-size: 23px;
                }
                @media #{$lg-layout} {
                    font-size: 18px;
                    margin-right: 5px;
                }
                @media #{$md-layout} {
                    font-size: 20px;
                }
                @media #{$xs-layout} {
                    font-size: 14px;
                    margin-right: 5px;
                }
            }
            span {
                position: absolute;
                top: -12px;
                font-size: 10px;
                width: 18px;
                height: 18px;
                display: inline-block;
                line-height: 18px;
                color: #fff;
                text-align: center;
                left: 12px;
                border-radius: 10px;
                &.red {
                    background-color: $theme-color-red;
                }
            }
            &.red {
                color: $theme-color-red;
                i {
                    color: #181818;
                    transition: all .3s ease 0s;
                }
                &:hover {
                    i {
                        color: $theme-color-red;
                    }
                }
            }
            &:hover {
                color: $theme-color-red;
            }
        }
    }
}

select {
    -moz-appearance: none;
    -webkit-appearance: none;
    padding: 0 15px;
    cursor: pointer;
    &.select-img {
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 13' width='8' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='rgb%28138,138,138%29' fill-rule='nonzero'%3E%3Cpath d='M6.854 9.174l-2.862 2.45-2.862-2.45a.75.75 0 00-.935.007.514.514 0 00-.008.8l3.333 2.852c.26.223.683.223.943 0l3.333-2.852a.518.518 0 00.182-.555c-.06-.2-.243-.355-.476-.407a.744.744 0 00-.648.155zM4.472.21a.637.637 0 00-.944 0L.188 3.787A.752.752 0 00.196 4.79c.257.276.674.28.936.01L4 1.726 6.868 4.8a.637.637 0 00.936-.009.752.752 0 00.008-1.003L4.472.21z'/%3E%3C/g%3E%3C/svg%3E");
        background-size: 8px 13px;
        background-position: right -10px center;
        background-origin: content-box;
        background-repeat: no-repeat;
        max-width: 100%;
    }
}

.main-menu-style-2 {
    .leather-logo {
        position: absolute;
        left: 48.5%;
        top: 50%;
        transform: translateY(-50%);
        @media #{$xx-layout} {
            left: 47.3%
        }
        @media #{$xl-layout} {
            left: 46.5%;
        }
        @media #{$lg-layout} {
            left: 44%; 
        }
        img {
            width: 165px;
            @media #{$lg-layout} {
                width: 120px;
            }
        }
    }
    .jewelry-logo {
        position: absolute;
        left: 48.3%;
        top: 50%;
        transform: translateY(-50%);
        @media #{$xx-layout} {
            left: 47.7%;
        }
        @media #{$xl-layout} {
            left: 47.3%;
        }
        @media #{$lg-layout} {
            left: 43.8%;
        }
        img {
            width: 165px;
            @media #{$xx-layout} {
                width: 135px;
            }
            @media #{$xl-layout} {
                width: 135px;
            }
            @media #{$lg-layout} {
                width: 120px;
            }
        }
    }
    .kids-logo {
        position: absolute;
        left: 48.5%;
        top: 50%;
        transform: translateY(-50%);
        @media #{$xx-layout} {
            left: 47.4%;
        }
        @media #{$xl-layout} {
            left: 46.7%;
        }
        @media #{$lg-layout} {
            left: 44%;
        }
        img {
            width: 165px;
            @media #{$lg-layout} {
                width: 120px;
            }
        }
    }
    &.main-menu-2-center {
        display: flex;
        justify-content: center;
    }
    > nav {
        > ul {
            > li {
                display: inline-block;
                position: relative;
                &:nth-child(3) {
                    margin-right: 300px;
                    @media #{$xx-layout} {
                        margin-right: 250px;
                    }
                    @media #{$xl-layout} {
                        margin-right: 230px;
                    }
                    @media #{$lg-layout} {
                        margin-right: 200px;
                    }
                }
                > a {
                    display: inline-block;
                    color: $white;
                    font-size: 22px;
                    font-weight: 500;
                    font-family: $noto;
                    @media #{$xx-layout} {
                        font-size: 22px;
                    }
                    @media #{$xl-layout} {
                        font-size: 19px;
                    }
                    @media #{$lg-layout} {
                        font-size: 18px; 
                    }
                    i {
                        font-size: 10px;
                        margin-left: 7px;
                        position: relative;
                        top: -3px;
                    }
                }
            }
        }
    }
    &.main-menu-padding-2 {
        > nav {
            > ul {
                > li {
                    padding-right: 26px;
                    @media #{$xx-layout} {
                        padding-right: 15px;
                    }
                    @media #{$xl-layout} {
                        padding-right: 15px;
                    }
                    @media #{$lg-layout} {
                        padding-right: 10px;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
    }
    &.main-menu-lh-2 {
        > nav {
            > ul {
                > li {
                    > a {
                        line-height: 130px;
                    }
                }
            }
        }
    }
    &.main-menu-style-2-black {
        > nav {
            > ul {
                > li {
                    > a {
                        color: #1a1a1a;
                        font-weight: 400;
                        letter-spacing: -1px;
                        @media #{$xl-layout} {
                            font-size: 20px;
                        }
                        @media #{$lg-layout} {
                            font-size: 18px;
                        }
                        &.active-pink , &:hover {
                            color: $theme-color-pink;
                        }
                    }
                }
            }
        }
    }
    &.main-menu-style-2-poppins {
        > nav {
            > ul {
                > li {
                    > a {
                        font-family: $poppins;
                    }
                }
            }
        }
    }
}

.search-style-2 {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	opacity: 0;
	visibility: hidden;
	background-color: rgba(0,0,0,.9);
	text-align: center;
	transition: all ease-in-out .25s;
	z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    &.search-visible {
        visibility: visible;
	    opacity: 1;
        & .megashop-search-content {
            transform: scale(1);
            opacity: 1;
            visibility: visible;
        }
    }
    > .search2-close {
        font-size: 40px;
        display: block;
        position: absolute;
        top: 20%;
        right: 20%;
        line-height: 40px;
        height: 40px;
        transition: all .3s;
        color: $white;
        &:hover {
            transform: rotate(90deg);
        }
        i {
            &:hover {
                color: $theme-color-yellow;
            }
        }
        
    }
    & .search-content-2 {
        display: inline-block;
        max-width: 90%;
        text-align: center;
        font-size: 18px;;
        transition: all ease-in-out .3s;
        transform: scale(.9);
        p {
           font-size: 20px;
            margin: 0 0 15px;
            color: $white;
            font-weight: 400;
        }
        .search-form-style-2 {
            position: relative;
            input {
                width: 800px;
                background-color: transparent;
               border-bottom: 2px solid $white;
                border: 0;
                text-align: center;
                font-size: 30px;
                padding: 34px 60px 10px 36px;
                color: $white;
                transition: all .3s ease-out;
                font-weight: 400;
                max-width: 100%;
                height: auto;
                border-bottom: 2px solid $white;
            }
            button {
                position: absolute;
                top: 43px;
                display: block;
                right: 5px;
                border: 0;
                background: 0 0;
                padding: 0;
                color: $white;
                font-size: 30px;
                height: 30px;
                &:hover {
                    color: rgba(255,255,255,1);
                }
            }
        }
    }
}

.header-top-style-wrap {
	display: flex;
	flex-wrap: wrap;
    .same-style-4 {
        margin-right: 40px;
        &:last-child {
            margin-right: 0;
        }
        a {
            color: $white;
            font-size: 16px;
            i {
                margin-right: 8px; 
                position: relative;
                top: 2px;
                &.angle {
                    margin-right: 0;
                    margin-left: 6px;
                    font-size: 12px;
                }
            }
            &:hover {
                color: $theme-color-red;
            }
        }
    }
    &.header-top-right-2 {
        justify-content: flex-end;
    }
}

.delivery-offer {
    p {
        font-size: 18px;
        color: $white;
    }
}

.header-aside-active {
	position: fixed;
	top: 0;
	width: 470px;
	min-height: 100vh;
	bottom: 0;
	z-index: 111;
	right: 0;
	visibility: hidden;
	opacity: 0;
	transition: all 400ms ease-out;
	transform: translate(200px,0);
    background-color: $white;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.09);
    z-index: 9999;
    @media #{$xx-layout} {
        width: 400px;
    }
    @media #{$xl-layout} {
        width: 400px;
    }
    @media #{$lg-layout} {
        width: 360px;
    }
    &.header-aside-active-left {
        transform: translate(-200px,0);
        left: 0;
    }
    &.inside {
        visibility: visible;
        opacity: 1;
        transform: translate(0,0);
    }
    & .header-aside-wrap {
        padding: 130px 70px 20px;
        height: 100%;
        @media #{$xx-layout} {
            padding: 110px 50px 20px;
        }
        @media #{$xl-layout} {
            padding: 110px 50px 20px;
        }
        @media #{$lg-layout} {
            padding: 110px 50px 20px;
        }
        .aside-close {
            position: absolute;
            top: 50px;
            left: 30px;
            font-size: 30px;
            line-height: 30px;
            transition: all 250ms ease-out;
            color: #6d6d6d;
            &:hover {
                transform: rotate(90deg);
                color: $theme-color-yellow;
                &.pink {
                    color: $theme-color-pink;
                }
            }
        }
        .header-aside-content {
            overflow: auto;
            height: 100%;
            padding-right: 10px;
            .header-aside-menu {
                margin: 0 0 140px;
                @media #{$xx-layout} {
                    margin: 0 0 70px;
                }
                @media #{$xl-layout} {
                    margin: 0 0 70px;
                }
                @media #{$lg-layout} {
                    margin: 0 0 40px;
                }
                nav {
                    ul {
                        li {
                            display: block;
                            a {
                                font-size: 16px;
                                color:#6d6d6d;
                                display: block;
                                padding: 0 0 24px;
                                @media #{$xx-layout} {
                                    padding: 0 0 20px;
                                }
                                @media #{$xl-layout} {
                                    padding: 0 0 20px;
                                }
                                @media #{$lg-layout} {
                                    padding: 0 0 15px;
                                }
                                &:hover {
                                    color: $theme-color-yellow;
                                }
                            }
                            &:last-child a {
                                padding: 0 0 0px;
                            }
                        }
                    }
                }
                &.pink {
                    nav {
                        ul {
                            li {
                                a {
                                    &:hover {
                                        color: $theme-color-pink;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .aside-banner {
                a {
                    position: relative;
                    display: block;
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        transition: all .5s;
                        background-color: #181818;
                        opacity: 0;
                    }
                    img {
                        width: 100%;
                    }
                }
                &:hover {
                    a {
                        &::before {
                            opacity: .4;
                            transform: scale(.9);
                        } 
                    }
                }
            }
            .aside-contact-info {
                margin: 35px 0 19px;
                ul {
                    li {
                        color: #6d6d6d;
                        display: flex;
                        margin: 0 0 4px;
                        font-size: 12px;
                        &:last-child {
                            margin: 0 0 0px;
                        }
                        i {
                            font-size: 22px;
                            margin: 1px 16px 0px 0;
                        }
                    }
                }
            }
            .aside-social-icon {
                a {
                    color: #6d6d6d;
                    font-size: 16px;
                    margin-right: 17px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            .aside-payments {
                margin: 0 0 5px;
                img {
                    max-width: 100%;
                }
            }
        }
    }
}

.mobile-menu-active {
	position: fixed;
	top: 0;
	width: 350px;
	min-height: 100vh;
	bottom: 0;
	z-index: 111;
	right: 0;
	visibility: hidden;
	opacity: 0;
	transition: all 400ms ease-out;
	transform: translate(200px,0);
    background-color: $white;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.09);
    z-index: 9999;
    @media #{$xs-layout} {
        width: 290px;
    }
    &.inside {
        visibility: visible;
        opacity: 1;
        transform: translate(0,0);
    }
    & .mobile-menu-all-wrap {
        padding: 80px 30px 20px;
        height: 100%;
        @media #{$xs-layout} {
            padding: 80px 20px 20px;
        }
        .mobile-menu-close {
            position: absolute;
            top: 20px; 
            left: 30px;
            font-size: 30px;
            line-height: 30px;
            transition: all 250ms ease-out;
            color: #6d6d6d;
            @media #{$xs-layout} {
                left: 20px;
            }
            &:hover {
                transform: rotate(90deg);
                color: $theme-color-yellow;
            }
        }
        .mobile-menu-content {
            overflow-y: auto;
            overflow-x: hidden;
            height: 100%;
            padding-right: 10px;
            .mobile-search {
                border-bottom: 1px solid #ddd;
                margin-bottom: 17px;
                padding-bottom: 30px;
                form {
                    position: relative;
                    input {
                        background-color: #f6f6f6;
                        border: none;
                        border-radius: 0px;
                        height: 50px;
                        padding: 0 60px 0 15px;
                        width: 100%;
                        font-size: 14px;
                        color: #181818;
                    }
                    button {
                        background-color: transparent;
                        border-color: #ddd;
                        border-image: none;
                        border-radius: 5px 0 0 5px;
                        border-style: none none none solid;
                        border-width: medium medium medium 1px;
                        color: #000000;
                        font-size: 18px;
                        height: 100%;
                        padding: 0 15px 0 14px;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        transition: all 0.3s ease 0s;
                        @media #{$xs-layout} {
                            font-size: 16px;
                        }
                        &:hover{
                            color: $theme-color-yellow;
                        }
                        i {
                            margin-top: 5px;
                            display: inline-block;
                        }
                    }
                }
            }
            .mobile-menu-wrap {
                border-bottom: 1px solid #ddd;
                margin-bottom: 26px;
                padding-bottom: 17px;
                .mobile-navigation {
                    nav {
                        height: 100%;
                        .mobile-menu {
                            li {
                                display: block;
                                position: relative;
                                &.menu-item-has-children {
                                    .menu-expand {
                                        line-height: 50;
                                        top: -5px;
                                        left: 95%;
                                        width: 30px;
                                        position: absolute;
                                        height: 50px;
                                        text-align: center;
                                        cursor: pointer;
                                        i {
                                            display: block;
                                            position: relative;
                                            width: 10px;
                                            margin-top: 25px;
                                            border-bottom: 1px solid #1a1a1a;
                                            transition: all 250ms ease-out;
                                            &::before {
                                                top: 0;
                                                width: 100%;
                                                content: "";
                                                display: block;
                                                position: absolute;
                                                transform: rotate(90deg);
                                                border-bottom: 1px solid #1a1a1a;
                                                transition: 0.4s;
                                            }
                                        }
                                    }
                                    &.active > .menu-expand i::before{
                                        transform: rotate(0);
                                    }
                                }
                                a {
                                    font-size: 16px;
                                    text-transform: capitalize;
                                    line-height: 18px;
                                    position: relative;
                                    display: inline-block;
                                    padding: 12px 0;
                                    color: #1a1a1a;
                                }
                                ul {
                                    li {
                                        a {
                                            padding: 10px 15px 5px;
                                            font-size: 14px;
                                            color: #555;
                                            &:hover {
                                                color: $theme-color-yellow;
                                            }
                                        }
                                        ul {
                                            li {
                                                a {
                                                    padding: 10px 30px 5px;
                                                    font-size: 14px;
                                                    color: #555;
                                                }
                                            }
                                        }
                                    }
                                }
                                &:hover {
                                    > a {
                                        color: $theme-color-yellow;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .mobile-header-info-wrap {
                border-bottom: 1px solid #ddd;
                margin-bottom: 11px;
                padding-bottom: 17px;
                @media #{$xs-layout} {
                    display: block;
                }
                .single-mobile-header-info {
                    position: relative;
                    margin-bottom: 15px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        font-size: 16px;
                        display: block;
                        color: #1a1a1a;
                        > i {
                            font-size: 14px;
                            position: relative;
                            top: -1px;
                            margin-right: 10px;
                            width: 12px;
                        }
                        span {
                            float: right;
                            font-size: 10px;
                            position: relative;
                            top: 8px;
                            line-height: 1;
                        }
                    }
                    .lang-curr-dropdown {
                        margin-top: 5px;
                        display: none;
                        background-color: #fff;
                        box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
                        padding: 22px 30px 26px;
                        width: 100%;
                        z-index: 11;
                        ul {
                            li {
                                padding-bottom: 10px;
                                &:last-child {
                                    padding-bottom: 0px;
                                }
                                a {
                                    font-size: 14px;
                                    color: #555;
                                    &:hover {
                                        color: $theme-color-yellow;
                                    }
                                }
                            }
                        }
                    }
                    &:hover > a {
                        color: $theme-color-yellow;
                    }
                }
            }
            .mobile-contact-info {
                margin: 23px 0 19px;
                ul {
                    li {
                        color: #1a1a1a;
                        display: flex;
                        margin: 0 0 8px;
                        font-size: 14px;
                        &:last-child {
                            margin: 0 0 0px;
                        }
                        i {
                            font-size: 14px;
                            margin-right: 12px;
                            position: relative;
                            top: 4px;
                        }
                    }
                }
            }
            .mobile-social-icon {
                a {
                    color: #1a1a1a;
                    font-size: 16px;
                    margin-right: 17px;
                    &:last-child {
                        margin-right: 0;
                    }
                    &:hover {
                        color: $theme-color-yellow;
                    }
                }
            }
            .mobile-copyright {
                p {
                    color: #1a1a1a;
                    font-size: 14px;
                    a {
                        color: #1a1a1a;
                        &:hover {
                            color: $theme-color-yellow;
                        }
                    }
                }
            }
        }
    }
}

.clickable-mainmenu-active {
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: rgba(24, 24, 24, 0.95);
	z-index: 3;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0s 0.5s;
    z-index: 99;
    &.mainmenu-visible {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.5s;
    }
    .clickable-mainmenu-width {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        a {
            &.mainmenu-close {
                position: fixed;
                top: 22%;
                right: 10%;
                font-size: 32px;
                color: #fff;
                z-index: 9;
                transition: all .3s ease 0s;
                @media #{$xx-layout} {
                    top: 11%;
                }
                @media #{$xl-layout} {
                    top: 11%;
                }
                @media #{$lg-layout} {
                    top: 11%;
                }
                &:hover {
                    color: $theme-color-yellow;
                }
            }
        }
        .clickable-mainmenu-style {
            display: flex;
            padding: 0 300px;
            overflow: auto;
            height: 90%;
            justify-content: center;
            @media #{$xx-layout} {
                padding: 0 150px;
            }
            @media #{$xl-layout} {
                padding: 0 100px;
            }
            @media #{$lg-layout} {
                padding: 0 80px;
            }
            > nav {
                width: 100%;
                align-items: center;
                display: flex;
                > ul {
                    width: 1030px;
                    margin-left: auto;
                    margin-right: auto;
                    justify-content: space-between;
                    display: flex;
                    max-height: 90%;
                    > li {
                        min-width: 200px;
                        &.has-sub-menu {
                            &.active {
                                > a {
                                    i {
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }
                            }
                        }
                        > a {
                            color: #fff;
                            font-size: 24px;
                            i {
                                color: #fff;
                                font-size: 22px;
                                font-weight: bold;
                                margin-left: 16px;
                                opacity: 0;
                                visibility: hidden;
                                transition: all .4s ease 0s;
                            }
                            &:hover {
                                color: $theme-color-yellow;
                                i {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                        > ul {
                            padding: 30px 0;
                            li {
                                padding: 0 0 15px;
                                a {
                                    color: #fff;
                                    display: block;
                                    i {
                                        color: #fff;
                                        font-size: 18px;
                                        font-weight: bold;
                                        margin-left: 16px;
                                        opacity: 0;
                                        visibility: hidden;
                                        transition: all .3s ease 0s;
                                        position: relative;
                                        top: 2px;
                                    }
                                    &:hover {
                                        color: $theme-color-yellow;
                                    }
                                }
                                &:hover {
                                    a i {
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }
                                &.has-sub-menu {
                                    &.active {
                                        a {
                                            i {
                                                font-size: 15px;
                                                font-weight: 400;
                                                top: 0px;
                                            }
                                        }
                                    }
                                }
                                ul {
                                    padding: 23px 0 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

