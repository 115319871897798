
/*-------- 6. Section title style ---------*/

.section-title-padding {
    padding: 0 130px 0 140px;
    @media #{$xx-layout} {
        padding: 0 50px 0 100px;
    }
    @media #{$xl-layout} {
        padding: 0 30px 0 15px;
    }
    @media #{$lg-layout} {
        padding: 0 30px 0 30px;
    }
    @media #{$md-layout} {
        padding: 0 20px 0 40px;
    }
    @media #{$xs-layout} {
        padding: 0 15px 0 15px;
    }
}

.section-title-1 {
    h3 {
        color: $theme-color-yellow;
        font-family: $playfair;
        font-style: italic;
        font-size: 36px;
        letter-spacing: -1px;
        line-height: 30px;
        margin: 0px 0 0;
        @media #{$xs-layout} {
            font-size: 30px;
        }
    }
    h2 {
        font-family: $playfair;
        font-size: 100px;
        font-style: italic;
        line-height: 1.3em;
        letter-spacing: -4px;
        @media #{$xx-layout} {
            font-size: 80px;
        }
        @media #{$xl-layout} {
            font-size: 80px;
        }
        @media #{$lg-layout} {
            font-size: 45px;
        }
        @media #{$md-layout} {
            font-size: 40px;
            letter-spacing: 0;
        }
        @media #{$xs-layout} {
            font-size: 34px;
            letter-spacing: 0;
        }
        &.margin-1 {
            margin: 0px 0 42px;
            @media #{$lg-layout} {
                margin: 0px 0 25px;
            }
            @media #{$md-layout} {
                margin: 10px 0 25px;
                line-height: 1.2em;
            }
            @media #{$xs-layout} {
                margin: 10px 0 20px;
                line-height: 1.2em;
            }
        }
    }
}

.section-pattern {
	height: 2px;
    &.section-pattern-width-1 {
        width: 280px;
        @media #{$lg-layout} {
            width: 160px;
        }
        @media #{$md-layout} {
            width: 160px;
        }
        @media #{$xs-layout} {
            width: 160px;
        }
    }
    &.section-pattern-margin-1 {
        margin: 0 0 40px;
        @media #{$lg-layout} {
            margin: 0px 0 20px;
        }
        @media #{$md-layout} {
            margin: 0px 0 20px;
        }
        @media #{$xs-layout} {
            margin: 0px 0 0px;
        }
    }
    &.section-pattern-margin-2 {
        margin: 0 auto;
    }
}

.btn-style-2 {
    a {
        color: #181818;
        font-size: 24px;
        display: inline-block;
        @media #{$lg-layout} {
            font-size: 20px;
        }
        @media #{$md-layout} {
            font-size: 20px;
        }
        @media #{$xs-layout} {
            font-size: 20px;
        }
        svg {
            color: #181818;
            width: 60px;
            margin-left: 25px;
            transition: all .3s ease 0s;
            @media #{$lg-layout} {
                width: 55px;
            }
            @media #{$md-layout} {
                width: 45px;
            }
            @media #{$xs-layout} {
                width: 34px;
                height: 34px;
                margin-left: 20px;
            }
        }
        &:hover {
            color: $theme-color-yellow;
            svg {
                color: $theme-color-yellow;
            }
        }
    }
    &.btn-right {
        float: right;
        @media #{$xs-layout} {
            float: left;
        }
        @media #{$sm-layout} {
            float: right;
        }
    }
    &.btn-mt-1 {
        margin-top: 30px;
        @media #{$lg-layout} {
            margin-top: 10px;
        }
        @media #{$md-layout} {
            margin-top: 0px;
        }
        @media #{$xs-layout} {
            margin-top: 0px;
            margin-bottom: 20px;
        }
        @media #{$sm-layout} {
            margin-top: 10px;
            margin-bottom: 0px;
        }
    }
    @media #{$xs-layout} {
        margin-top: 15px;
    }
}

.section-title-2 {
    h2 {
        font-family: $playfair;
        font-size: 80px;
        font-style: italic;
        line-height: 1;
        letter-spacing: -3px;
        &.margin-1 {
            margin: 0px 0 32px;
            @media #{$xs-layout} {
                margin: 0px 0 20px;
            }
        }
        &.margin-2 {
            margin: 0px 0 32px;
        }
        @media #{$lg-layout} {
            font-size: 45px;
        }
        @media #{$md-layout} {
            font-size: 45px;
        }
        @media #{$xs-layout} {
            font-size: 45px;
        }
    }
    @media #{$md-layout} {
        &.mb-90 {
            margin-bottom: 50px;
        }
    }
    @media #{$xs-layout} {
        &.mb-50 {
            margin-bottom: 30px;
        }
        &.mb-90 {
            margin-bottom: 30px;
        }
    }
}

.section-title-3 {
    h2 {
        font-size: 42px;
        font-weight: 600;
        letter-spacing: -1px;
        margin: 0;
        position: relative;
        padding-bottom: 40px;
        @media #{$lg-layout} {
            font-size: 35px;
        }
        @media #{$md-layout} {
            font-size: 30px;
            padding-bottom: 30px;
        }
        @media #{$xs-layout} {
            font-size: 22px;
            padding-bottom: 20px;
        }
        &:before {
            position: absolute;
            left: 0;
            bottom: 0;
            content: "";
            background-color: $theme-color-yellow-2;
            height: 5px;
            width: 130px;
        }
    }
    @media #{$md-layout} {
        &.mb-60 {
            margin-bottom: 40px;
        }
    }
    @media #{$xs-layout} {
        &.mb-60 {
            margin-bottom: 30px;
        }
    }
}

.btn-style-5 {
    a {
        display: block;
        float: right;
        color: #6D6D6D;
        @media #{$xs-layout} {
            float: left;
        }
        @media #{$sm-layout} {
            float: right;
        }
        i {
            font-size: 14px;
            margin: 0 0 0 5px;
            position: relative;
            top: 4px;
        }
        &:hover {
            color: $theme-color-yellow-2;
        }
    }
    @media #{$xs-layout} {
        &.btn-style-5-responsive {
            margin: 13px 0 16px;
            display: block;
            overflow: hidden;
        }
    }
}

.section-title-4 {
    h2 {
        font-size: 36px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 0;
        line-height: 1;
        @media #{$lg-layout} {
            font-size: 30px;
        }
        @media #{$md-layout} {
            font-size: 30px;
        }
        @media #{$xs-layout} {
            font-size: 20px;
            line-height: 1.3;
        }
        @media #{$sm-layout} {
            font-size: 25px;
        }
        &.white {
            color: $white;
        }
        span {
            color: $theme-color-red;
        }
    }
    > span {
        color: #6D6D6D;
        font-size: 24px;
        display: block;
        margin: 10px 0 36px;
        @media #{$xs-layout} {
            font-size: 17px;
            margin: 5px 0 20px;
        }
    }
    .st-angle-down {
        svg {
            color: $theme-color-red;
        }
    }
    @media #{$md-layout} {
        &.mb-60 {
            margin-bottom: 40px;
        }
    }
    @media #{$xs-layout} {
        &.mb-60 {
            margin-bottom: 30px;
        }
        &.mb-55 {
            margin-bottom: 30px;
        }
    }
}

.section-title-5 {
    h2 {
        font-size: 50px;
        font-weight: 500;
        font-family: $noto;
        letter-spacing: -2px;
        color: $theme-color-yellow;
        margin: 0;
        @media #{$lg-layout} {
            font-size: 40px;
        }
        @media #{$md-layout} {
            font-size: 35px;
        }
        @media #{$xs-layout} {
            font-size: 26px;
        }
        @media #{$sm-layout} {
            font-size: 30px;
        }
    }
    @media #{$md-layout} {
        &.mb-55 {
            margin-bottom: 35px;
        }
        &.mb-30 {
            margin-bottom: 25px;
        }
        &.mb-40 {
            margin-bottom: 25px;
        }
        &.mb-25 {
            margin-bottom: 15px;
        }
    }
    @media #{$xs-layout} {
        &.mb-55 {
            margin-bottom: 25px;
        }
        &.mb-30 {
            margin-bottom: 15px;
        }
        &.mb-40 {
            margin-bottom: 15px;
        }
        &.mb-25 {
            margin-bottom: 0px;
        }
    }
}

.section-title-6 {
    position: relative;
    z-index: 9;
    h2 {
        font-size: 46px;
        font-weight: 400;
        line-height: 1em;
        letter-spacing: -1px;
        font-family: $playfair;
        margin: 0;
        @media #{$xs-layout} {
            font-size: 24px;
            letter-spacing: 0;
        }
        @media #{$sm-layout} {
            font-size: 35px;
        }
        &.white {
            color: $white;
        }
        &.font-size-dec {
            font-size: 36px;
            line-height: 1.2em;
            @media #{$xs-layout} {
                font-size: 24px;
            }
        }
    }
    a {
        color: #727272;
        font-size: 16px;
        margin: 17px 0 0;
        display: block;
        i {
            font-size: 18px;
            color: #181818;
            margin-right: 10px;
            top: 1px;
            position: relative;
        }
    }
    p {
        color: #6D6D6D;
        font-size: 18px;
        margin: 17px 0 0;
        display: block;
        &.font-dec {
            font-size: 14px;
            line-height: 28px;
            margin: 16px auto 0;
            @media #{$md-layout} {
                margin: 12px auto 0;
            }
            @media #{$xs-layout} {
                margin: 10px auto 0;
                line-height: 24px;
            }
        }
        &.st6-peragraph-width-1 {
            width: 30%;
            @media #{$xx-layout} {
                width: 37%;
            }
            @media #{$xl-layout} {
                width: 42%;
            }
            @media #{$lg-layout} {
                width: 50%;
            }
            @media #{$md-layout} {
                width: 65%;
            }
            @media #{$xs-layout} {
                width: 90%;
            }
        }
    }
    @media #{$md-layout} {
        &.section-title-6-center {
            text-align: center;
            margin: 0 0 50px;
        }
        &.mb-55 {
            margin-bottom: 35px;
        }
    }
    @media #{$xs-layout} {
        &.mb-25 {
            margin-bottom: 5px;
        }
        &.mb-40 {
            margin-bottom: 20px;
        }
        &.mb-50 {
            margin-bottom: 15px;
        }
        &.mb-55 {
            margin-bottom: 25px;
        }
        &.section-title-6-center {
            text-align: center; 
            margin: 0 0 30px;
        }
    }
}

.section-title-7 {
    h2 {
        color: $white;
        font-family: $montserrat;
        font-size: 26px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 15px;
        margin: 0;
        @media #{$xs-layout} {
            letter-spacing: 5px;
        }
    }
}

.section-title-8 {
    h2 {
        color: $white;
        margin: 0;
        font-size: 36px;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-family: $montserrat;
        @media #{$xs-layout} {
            font-size: 26px;
            letter-spacing: 1px;
        }
    }
    @media #{$md-layout} {
        &.mb-50 {
            margin-bottom: 30px;
        }
        &.pb-55 {
            padding-bottom: 35px;
        }
    }
    @media #{$xs-layout} {
        &.mb-50 {
            margin-bottom: 25px;
        }
        &.pb-55 {
            padding-bottom: 30px;
        }
    }
}

.section-title-9 {
    img {
        max-width: 100%;
    }
    h2 {
        font-size: 42px;
        font-family: $pacifico;
        margin: 16px 0 0;
        @media #{$md-layout} {
            font-size: 35px;
        }
        @media #{$xs-layout} {
            font-size: 27px;
        }
    }
    @media #{$md-layout} {
        &.mb-60 {
            margin-bottom: 40px;
        }
    }
    @media #{$xs-layout} {
        &.mb-60 {
            margin-bottom: 35px;
        }
        &.mb-45 {
            margin-bottom: 22px;
        }
    }
}

.section-title-10 {
    img {
        width: 175px;
        @media #{$xs-layout} {
            width: 125px;
        }
    }
    h2 {
        font-size: 42px;
        font-style: italic;
        letter-spacing: -1px;
        font-family: $playfair;
        margin: 20px 0 27px;
        @media #{$xs-layout} {
            font-size: 30px;
            margin: 20px 0 14px;
            letter-spacing: 0px;
        }
    }
    p {
        font-size: 18px;
        @media #{$md-layout} {
            font-size: 17px;
        }
        @media #{$xs-layout} {
            font-size: 16px;
        }
    }
    @media #{$md-layout} {
        &.mb-60 { 
            margin-bottom: 40px;
        }
    }
    @media #{$xs-layout} {
        &.mb-60 { 
            margin-bottom: 25px;
        }
    }
}

.section-title-11 {
    h2 {
        font-size: 56px;
        color: #181818;
        margin: 0;
        position: relative;
        font-family: $playfair;
        display: inline-block;
        @media #{$lg-layout} {
            font-size: 48px;
        }
        @media #{$md-layout} {
            font-size: 40px;
        }
        @media #{$xs-layout} {
            font-size: 25px;
        }
        @media #{$sm-layout} {
            font-size: 30px;
        }
        &:before {
            position: absolute;
            left: -240px;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            background-color: #969696;
            height: 1px;
            width: 200px;
            @media #{$md-layout} {
                width: 100px;
                left: -140px;
            }
            @media #{$xs-layout} {
                width: 40px;
                left: -50px;
            }
        }
        &:after {
            position: absolute;
            right: -240px;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            background-color: #969696;
            height: 1px;
            width: 200px;
            @media #{$md-layout} {
                width: 100px;
                right: -140px;
            }
            @media #{$xs-layout} {
                width: 40px;
                right: -50px;
            }
        }
    }
    @media #{$md-layout} {
        &.mb-60 {
            margin-bottom: 40px;
        }
    }
    @media #{$xs-layout} {
        &.mb-60 {
            margin-bottom: 28px;
        }
        &.mb-55 {
            margin-bottom: 20px;
        }
    }
}

.subscribe-title-2 {
    h2 {
        font-size: 44px;
        color: $white;
        font-family: $playfair;
        margin: 0 0 15px;
        @media #{$xs-layout} {
            font-size: 37px;
        }
    }
    p {
        font-size: 16px;
        color: $white;
        line-height: 28px;
        width: 70%;
        @media #{$md-layout} {
            margin: 0 auto;
        }
        @media #{$xs-layout} {
            margin: 0 auto;
            width: 90%;
        }
    }
    @media #{$md-layout} {
        text-align: center;
        margin: 0 0 30px;
    }
    @media #{$xs-layout} {
        text-align: center;
        margin: 0 0 20px;
    }
}

.section-title-12 {
    h2 {
        font-family: $noto;
        font-size: 46px;
        font-weight: 500;
        line-height: 1.2em;
        letter-spacing: -1px;
        margin: 0;
        @media #{$md-layout} {
            font-size: 40px;
        }
        @media #{$xs-layout} {
            font-size: 32px;
        }
    }
    p {
        font-size: 18px;
        line-height: 36px;
        margin: 12px auto 0;
        width: 59%;
        @media #{$lg-layout} {
            font-size: 16px;
            line-height: 34px;
            width: 80%;
        }
        @media #{$md-layout} {
            font-size: 16px;
            line-height: 32px;
            width: 85%;
        }
        @media #{$xs-layout} {
            font-size: 15px;
            line-height: 30px;
            width: 92%;
        }
    }
    &.letter-spacing-negative {
        h2 {
            letter-spacing: -2px;
        } 
    }
    @media #{$md-layout} {
        &.mb-35 {
            margin-bottom: 15px;
        }
        &.mb-40 {
            margin-bottom: 20px;
        }
        &.mb-45 {
            margin-bottom: 25px;
        }
        &.mb-55 {
            margin-bottom: 35px;
        }
    }
    @media #{$xs-layout} {
        &.mb-35 {
            margin-bottom: 15px;
        }
        &.mb-40 {
            margin-bottom: 17px;
        }
        &.mb-45 {
            margin-bottom: 25px;
        }
        &.mb-55 {
            margin-bottom: 35px;
        }
    }
}

.section-title-13 {
    h2 {
        font-size: 54px;
        font-family: $satisfy;
        margin: 0;
        @media #{$md-layout} {
            font-size: 45px;
        }
        @media #{$xs-layout} {
            font-size: 36px;
        }
    }
    p {
        line-height: 28px;
        width: 56%;
        margin: 15px auto 0;
        @media #{$lg-layout} {
            width: 68%;
        }
        @media #{$md-layout} {
            width: 90%;
        }
        @media #{$xs-layout} {
            width: 95%; 
        }
    }
    @media #{$md-layout} {
        &.mb-60 {
            margin-bottom: 45px;
        }
        &.mb-50 {
            margin-bottom: 35px;
        }
        &.mb-45 {
            margin-bottom: 30px;
        }
        &.mb-35 {
            margin-bottom: 20px;
        }
    }
    @media #{$xs-layout} {
        &.mb-60 {
            margin-bottom: 20px;
        }
        &.mb-50 {
            margin-bottom: 20px;
        }
        &.mb-40 {
            margin-bottom: 20px;
        }
        &.mb-45 {
            margin-bottom: 15px;
        }
        &.mb-35 {
            margin-bottom: 5px;
        }
        &.mb-25 {
            margin-bottom: 5px;
        }
    }
}

.section-title-14 {
    h1 {
        font-size: 30px;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 0;
        @media #{$xs-layout} {
            font-size: 23px;
        }
    }
    @media #{$md-layout} {
        &.mb-55 {
            margin-bottom: 35px;
        }
    }
    @media #{$xs-layout} {
        &.mb-55 {
            margin-bottom: 25px;
        }
    }
}

.section-title-15 {
    h2 {
        font-size: 34px;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin: 0 0 28px;
        @media #{$md-layout} {
            margin: 0 0 12px;
            margin: 0 0 10px;
        }
        @media #{$xs-layout} {
            font-size: 30px;
            margin: 0 0 10px;
        }
    }
    p {
        font-size: 18px;
    }
    @media #{$md-layout} {
        text-align: center;
    }
    @media #{$xs-layout} {
        text-align: center;
        &.mb-50 {
            margin-bottom: 30px;
        }
    }
}

.section-title-16 {
    h2 {
        font-size: 24px;
        font-weight: 600;
        margin: 0;
    }
    @media #{$xs-layout} {
        &.mb-55 {
            margin-bottom: 25px;
        }
    }
}

.section-title-17 {
    h2 {
        font-size: 32px;
        font-weight: 600;
        margin: 0;
        padding: 0 0 20px 0;
        position: relative;
        display: inline-block;
        @media #{$xs-layout} {
            font-size: 26px;
            padding: 0 0 10px 0;
        }
        &:before {
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            margin: 0 auto;
            height: 3px;
            width: 88px;
            background-color: #D8D8D8;
            bottom: 0;
        }
    }
    @media #{$xs-layout} {
        &.mb-55 {
            margin-bottom: 30px;
        }
    }
}

.section-title-18 {
    h1 {
        font-size: 32px;
        margin: 0;
        line-height: 28px;
        @media #{$xs-layout} {
            font-size: 25px;
        }
    }
    @media #{$md-layout} {
        &.mb-60 {
            margin-bottom: 40px;
        }
    }
    @media #{$xs-layout} {
        &.mb-60 {
            margin-bottom: 30px;
        }
    }
}


