/*-----------------------------------------------------------------------------------
    
    Template Name: Lauriel - Multipurpose eCommerce HTML Template
    Version: 1.2
    
-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
    1. Template default CSS
    2. Animation CSS
    3. Header style
    4. Slider style
    5. Banner style
    6. Section title style
    7. Product style
    8. Testimonial style
    9. Video style
    10. Blog style
    11. Footer style
    12. Instafeed style
    13. Others style
    14. Newsletter style
    15. Services style
    16. Brand logo style
    17. Subscribe style
    18. About us style
    19. Contact us style
    20. Breadcrumb style
    21. Sidebar style
    22. Shop style
    23. Faq style
    24. Product details style
    25. Order tracking style
    26. Cart style
    27. Checkout style
    28. Compare style
    29. My account style
    30. Login register style
    31. Wishlist style
    
-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  1. Template default CSS
/*----------------------------------------*/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&family=Noto+Serif+JP:wght@300;500;600;700;900&family=Pacifico&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600&family=Poppins:wght@300;400;500;600;700;800&family=Roboto+Condensed:wght@300;400;700&display=swap");
/*-- Common Style --*/
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  line-height: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #211E1C;
  font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #211E1C;
  font-weight: 400;
  margin-top: 0;
  font-family: "Poppins", sans-serif;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #6D6D6D;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  color: inherit;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #211E1C;
}

a,
button,
img,
input,
span {
  transition: all .3s ease 0s;
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button,
input[type="submit"] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/*-- 
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.section {
  float: left;
  width: 100%;
}

.f-right {
  float: right;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.bg-img {
  background-position: center center;
  background-size: cover;
}

.position-relative {
  position: relative;
}

/*************************
  Basic margin padding
*************************/
.m-0 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.p-0 {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

/*************************
         Margin top
*************************/
.mt-0 {
  margin-top: 0;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-130 {
    margin-top: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-130 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .mt-130 {
    margin-top: 60px;
  }
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-175 {
  margin-top: 175px;
}

/*************************
      Margin right
*************************/
.mr-0 {
  margin-right: 0px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-150 {
  margin-right: 150px;
}

/*************************
      Margin bottom
*************************/
.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-37 {
  margin-bottom: 37px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-120 {
  margin-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-120 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .mb-120 {
    margin-bottom: 60px;
  }
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-150 {
  margin-bottom: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-150 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .mb-150 {
    margin-bottom: 60px;
  }
}

.mb-160 {
  margin-bottom: 160px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-160 {
    margin-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-160 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .mb-160 {
    margin-bottom: 60px;
  }
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-180 {
  margin-bottom: 180px;
}

/*************************
        Margin left
*************************/
.ml-0 {
  margin-left: 0;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-200 {
  margin-left: 200px;
}

.ml-300 {
  margin-left: 300px;
}

.ml-ntv5 {
  margin-left: -5px;
}

/*************************
        Padding top
*************************/
.pt-0 {
  padding-top: 0;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-100 {
  padding-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-100 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-100 {
    padding-top: 40px;
  }
}

.pt-105 {
  padding-top: 105px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-105 {
    padding-top: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-105 {
    padding-top: 45px;
  }
}

.pt-110 {
  padding-top: 110px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-110 {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-110 {
    padding-top: 50px;
  }
}

.pt-115 {
  padding-top: 115px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-115 {
    padding-top: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-115 {
    padding-top: 55px;
  }
}

.pt-120 {
  padding-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-120 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-120 {
    padding-top: 60px;
  }
}

.pt-125 {
  padding-top: 125px;
}

.pt-128 {
  padding-top: 128px;
}

.pt-130 {
  padding-top: 130px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-130 {
    padding-top: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-130 {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-130 {
    padding-top: 30px;
  }
}

.pt-135 {
  padding-top: 135px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-135 {
    padding-top: 75px;
  }
}

.pt-165 {
  padding-top: 165px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-165 {
    padding-top: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-165 {
    padding-top: 45px;
  }
}

.pt-140 {
  padding-top: 140px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-140 {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-140 {
    padding-top: 50px;
  }
}

.pt-145 {
  padding-top: 145px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-145 {
    padding-top: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-145 {
    padding-top: 55px;
  }
}

.pt-150 {
  padding-top: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-150 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-150 {
    padding-top: 60px;
  }
}

.pt-155 {
  padding-top: 155px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-155 {
    padding-top: 95px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-155 {
    padding-top: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-155 {
    padding-top: 55px;
  }
}

.pt-160 {
  padding-top: 160px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-160 {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-160 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-160 {
    padding-top: 60px;
  }
}

.pt-170 {
  padding-top: 170px;
}

.pt-180 {
  padding-top: 180px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-180 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-180 {
    padding-top: 50px;
  }
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

@media only screen and (max-width: 767px) {
  .pt-190 {
    padding-top: 80px;
  }
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

@media only screen and (max-width: 767px) {
  .pt-200 {
    padding-top: 70px;
  }
}

.pt-220 {
  padding-top: 220px;
}

.pt-250 {
  padding-top: 250px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-250 {
    padding-top: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-250 {
    padding-top: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-250 {
    padding-top: 60px;
  }
}

.pt-255 {
  padding-top: 255px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-255 {
    padding-top: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-255 {
    padding-top: 80px;
  }
}

.pt-210 {
  padding-top: 210px;
}

.pt-230 {
  padding-top: 230px;
}

.pt-270 {
  padding-top: 270px;
}

.pt-290 {
  padding-top: 290px;
}

.pt-295 {
  padding-top: 295px;
}

.pt-280 {
  padding-top: 280px;
}

.pt-240 {
  padding-top: 240px;
}

.pt-330 {
  padding-top: 330px;
}

.pt-340 {
  padding-top: 340px;
}

.pt-350 {
  padding-top: 350px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-350 {
    padding-top: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-350 {
    padding-top: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-350 {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .pt-350 {
    padding-top: 120px;
  }
}

.pt-365 {
  padding-top: 365px;
}

.pt-360 {
  padding-top: 360px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-360 {
    padding-top: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-360 {
    padding-top: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-360 {
    padding-top: 100px;
  }
}

.pt-370 {
  padding-top: 370px;
}

.pt-380 {
  padding-top: 380px;
}

.pt-400 {
  padding-top: 400px;
}

/*************************
        Padding right
*************************/
.pr-0 {
  padding-right: 0;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-270 {
  padding-right: 270px;
}

/*************************
        Padding bottom
*************************/
.pb-0 {
  padding-bottom: 0;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-105 {
    padding-bottom: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-105 {
    padding-bottom: 45px;
  }
}

.pb-110 {
  padding-bottom: 110px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-110 {
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-110 {
    padding-bottom: 50px;
  }
}

.pb-115 {
  padding-bottom: 115px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-115 {
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-115 {
    padding-bottom: 55px;
  }
}

.pb-120 {
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-120 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-120 {
    padding-bottom: 60px;
  }
}

.pb-125 {
  padding-bottom: 125px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-125 {
    padding-bottom: 65px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-125 {
    padding-bottom: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-125 {
    padding-bottom: 35px;
  }
}

.pb-130 {
  padding-bottom: 130px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-130 {
    padding-bottom: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-130 {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-130 {
    padding-bottom: 30px;
  }
}

.pb-135 {
  padding-bottom: 135px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-135 {
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-135 {
    padding-bottom: 60px;
  }
}

.pb-140 {
  padding-bottom: 140px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-140 {
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-140 {
    padding-bottom: 60px;
  }
}

.pb-145 {
  padding-bottom: 145px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-145 {
    padding-bottom: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-145 {
    padding-bottom: 20px;
  }
}

.pb-150 {
  padding-bottom: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-150 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-150 {
    padding-bottom: 60px;
  }
}

.pb-155 {
  padding-bottom: 155px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-155 {
    padding-bottom: 95px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-155 {
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-155 {
    padding-bottom: 55px;
  }
}

.pb-160 {
  padding-bottom: 160px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-160 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-160 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-160 {
    padding-bottom: 60px;
  }
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-180 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-180 {
    padding-bottom: 50px;
  }
}

.pb-280 {
  padding-bottom: 280px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pb-205 {
  padding-bottom: 205px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-220 {
  padding-bottom: 220px;
}

.pb-215 {
  padding-bottom: 215px;
}

.pb-250 {
  padding-bottom: 250px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-250 {
    padding-bottom: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-250 {
    padding-bottom: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-250 {
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .pb-250 {
    padding-bottom: 100px;
  }
}

.pb-285 {
  padding-bottom: 285px;
}

.pb-270 {
  padding-bottom: 270px;
}

.pb-290 {
  padding-bottom: 290px;
}

.pb-315 {
  padding-bottom: 315px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-315 {
    padding-bottom: 215px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-315 {
    padding-bottom: 120px;
  }
}

.pb-330 {
  padding-bottom: 330px;
}

.pb-335 {
  padding-bottom: 335px;
}

.pb-340 {
  padding-bottom: 340px;
}

.pb-325 {
  padding-bottom: 325px;
}

.pb-320 {
  padding-bottom: 300px;
}

/*************************
    Padding left
*************************/
.pl-0 {
  padding-left: 0;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-190 {
  padding-left: 190px;
}

/***************************
    Page section padding 
****************************/
.ptb-0 {
  padding: 0;
}

.ptb-10 {
  padding: 10px 0;
}

.ptb-20 {
  padding: 20px 0;
}

.ptb-30 {
  padding: 30px 0;
}

.ptb-35 {
  padding: 35px 0;
}

.ptb-32 {
  padding: 32px 0;
}

.ptb-40 {
  padding: 40px 0;
}

.ptb-50 {
  padding: 50px 0;
}

.ptb-60 {
  padding: 60px 0;
}

.ptb-70 {
  padding: 70px 0;
}

.ptb-80 {
  padding: 80px 0;
}

.ptb-90 {
  padding: 90px 0;
}

.ptb-100 {
  padding: 100px 0;
}

.ptb-110 {
  padding: 110px 0;
}

.ptb-120 {
  padding: 120px 0;
}

.ptb-130 {
  padding: 130px 0;
}

.ptb-140 {
  padding: 140px 0;
}

.ptb-150 {
  padding: 150px 0;
}

.ptb-160 {
  padding: 160px 0;
}

.ptb-170 {
  padding: 170px 0;
}

.ptb-177 {
  padding: 177px 0;
}

.ptb-180 {
  padding: 180px 0;
}

.ptb-190 {
  padding: 190px 0;
}

.ptb-200 {
  padding: 200px 0;
}

.ptb-210 {
  padding: 210px 0;
}

.ptb-220 {
  padding: 220px 0;
}

.ptb-290 {
  padding: 290px 0;
}

.ptb-310 {
  padding: 310px 0;
}

/***************************
    Page section margin 
****************************/
.mtb-0 {
  margin: 0;
}

.mtb-10 {
  margin: 10px 0;
}

.mtb-15 {
  margin: 15px 0;
}

.mtb-20 {
  margin: 20px 0;
}

.mtb-30 {
  margin: 30px 0;
}

.mtb-40 {
  margin: 40px 0;
}

.mtb-50 {
  margin: 50px 0;
}

.mtb-60 {
  margin: 60px 0;
}

.mtb-70 {
  margin: 70px 0;
}

.mtb-80 {
  margin: 80px 0;
}

.mtb-90 {
  margin: 90px 0;
}

.mtb-100 {
  margin: 100px 0;
}

.mtb-110 {
  margin: 110px 0;
}

.mtb-120 {
  margin: 120px 0;
}

.mtb-130 {
  margin: 130px 0;
}

.mtb-140 {
  margin: 140px 0;
}

.mtb-150 {
  margin: 150px 0;
}

.mtb-290 {
  margin: 290px 0;
}

/*-- 
    - Input Placeholder
-----------------------------------------*/
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input {
  background: #eceff8;
  border: 2px solid #eceff8;
  height: 45px;
  box-shadow: none;
  padding-left: 10px;
  font-size: 14px;
  color: #737373;
  width: 100%;
}

select {
  width: 100%;
  background: transparent;
  border: 0px solid #eceff8;
  box-shadow: none;
  font-size: 14px;
  color: #737373;
}

option {
  background: #fff;
  border: 0px solid #626262;
  padding-left: 10px;
  font-size: 14px;
}

input:focus {
  background: transparent;
}

textarea {
  resize: vertical;
  background: #eceff8;
  border: 2px solid #eceff8;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  color: #737373;
}

textarea:focus {
  background: transparent;
  border: 2px solid #4FC1F0;
  outline: none;
}

.custom-d-flex {
  display: flex !important;
}

.custom-align-item-center {
  align-items: center;
}

.custom-align-item-end {
  align-items: flex-end;
}

.justify-content-center {
  align-items: center;
}

.custom-col-5 {
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .custom-col-5 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-col-5 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-col-5 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-5 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .custom-col-5.custom-col-md-33 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-5 {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .custom-col-5.custom-col-xs-50 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-col-5 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .custom-col-5.custom-col-sm-33 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

/*-- 
    Scroll Up 
-----------------------------------------*/
#scrollUp {
  width: 50px;
  height: 50px;
  background-color: #262626;
  color: #fff;
  right: 50px;
  bottom: 53px;
  text-align: center;
  overflow: hidden;
  border-radius: 50px;
  z-index: 9811 !important;
}

@media only screen and (max-width: 767px) {
  #scrollUp {
    width: 40px;
    height: 40px;
    right: 20px;
  }
}

#scrollUp i {
  display: block;
  line-height: 50px;
  font-size: 22px;
}

@media only screen and (max-width: 767px) {
  #scrollUp i {
    line-height: 40px;
    font-size: 16px;
  }
}

.height-100vh {
  height: 100vh !important;
}

.transparent-bar {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}

/*--
    Background Color
------------------------------------------*/
.bg-gray {
  background-color: #F9F9F9;
}

.bg-gray-2 {
  background-color: #F5F1ED;
}

.bg-gray-3 {
  background-color: #FBFBFB;
}

.bg-white {
  background-color: #fff;
}

.bg-yellow {
  background-color: #D98719;
}

.bg-yellow-2 {
  background-color: #FFD53D;
}

.bg-light-yellow {
  background-color: #FEFFEB;
}

.bg-red {
  background-color: #e41515;
}

.bg-black {
  background-color: #181818;
}

.bg-pink {
  background-color: #FFE5E5;
}

.bg-green {
  background-color: #538165;
}

.bg-cider {
  background-color: #A68A76;
}

.bg-jade-green {
  background-color: #81CCA4;
}

.bg-steel {
  background-color: #707070;
}

.bg-goldenrod {
  background-color: #CABD8F;
}

.border-top-1 {
  border-top: 1px solid #D8D8D8;
}

.border-top-2 {
  border-top: 1px solid #444;
}

.border-top-3 {
  border-top: 1px solid #ebebeb;
}

.border-bottom-1 {
  border-bottom: 1px solid #D6D6D6;
}

.border-bottom-2 {
  border-bottom: 1px solid #D8D8D8;
}

.border-bottom-3 {
  border-bottom: 1px solid #ebebeb;
}

.default-overlay {
  position: relative;
}

.default-overlay:before {
  content: "";
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 250ms ease-out;
  z-index: 9;
}

.default-overlay.overly-point-4:before {
  background-color: #181818;
  opacity: .4;
}

.default-overlay.overly-point-3:before {
  background-color: #181818;
  opacity: .3;
}

.default-overlay.overly-point-2:before {
  background-color: #181818;
  opacity: .2;
}

.default-overlay-white {
  position: relative;
}

.default-overlay-white:before {
  content: "";
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 250ms ease-out;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .res-white-overly-xs {
    position: relative;
  }
  .res-white-overly-xs:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #fff;
    opacity: .4;
    z-index: 9;
  }
}

@media only screen and (max-width: 767px) {
  .res-black-overly-xs {
    position: relative;
  }
  .res-black-overly-xs:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #000;
    opacity: .5;
    z-index: 9;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .res-white-overly-md {
    position: relative;
  }
  .res-white-overly-md:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #fff;
    opacity: .5;
    z-index: 1;
  }
}

@media only screen and (max-width: 767px) {
  .xs-mb-30 {
    margin-bottom: 30px;
  }
}

/* img bounce */
@-webkit-keyframes bounce-img {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 1;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes bounce-img {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 1;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

/* img bounce 2 */
@-webkit-keyframes bounce-img-2 {
  0% {
    opacity: 1;
    transform: translateX(0px);
  }
  50% {
    opacity: 1;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes bounce-img-2 {
  0% {
    opacity: 1;
    transform: translateX(0px);
  }
  50% {
    opacity: 1;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.padding-40-row-col .row {
  margin-right: -20px;
  margin-left: -20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-40-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-40-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-40-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-40-row-col .row div[class^="col-"] {
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-40-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-40-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-40-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-80-row-col .row {
  margin-right: -40px;
  margin-left: -40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-80-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-80-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-80-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-80-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-80-row-col .row div[class^="col-"] {
  padding-left: 40px;
  padding-right: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-80-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-80-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-80-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-80-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-106-row-col .row {
  margin-right: -53px;
  margin-left: -53px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-106-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-106-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-106-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-106-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-106-row-col .row div[class^="col-"] {
  padding-left: 53px;
  padding-right: 53px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-106-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-106-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-106-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-106-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-20-row-col .row {
  margin-right: -10px;
  margin-left: -10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-20-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-20-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-20-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-20-row-col .row div[class^="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-20-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-20-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-20-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-60-row-col .row {
  margin-right: -30px;
  margin-left: -30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-60-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-60-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-60-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-60-row-col .row div[class^="col-"] {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-60-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-60-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-60-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-60-row-col-2 .row {
  margin-right: -30px;
  margin-left: -30px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .padding-60-row-col-2 .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-60-row-col-2 .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-60-row-col-2 .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-60-row-col-2 .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-60-row-col-2 .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-60-row-col-2 .row div[class^="col-"] {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .padding-60-row-col-2 .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-60-row-col-2 .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-60-row-col-2 .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-60-row-col-2 .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-60-row-col-2 .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-50-row-col .row {
  margin-right: -25px;
  margin-left: -25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-50-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-50-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-50-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-50-row-col .row div[class^="col-"] {
  padding-left: 25px;
  padding-right: 25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-50-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-50-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-50-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-100-row-col .row {
  margin-right: -50px;
  margin-left: -50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-100-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-100-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-100-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-100-row-col .row div[class^="col-"] {
  padding-left: 50px;
  padding-right: 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-100-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-100-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-100-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-8-row-col .row {
  margin-right: -4px;
  margin-left: -4px;
}

.padding-8-row-col .row div[class^="col-"] {
  padding-left: 4px;
  padding-right: 4px;
}

.section-padding-1 .container-fluid {
  padding: 0 140px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-1 .container-fluid {
    padding: 0 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-1 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-1 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-2 .container-fluid {
  padding: 0 200px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-2 .container-fluid {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-2 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-2 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-2 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-2 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-3 .container-fluid {
  padding: 0 120px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-3 .container-fluid {
    padding: 0 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-3 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-3 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-3 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-3 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-4 .container-fluid {
  padding: 0 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-4 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-4 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-4 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-4 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-5 .container-fluid {
  padding: 0 100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-5 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-5 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-5 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-5 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-6 .container-fluid {
  padding: 0 250px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-6 .container-fluid {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-6 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-6 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-6 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-6 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-7 .container-fluid {
  padding: 0 300px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-7 .container-fluid {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-7 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-7 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-7 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-7 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-8 .container-fluid {
  padding: 0 150px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-8 .container-fluid {
    padding: 0 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-8 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-8 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-8 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-8 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-9 .container-fluid {
  padding: 0 130px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-9 .container-fluid {
    padding: 0 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-9 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-9 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-9 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-9 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-10 .container-fluid {
  padding: 0 275px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-10 .container-fluid {
    padding: 0 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-10 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-10 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-10 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-10 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-11 .container-fluid {
  padding: 0 170px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-11 .container-fluid {
    padding: 0 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-11 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-11 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-11 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-11 .container-fluid {
    padding: 0 15px;
  }
}

.parallax-img {
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

.col-custom-61 {
  flex: 0 0 61%;
  max-width: 61%;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .col-custom-61 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-custom-61 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .col-custom-61 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-custom-39 {
  flex: 0 0 39%;
  max-width: 39%;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .col-custom-39 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-custom-39 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .col-custom-39 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-custom-40 {
  flex: 0 0 39%;
  max-width: 39%;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-custom-40 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .col-custom-40 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-custom-60 {
  flex: 0 0 60%;
  max-width: 60%;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-custom-60 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .col-custom-60 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-custom-35 {
  flex: 0 0 35%;
  max-width: 35%;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-custom-35 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .col-custom-35 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-custom-64 {
  flex: 0 0 64%;
  max-width: 64%;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-custom-64 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .col-custom-64 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-banner-42 {
  flex: 0 0 42.927%;
  max-width: 42.927%;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .col-banner-42 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-banner-42 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .col-banner-42 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-banner-28 {
  flex: 0 0 28.72%;
  max-width: 28.72%;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .col-banner-28 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-banner-28 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .col-banner-28 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-banner-27 {
  flex: 0 0 27.992%;
  max-width: 27.992%;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .col-banner-27 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-banner-27 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .col-banner-27 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-32-shop {
  flex: 0 0 32%;
  max-width: 32%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (max-width: 767px) {
  .col-32-shop {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-32-shop {
    flex: 0 0 40%;
    max-width: 40%;
  }
}

@media only screen and (min-width: 410px) and (max-width: 575px) {
  .col-32-shop {
    flex: 0 0 40%;
    max-width: 40%;
  }
}

.col-68-shop {
  flex: 0 0 68%;
  max-width: 68%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (max-width: 767px) {
  .col-68-shop {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-68-shop {
    flex: 0 0 60%;
    max-width: 60%;
  }
}

@media only screen and (min-width: 410px) and (max-width: 575px) {
  .col-68-shop {
    flex: 0 0 60%;
    max-width: 60%;
  }
}

.col-42-shop {
  flex: 0 0 42%;
  max-width: 42%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-42-shop {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .col-42-shop {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-42-shop {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-58-shop {
  flex: 0 0 58%;
  max-width: 58%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-58-shop {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .col-58-shop {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-58-shop {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-46-team {
  flex: 0 0 46%;
  max-width: 46%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .col-46-team {
    flex: 0 0 34%;
    max-width: 34%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-46-team {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .col-46-team {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-46-team {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-54-team {
  flex: 0 0 54%;
  max-width: 54%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .col-54-team {
    flex: 0 0 66%;
    max-width: 66%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-54-team {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .col-54-team {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-54-team {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 410px) and (max-width: 575px) {
  .col-54-team .col-12 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.col-63-contact {
  flex: 0 0 63%;
  max-width: 63%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-63-contact {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .col-63-contact {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-63-contact {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-36-contact {
  flex: 0 0 36%;
  max-width: 36%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-36-contact {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .col-36-contact {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-36-contact {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/*-------- 2. Animation CSS ---------*/
@keyframes hastechFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hastechZoomIn {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hastechZoomOut {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hastechMoveUp {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hastechMoveUpBig {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hastechMoveDown {
  0% {
    opacity: 0;
    transform: translateY(-25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hastechMoveDownBig {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hastechMoveLeft {
  0% {
    opacity: 0;
    transform: translateX(25px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes hastechMoveLeftBig {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes hastechMoveRight {
  0% {
    opacity: 0;
    transform: translateX(-25px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes hastechMoveRightBig {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes hastechFallPerspective {
  0% {
    opacity: 0;
    transform: perspective(1000px) translateY(50px) translateZ(-300px) rotateX(-35deg);
  }
  100% {
    opacity: 1;
    transform: perspective(1000px) translateY(0) translateZ(0) rotateX(0deg);
  }
}

@keyframes hastechFlipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}

@keyframes hastechFlipInY {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes zoomOut {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes moveUp {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes columnMoveUp {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fallPerspective {
  0% {
    opacity: 0;
    transform: perspective(1000px) translateY(50px) translateZ(-300px) rotateX(-35deg);
  }
  100% {
    opacity: 1;
    transform: perspective(1000px) translateY(0) translateZ(0) rotateX(0deg);
  }
}

@keyframes edit-button-pulse {
  0% {
    box-shadow: 0 0 2px 0 rgba(183, 8, 78, 0.6);
  }
  30% {
    box-shadow: 0 0 2px 10px rgba(183, 8, 78, 0);
  }
  100% {
    box-shadow: 0 0 2px 0 rgba(183, 8, 78, 0);
  }
}

@keyframes shortfadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.shortfadeInUp {
  animation-name: shortfadeInUp;
}

.animated-duration {
  animation-duration: 1.25s;
}

.custom-delay-200 {
  animation-delay: .2s;
}

.custom-delay-300 {
  animation-delay: .3s;
}

.custom-delay-400 {
  animation-delay: .4s;
}

.custom-delay-500 {
  animation-delay: .5s;
}

.custom-delay-600 {
  animation-delay: .6s;
}

.custom-delay-700 {
  animation-delay: .7s;
}

.custom-delay-800 {
  animation-delay: .8s;
}

.custom-delay-900 {
  animation-delay: .9s;
}

/*-------- 3. Header style ---------*/
.header-small-device {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-small-device {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .header-small-device {
    display: block;
  }
}

.header-small-device.small-device-ptb-1 {
  padding: 20px 0 0;
}

.header-small-device.small-device-ptb-2 {
  padding: 30px 0 30px;
}

.header-small-device.small-device-ptb-3 {
  padding: 20px 0 20px;
}

.header-small-device .small-device-categori-wrap {
  padding: 20px 0 0;
}

@media only screen and (max-width: 767px) {
  .header-small-device .small-device-categori-wrap .header-offer-cart-wrap {
    padding: 0px 0 20px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 576px) and (max-width: 767px) {
  .header-small-device .small-device-categori-wrap .header-offer-cart-wrap {
    padding: 12px 0;
    justify-content: flex-end;
  }
}

.header-large-device {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-large-device {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header-large-device {
    display: none;
  }
}

.header-ptb-1 {
  padding: 50px 0;
}

@media only screen and (max-width: 767px) {
  .header-ptb-1 {
    padding: 30px 0;
  }
}

.logo-width-1 a,
.mobile-logo-width a {
  display: inline-block;
}

.logo-width-1 a img,
.mobile-logo-width a img {
  width: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .logo-width-1 a img,
  .mobile-logo-width a img {
    width: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .logo-width-1 a img,
  .mobile-logo-width a img {
    width: 100px;
  }
}

.logo-width-4 a {
  display: inline-block;
}

.logo-width-4 a img {
  width: 162px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .logo-width-4 a img {
    width: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .logo-width-4 a img {
    width: 120px;
  }
}

.logo-width-3 a {
  display: inline-block;
}

.logo-width-3 a img {
  width: 300px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .logo-width-3 a img {
    width: 180px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .logo-width-3 a img {
    width: 180px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .logo-width-3 a img {
    width: 150px;
  }
}

.header-action-wrap.header-action-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: -5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-action-wrap.header-action-flex {
    margin-bottom: -8px;
  }
}

@media only screen and (max-width: 767px) {
  .header-action-wrap.header-action-flex {
    margin-bottom: -8px;
  }
}

.header-action-wrap.header-action-mrg-1 .same-style {
  margin-left: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-action-wrap.header-action-mrg-1 .same-style {
    margin-left: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .header-action-wrap.header-action-mrg-1 .same-style {
    margin-left: 23px;
  }
}

.header-action-wrap.header-action-mrg-1 .same-style:first-child {
  margin-left: 0;
}

.header-action-wrap.header-action-mrg-2 .same-style {
  margin-left: 10px;
}

.header-action-wrap.header-action-mrg-2 .same-style:first-child {
  margin-left: 0;
}

.header-action-wrap .same-style > a {
  color: #fff;
  font-size: 20px;
  display: inline-block;
  position: relative;
}

.header-action-wrap .same-style > a:hover {
  color: #D98719;
}

.header-action-wrap .same-style > a:hover.pink {
  color: #FF7473;
}

.header-action-wrap .same-style > a:hover.green {
  color: #538165;
}

.header-action-wrap .same-style > a:hover.green-2 {
  color: #6BB33E;
}

.header-action-wrap .same-style > a:hover.yellow {
  color: #D98719;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-action-wrap .same-style > a.same-style-hvr-red:hover {
    color: #e41515;
  }
}

@media only screen and (max-width: 767px) {
  .header-action-wrap .same-style > a.same-style-hvr-red:hover {
    color: #e41515;
  }
}

.header-action-wrap .same-style > a span {
  position: absolute;
  top: -14px;
  font-size: 10px;
  width: 18px;
  height: 18px;
  display: inline-block;
  line-height: 18px;
  color: #fff;
  text-align: center;
  right: -6px;
  border-radius: 10px;
}

.header-action-wrap .same-style > a span.yellow {
  background-color: #D98719;
}

.header-action-wrap .same-style > a span.red {
  background-color: #e41515;
}

.header-action-wrap .same-style > a span.pink {
  background-color: #FF7473;
}

.header-action-wrap .same-style > a span.green {
  background-color: #538165;
}

.header-action-wrap .same-style.same-style-black > a {
  color: #181818;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-action-wrap .same-style.same-style-black > a:hover {
    color: #D98719;
  }
  .header-action-wrap .same-style.same-style-black > a.same-style-hvr-red:hover {
    color: #e41515;
  }
  .header-action-wrap .same-style.same-style-black > a.same-style-hvr-pink:hover {
    color: #FF7473;
  }
  .header-action-wrap .same-style.same-style-black > a.same-style-hvr-green:hover {
    color: #538165;
  }
}

@media only screen and (max-width: 767px) {
  .header-action-wrap .same-style.same-style-black > a:hover {
    color: #D98719;
  }
  .header-action-wrap .same-style.same-style-black > a.same-style-hvr-red:hover {
    color: #e41515;
  }
  .header-action-wrap .same-style.same-style-black > a.same-style-hvr-pink:hover {
    color: #FF7473;
  }
  .header-action-wrap .same-style.same-style-black > a.same-style-hvr-green:hover {
    color: #538165;
  }
}

.header-action-wrap .same-style.main-menu-icon > a {
  font-size: 26px;
}

.header-action-wrap .same-style.same-style-border > a {
  border: 2px solid rgba(255, 255, 255, 0.3);
  padding: 16px 13px 13px;
}

.header-action-wrap .same-style.same-style-border > a.red {
  color: #ffffff;
}

.header-action-wrap .same-style.same-style-border > a.red:hover {
  color: #e41515;
}

.search-style-1 form .form-search-1 {
  position: relative;
}

.search-style-1 form .form-search-1 input {
  font-size: 20px;
  color: #ffffff;
  border: none;
  background-color: transparent;
  height: 30px;
  padding: 2px 50px 2px 0;
  width: 400px;
}

.search-style-1 form .form-search-1 input::-moz-input-placeholder {
  color: #ffffff !important;
  opacity: 1 !important;
}

.search-style-1 form .form-search-1 input::-webkit-input-placeholder {
  color: #ffffff !important;
  opacity: 1 !important;
}

.search-style-1 form .form-search-1 button {
  border: none;
  background-color: transparent;
  font-size: 20px;
  color: #ffffff;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  transition: all .3s ease 0s;
}

.search-style-1 form .form-search-1 button:hover {
  color: #D98719;
}

.main-wrapper .body-overlay {
  background-color: #232324;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}

.main-wrapper.overlay-active .body-overlay {
  opacity: .7;
  visibility: visible;
  z-index: 9999;
}

.main-wrapper-2 .body-overlay-2 {
  background-color: #232324;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 400ms ease-out;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}

.main-wrapper-2.overlay-active-2 .body-overlay-2 {
  opacity: .7;
  visibility: visible;
  z-index: 9999;
}

.sidebar-cart-active {
  position: fixed;
  top: 0;
  width: 420px;
  padding: 104px 50px 50px;
  min-height: 100vh;
  bottom: 0;
  z-index: 9999;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-in;
  -moz-transition: all 250ms ease-in;
  transform: translate(200px, 0);
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
}

.sidebar-cart-active.inside {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active {
    width: 350px;
    padding: 104px 30px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active {
    padding: 60px 15px 50px;
    width: 280px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close {
  position: absolute;
  top: 48px;
  right: 50px;
  font-size: 36px;
  line-height: 30px;
  color: #6D6D6D;
  transition: all 250ms ease-out;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all .cart-close {
    right: 30px;
    font-size: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-close {
    top: 20px;
    right: 17px;
    font-size: 23px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close:hover {
  transform: rotate(90deg);
  color: #D98719;
}

.sidebar-cart-active .sidebar-cart-all .cart-content > h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 35px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul {
  margin: 0 -30px 0 0;
  padding: 0 30px 0 0;
  max-height: 310px;
  max-height: calc(100vh - 430px);
  overflow: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content ul {
    max-height: calc(100vh - 365px);
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content ul {
    max-height: calc(100vh - 360px);
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li {
  display: flex;
  margin-bottom: 30px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-img {
  flex: 0 0 70px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-img a img {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title {
  margin-left: 20px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title h4 {
  font-size: 15px;
  margin: 0 0 8px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title h4 a {
  color: #211E1C;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title h4 a:hover {
  color: #D98719;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title span {
  font-size: 15px;
  color: #6D6D6D;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete {
  display: flex;
  flex-grow: 100;
  justify-content: flex-end;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete a {
  display: block;
  font-size: 16px;
  height: 20px;
  line-height: 17px;
  overflow: hidden;
  width: 20px;
  text-align: right;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total {
  display: block;
  width: 100%;
  margin: 16px 0 26px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 {
  font-size: 16px;
  margin: 0;
  font-weight: 600;
  color: #6D6D6D;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 span {
  font-size: 15px;
  color: #6D6D6D;
  float: right;
  font-weight: 400;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn {
  display: block;
  width: 100%;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a {
  width: 100%;
  display: block;
  margin: 10px 0 0;
  text-align: center;
  line-height: 20px;
  padding: 19px 20px 20px;
  background-color: #181818;
  color: #fff;
  text-transform: capitalize;
  font-size: 16px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a:hover {
  background-color: #D98719;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a:first-child {
  margin-top: 0;
}

.welcome-content p {
  color: #181818;
  font-size: 15px;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .welcome-content p {
    font-size: 14px;
  }
}

.header-top-right {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.same-style-2 {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .same-style-2 {
    margin-right: 15px;
  }
}

.same-style-2:last-child {
  margin-right: 0;
}

.same-style-2 a {
  color: #181818;
  font-size: 15px;
}

.same-style-2 a i {
  position: relative;
  margin-right: 5px;
  top: 2px;
}

.same-style-2 a:hover {
  color: #D98719;
}

.same-style-2.same-style-2-red a:hover {
  color: #e41515;
}

.header-language-wrap, .header-currency-wrap {
  position: relative;
}

.header-language-wrap .language-dropdown, .header-language-wrap .currency-dropdown, .header-currency-wrap .language-dropdown, .header-currency-wrap .currency-dropdown {
  background: #fff none repeat scroll 0 0;
  position: absolute;
  left: 50%;
  top: 112%;
  width: 120px;
  z-index: 9999;
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.1);
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease 0s;
  text-align: center;
}

.header-language-wrap .language-dropdown ul, .header-language-wrap .currency-dropdown ul, .header-currency-wrap .language-dropdown ul, .header-currency-wrap .currency-dropdown ul {
  padding: 10px 0px;
}

.header-language-wrap .language-dropdown ul li, .header-language-wrap .currency-dropdown ul li, .header-currency-wrap .language-dropdown ul li, .header-currency-wrap .currency-dropdown ul li {
  display: block;
}

.header-language-wrap .language-dropdown ul li a, .header-language-wrap .currency-dropdown ul li a, .header-currency-wrap .language-dropdown ul li a, .header-currency-wrap .currency-dropdown ul li a {
  display: block;
  padding: 10px 5px;
  color: #181818;
  font-size: 14px;
  line-height: 1;
}

.header-language-wrap .language-dropdown ul li a:hover, .header-language-wrap .currency-dropdown ul li a:hover, .header-currency-wrap .language-dropdown ul li a:hover, .header-currency-wrap .currency-dropdown ul li a:hover {
  color: #D98719;
}

.header-language-wrap .language-dropdown.show, .header-language-wrap .currency-dropdown.show, .header-currency-wrap .language-dropdown.show, .header-currency-wrap .currency-dropdown.show {
  opacity: 1;
  visibility: visible;
}

.header-language-wrap .language-dropdown.lang-bg-black, .header-language-wrap .currency-dropdown.lang-bg-black, .header-currency-wrap .language-dropdown.lang-bg-black, .header-currency-wrap .currency-dropdown.lang-bg-black {
  background-color: #181818;
}

.header-language-wrap .language-dropdown.lang-bg-black ul li a, .header-language-wrap .currency-dropdown.lang-bg-black ul li a, .header-currency-wrap .language-dropdown.lang-bg-black ul li a, .header-currency-wrap .currency-dropdown.lang-bg-black ul li a {
  color: #ffffff;
}

.header-language-wrap .language-dropdown.lang-bg-black ul li a:hover, .header-language-wrap .currency-dropdown.lang-bg-black ul li a:hover, .header-currency-wrap .language-dropdown.lang-bg-black ul li a:hover, .header-currency-wrap .currency-dropdown.lang-bg-black ul li a:hover {
  color: #e41515;
}

.header-top-ptb-1 {
  padding: 16px 0 18px;
}

.header-search-categori-mrg {
  margin: 0 -30px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-search-categori-mrg {
    margin: 0 0px 0 -200px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-search-categori-mrg {
    margin: 0 0px 0 -200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-search-categori-mrg {
    margin: 0 -20px 0 12px;
  }
}

.header-search-categori-wrap form {
  display: flex;
  position: relative;
  border: 2px solid rgba(24, 24, 24, 0.1);
}

.header-search-categori-wrap form input.search-field {
  order: -1;
  color: #181818;
  font-size: 15px;
  background-color: transparent;
  font-weight: 600;
  border: none;
  height: 60px;
  padding: 2px 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-search-categori-wrap form input.search-field {
    width: 47%;
    padding: 2px 10px;
  }
}

.header-search-categori-wrap form select {
  height: 60px;
  border: none;
  margin-right: 56px;
  width: 150px;
  padding-right: 25px;
  padding-left: 20px;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: #181818;
  cursor: pointer;
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-search-categori-wrap form select {
    padding-left: 10px;
    margin-right: 45px;
  }
}

.header-search-categori-wrap form button.search-button {
  position: absolute;
  right: 25px;
  top: 53%;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  padding: 0;
  font-size: 22px;
  color: #181818;
  transition: all .3s ease 0s;
}

.header-search-categori-wrap form button.search-button:hover {
  color: #D98719;
}

.header-support-wrap {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.header-support-wrap .header-single-support {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.header-support-wrap .header-single-support:first-child {
  margin-left: 0;
}

.header-support-wrap .header-single-support .header-support-icon {
  margin-right: 10px;
}

.header-support-wrap .header-single-support .header-support-icon a {
  color: #1a1a1a;
  font-size: 30px;
  display: block;
}

.header-support-wrap .header-single-support .header-support-content span {
  color: #6D6D6D;
  line-height: 1;
  display: block;
}

.header-support-wrap .header-single-support .header-support-content h3 {
  color: #1A1A1A;
  font-size: 20px;
  margin: 5px 0 0;
  font-weight: 600;
  line-height: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-support-wrap .header-single-support .header-support-content h3 {
    font-size: 17px;
  }
}

.header-support-wrap .header-single-support .header-support-content h3 a {
  color: #1A1A1A;
}

.header-support-wrap .header-single-support.red:hover .header-support-icon a {
  color: #e41515;
}

.header-support-wrap .header-single-support.red:hover .header-support-content h3 a {
  color: #e41515;
}

.header-support-wrap .header-single-support:hover .header-support-icon a {
  color: #FFD53D;
}

.header-support-wrap .header-single-support:hover .header-support-content h3 a {
  color: #FFD53D;
}

.header-middle-ptb {
  padding: 42px 0;
}

.main-categori-menu-wrap {
  display: flex;
  flex-wrap: wrap;
}

.main-categori {
  position: relative;
}

.main-categori > a {
  background-color: #181818;
  color: #fff;
  height: 100%;
  padding: 0 40px 0 35px;
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-categori > a {
    padding: 0 30px 0 25px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-categori > a {
    font-weight: 500;
    font-size: 18px;
    color: #181818;
    background-color: transparent;
  }
}

@media only screen and (max-width: 767px) {
  .main-categori > a {
    font-weight: 400;
    font-size: 16px;
    color: #181818;
    background-color: transparent;
  }
}

.main-categori > a i {
  position: relative;
  margin-right: 15px;
}

@media only screen and (max-width: 767px) {
  .main-categori > a i {
    margin-right: 5px;
  }
}

.main-categori > a.small-device-categori {
  padding: 0px 0px 20px;
  justify-content: flex-start;
}

@media only screen and (max-width: 767px) {
  .main-categori > a.small-device-categori {
    padding: 0px 0px 20px;
  }
}

.main-categori > a.categori-active1-icon.open i:before {
  content: "\ea3a";
}

.main-categori > a.categori-active2-icon.open i:before {
  content: "\ea30";
}

.main-categori .main-categori-dropdown {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9;
  background-color: #181818;
  box-shadow: 0 1px 19.8px 0.2px rgba(12, 31, 46, 0.15);
  padding: 10px 0 10px;
  transition: margin .1s linear .3s,visibility .1s linear .3s,opacity .1s linear .3s;
  z-index: 99;
}

.main-categori .main-categori-dropdown.categori-dropdown-visible-1 {
  margin-top: 20px;
  visibility: hidden;
  opacity: 0;
}

.main-categori .main-categori-dropdown.categori-dropdown-visible-1.open {
  margin-top: 0px;
  visibility: visible;
  opacity: 1;
}

.main-categori .main-categori-dropdown.categori-dropdown-visible-2 {
  visibility: visible;
  opacity: 1;
  margin-top: 0px;
  min-height: 742px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-categori .main-categori-dropdown.categori-dropdown-visible-2 {
    min-height: 642px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-categori .main-categori-dropdown.categori-dropdown-visible-2 {
    min-height: 579px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-categori .main-categori-dropdown.categori-dropdown-visible-2 {
    min-height: 529px;
  }
}

.main-categori .main-categori-dropdown.categori-dropdown-visible-2.open {
  margin-top: 20px;
  visibility: hidden;
  opacity: 0;
}

.main-categori .main-categori-dropdown ul li a {
  color: #bfbfbf;
  display: block;
  font-size: 16px;
  padding: 14px 35px 14px 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-categori .main-categori-dropdown ul li a {
    padding: 12px 35px 12px 35px;
  }
}

@media only screen and (max-width: 767px) {
  .main-categori .main-categori-dropdown ul li a {
    padding: 12px 15px 12px 15px;
    font-size: 15px;
  }
}

.main-categori .main-categori-dropdown ul li a:hover {
  color: #ffffff;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-categori.mr-45 {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-categori.mr-45 {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .main-categori.mr-45 {
    margin-right: 0px;
  }
}

.main-categori-position {
  position: absolute;
  top: 0;
  left: 140px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-categori-position {
    left: 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-categori-position {
    left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-categori-position {
    left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-categori-position {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .main-categori-position {
    display: none;
  }
}

.main-categori-position > a {
  background-color: #e41515;
  padding: 22px 40px 25px 35px;
}

.main-menu > nav > ul > li {
  display: inline-block;
  position: relative;
}

.main-menu > nav > ul > li.position-static {
  position: static;
}

.main-menu > nav > ul > li > a {
  display: inline-block;
  font-size: 18px;
  color: #181818;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > a {
    font-size: 16px;
  }
}

.main-menu > nav > ul > li > a i {
  font-size: 10px;
  margin-left: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > a i {
    font-size: 8px;
    margin-left: 2px;
  }
}

.main-menu > nav > ul > li > a.active {
  color: #e41515;
}

.main-menu > nav > ul > li:hover > a {
  color: #e41515;
}

.main-menu.main-menu-padding-1 > nav > ul > li {
  padding-right: 25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.main-menu-padding-1 > nav > ul > li {
    padding-right: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.main-menu-padding-1 > nav > ul > li {
    padding-right: 12px;
  }
}

.main-menu.main-menu-padding-1 > nav > ul > li:last-child {
  padding-right: 0;
}

.main-menu.main-menu-padding-3 > nav > ul > li {
  padding-right: 28px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.main-menu-padding-3 > nav > ul > li {
    padding-right: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.main-menu-padding-3 > nav > ul > li {
    padding-right: 12px;
  }
}

.main-menu.main-menu-padding-3 > nav > ul > li:last-child {
  padding-right: 0;
}

.main-menu.main-menu-lh-1 > nav > ul > li > a {
  line-height: 70px;
}

.main-menu.main-menu-lh-3 > nav > ul > li > a {
  line-height: 144px;
}

.main-menu.main-menu-lh-4 > nav > ul > li > a {
  line-height: 140px;
}

.main-menu.main-menu-color-change > nav > ul > li > a {
  color: #1a1a1a;
}

.main-menu.main-menu-white > nav > ul > li > a {
  color: #ffffff;
}

.main-menu.main-menu-white > nav > ul > li > a.active {
  color: #e41515;
}

.main-menu.main-menu-white > nav > ul > li:hover > a {
  color: #e41515;
}

.main-menu.main-menu-hover-green > nav > ul > li > a.active {
  color: #538165;
}

.main-menu.main-menu-hover-green > nav > ul > li:hover > a {
  color: #538165;
}

.main-menu.main-menu-hover-green-2 > nav > ul > li > a.active {
  color: #6BB33E;
}

.main-menu.main-menu-hover-green-2 > nav > ul > li:hover > a {
  color: #6BB33E;
}

.main-menu.main-menu-hover-yellow > nav > ul > li > a.active {
  color: #D98719;
}

.main-menu.main-menu-hover-yellow > nav > ul > li:hover > a {
  color: #D98719;
}

.main-menu.main-menu-mrg-1 {
  margin-left: 355px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.main-menu-mrg-1 {
    margin-left: 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.main-menu-mrg-1 {
    margin-left: 0px;
  }
}

.main-menu-dropdown-style > nav > ul > li > ul {
  box-shadow: 0 1px 24px 0 rgba(0, 0, 0, 0.09);
  background-color: #181818;
  position: absolute;
  top: 100%;
  left: 0;
  transition: margin .1s linear .3s,visibility .1s linear .3s,opacity .1s linear .3s;
  opacity: 0;
  visibility: hidden;
  margin-top: 20px;
  z-index: 999;
}

.main-menu-dropdown-style > nav > ul > li > ul.sub-menu-width {
  width: 250px;
  padding: 30px 0 34px;
  text-align: left;
}

.main-menu-dropdown-style > nav > ul > li > ul.sub-menu-width li {
  display: block;
  padding: 0 15px 20px 30px;
  position: relative;
}

.main-menu-dropdown-style > nav > ul > li > ul.sub-menu-width li:last-child {
  padding-bottom: 0;
}

.main-menu-dropdown-style > nav > ul > li > ul.sub-menu-width li a {
  display: block;
  color: #fff;
}

.main-menu-dropdown-style > nav > ul > li > ul.sub-menu-width li a i {
  float: right;
  font-size: 10px;
  color: #999;
  position: relative;
  top: 6px;
}

.main-menu-dropdown-style > nav > ul > li > ul.sub-menu-width li ul.lavel-menu {
  padding: 30px 0 34px;
  width: 250px;
  transition: all 250ms ease-out;
  box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
  background-color: #181818;
  position: absolute;
  top: -17px;
  left: 100%;
  margin: 0;
  visibility: hidden;
  opacity: 0;
}

.main-menu-dropdown-style > nav > ul > li > ul.sub-menu-width li:hover > a {
  color: #D98719;
  text-decoration: underline;
}

.main-menu-dropdown-style > nav > ul > li > ul.sub-menu-width li:hover > ul.lavel-menu {
  visibility: visible;
  opacity: 1;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-1 {
  width: 100%;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-1 > li > ul {
  width: 1170px;
  margin: auto;
  padding-top: 80px;
  padding-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-1 > li > ul {
    width: 950px;
    padding-top: 45px;
  }
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-1 > li > ul li {
  padding: 15px;
  flex: 0 0 25%;
  max-width: 25%;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-1 > li > ul li a {
  display: block;
  text-align: left;
  overflow: hidden;
  padding: 10px 0;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-1 > li > ul li a img {
  width: 100%;
  transition: all .3s;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-1 > li > ul li a span {
  display: block;
  font-weight: 600;
  color: #ffffff;
  font-size: 14px;
  margin: 20px 0 0;
  position: relative;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-1 > li > ul li a span::before {
  content: '\ea6b';
  font-family: LaStudioIcons;
  padding-right: 5px;
  position: absolute;
  left: -20px;
  top: 0;
  transition: all .3s;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-1 > li > ul li a:hover img {
  transform: translateY(-10px);
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-1 > li > ul li a:hover span {
  padding-left: 20px;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-1 > li > ul li a:hover span::before {
  left: 0px;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 {
  width: 100%;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul {
  width: 1600px;
  margin: auto;
  padding: 53px 0 40px;
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul {
    width: 1300px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul {
    width: 1170px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul {
    width: 950px;
    padding: 40px 0 40px;
  }
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li {
  padding: 15px;
  flex: 0 0 33.333%;
  max-width: 33.333%;
  text-align: left;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li > a {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  display: block;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li ul {
  display: flex;
  flex-wrap: wrap;
  margin: 23px 0 0;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li ul li {
  flex: 0 0 50%;
  max-width: 50%;
  display: inline-block;
  padding: 0 0 17px;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li ul li:last-child {
  padding: 0 0 0;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li ul li a {
  color: #ffffff;
  font-size: 12px;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li ul li a:hover {
  color: #D98719;
  text-decoration: underline;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li .menu-banner-wrap {
  margin: 23px 0 0;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li .menu-banner-wrap .menu-banner-1-wrap {
  position: relative;
  margin-bottom: 20px;
  display: inline-block;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li .menu-banner-wrap .menu-banner-1-wrap a {
  display: block;
  position: relative;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li .menu-banner-wrap .menu-banner-1-wrap a img {
  max-width: 100%;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li .menu-banner-wrap .menu-banner-1-wrap .menu-banner-content-1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li .menu-banner-wrap .menu-banner-1-wrap .menu-banner-content-1 span {
  display: block;
  font-size: 15px;
  font-style: italic;
  color: #181818;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li .menu-banner-wrap .menu-banner-1-wrap .menu-banner-content-1 h4 {
  font-size: 22px;
  font-weight: 600;
  color: #E41515;
  margin: 3px 0 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li .menu-banner-wrap .menu-banner-1-wrap .menu-banner-content-1 h4 {
    font-size: 18px;
  }
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li .menu-banner-wrap .menu-banner-1-wrap .menu-banner-content-1 a {
  display: inline-block;
  font-size: 12px;
  color: #181818;
  line-height: 1;
  border: 1px solid #979797;
  padding: 10px 20px 12px;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li .menu-banner-wrap .menu-banner-1-wrap .menu-banner-content-1 a:hover {
  background-color: #E41515;
  border: 1px solid #E41515;
  color: #ffffff;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li .menu-banner-wrap .menu-banner-2-wrap {
  position: relative;
  margin-bottom: 20px;
  display: inline-block;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li .menu-banner-wrap .menu-banner-2-wrap a {
  display: block;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li .menu-banner-wrap .menu-banner-2-wrap a img {
  max-width: 100%;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li .menu-banner-wrap .menu-banner-2-wrap .menu-banner-content-2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 36px;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li .menu-banner-wrap .menu-banner-2-wrap .menu-banner-content-2 span {
  display: block;
  font-size: 13px;
  color: #181818;
  text-transform: uppercase;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li .menu-banner-wrap .menu-banner-2-wrap .menu-banner-content-2 h4 {
  font-size: 24px;
  font-weight: bold;
  color: #D98719;
  margin: 1px 0 18px;
  font-style: italic;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li .menu-banner-wrap .menu-banner-2-wrap .menu-banner-content-2 h4 {
    font-size: 17px;
    margin: 1px 0 10px;
  }
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li .menu-banner-wrap .menu-banner-2-wrap .menu-banner-content-2 a {
  display: inline-block;
  font-size: 12px;
  color: #181818;
  line-height: 1;
  border: 1px solid #979797;
  padding: 10px 20px 12px;
}

.main-menu-dropdown-style > nav > ul > li > ul.mega-menu-style-2 > li > ul > li .menu-banner-wrap .menu-banner-2-wrap .menu-banner-content-2 a:hover {
  background-color: #E41515;
  border: 1px solid #E41515;
  color: #ffffff;
}

.main-menu-dropdown-style > nav > ul > li:hover:hover > ul {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

.main-menu-dropdown-style.main-menu-hover-green > nav > ul > li > ul.sub-menu-width li:hover > a {
  color: #538165;
}

.main-menu-dropdown-style.main-menu-hover-green > nav > ul > li > ul.mega-menu-style-2 > li > ul > li ul li a:hover {
  color: #538165;
}

.main-menu-dropdown-style.main-menu-hover-pink > nav > ul > li > ul.sub-menu-width li:hover > a {
  color: #FF7473;
}

.main-menu-dropdown-style.main-menu-hover-pink > nav > ul > li > ul.mega-menu-style-2 > li > ul > li ul li a:hover {
  color: #FF7473;
}

.main-menu-dropdown-style.main-menu-hover-red > nav > ul > li > ul.sub-menu-width li:hover > a {
  color: #e41515;
}

.main-menu-dropdown-style.main-menu-hover-red > nav > ul > li > ul.mega-menu-style-2 > li > ul > li ul li a:hover {
  color: #e41515;
}

.header-offer-cart-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-offer-cart-wrap {
    padding: 0 0 18px;
  }
}

@media only screen and (max-width: 767px) {
  .header-offer-cart-wrap {
    justify-content: center;
  }
}

.header-offer-cart-wrap .same-style-3 {
  margin-left: 22px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-offer-cart-wrap .same-style-3 {
    margin-left: 10px;
  }
}

.header-offer-cart-wrap .same-style-3:first-child {
  margin-left: 0;
}

.header-offer-cart-wrap .same-style-3 > a {
  font-size: 20px;
  color: #181818;
  font-weight: 600;
  display: flex;
  align-items: center;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-offer-cart-wrap .same-style-3 > a {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-offer-cart-wrap .same-style-3 > a {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-offer-cart-wrap .same-style-3 > a {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-offer-cart-wrap .same-style-3 > a {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .header-offer-cart-wrap .same-style-3 > a {
    font-size: 15px;
  }
}

.header-offer-cart-wrap .same-style-3 > a i {
  font-size: 26px;
  margin-right: 10px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-offer-cart-wrap .same-style-3 > a i {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-offer-cart-wrap .same-style-3 > a i {
    font-size: 23px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-offer-cart-wrap .same-style-3 > a i {
    font-size: 18px;
    margin-right: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-offer-cart-wrap .same-style-3 > a i {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header-offer-cart-wrap .same-style-3 > a i {
    font-size: 14px;
    margin-right: 5px;
  }
}

.header-offer-cart-wrap .same-style-3 > a span {
  position: absolute;
  top: -12px;
  font-size: 10px;
  width: 18px;
  height: 18px;
  display: inline-block;
  line-height: 18px;
  color: #fff;
  text-align: center;
  left: 12px;
  border-radius: 10px;
}

.header-offer-cart-wrap .same-style-3 > a span.red {
  background-color: #e41515;
}

.header-offer-cart-wrap .same-style-3 > a.red {
  color: #e41515;
}

.header-offer-cart-wrap .same-style-3 > a.red i {
  color: #181818;
  transition: all .3s ease 0s;
}

.header-offer-cart-wrap .same-style-3 > a.red:hover i {
  color: #e41515;
}

.header-offer-cart-wrap .same-style-3 > a:hover {
  color: #e41515;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  padding: 0 15px;
  cursor: pointer;
}

select.select-img {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 13' width='8' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='rgb%28138,138,138%29' fill-rule='nonzero'%3E%3Cpath d='M6.854 9.174l-2.862 2.45-2.862-2.45a.75.75 0 00-.935.007.514.514 0 00-.008.8l3.333 2.852c.26.223.683.223.943 0l3.333-2.852a.518.518 0 00.182-.555c-.06-.2-.243-.355-.476-.407a.744.744 0 00-.648.155zM4.472.21a.637.637 0 00-.944 0L.188 3.787A.752.752 0 00.196 4.79c.257.276.674.28.936.01L4 1.726 6.868 4.8a.637.637 0 00.936-.009.752.752 0 00.008-1.003L4.472.21z'/%3E%3C/g%3E%3C/svg%3E");
  background-size: 8px 13px;
  background-position: right -10px center;
  background-origin: content-box;
  background-repeat: no-repeat;
  max-width: 100%;
}

.main-menu-style-2 .leather-logo {
  position: absolute;
  left: 48.5%;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-style-2 .leather-logo {
    left: 47.3%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-style-2 .leather-logo {
    left: 46.5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-style-2 .leather-logo {
    left: 44%;
  }
}

.main-menu-style-2 .leather-logo img {
  width: 165px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-style-2 .leather-logo img {
    width: 120px;
  }
}

.main-menu-style-2 .jewelry-logo {
  position: absolute;
  left: 48.3%;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-style-2 .jewelry-logo {
    left: 47.7%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-style-2 .jewelry-logo {
    left: 47.3%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-style-2 .jewelry-logo {
    left: 43.8%;
  }
}

.main-menu-style-2 .jewelry-logo img {
  width: 165px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-style-2 .jewelry-logo img {
    width: 135px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-style-2 .jewelry-logo img {
    width: 135px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-style-2 .jewelry-logo img {
    width: 120px;
  }
}

.main-menu-style-2 .kids-logo {
  position: absolute;
  left: 48.5%;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-style-2 .kids-logo {
    left: 47.4%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-style-2 .kids-logo {
    left: 46.7%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-style-2 .kids-logo {
    left: 44%;
  }
}

.main-menu-style-2 .kids-logo img {
  width: 165px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-style-2 .kids-logo img {
    width: 120px;
  }
}

.main-menu-style-2.main-menu-2-center {
  display: flex;
  justify-content: center;
}

.main-menu-style-2 > nav > ul > li {
  display: inline-block;
  position: relative;
}

.main-menu-style-2 > nav > ul > li:nth-child(3) {
  margin-right: 300px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-style-2 > nav > ul > li:nth-child(3) {
    margin-right: 250px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-style-2 > nav > ul > li:nth-child(3) {
    margin-right: 230px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-style-2 > nav > ul > li:nth-child(3) {
    margin-right: 200px;
  }
}

.main-menu-style-2 > nav > ul > li > a {
  display: inline-block;
  color: #ffffff;
  font-size: 22px;
  font-weight: 500;
  font-family: "Noto Serif JP", serif;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-style-2 > nav > ul > li > a {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-style-2 > nav > ul > li > a {
    font-size: 19px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-style-2 > nav > ul > li > a {
    font-size: 18px;
  }
}

.main-menu-style-2 > nav > ul > li > a i {
  font-size: 10px;
  margin-left: 7px;
  position: relative;
  top: -3px;
}

.main-menu-style-2.main-menu-padding-2 > nav > ul > li {
  padding-right: 26px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-style-2.main-menu-padding-2 > nav > ul > li {
    padding-right: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-style-2.main-menu-padding-2 > nav > ul > li {
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-style-2.main-menu-padding-2 > nav > ul > li {
    padding-right: 10px;
  }
}

.main-menu-style-2.main-menu-padding-2 > nav > ul > li:last-child {
  padding-right: 0;
}

.main-menu-style-2.main-menu-lh-2 > nav > ul > li > a {
  line-height: 130px;
}

.main-menu-style-2.main-menu-style-2-black > nav > ul > li > a {
  color: #1a1a1a;
  font-weight: 400;
  letter-spacing: -1px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-style-2.main-menu-style-2-black > nav > ul > li > a {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-style-2.main-menu-style-2-black > nav > ul > li > a {
    font-size: 18px;
  }
}

.main-menu-style-2.main-menu-style-2-black > nav > ul > li > a.active-pink, .main-menu-style-2.main-menu-style-2-black > nav > ul > li > a:hover {
  color: #FF7473;
}

.main-menu-style-2.main-menu-style-2-poppins > nav > ul > li > a {
  font-family: "Poppins", sans-serif;
}

.search-style-2 {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.9);
  text-align: center;
  transition: all ease-in-out .25s;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-style-2.search-visible {
  visibility: visible;
  opacity: 1;
}

.search-style-2.search-visible .megashop-search-content {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.search-style-2 > .search2-close {
  font-size: 40px;
  display: block;
  position: absolute;
  top: 20%;
  right: 20%;
  line-height: 40px;
  height: 40px;
  transition: all .3s;
  color: #ffffff;
}

.search-style-2 > .search2-close:hover {
  transform: rotate(90deg);
}

.search-style-2 > .search2-close i:hover {
  color: #D98719;
}

.search-style-2 .search-content-2 {
  display: inline-block;
  max-width: 90%;
  text-align: center;
  font-size: 18px;
  transition: all ease-in-out .3s;
  transform: scale(0.9);
}

.search-style-2 .search-content-2 p {
  font-size: 20px;
  margin: 0 0 15px;
  color: #ffffff;
  font-weight: 400;
}

.search-style-2 .search-content-2 .search-form-style-2 {
  position: relative;
}

.search-style-2 .search-content-2 .search-form-style-2 input {
  width: 800px;
  background-color: transparent;
  border-bottom: 2px solid #ffffff;
  border: 0;
  text-align: center;
  font-size: 30px;
  padding: 34px 60px 10px 36px;
  color: #ffffff;
  transition: all .3s ease-out;
  font-weight: 400;
  max-width: 100%;
  height: auto;
  border-bottom: 2px solid #ffffff;
}

.search-style-2 .search-content-2 .search-form-style-2 button {
  position: absolute;
  top: 43px;
  display: block;
  right: 5px;
  border: 0;
  background: 0 0;
  padding: 0;
  color: #ffffff;
  font-size: 30px;
  height: 30px;
}

.search-style-2 .search-content-2 .search-form-style-2 button:hover {
  color: white;
}

.header-top-style-wrap {
  display: flex;
  flex-wrap: wrap;
}

.header-top-style-wrap .same-style-4 {
  margin-right: 40px;
}

.header-top-style-wrap .same-style-4:last-child {
  margin-right: 0;
}

.header-top-style-wrap .same-style-4 a {
  color: #ffffff;
  font-size: 16px;
}

.header-top-style-wrap .same-style-4 a i {
  margin-right: 8px;
  position: relative;
  top: 2px;
}

.header-top-style-wrap .same-style-4 a i.angle {
  margin-right: 0;
  margin-left: 6px;
  font-size: 12px;
}

.header-top-style-wrap .same-style-4 a:hover {
  color: #e41515;
}

.header-top-style-wrap.header-top-right-2 {
  justify-content: flex-end;
}

.delivery-offer p {
  font-size: 18px;
  color: #ffffff;
}

.header-aside-active {
  position: fixed;
  top: 0;
  width: 470px;
  min-height: 100vh;
  bottom: 0;
  z-index: 111;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 400ms ease-out;
  transform: translate(200px, 0);
  background-color: #ffffff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  z-index: 9999;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-aside-active {
    width: 400px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-aside-active {
    width: 400px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-aside-active {
    width: 360px;
  }
}

.header-aside-active.header-aside-active-left {
  transform: translate(-200px, 0);
  left: 0;
}

.header-aside-active.inside {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

.header-aside-active .header-aside-wrap {
  padding: 130px 70px 20px;
  height: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-aside-active .header-aside-wrap {
    padding: 110px 50px 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-aside-active .header-aside-wrap {
    padding: 110px 50px 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-aside-active .header-aside-wrap {
    padding: 110px 50px 20px;
  }
}

.header-aside-active .header-aside-wrap .aside-close {
  position: absolute;
  top: 50px;
  left: 30px;
  font-size: 30px;
  line-height: 30px;
  transition: all 250ms ease-out;
  color: #6d6d6d;
}

.header-aside-active .header-aside-wrap .aside-close:hover {
  transform: rotate(90deg);
  color: #D98719;
}

.header-aside-active .header-aside-wrap .aside-close:hover.pink {
  color: #FF7473;
}

.header-aside-active .header-aside-wrap .header-aside-content {
  overflow: auto;
  height: 100%;
  padding-right: 10px;
}

.header-aside-active .header-aside-wrap .header-aside-content .header-aside-menu {
  margin: 0 0 140px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-aside-active .header-aside-wrap .header-aside-content .header-aside-menu {
    margin: 0 0 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-aside-active .header-aside-wrap .header-aside-content .header-aside-menu {
    margin: 0 0 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-aside-active .header-aside-wrap .header-aside-content .header-aside-menu {
    margin: 0 0 40px;
  }
}

.header-aside-active .header-aside-wrap .header-aside-content .header-aside-menu nav ul li {
  display: block;
}

.header-aside-active .header-aside-wrap .header-aside-content .header-aside-menu nav ul li a {
  font-size: 16px;
  color: #6d6d6d;
  display: block;
  padding: 0 0 24px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-aside-active .header-aside-wrap .header-aside-content .header-aside-menu nav ul li a {
    padding: 0 0 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-aside-active .header-aside-wrap .header-aside-content .header-aside-menu nav ul li a {
    padding: 0 0 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-aside-active .header-aside-wrap .header-aside-content .header-aside-menu nav ul li a {
    padding: 0 0 15px;
  }
}

.header-aside-active .header-aside-wrap .header-aside-content .header-aside-menu nav ul li a:hover {
  color: #D98719;
}

.header-aside-active .header-aside-wrap .header-aside-content .header-aside-menu nav ul li:last-child a {
  padding: 0 0 0px;
}

.header-aside-active .header-aside-wrap .header-aside-content .header-aside-menu.pink nav ul li a:hover {
  color: #FF7473;
}

.header-aside-active .header-aside-wrap .header-aside-content .aside-banner a {
  position: relative;
  display: block;
}

.header-aside-active .header-aside-wrap .header-aside-content .aside-banner a::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all .5s;
  background-color: #181818;
  opacity: 0;
}

.header-aside-active .header-aside-wrap .header-aside-content .aside-banner a img {
  width: 100%;
}

.header-aside-active .header-aside-wrap .header-aside-content .aside-banner:hover a::before {
  opacity: .4;
  transform: scale(0.9);
}

.header-aside-active .header-aside-wrap .header-aside-content .aside-contact-info {
  margin: 35px 0 19px;
}

.header-aside-active .header-aside-wrap .header-aside-content .aside-contact-info ul li {
  color: #6d6d6d;
  display: flex;
  margin: 0 0 4px;
  font-size: 12px;
}

.header-aside-active .header-aside-wrap .header-aside-content .aside-contact-info ul li:last-child {
  margin: 0 0 0px;
}

.header-aside-active .header-aside-wrap .header-aside-content .aside-contact-info ul li i {
  font-size: 22px;
  margin: 1px 16px 0px 0;
}

.header-aside-active .header-aside-wrap .header-aside-content .aside-social-icon a {
  color: #6d6d6d;
  font-size: 16px;
  margin-right: 17px;
}

.header-aside-active .header-aside-wrap .header-aside-content .aside-social-icon a:last-child {
  margin-right: 0;
}

.header-aside-active .header-aside-wrap .header-aside-content .aside-payments {
  margin: 0 0 5px;
}

.header-aside-active .header-aside-wrap .header-aside-content .aside-payments img {
  max-width: 100%;
}

.mobile-menu-active {
  position: fixed;
  top: 0;
  width: 350px;
  min-height: 100vh;
  bottom: 0;
  z-index: 111;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 400ms ease-out;
  transform: translate(200px, 0);
  background-color: #ffffff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  z-index: 9999;
}

@media only screen and (max-width: 767px) {
  .mobile-menu-active {
    width: 290px;
  }
}

.mobile-menu-active.inside {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

.mobile-menu-active .mobile-menu-all-wrap {
  padding: 80px 30px 20px;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .mobile-menu-active .mobile-menu-all-wrap {
    padding: 80px 20px 20px;
  }
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-close {
  position: absolute;
  top: 20px;
  left: 30px;
  font-size: 30px;
  line-height: 30px;
  transition: all 250ms ease-out;
  color: #6d6d6d;
}

@media only screen and (max-width: 767px) {
  .mobile-menu-active .mobile-menu-all-wrap .mobile-menu-close {
    left: 20px;
  }
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-close:hover {
  transform: rotate(90deg);
  color: #D98719;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding-right: 10px;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-search {
  border-bottom: 1px solid #ddd;
  margin-bottom: 17px;
  padding-bottom: 30px;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-search form {
  position: relative;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-search form input {
  background-color: #f6f6f6;
  border: none;
  border-radius: 0px;
  height: 50px;
  padding: 0 60px 0 15px;
  width: 100%;
  font-size: 14px;
  color: #181818;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-search form button {
  background-color: transparent;
  border-color: #ddd;
  border-image: none;
  border-radius: 5px 0 0 5px;
  border-style: none none none solid;
  border-width: medium medium medium 1px;
  color: #000000;
  font-size: 18px;
  height: 100%;
  padding: 0 15px 0 14px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-search form button {
    font-size: 16px;
  }
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-search form button:hover {
  color: #D98719;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-search form button i {
  margin-top: 5px;
  display: inline-block;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-menu-wrap {
  border-bottom: 1px solid #ddd;
  margin-bottom: 26px;
  padding-bottom: 17px;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-menu-wrap .mobile-navigation nav {
  height: 100%;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-menu-wrap .mobile-navigation nav .mobile-menu li {
  display: block;
  position: relative;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand {
  line-height: 50;
  top: -5px;
  left: 95%;
  width: 30px;
  position: absolute;
  height: 50px;
  text-align: center;
  cursor: pointer;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand i {
  display: block;
  position: relative;
  width: 10px;
  margin-top: 25px;
  border-bottom: 1px solid #1a1a1a;
  transition: all 250ms ease-out;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand i::before {
  top: 0;
  width: 100%;
  content: "";
  display: block;
  position: absolute;
  transform: rotate(90deg);
  border-bottom: 1px solid #1a1a1a;
  transition: 0.4s;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children.active > .menu-expand i::before {
  transform: rotate(0);
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-menu-wrap .mobile-navigation nav .mobile-menu li a {
  font-size: 16px;
  text-transform: capitalize;
  line-height: 18px;
  position: relative;
  display: inline-block;
  padding: 12px 0;
  color: #1a1a1a;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li a {
  padding: 10px 15px 5px;
  font-size: 14px;
  color: #555;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li a:hover {
  color: #D98719;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li ul li a {
  padding: 10px 30px 5px;
  font-size: 14px;
  color: #555;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-menu-wrap .mobile-navigation nav .mobile-menu li:hover > a {
  color: #D98719;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-header-info-wrap {
  border-bottom: 1px solid #ddd;
  margin-bottom: 11px;
  padding-bottom: 17px;
}

@media only screen and (max-width: 767px) {
  .mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-header-info-wrap {
    display: block;
  }
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-header-info-wrap .single-mobile-header-info {
  position: relative;
  margin-bottom: 15px;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-header-info-wrap .single-mobile-header-info:last-child {
  margin-bottom: 0;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-header-info-wrap .single-mobile-header-info a {
  font-size: 16px;
  display: block;
  color: #1a1a1a;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-header-info-wrap .single-mobile-header-info a > i {
  font-size: 14px;
  position: relative;
  top: -1px;
  margin-right: 10px;
  width: 12px;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-header-info-wrap .single-mobile-header-info a span {
  float: right;
  font-size: 10px;
  position: relative;
  top: 8px;
  line-height: 1;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown {
  margin-top: 5px;
  display: none;
  background-color: #fff;
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  padding: 22px 30px 26px;
  width: 100%;
  z-index: 11;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li {
  padding-bottom: 10px;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li:last-child {
  padding-bottom: 0px;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li a {
  font-size: 14px;
  color: #555;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li a:hover {
  color: #D98719;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-header-info-wrap .single-mobile-header-info:hover > a {
  color: #D98719;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-contact-info {
  margin: 23px 0 19px;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-contact-info ul li {
  color: #1a1a1a;
  display: flex;
  margin: 0 0 8px;
  font-size: 14px;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-contact-info ul li:last-child {
  margin: 0 0 0px;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-contact-info ul li i {
  font-size: 14px;
  margin-right: 12px;
  position: relative;
  top: 4px;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-social-icon a {
  color: #1a1a1a;
  font-size: 16px;
  margin-right: 17px;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-social-icon a:last-child {
  margin-right: 0;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-social-icon a:hover {
  color: #D98719;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-copyright p {
  color: #1a1a1a;
  font-size: 14px;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-copyright p a {
  color: #1a1a1a;
}

.mobile-menu-active .mobile-menu-all-wrap .mobile-menu-content .mobile-copyright p a:hover {
  color: #D98719;
}

.clickable-mainmenu-active {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(24, 24, 24, 0.95);
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0s 0.5s;
  z-index: 99;
}

.clickable-mainmenu-active.mainmenu-visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s;
}

.clickable-mainmenu-active .clickable-mainmenu-width {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.clickable-mainmenu-active .clickable-mainmenu-width a.mainmenu-close {
  position: fixed;
  top: 22%;
  right: 10%;
  font-size: 32px;
  color: #fff;
  z-index: 9;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .clickable-mainmenu-active .clickable-mainmenu-width a.mainmenu-close {
    top: 11%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .clickable-mainmenu-active .clickable-mainmenu-width a.mainmenu-close {
    top: 11%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clickable-mainmenu-active .clickable-mainmenu-width a.mainmenu-close {
    top: 11%;
  }
}

.clickable-mainmenu-active .clickable-mainmenu-width a.mainmenu-close:hover {
  color: #D98719;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style {
  display: flex;
  padding: 0 300px;
  overflow: auto;
  height: 90%;
  justify-content: center;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style {
    padding: 0 150px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style {
    padding: 0 80px;
  }
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav {
  width: 100%;
  align-items: center;
  display: flex;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul {
  width: 1030px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  display: flex;
  max-height: 90%;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li {
  min-width: 200px;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li.has-sub-menu.active > a i {
  opacity: 1;
  visibility: visible;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > a {
  color: #fff;
  font-size: 24px;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > a i {
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  margin-left: 16px;
  opacity: 0;
  visibility: hidden;
  transition: all .4s ease 0s;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > a:hover {
  color: #D98719;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > a:hover i {
  opacity: 1;
  visibility: visible;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul {
  padding: 30px 0;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul li {
  padding: 0 0 15px;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul li a {
  color: #fff;
  display: block;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul li a i {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin-left: 16px;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
  position: relative;
  top: 2px;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul li a:hover {
  color: #D98719;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul li:hover a i {
  opacity: 1;
  visibility: visible;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul li.has-sub-menu.active a i {
  font-size: 15px;
  font-weight: 400;
  top: 0px;
}

.clickable-mainmenu-active .clickable-mainmenu-width .clickable-mainmenu-style > nav > ul > li > ul li ul {
  padding: 23px 0 10px;
}

/*-------- 4. Slider style ---------*/
.slider-area {
  position: relative;
  overflow: hidden;
}

.slider-height-1 {
  height: 991px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-1 {
    height: 891px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-1 {
    height: 780px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-1 {
    height: 750px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-1 {
    height: 700px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-1 {
    height: 550px;
  }
}

.slider-height-2 {
  height: 773px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-2 {
    height: 650px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-2 {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-2 {
    height: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-2 {
    height: auto;
    padding: 50px 0 50px;
  }
}

.slider-height-3 {
  height: 813px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-3 {
    height: 713px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-3 {
    height: 650px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-3 {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-3 {
    height: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-3 {
    height: auto;
    padding: 50px 0 50px;
  }
}

.slider-height-4 {
  height: 1030px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-4 {
    height: 900px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-4 {
    height: 900px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-4 {
    height: 800px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-4 {
    height: 650px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-4 {
    height: 500px;
  }
}

.slider-height-5 {
  height: 1090px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-5 {
    height: 900px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-5 {
    height: 900px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-5 {
    height: 800px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-5 {
    height: 650px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-5 {
    height: 500px;
  }
}

.slider-height-6 {
  height: 1090px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-6 {
    height: 900px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-6 {
    height: 900px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-6 {
    height: 800px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-6 {
    height: 650px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-6 {
    height: 550px;
  }
}

.slider-height-7 {
  height: 991px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-7 {
    height: 700px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-7 {
    height: 700px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-7 {
    height: 650px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-7 {
    height: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-7 {
    height: 450px;
  }
}

.slider-height-8 {
  height: 1090px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-8 {
    height: 700px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-8 {
    height: 700px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-8 {
    height: 650px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-8 {
    height: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-8 {
    height: 450px;
  }
}

.slider-height-9 {
  height: 991px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-9 {
    height: 650px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-9 {
    height: 650px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-9 {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-9 {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-9 {
    height: 400px;
  }
}

.slider-height-10 {
  height: 1090px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-10 {
    height: 750px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-10 {
    height: 750px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-10 {
    height: 700px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-10 {
    height: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-10 {
    height: 480px;
  }
}

.hero-slider-content-1 {
  width: 100%;
}

.hero-slider-content-1 h2 {
  font-size: 59px;
  color: #fff;
  font-family: "Playfair Display", serif;
  margin: 0;
  letter-spacing: -2px;
  font-style: italic;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-1 h2 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-1 h2 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-1 h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-1 h2 {
    font-size: 32px;
  }
}

.hero-slider-content-1 h1 {
  font-size: 149px;
  color: #fff;
  font-family: "Playfair Display", serif;
  margin: 10px 0 35px;
  line-height: 130px;
  letter-spacing: -5px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-1 h1 {
    font-size: 130px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-1 h1 {
    font-size: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-1 h1 {
    font-size: 100px;
    line-height: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-1 h1 {
    font-size: 90px;
    line-height: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-1 h1 {
    font-size: 40px;
    line-height: 45px;
    margin: 10px 0 30px;
    letter-spacing: 0;
  }
}

.btn-style-1 a {
  display: inline-block;
  color: #000000;
  line-height: 1;
  font-size: 20px;
  background-color: #ffffff;
  padding: 26px 35px 29px;
}

.btn-style-1 a.btn-1-font-2 {
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .btn-style-1 a.btn-1-font-2 {
    font-size: 14px;
  }
}

.btn-style-1 a.btn-1-padding-dec-2 {
  padding: 26px 50px 26px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-1 a.btn-1-padding-dec-2 {
    padding: 20px 40px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-1 a.btn-1-padding-dec-2 {
    padding: 20px 40px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-1 a.btn-1-padding-dec-2 {
    padding: 15px 30px 15px;
  }
}

.btn-style-1 a.btn-1-padding-dec-3 {
  padding: 31px 50px 31px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-1 a.btn-1-padding-dec-3 {
    padding: 26px 40px 26px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-1 a.btn-1-padding-dec-3 {
    padding: 26px 40px 26px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-1 a.btn-1-padding-dec-3 {
    padding: 15px 30px 15px;
  }
}

.btn-style-1 a.btn-1-padding-dec-4 {
  padding: 21px 40px 26px;
}

@media only screen and (max-width: 767px) {
  .btn-style-1 a.btn-1-padding-dec-4 {
    padding: 15px 30px 15px;
  }
}

.btn-style-1 a.black-bg {
  background-color: #181818;
  color: #ffffff;
}

.btn-style-1 a.black-bg:hover {
  background-color: #D98719;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-1 a {
    padding: 20px 35px 23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-1 a {
    padding: 20px 35px 23px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-1 a {
    padding: 14px 35px 17px;
    font-size: 17px;
  }
}

.btn-style-1 a:hover {
  color: #ffffff;
  background-color: #000000;
}

.hero-slider-active-1 {
  position: relative;
}

.nav-style-1 .slider-icon-1 {
  position: absolute;
  bottom: 8%;
  right: 80px;
  cursor: pointer;
  width: 60px;
  height: 60px;
  line-height: 66px;
  text-align: center;
  z-index: 9;
  border-radius: 50%;
  font-size: 20px;
  color: #1e1e1e;
  background: rgba(255, 255, 255, 0.5);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-1 .slider-icon-1 {
    font-size: 15px;
    width: 50px;
    height: 50px;
    line-height: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-1 .slider-icon-1 {
    right: 20px;
    width: 40px;
    height: 40px;
    line-height: 46px;
    font-size: 16px;
  }
}

.nav-style-1 .slider-icon-1.slider-icon-next {
  bottom: 16%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-1 .slider-icon-1.slider-icon-next {
    bottom: 18%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-1 .slider-icon-1.slider-icon-next {
    bottom: 17%;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-1 .slider-icon-1.slider-icon-next {
    bottom: 18%;
  }
}

.nav-style-1 .slider-icon-1:hover {
  background: white;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-1.nav-style-1-bikes .slider-icon-1 {
    right: 30px;
    width: 40px;
    height: 40px;
    line-height: 45px;
    font-size: 17px;
  }
  .nav-style-1.nav-style-1-bikes .slider-icon-1.slider-icon-next {
    bottom: 15%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-1.nav-style-1-bikes .slider-icon-1 {
    right: 20px;
    width: 40px;
    height: 40px;
    line-height: 45px;
    font-size: 17px;
  }
  .nav-style-1.nav-style-1-bikes .slider-icon-1.slider-icon-next {
    bottom: 16%;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-1.nav-style-1-bikes .slider-icon-1 {
    right: 10px;
  }
}

.nav-style-1.jewelry-nav-style .slider-icon-1.slider-icon-next {
  bottom: 16%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .nav-style-1.jewelry-nav-style .slider-icon-1.slider-icon-next {
    bottom: 20%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-1.jewelry-nav-style .slider-icon-1.slider-icon-next {
    bottom: 20%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-1.jewelry-nav-style .slider-icon-1.slider-icon-next {
    bottom: 21%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-1.jewelry-nav-style .slider-icon-1.slider-icon-next {
    bottom: 19%;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-1.jewelry-nav-style .slider-icon-1.slider-icon-next {
    bottom: 22%;
  }
}

.social-info-text {
  position: absolute;
  max-width: 100%;
  bottom: 25%;
  z-index: 2;
  transform-origin: 0 0;
  transform: rotate(-90deg);
  left: 140px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .social-info-text {
    left: 60px;
    bottom: 12%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .social-info-text {
    left: 15px;
    bottom: 12%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .social-info-text {
    left: 30px;
    bottom: 18%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .social-info-text {
    left: 40px;
    bottom: 28%;
  }
  .social-info-text.hm1 {
    bottom: 17%;
  }
}

@media only screen and (max-width: 767px) {
  .social-info-text {
    left: 8px;
    bottom: 27%;
  }
  .social-info-text.hm1 {
    bottom: 16%;
  }
}

.social-info-text ul {
  display: flex;
}

.social-info-text ul li {
  margin: 0 24px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .social-info-text ul li {
    margin: 0 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .social-info-text ul li {
    margin: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .social-info-text ul li {
    margin: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .social-info-text ul li {
    margin: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .social-info-text ul li {
    margin: 0 10px;
  }
}

.social-info-text ul li a {
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .social-info-text ul li a {
    font-size: 15px;
  }
}

.social-info-text ul li a:hover {
  color: #D98719;
}

.single-animation-wrap.slick-active .slider-animated-1 h2 {
  animation-delay: 1.1s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 p {
  animation-delay: 1.4s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 span {
  animation-delay: 1.1s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 h4 {
  animation-delay: 1.1s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 h3 {
  animation-delay: 1.1s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 h5 {
  animation-delay: 1.4s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 h1 {
  animation-delay: 1.7s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 a {
  animation-delay: 2.0s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 .btn-style-8 {
  animation-delay: 2.0s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1.hero-single-slider-img-1 img {
  animation-delay: 1.5s;
  animation-name: fadeInLeft;
}

.single-animation-wrap.slick-active .slider-animated-1.hero-single-slider-img-2 img {
  animation-delay: 1.6s;
  animation-name: fadeInRight;
}

.single-animation-wrap.slick-active .slider-animated-1.autoparts-slider-offer-1 img, .single-animation-wrap.slick-active .slider-animated-1.autoparts-slider-offer-2 img {
  animation-delay: 1.5s;
  animation-name: fadeInRight;
}

.single-animation-wrap.slick-active .slider-animated-1.hero-single-slider-img-3 img {
  animation-delay: 1.8s;
  animation-name: fadeInRight;
}

.single-animation-wrap.slick-active .slider-animated-1.hero-single-slider-img-4 img, .single-animation-wrap.slick-active .slider-animated-1.hero-single-slider-img-5 img, .single-animation-wrap.slick-active .slider-animated-1.hero-single-slider-img-6 img {
  animation-delay: 1.5s;
  animation-name: fadeInRight;
}

.single-animation-wrap.slick-active .slider-animated-1.hero-single-slider-img-8 img {
  animation-delay: .5s;
  animation-name: fadeInRight;
}

.single-animation-wrap.slick-active .slider-animated-1.hero-single-img-wrap > img {
  animation-delay: 1.0s;
  animation-name: zoomIn;
}

.single-animation-wrap.slick-active .slider-animated-1.hero-single-img-wrap .hero-single-slider-img-7 > img {
  animation-delay: 1.5s;
  animation-name: zoomIn;
}

.single-animation-wrap.slick-active .slider-animated-1.hero-single-img-wrap h3 {
  animation-delay: 1.8s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 .hero-slider-content-9 h2 {
  animation-delay: .9s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 .text-img-1 img {
  animation-delay: .7s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 .text-img-2 img {
  animation-delay: .7s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 .text-img-3 img {
  animation-delay: 1.2s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 .text-img-4 img {
  animation-delay: .8s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 .organic-text-img img {
  animation-delay: 1.6s;
  animation-name: fadeInUp;
}

.slider-icon-2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #181818;
  background-color: #ffffff;
  z-index: 9;
  cursor: pointer;
}

.slider-icon-2 i {
  line-height: 50px;
}

@media only screen and (max-width: 767px) {
  .slider-icon-2 {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .slider-icon-2 i {
    line-height: 40px;
  }
}

.slider-icon-2:hover {
  color: #ffffff;
  background-color: #181818;
}

.slider-icon-2.slider-icon-2-prev {
  left: 0;
}

.slider-icon-2.slider-icon-2-next {
  right: 0;
}

.footer-two-area {
  height: auto !important;
}

.fullpage-pl-1 {
  padding-left: 365px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .fullpage-pl-1 {
    padding-left: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .fullpage-pl-1 {
    padding-left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fullpage-pl-1 {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fullpage-pl-1 {
    padding-left: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .fullpage-pl-1 {
    padding-left: 0px;
  }
}

.fullpage-content-1 h1 {
  color: #fff;
  font-family: "Playfair Display", serif;
  font-size: 80px;
  letter-spacing: -3px;
  line-height: 1.3em;
  margin: 0 0 51px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .fullpage-content-1 h1 {
    font-size: 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .fullpage-content-1 h1 {
    font-size: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fullpage-content-1 h1 {
    font-size: 55px;
    margin: 0 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fullpage-content-1 h1 {
    font-size: 40px;
    letter-spacing: 0;
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .fullpage-content-1 h1 {
    font-size: 23px;
    margin: 0 0 30px;
    letter-spacing: 0px;
  }
}

.btn-style-3 a {
  display: inline-block;
  line-height: 1;
  color: #181818;
  font-size: 24px;
  background-color: #fff;
  padding: 26px 40px 31px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-3 a {
    font-size: 20px;
    padding: 18px 40px 23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-3 a {
    font-size: 20px;
    padding: 17px 30px 22px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-3 a {
    font-size: 18px;
    padding: 15px 30px 20px;
  }
}

.btn-style-3 a:hover {
  background-color: #D98719;
  color: #fff;
}

.fullpage-content-2 h1 {
  color: #fff;
  font-family: "Playfair Display", serif;
  font-size: 100px;
  letter-spacing: -4px;
  line-height: 1.3em;
  margin: 0 0 50px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .fullpage-content-2 h1 {
    font-size: 90px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .fullpage-content-2 h1 {
    font-size: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fullpage-content-2 h1 {
    font-size: 80px;
    margin: 0 0 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fullpage-content-2 h1 {
    font-size: 55px;
    margin: 0 0 30px;
    letter-spacing: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .fullpage-content-2 h1 {
    font-size: 29px;
    margin: 0 0 20px;
    letter-spacing: 0px;
  }
}

.fullpage-content-3 h1 {
  color: #fff;
  font-size: 150px;
  letter-spacing: -5px;
  line-height: 1.4em;
  margin: 0 0 50px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .fullpage-content-3 h1 {
    font-size: 95px;
    line-height: 1.2em;
    margin: 0 0 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .fullpage-content-3 h1 {
    font-size: 95px;
    line-height: 1.2em;
    margin: 0 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fullpage-content-3 h1 {
    font-size: 60px;
    line-height: 1.2em;
    margin: 0 0 40px;
    letter-spacing: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fullpage-content-3 h1 {
    font-size: 50px;
    line-height: 1.2em;
    margin: 0 0 30px;
    letter-spacing: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .fullpage-content-3 h1 {
    font-size: 30px;
    letter-spacing: 0px;
    margin: 0 0 25px;
  }
}

#fp-nav {
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #fp-nav {
    top: 40%;
  }
}

@media only screen and (max-width: 767px) {
  #fp-nav {
    top: 40%;
  }
}

#fp-nav ul li {
  margin: 0px 0 16px;
}

@media only screen and (max-width: 767px) {
  #fp-nav ul li {
    margin: 0px 0 8px;
  }
}

#fp-nav ul li:last-child {
  margin-bottom: 0;
}

#fp-nav ul li a span {
  height: 14px;
  width: 14px;
  background: #fff;
  opacity: .5;
  margin: 0;
}

#fp-nav ul li a.active span {
  height: 14px;
  width: 14px;
  margin: 0;
  background: #D98719;
}

#fp-nav ul li:hover a span {
  height: 14px;
  width: 14px;
  opacity: 1;
  margin: 0;
}

#fp-nav ul li:hover a.active span {
  height: 14px;
  width: 14px;
  opacity: 1;
  margin: 0;
}

#fp-nav.right {
  right: 68px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #fp-nav.right {
    right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  #fp-nav.right {
    right: 18px;
  }
}

.hero-single-slider-img-1 {
  text-align: center;
}

.hero-single-slider-img-1 img {
  max-width: 100%;
  display: inline-block;
}

.hero-slider-content-2 {
  margin-right: -100px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-2 {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-2 {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-2 {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-2 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-2 {
    margin-right: 0px;
    margin-top: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-2 {
    margin-right: 0px;
    margin-top: 0px;
  }
}

.hero-slider-content-2 h2 {
  color: #181818;
  font-size: 55px;
  line-height: 45px;
  margin: 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-2 h2 {
    font-size: 45px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-2 h2 {
    font-size: 42px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-2 h2 {
    font-size: 39px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-2 h2 {
    font-size: 28px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-2 h2 {
    font-size: 25px;
    line-height: 28px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-2 h2 {
    font-size: 23px;
  }
}

.hero-slider-content-2 p {
  color: #6d6d6d;
  font-size: 16px;
  line-height: 30px;
  margin: 16px auto 0;
  width: 72%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-2 p {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-2 p {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-2 p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-2 p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-2 p {
    width: 100%;
    font-size: 15px;
    line-height: 27px;
    margin: 10px auto 0;
  }
}

.hero-slider-content-2 h1 {
  color: #eab700;
  font-size: 65px;
  margin: 0 0 25px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-2 h1 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-2 h1 {
    font-size: 52px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-2 h1 {
    font-size: 52px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-2 h1 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-2 h1 {
    font-size: 25px;
    margin: 0 0 15px;
  }
}

.btn-style-4 a {
  font-size: 18px;
  display: inline-block;
  background-color: #ffd53d;
  color: #181818;
  font-weight: bold;
  line-height: 1;
  padding: 27px 50px 32px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .btn-style-4 a {
    padding: 20px 40px 23px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-4 a {
    padding: 20px 40px 23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-4 a {
    padding: 15px 30px 18px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-4 a {
    padding: 13px 30px 16px;
    font-size: 16px;
  }
}

.btn-style-4 a.padding-dec-1 {
  padding: 20px 50px 25px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .btn-style-4 a.padding-dec-1 {
    padding: 15px 30px 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .btn-style-4 a.padding-dec-1 {
    padding: 12px 25px 17px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-4 a.padding-dec-1 {
    padding: 12px 25px 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-4 a.padding-dec-1 {
    padding: 12px 25px 17px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-4 a.padding-dec-1 {
    padding: 10px 25px 14px;
  }
}

.btn-style-4 a.padding-dec-2 {
  padding: 17px 35px 21px;
}

@media only screen and (max-width: 767px) {
  .btn-style-4 a.padding-dec-2 {
    padding: 10px 25px 14px;
  }
}

.btn-style-4 a:hover {
  background-color: #000000;
  color: #ffffff;
}

.hero-slider-content-3 {
  margin-right: -215px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-3 {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-3 {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-3 {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-3 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-3 {
    margin-right: 0px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-3 {
    margin-right: 0px;
    margin-top: 0px;
  }
}

.hero-slider-content-3 span {
  letter-spacing: 9.9103px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  display: inline-block;
  border: 2px solid #ffffff;
  line-height: 1;
  padding: 10px 20px 10px 27px;
}

.hero-slider-content-3 h5 {
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 1.9821px;
  margin: 32px 0 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-3 h5 {
    font-size: 17px;
    letter-spacing: 1px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-3 h5 {
    font-size: 17px;
    letter-spacing: 1px;
    margin: 22px 0 10px;
  }
}

.hero-slider-content-3 h1 {
  color: #eab700;
  font-size: 65px;
  font-weight: 600;
  margin: 0 0 28px;
  letter-spacing: 9.9103px;
  font-style: italic;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-3 h1 {
    font-size: 45px;
    letter-spacing: 3.9103px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-3 h1 {
    font-size: 35px;
    letter-spacing: 3.9103px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-3 h1 {
    font-size: 28px;
    letter-spacing: 3.9103px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-3 h1 {
    font-size: 28px;
  }
}

.single-hero-slider {
  position: relative;
}

.single-hero-slider .autoparts-slider-offer-1 {
  position: absolute;
  left: 42%;
  top: 33.5%;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-hero-slider .autoparts-slider-offer-1 {
    left: 49%;
    top: 32%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-hero-slider .autoparts-slider-offer-1 {
    left: 45%;
    top: 28.5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider .autoparts-slider-offer-1 {
    left: 48%;
    top: 24.5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider .autoparts-slider-offer-1 {
    left: 30%;
    top: 21.5%;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-slider .autoparts-slider-offer-1 {
    left: 56%;
    top: 10.5%;
  }
}

.single-hero-slider .autoparts-slider-offer-1 img {
  width: 247px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-hero-slider .autoparts-slider-offer-1 img {
    width: 200px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-hero-slider .autoparts-slider-offer-1 img {
    width: 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider .autoparts-slider-offer-1 img {
    width: 170px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider .autoparts-slider-offer-1 img {
    width: 170px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-slider .autoparts-slider-offer-1 img {
    width: 120px;
  }
}

.single-hero-slider .autoparts-slider-offer-2 {
  position: absolute;
  right: 9%;
  bottom: 23%;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .single-hero-slider .autoparts-slider-offer-2 {
    bottom: 8%;
  }
}

.single-hero-slider .autoparts-slider-offer-2 img {
  width: 257px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider .autoparts-slider-offer-2 img {
    width: 170px;
  }
}

@media only screen and (max-width: 767px) {
  .single-hero-slider .autoparts-slider-offer-2 img {
    width: 160px;
  }
}

.hero-single-slider-img-2 {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .hero-single-slider-img-2 {
    margin-top: 20px;
  }
}

.hero-single-slider-img-2.autoparts-slider-mrg-1 {
  margin: 0 -100px 0 -100px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-single-slider-img-2.autoparts-slider-mrg-1 {
    margin: 0 0px 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-single-slider-img-2.autoparts-slider-mrg-1 {
    margin: 0 0px 0 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-single-slider-img-2.autoparts-slider-mrg-1 {
    margin: 0 0px 0 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-single-slider-img-2.autoparts-slider-mrg-1 {
    margin: 0 0px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-single-slider-img-2.autoparts-slider-mrg-1 {
    margin: 20px 0px 0 0px;
  }
}

.hero-single-slider-img-2.autoparts-slider-mrg-2 {
  margin: 0 0px 0 -103px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-single-slider-img-2.autoparts-slider-mrg-2 {
    margin: 0 0px 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-single-slider-img-2.autoparts-slider-mrg-2 {
    margin: 0 0px 0 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-single-slider-img-2.autoparts-slider-mrg-2 {
    margin: 0 0px 0 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-single-slider-img-2.autoparts-slider-mrg-2 {
    margin: 0 0px 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-single-slider-img-2.autoparts-slider-mrg-2 {
    margin: 20px 0px 0 0px;
  }
  .hero-single-slider-img-2.autoparts-slider-mrg-2 img {
    width: 150px;
  }
}

.hero-single-slider-img-2 img {
  max-width: 100%;
  display: inline-block;
}

.hero-slider-content-4 {
  margin-left: 40px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-4 {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-4 {
    margin-left: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-4 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-4 {
    margin-left: 0px;
  }
}

.hero-slider-content-4 h2 {
  color: #181818;
  font-size: 46px;
  line-height: 63px;
  margin: 0;
  font-weight: bold;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-4 h2 {
    font-size: 37px;
    line-height: 47px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-4 h2 {
    font-size: 35px;
    line-height: 45px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-4 h2 {
    font-size: 27px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-4 h2 {
    font-size: 35px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-4 h2 {
    font-size: 30px;
    line-height: 40px;
  }
}

.hero-slider-content-4 p {
  color: #181818;
  font-size: 16px;
  line-height: 34px;
  margin: 5px 0 30px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-4 p {
    font-size: 15px;
    line-height: 30px;
    margin: 5px 0 20px;
  }
}

.btn-style-6 a {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  padding: 23px 50px 30px;
  color: #ffffff;
  background-color: #e41515;
}

.btn-style-6 a:hover {
  background-color: #000000;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-6 a {
    padding: 16px 35px 23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-6 a {
    padding: 16px 35px 23px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-6 a {
    padding: 14px 30px 21px;
    font-size: 16px;
  }
}

.nav-style-2 > span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #000;
  font-size: 15px;
  background-color: white;
  z-index: 9;
  cursor: pointer;
  transition: all .3s ease 0s;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-2 > span {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-2 > span {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-2 > span {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

.nav-style-2 > span i {
  transform: scale(1);
  transition: all .3s ease 0s;
}

.nav-style-2 > span.slider-icon-prev {
  left: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-2 > span.slider-icon-prev {
    left: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-2 > span.slider-icon-prev {
    left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-2 > span.slider-icon-prev {
    left: 15px;
  }
}

.nav-style-2 > span.slider-icon-next {
  right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-2 > span.slider-icon-next {
    right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-2 > span.slider-icon-next {
    right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-2 > span.slider-icon-next {
    right: 15px;
  }
}

.nav-style-2 > span:hover {
  background-color: rgba(255, 255, 255, 0.75);
}

.nav-style-2 > span:hover i {
  transform: scale(1.5);
}

.nav-style-2.nav-style-2-border-radius > span {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.1);
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .nav-style-2.nav-style-2-border-radius > span {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

.nav-style-2.nav-style-2-border-radius > span:hover {
  background: rgba(0, 0, 0, 0.5);
}

.nav-style-2:hover > span {
  opacity: 1;
  visibility: visible;
}

.dot-style-2 ul {
  position: absolute;
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .dot-style-2 ul {
    bottom: 26px;
  }
}

.dot-style-2 ul li {
  display: inline-block;
  margin: 0 5px;
}

.dot-style-2 ul li button {
  border: none;
  padding: 0;
  border: 5px solid #5b5b5b;
  background-color: #ffffff;
  width: 13px;
  height: 13px;
  line-height: 13px;
  border-radius: 100%;
  font-size: 0;
  display: inline-block;
}

.dot-style-2 ul li.slick-active button {
  border: 5px solid #ffffff;
  background-color: #5b5b5b;
}

.youtube-video-area {
  position: relative;
}

.youtube-video-area::before {
  background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;
}

.youtube-video-area .youtube-bg-content {
  position: relative;
  z-index: 95;
}

.youtube-video-area .youtube-bg-content h2 {
  color: #ffffff;
  font-size: 79px;
  font-weight: 500;
  margin: 0;
  font-family: "Noto Serif JP", serif;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .youtube-video-area .youtube-bg-content h2 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .youtube-video-area .youtube-bg-content h2 {
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .youtube-video-area .youtube-bg-content h2 {
    font-size: 27px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .youtube-video-area .youtube-bg-content h2 {
    font-size: 40px;
  }
}

.youtube-video-area .youtube-bg-content p {
  color: #ffffff;
  font-size: 24px;
  margin: 22px 0 58px;
  font-family: "Noto Serif JP", serif;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .youtube-video-area .youtube-bg-content p {
    font-size: 20px;
    margin: 22px 0 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .youtube-video-area .youtube-bg-content p {
    font-size: 20px;
    margin: 22px 0 48px;
  }
}

@media only screen and (max-width: 767px) {
  .youtube-video-area .youtube-bg-content p {
    font-size: 17px;
    margin: 17px 0 30px;
    padding: 0 10px;
  }
}

.compact .buttonBar {
  display: none;
}

.cosmetic-hero-padding-1 {
  margin-bottom: 120px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cosmetic-hero-padding-1 {
    margin-bottom: 88px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cosmetic-hero-padding-1 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .cosmetic-hero-padding-1 {
    margin-bottom: 90px;
  }
}

.hero-slider-content-5 {
  margin: 0 0 0 40px;
  position: relative;
  z-index: 99;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-5 {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-5 {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-5 {
    margin: 0 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-5 {
    margin: -80px 0 0 0px;
  }
}

.hero-slider-content-5 h2 {
  font-size: 59px;
  color: #181818;
  margin: 0;
  font-family: "Playfair Display", serif;
  letter-spacing: -0.9911px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-5 h2 {
    font-size: 53px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-5 h2 {
    font-size: 53px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-5 h2 {
    font-size: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-5 h2 {
    font-size: 33px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-5 h2 {
    font-size: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-5 h2 {
    font-size: 30px;
  }
}

.hero-slider-content-5 p {
  font-size: 18px;
  color: #6D6D6D;
  line-height: 36px;
  margin: 20px auto 40px;
  width: 60%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-5 p {
    width: 72%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-5 p {
    width: 72%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-5 p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-5 p {
    width: 100%;
    margin: 15px auto 30px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-5 p {
    font-size: 15px;
    line-height: 30px;
    width: 100%;
    margin: 12px auto 25px;
  }
}

.hero-slider-content-6 {
  position: relative;
  z-index: 99;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-6 {
    margin: -105px 0 0;
  }
}

.hero-slider-content-6 span {
  display: block;
  font-size: 24px;
  font-style: italic;
  color: #181818;
  font-family: "Playfair Display", serif;
}

.hero-slider-content-6 h1 {
  display: block;
  font-size: 40px;
  color: #181818;
  font-family: "Playfair Display", serif;
  margin: 12px 0 32px;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-6 h1 {
    margin: 12px 0 25px;
    font-size: 23px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-6 h1 {
    margin: 12px 0 25px;
    font-size: 40px;
  }
}

.hero-single-slider-img-3 {
  position: absolute;
  right: 22%;
  bottom: -120px;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-single-slider-img-3 {
    right: 10%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-single-slider-img-3 {
    right: 5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-single-slider-img-3 {
    right: 7%;
    bottom: -88px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-single-slider-img-3 {
    right: 3%;
    bottom: -50px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-single-slider-img-3 {
    right: auto;
    bottom: -90px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.hero-single-slider-img-3 img {
  width: 395px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-single-slider-img-3 img {
    width: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-single-slider-img-3 img {
    width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-single-slider-img-3 img {
    width: 110px;
  }
}

.hero-single-slider-img-4 {
  position: absolute;
  right: 37.5%;
  bottom: 0px;
  z-index: 5;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-single-slider-img-4 {
    right: 31.5%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-single-slider-img-4 {
    right: 29.5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-single-slider-img-4 {
    right: 29.5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-single-slider-img-4 {
    right: 28.5%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-single-slider-img-4 {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

.hero-single-slider-img-4 img {
  width: 324px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-single-slider-img-4 img {
    width: 224px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-single-slider-img-4 img {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-single-slider-img-4 img {
    width: 150px;
  }
}

.hero-single-slider-img-5 {
  position: absolute;
  right: 16%;
  bottom: -120px;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-single-slider-img-5 {
    right: 7%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-single-slider-img-5 {
    right: 5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-single-slider-img-5 {
    right: 4%;
    bottom: -88px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-single-slider-img-5 {
    right: 3%;
    bottom: -50px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-single-slider-img-5 {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    bottom: -90px;
  }
}

.hero-single-slider-img-5 img {
  width: 674px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-single-slider-img-5 img {
    width: 530px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-single-slider-img-5 img {
    width: 530px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-single-slider-img-5 img {
    width: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-single-slider-img-5 img {
    width: 320px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-single-slider-img-5 img {
    width: 150px;
  }
}

.hero-single-slider-img-6 {
  position: absolute;
  right: -19%;
  bottom: 0px;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .hero-single-slider-img-6 {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

.hero-single-slider-img-6 img {
  width: 1282px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-single-slider-img-6 img {
    width: 1120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-single-slider-img-6 img {
    width: 1140px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-single-slider-img-6 img {
    width: 880px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-single-slider-img-6 img {
    width: 810px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-single-slider-img-6 img {
    width: 250px;
  }
}

.nav-style-4 > span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  text-align: center;
  color: #000;
  font-size: 15px;
  background: rgba(255, 255, 255, 0.5);
  z-index: 9;
  cursor: pointer;
  border-radius: 50%;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-4 > span {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-4 > span {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-4 > span {
    width: 40px;
    height: 40px;
  }
}

.nav-style-4 > span i {
  transform: scale(1);
  transition: all .3s ease 0s;
  line-height: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-4 > span i {
    line-height: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-4 > span i {
    line-height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-4 > span i {
    line-height: 40px;
  }
}

.nav-style-4 > span.slider-icon-prev {
  left: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-4 > span.slider-icon-prev {
    left: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-4 > span.slider-icon-prev {
    left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-4 > span.slider-icon-prev {
    left: 15px;
  }
}

.nav-style-4 > span.slider-icon-next {
  right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-4 > span.slider-icon-next {
    right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-4 > span.slider-icon-next {
    right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-4 > span.slider-icon-next {
    right: 15px;
  }
}

.nav-style-4 > span:hover {
  background-color: rgba(255, 255, 255, 0.75);
}

.nav-style-4 > span:hover i {
  transform: scale(1.5);
}

.hero-slider-content-7 {
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-7 {
    margin-top: -60px;
  }
}

.hero-slider-content-7 h2 {
  font-size: 22px;
  font-weight: bold;
  color: #ffffff;
  margin: 0 0 0;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  letter-spacing: 7.9273px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-7 h2 {
    font-size: 18px;
    letter-spacing: 5.9273px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-7 h2 {
    font-size: 18px;
    letter-spacing: 5.9273px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-7 h2 {
    font-size: 18px;
    letter-spacing: 3.927px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-7 h2 {
    font-size: 14px;
    letter-spacing: 1.927px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-7 h2 {
    font-size: 18px;
    letter-spacing: 3.927px;
  }
}

.hero-slider-content-7 h1 {
  font-size: 129px;
  font-weight: 800;
  color: #ffffff;
  margin: 20px 0 31px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  letter-spacing: 7.9273px;
  font-style: italic;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-7 h1 {
    font-size: 90px;
    margin: 10px 0 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-7 h1 {
    font-size: 80px;
    margin: 15px 0 30px;
    line-height: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-7 h1 {
    font-size: 75px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-7 h1 {
    font-size: 55px;
    margin: 10px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-7 h1 {
    font-size: 29px;
    margin: 10px 0 20px;
    letter-spacing: 6.927px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-7 h1 {
    font-size: 36px;
  }
}

.btn-style-8 {
  display: block;
}

.btn-style-8 a {
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  line-height: 1;
  padding: 40px 112px;
  transform: skew(-16deg) !important;
  background-color: #e41515;
}

.btn-style-8 a span {
  transform: skew(16deg) !important;
  display: block;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .btn-style-8 a {
    padding: 30px 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .btn-style-8 a {
    padding: 27px 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-8 a {
    padding: 23px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-8 a {
    padding: 20px 40px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-8 a {
    padding: 16px 35px;
    font-size: 14px;
  }
}

.btn-style-8 a:hover {
  background-color: #b40404;
}

.hero-single-slider-img-8 {
  position: absolute;
  right: -68px;
  bottom: 0;
  z-index: 1;
}

.hero-single-slider-img-8 img {
  max-width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-single-slider-img-8 {
    right: -200px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-single-slider-img-8 {
    right: -200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-single-slider-img-8 {
    right: -200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-single-slider-img-8 {
    right: -200px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-single-slider-img-8 {
    right: -327px;
  }
}

.hero-single-img-wrap {
  position: absolute;
  right: 120px;
  bottom: 60px;
  z-index: 2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-single-img-wrap {
    right: 50px;
    bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-single-img-wrap {
    right: 20px;
    bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-single-img-wrap {
    right: 15px;
    bottom: 15px;
  }
}

.hero-single-img-wrap > img {
  width: 490px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-single-img-wrap > img {
    width: 350px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-single-img-wrap > img {
    width: 350px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-single-img-wrap > img {
    width: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-single-img-wrap > img {
    width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-single-img-wrap > img {
    width: 170px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-single-img-wrap > img {
    width: 210px;
  }
}

.hero-single-img-wrap .hero-single-slider-img-7 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
}

.hero-single-img-wrap .hero-single-slider-img-7 > img {
  width: 331px;
  display: inline-block;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-single-img-wrap .hero-single-slider-img-7 > img {
    width: 231px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-single-img-wrap .hero-single-slider-img-7 > img {
    width: 231px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-single-img-wrap .hero-single-slider-img-7 > img {
    width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-single-img-wrap .hero-single-slider-img-7 > img {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-single-img-wrap .hero-single-slider-img-7 > img {
    width: 130px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-single-img-wrap .hero-single-slider-img-7 > img {
    width: 150px;
  }
}

.hero-single-img-wrap .hero-single-slider-img-7 > img.width-2 {
  width: 289px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-single-img-wrap .hero-single-slider-img-7 > img.width-2 {
    width: 231px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-single-img-wrap .hero-single-slider-img-7 > img.width-2 {
    width: 231px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-single-img-wrap .hero-single-slider-img-7 > img.width-2 {
    width: 160px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-single-img-wrap .hero-single-slider-img-7 > img.width-2 {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-single-img-wrap .hero-single-slider-img-7 > img.width-2 {
    width: 90px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-single-img-wrap .hero-single-slider-img-7 > img.width-2 {
    width: 110px;
  }
}

.hero-single-img-wrap h3 {
  color: #ffffff;
  margin: 0;
  letter-spacing: 3.9636px;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  bottom: 65px;
  left: 0;
  right: 0;
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-single-img-wrap h3 {
    letter-spacing: 1.9636px;
    bottom: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-single-img-wrap h3 {
    letter-spacing: 0.9636px;
    bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-single-img-wrap h3 {
    letter-spacing: 0px;
    bottom: 30px;
    font-size: 12px;
  }
}

.hero-slider-content-8 {
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-8 {
    margin: -70px 0 0;
  }
}

.hero-slider-content-8 h2 {
  font-size: 59px;
  letter-spacing: 3.9636px;
  color: #ffffff;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-style: italic;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-8 h2 {
    font-size: 45px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-8 h2 {
    font-size: 45px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-8 h2 {
    font-size: 26px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-8 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-8 h2 {
    font-size: 17px;
    letter-spacing: 0.964px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-8 h2 {
    font-size: 22px;
    letter-spacing: 1.964px;
  }
}

.hero-slider-content-8 h1 {
  font-size: 59px;
  letter-spacing: 3.9636px;
  color: #ffffff;
  margin: 6px 0 50px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-style: italic;
  font-weight: bold;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-8 h1 {
    font-size: 45px;
    margin: 6px 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-8 h1 {
    font-size: 45px;
    margin: 6px 0 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-8 h1 {
    font-size: 35px;
    margin: 6px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-8 h1 {
    font-size: 30px;
    margin: 6px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-8 h1 {
    font-size: 20px;
    letter-spacing: 0;
    margin: 6px 0 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-8 h1 {
    font-size: 27px;
    letter-spacing: 1px;
  }
}

.kids-slider-shape {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
}

@media only screen and (max-width: 767px) {
  .kids-slider-shape {
    left: -5px;
  }
}

.hero-slider-content-9 {
  position: relative;
  z-index: 99;
}

.hero-slider-content-9 .text-img-1 img {
  width: 636px;
  display: inline-block;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-9 .text-img-1 img {
    width: 490px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-9 .text-img-1 img {
    width: 490px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-9 .text-img-1 img {
    width: 450px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-9 .text-img-1 img {
    width: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-9 .text-img-1 img {
    width: 230px;
  }
}

.hero-slider-content-9 h2 {
  font-size: 36px;
  font-weight: 600;
  color: #181818;
  letter-spacing: -1px;
  margin: 30px 0 32px;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-9 h2 {
    font-size: 30px;
    margin: 25px 0 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-9 h2 {
    font-size: 30px;
    margin: 25px 0 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-9 h2 {
    font-size: 25px;
    margin: 25px 0 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-9 h2 {
    font-size: 25px;
    margin: 25px 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-9 h2 {
    font-size: 20px;
    margin: 15px 0 20px;
  }
}

.kids-slider-mrg-1 {
  margin: -190px 0 0 -70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-slider-mrg-1 {
    margin: 0px 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .kids-slider-mrg-1 {
    margin: 0px 0 0 0px;
  }
}

.kids-slider-mrg-2 {
  margin: -190px -200px 0 0px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .kids-slider-mrg-2 {
    margin: -190px -20px 0 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .kids-slider-mrg-2 {
    margin: -190px 0px 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kids-slider-mrg-2 {
    margin: -190px 0px 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-slider-mrg-2 {
    margin: -100px 0px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .kids-slider-mrg-2 {
    margin: 0px 0px 0 0px;
  }
}

.kids-slider-mrg-3 {
  margin: -190px 0px 0 200px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .kids-slider-mrg-3 {
    margin: -190px 0px 0 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .kids-slider-mrg-3 {
    margin: -190px 0px 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kids-slider-mrg-3 {
    margin: -190px 0px 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-slider-mrg-3 {
    margin: -100px 0px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .kids-slider-mrg-3 {
    margin: 0px 0px 0 0px;
  }
}

.btn-style-9 a {
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
  line-height: 1;
  background-color: #FF7473;
  border-radius: 50px;
  padding: 32px 40px 37px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .btn-style-9 a {
    padding: 22px 40px 27px;
    font-size: 18px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .btn-style-9 a {
    padding: 22px 40px 27px;
    font-size: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-9 a {
    padding: 18px 30px 23px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-9 a {
    padding: 18px 30px 23px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-9 a {
    padding: 15px 30px 20px;
    font-size: 15px;
  }
}

.btn-style-9 a.btn-9-padding-dec-1 {
  padding: 32px 77px 37px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .btn-style-9 a.btn-9-padding-dec-1 {
    padding: 22px 50px 27px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .btn-style-9 a.btn-9-padding-dec-1 {
    padding: 22px 50px 27px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-9 a.btn-9-padding-dec-1 {
    padding: 16px 40px 21px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-9 a.btn-9-padding-dec-1 {
    padding: 14px 35px 19px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-9 a.btn-9-padding-dec-1 {
    padding: 13px 30px 18px;
  }
}

.btn-style-9 a:hover {
  background-color: #ff3a2d;
}

.hero-slider-content-10 {
  position: relative;
  z-index: 99;
}

.hero-slider-content-10 .text-img-2 img {
  width: 108px;
  display: inline-block;
}

.hero-slider-content-10 .text-img-3 {
  margin: 0 0 54px;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-10 .text-img-3 {
    margin: 10px 0 30px;
  }
}

.hero-slider-content-10 .text-img-3 img {
  width: 646px;
  display: inline-block;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-10 .text-img-3 img {
    width: 446px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-10 .text-img-3 img {
    width: 446px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-10 .text-img-3 img {
    width: 430px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-10 .text-img-3 img {
    width: 430px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-10 .text-img-3 img {
    width: 220px;
  }
}

.hero-slider-content-10 .text-img-4 {
  margin: 0 0 64px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-10 .text-img-4 {
    margin: 0 0 44px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-10 .text-img-4 {
    margin: 0 0 44px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-10 .text-img-4 {
    margin: 0 0 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-10 .text-img-4 {
    margin: 0 0 35px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-10 .text-img-4 {
    margin: 0 0 30px;
  }
}

.hero-slider-content-10 .text-img-4 img {
  width: 589px;
  display: inline-block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-10 .text-img-4 img {
    width: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-10 .text-img-4 img {
    width: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-10 .text-img-4 img {
    width: 260px;
  }
}

.mouse-scroll-area {
  position: absolute;
  top: 0% !important;
  left: 0 !important;
  z-index: 9 !important;
  width: 100% !important;
  height: 100% !important;
}

.mouse-scroll-area .kids-shape-1 {
  position: absolute;
  top: 10% !important;
  left: 80% !important;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mouse-scroll-area .kids-shape-1 {
    left: 75% !important;
  }
}

@media only screen and (max-width: 767px) {
  .mouse-scroll-area .kids-shape-1 {
    left: 55% !important;
  }
}

.mouse-scroll-area .kids-shape-1 img {
  width: 218px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .mouse-scroll-area .kids-shape-1 img {
    width: 180px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .mouse-scroll-area .kids-shape-1 img {
    width: 180px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mouse-scroll-area .kids-shape-1 img {
    width: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mouse-scroll-area .kids-shape-1 img {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .mouse-scroll-area .kids-shape-1 img {
    width: 100px;
  }
}

.mouse-scroll-area .kids-shape-2 {
  position: absolute;
  top: 27% !important;
  left: 81% !important;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .mouse-scroll-area .kids-shape-2 {
    left: 83% !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .mouse-scroll-area .kids-shape-2 {
    left: 83% !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mouse-scroll-area .kids-shape-2 {
    left: 83% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mouse-scroll-area .kids-shape-2 {
    left: 77% !important;
  }
}

@media only screen and (max-width: 767px) {
  .mouse-scroll-area .kids-shape-2 {
    left: 77% !important;
  }
}

.mouse-scroll-area .kids-shape-2 img {
  width: 289px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .mouse-scroll-area .kids-shape-2 img {
    width: 190px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .mouse-scroll-area .kids-shape-2 img {
    width: 190px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mouse-scroll-area .kids-shape-2 img {
    width: 160px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mouse-scroll-area .kids-shape-2 img {
    width: 160px;
  }
}

@media only screen and (max-width: 767px) {
  .mouse-scroll-area .kids-shape-2 img {
    width: 120px;
  }
}

.mouse-scroll-area .kids-shape-3 {
  position: absolute;
  top: 64% !important;
  left: 77% !important;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .mouse-scroll-area .kids-shape-3 {
    top: 62% !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .mouse-scroll-area .kids-shape-3 {
    top: 62% !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mouse-scroll-area .kids-shape-3 {
    top: 62% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mouse-scroll-area .kids-shape-3 {
    top: 62% !important;
  }
}

@media only screen and (max-width: 767px) {
  .mouse-scroll-area .kids-shape-3 {
    top: 62% !important;
    left: 57% !important;
  }
}

.mouse-scroll-area .kids-shape-3 img {
  width: 181px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .mouse-scroll-area .kids-shape-3 img {
    width: 150px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .mouse-scroll-area .kids-shape-3 img {
    width: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mouse-scroll-area .kids-shape-3 img {
    width: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mouse-scroll-area .kids-shape-3 img {
    width: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .mouse-scroll-area .kids-shape-3 img {
    width: 80px;
  }
}

.mouse-scroll-area .kids-shape-4 {
  position: absolute;
  top: 62% !important;
  left: 8% !important;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .mouse-scroll-area .kids-shape-4 {
    left: 4% !important;
    top: 65% !important;
  }
}

.mouse-scroll-area .kids-shape-4 img {
  width: 181px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .mouse-scroll-area .kids-shape-4 img {
    width: 150px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .mouse-scroll-area .kids-shape-4 img {
    width: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mouse-scroll-area .kids-shape-4 img {
    width: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mouse-scroll-area .kids-shape-4 img {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .mouse-scroll-area .kids-shape-4 img {
    width: 90px;
  }
}

.mouse-scroll-area .kids-shape-5 {
  position: absolute;
  top: 45% !important;
  left: 0% !important;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mouse-scroll-area .kids-shape-5 {
    top: 40% !important;
  }
}

@media only screen and (max-width: 767px) {
  .mouse-scroll-area .kids-shape-5 {
    top: 20% !important;
  }
}

.mouse-scroll-area .kids-shape-5 img {
  width: 173px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .mouse-scroll-area .kids-shape-5 img {
    width: 150px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .mouse-scroll-area .kids-shape-5 img {
    width: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mouse-scroll-area .kids-shape-5 img {
    width: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mouse-scroll-area .kids-shape-5 img {
    width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .mouse-scroll-area .kids-shape-5 img {
    width: 100px;
  }
}

.hero-slider-content-11 h4 {
  font-size: 40px;
  color: #ffffff;
  font-family: "Playfair Display", serif;
  font-style: italic;
  margin: 0;
  letter-spacing: -0.991px;
  line-height: 1;
}

.hero-slider-content-11 h1 {
  font-size: 99px;
  color: #ffffff;
  font-family: "Playfair Display", serif;
  font-style: italic;
  margin: 0 0 57px;
  letter-spacing: -2.973px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-11 h1 {
    margin: 0 0 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-11 h1 {
    margin: 0 0 40px;
    font-size: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-11 h1 {
    margin: 0 0 30px;
    font-size: 85px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-11 h1 {
    margin: 0 0 30px;
    font-size: 40px;
    letter-spacing: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-11 h1 {
    font-size: 52px;
  }
}

.hero-slider-content-12 h1 {
  font-size: 79px;
  color: #ffffff;
  font-family: "Playfair Display", serif;
  font-style: italic;
  margin: 0 0 47px;
  letter-spacing: -3px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-12 h1 {
    font-size: 70px;
    margin: 0 0 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-12 h1 {
    font-size: 70px;
    margin: 0 0 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-12 h1 {
    font-size: 55px;
    margin: 0 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-12 h1 {
    font-size: 35px;
    margin: 0 0 30px;
    letter-spacing: 0;
  }
}

.hero-slider-content-13 {
  margin: 0 0 0 -40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-13 {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-13 {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-13 {
    margin: 0 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-13 {
    margin: 0 0 0 0px;
  }
}

.hero-slider-content-13 h1 {
  font-size: 79px;
  color: #343434;
  font-family: "Playfair Display", serif;
  font-style: italic;
  margin: 0 0 25px;
  letter-spacing: -3px;
  line-height: 99px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-13 h1 {
    font-size: 67px;
    line-height: 85px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-13 h1 {
    font-size: 67px;
    line-height: 85px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-13 h1 {
    font-size: 65px;
    line-height: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-13 h1 {
    font-size: 40px;
    line-height: 45px;
    letter-spacing: 0;
  }
}

.btn-style-10 a {
  display: inline-block;
  background-color: #ffffff;
  line-height: 1;
  font-size: 18px;
  color: #000000;
  padding: 22px 40px 27px;
}

@media only screen and (max-width: 767px) {
  .btn-style-10 a {
    font-size: 16px;
    padding: 13px 25px 18px;
  }
}

.btn-style-10 a.bg-black {
  color: #ffffff;
  background-color: #000000;
}

.btn-style-10 a.bg-black:hover {
  color: #000000;
  background-color: #ffffff;
}

.btn-style-10 a:hover {
  color: #ffffff;
  background-color: #000000;
}

.btn-style-11 a {
  display: inline-block;
  background-color: #181818;
  line-height: 1;
  font-size: 18px;
  color: #ffffff;
  padding: 21px 50px 27px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-11 a {
    font-size: 16px;
    padding: 16px 35px 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-11 a {
    font-size: 16px;
    padding: 16px 35px 22px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-11 a {
    font-size: 16px;
    padding: 13px 25px 18px;
  }
}

.btn-style-11 a:hover {
  color: #181818;
  background-color: #F3FCEB;
}

.nav-style-5 .slider-icon-1 {
  position: absolute;
  bottom: 7%;
  cursor: pointer;
  width: 60px;
  height: 60px;
  line-height: 66px;
  text-align: center;
  z-index: 9;
  border-radius: 50%;
  font-size: 20px;
  color: #1e1e1e;
  background: rgba(255, 255, 255, 0.5);
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-5 .slider-icon-1 {
    width: 50px;
    height: 50px;
    line-height: 56px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-5 .slider-icon-1 {
    width: 40px;
    height: 40px;
    line-height: 46px;
    font-size: 16px;
  }
}

.nav-style-5 .slider-icon-1.slider-icon-prev {
  margin-left: -40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-5 .slider-icon-1.slider-icon-prev {
    margin-left: -35px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-5 .slider-icon-1.slider-icon-prev {
    margin-left: -25px;
  }
}

.nav-style-5 .slider-icon-1.slider-icon-next {
  margin-left: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-5 .slider-icon-1.slider-icon-next {
    margin-left: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-5 .slider-icon-1.slider-icon-next {
    margin-left: 25px;
  }
}

.nav-style-5 .slider-icon-1:hover {
  background: white;
}

.slider-content-14-position-1 {
  position: absolute;
  right: 14%;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .slider-content-14-position-1 {
    right: 0;
    left: 0;
    text-align: center;
  }
}

.slider-content-14-position-2 {
  position: absolute;
  right: 26%;
  bottom: 13%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-14-position-2 {
    right: 10%;
    bottom: 10%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-14-position-2 {
    right: 8%;
    bottom: 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-14-position-2 {
    right: 8%;
    bottom: 10%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-14-position-2 {
    right: 0;
    left: 0;
    text-align: center;
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
  }
}

.slider-content-14-position-3 {
  position: absolute;
  left: 28%;
  top: 37%;
}

@media only screen and (max-width: 767px) {
  .slider-content-14-position-3 {
    right: 0;
    left: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
  }
}

.hero-slider-content-14 h1 {
  font-size: 44px;
  line-height: 59px;
  color: #181818;
  letter-spacing: -1px;
  margin: 0 0 31px;
  font-family: "Playfair Display", serif;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-14 h1 {
    font-size: 40px;
    line-height: 57px;
    margin: 0 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-14 h1 {
    font-size: 40px;
    line-height: 57px;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-14 h1 {
    font-size: 26px;
    line-height: 35px;
    letter-spacing: 0px;
    margin: 0 0 23px;
  }
}

.hero-slider-content-15 {
  width: 100%;
}

.hero-slider-content-15 h1 {
  color: #ffffff;
  font-size: 79px;
  font-family: "Noto Serif JP", serif;
  font-weight: 500;
  margin: 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-15 h1 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-15 h1 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-15 h1 {
    font-size: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-15 h1 {
    font-size: 50px;
    padding: 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-15 h1 {
    font-size: 28px;
    padding: 0 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-15 h1 {
    font-size: 37px;
  }
}

.hero-slider-content-15 h1.slider-content-15-mb-1 {
  margin: 0 0 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-15 h1.slider-content-15-mb-1 {
    margin: 0 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-15 h1.slider-content-15-mb-1 {
    margin: 0 0 30px;
  }
}

.hero-slider-content-15 p {
  font-size: 24px;
  color: #ffffff;
  margin: 19px 0 60px;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-15 p {
    font-size: 19px;
    margin: 10px 0 35px;
  }
}

.hero-slider-content-16 {
  position: relative;
  z-index: 99;
}

.hero-slider-content-16 h3 {
  font-size: 46px;
  font-family: "Satisfy";
  color: #181818;
  margin: 0;
}

.hero-slider-content-16 .organic-text-img {
  margin: 20px 0 53px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-16 .organic-text-img {
    margin: 20px 0 33px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-16 .organic-text-img {
    margin: 20px 0 33px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-16 .organic-text-img {
    margin: 20px 0 33px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-16 .organic-text-img {
    margin: 20px 0 33px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-16 .organic-text-img {
    margin: 15px 0 25px;
  }
}

.hero-slider-content-16 .organic-text-img img {
  display: inline-block;
  width: 545px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-16 .organic-text-img img {
    width: 430px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-16 .organic-text-img img {
    width: 400px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-16 .organic-text-img img {
    width: 345px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-16 .organic-text-img img {
    width: 345px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-16 .organic-text-img img {
    width: 240px;
  }
}

.organic-slider-mrg {
  margin-left: -140px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .organic-slider-mrg {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .organic-slider-mrg {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .organic-slider-mrg {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .organic-slider-mrg {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .organic-slider-mrg {
    margin-left: 0px;
  }
}

.organic-shape-1 {
  position: absolute;
  top: 5% !important;
  left: 41.5% !important;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .organic-shape-1 {
    left: 48.5% !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .organic-shape-1 {
    left: 48.5% !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .organic-shape-1 {
    left: 48.5% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .organic-shape-1 {
    left: 48.5% !important;
  }
}

@media only screen and (max-width: 767px) {
  .organic-shape-1 {
    left: 48.5% !important;
  }
}

.organic-shape-1 img {
  width: 990px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .organic-shape-1 img {
    width: 620px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .organic-shape-1 img {
    width: 620px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .organic-shape-1 img {
    width: 480px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .organic-shape-1 img {
    width: 380px;
  }
}

@media only screen and (max-width: 767px) {
  .organic-shape-1 img {
    width: 200px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .organic-shape-1 img {
    width: 250px;
  }
}

.organic-shape-2 {
  position: absolute;
  left: 72% !important;
  top: 65% !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .organic-shape-2 {
    top: 60% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .organic-shape-2 {
    top: 60% !important;
  }
}

@media only screen and (max-width: 767px) {
  .organic-shape-2 {
    top: 60% !important;
  }
}

.organic-shape-2 img {
  width: 540px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .organic-shape-2 img {
    width: 350px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .organic-shape-2 img {
    width: 350px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .organic-shape-2 img {
    width: 320px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .organic-shape-2 img {
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .organic-shape-2 img {
    width: 150px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .organic-shape-2 img {
    width: 250px;
  }
}

.organic-shape-3 {
  position: absolute;
  left: 44% !important;
  top: 80% !important;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .organic-shape-3 {
    top: 69% !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .organic-shape-3 {
    top: 69% !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .organic-shape-3 {
    top: 69% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .organic-shape-3 {
    top: 69% !important;
  }
}

@media only screen and (max-width: 767px) {
  .organic-shape-3 {
    top: 69% !important;
  }
}

.organic-shape-3 img {
  width: 243px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .organic-shape-3 img {
    width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .organic-shape-3 img {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .organic-shape-3 img {
    width: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .organic-shape-3 img {
    width: 200px;
  }
}

.organic-shape-4 {
  position: absolute;
  left: -5% !important;
  top: 52% !important;
  z-index: 5;
}

.organic-shape-4 img {
  width: 431px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .organic-shape-4 img {
    width: 331px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .organic-shape-4 img {
    width: 331px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .organic-shape-4 img {
    width: 231px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .organic-shape-4 img {
    width: 231px;
  }
}

@media only screen and (max-width: 767px) {
  .organic-shape-4 img {
    width: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .organic-shape-4 img {
    width: 200px;
  }
}

.organic-shape-5 {
  position: absolute;
  left: -5.2% !important;
  top: 23% !important;
  z-index: 5;
}

.organic-shape-5 img {
  width: 331px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .organic-shape-5 img {
    width: 231px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .organic-shape-5 img {
    width: 231px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .organic-shape-5 img {
    width: 220px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .organic-shape-5 img {
    width: 220px;
  }
}

@media only screen and (max-width: 767px) {
  .organic-shape-5 img {
    width: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .organic-shape-5 img {
    width: 200px;
  }
}

.dot-style-3 ul {
  position: absolute;
  bottom: 2.3%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
}

.dot-style-3 ul li {
  display: inline-block;
  margin: 0 8px;
}

.dot-style-3 ul li button {
  border: none;
  padding: 0;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #ffffff;
  font-size: 0px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
}

.dot-style-3 ul li.slick-active button {
  background-color: #ffffff;
}

.fashion-1-slider-bg-color-1 {
  background-color: #C1BAB4;
}

.fashion-1-slider-bg-color-2 {
  background-color: #1C1C1C;
}

.fashion-2-slider-bg-color-2 {
  background-color: #CAC3BB;
}

.fashion-2-slider-bg-color-3 {
  background-color: #776E65;
}

.fashion-2-slider-bg-color-4 {
  background-color: #C3CED4;
}

.fashion-2-slider-bg-color-5 {
  background-color: #574E42;
}

.electronic-slider-bg-color-1 {
  background-color: #FAFAFA;
}

.electronic-slider-bg-color-2 {
  background-color: #232E44;
}

.auto-parts-slider-bg-color-1 {
  background-color: #EDEBEC;
}

.leather-slider-bg-color {
  background-color: #7A7570;
}

.cosmetic-slider-bg-color {
  background-color: #FFE0DD;
}

.organic-slider-bg-color {
  background-color: #F0FBF7;
}

.motorbikes-slider-bg-color {
  background-color: #8E9397;
}

.kids-slider-bg-color {
  background-color: #ECEEEB;
}

.plants-slider-bg-color-1 {
  background-color: #22282D;
}

.plants-slider-bg-color-2 {
  background-color: #B3A18B;
}

.plants-slider-bg-color-3 {
  background-color: #F5F6F8;
}

.furniture-slider-bg-color-1 {
  background-color: #E7E7E9;
}

.furniture-slider-bg-color-2 {
  background-color: #D6D5D0;
}

.furniture-slider-bg-color-3 {
  background-color: #F2E5DD;
}

.jewelry-slider-bg-color-1 {
  background-color: #04231E;
}

.jewelry-slider-bg-color-2 {
  background-color: #E8B3BD;
}

.jewelry-slider-bg-color-3 {
  background-color: #A4886E;
}

.video-bg-content-wrap {
  background-image: url("../../assets/images/bg/youtube-bg.jpg");
  height: 100vh;
  background-color: #7A7570;
  background-position: center center;
  background-size: cover;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .youtube-video {
    padding-bottom: 0 !important;
  }
}

/*-------- 5. Banner style ---------*/
.new-collections-banner img {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .new-collections-banner {
    margin-top: 20px;
  }
}

.banner-wrap {
  position: relative;
}

.banner-wrap .banner-img a {
  display: block;
}

.banner-wrap .banner-img a img {
  width: 100%;
}

.banner-wrap .banner-img.banner-img-overflow {
  overflow: hidden;
}

.banner-wrap .banner-img.banner-zoom a img {
  transition: all 2s;
  transform: scale(1);
}

.banner-wrap .banner-position-1 {
  position: absolute;
  left: 60px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-position-1 {
    left: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-position-1 {
    left: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-1 {
    left: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-position-1 {
    left: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-1 {
    left: 15px;
  }
}

.banner-wrap .banner-position-2 {
  position: absolute;
  left: 380px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-position-2 {
    left: 240px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-position-2 {
    left: 240px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-2 {
    left: 240px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-position-2 {
    left: 370px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-2 {
    left: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-position-2 {
    left: 300px;
  }
}

.banner-wrap .banner-position-3 {
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-3 {
    left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-3 {
    left: 15px;
  }
}

.banner-wrap .banner-content-1 {
  position: absolute;
  left: 95px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-1 {
    left: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-1 {
    left: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-1 {
    left: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-1 {
    left: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-1 {
    left: 15px;
  }
}

.banner-wrap .banner-content-1 h3 {
  font-size: 46px;
  color: #ffffff;
  line-height: 1.4em;
  font-weight: 600;
  width: 55%;
  margin: 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-1 h3 {
    font-size: 35px;
    width: 70%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-1 h3 {
    font-size: 32px;
    width: 70%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-1 h3 {
    font-size: 32px;
    width: 70%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-1 h3 {
    font-size: 32px;
    width: 70%;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-1 h3 {
    font-size: 18px;
    width: 92%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-1 h3 {
    font-size: 28px;
  }
}

.banner-wrap .banner-content-1 p {
  font-size: 16px;
  color: #ffffff;
  line-height: 2em;
  width: 52%;
  margin: 20px 0 42px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-1 p {
    width: 60%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-1 p {
    width: 70%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-1 p {
    width: 70%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-1 p {
    width: 70%;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-1 p {
    width: 100%;
    font-size: 15px;
    line-height: 24px;
    margin: 0px 0 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-1 p {
    width: 85%;
    margin: 12px 0 22px;
  }
}

.banner-wrap .banner-content-2 h3 {
  line-height: 1.4em;
  font-size: 28px;
  font-weight: 600;
  color: #ffffff;
  margin: 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-2 h3 {
    font-size: 23px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-2 h3 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-2 h3 {
    font-size: 23px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 h3 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-2 h3 {
    font-size: 23px;
  }
}

.banner-wrap .banner-content-2 h3.banner-text-width1 {
  width: 54%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-2 h3.banner-text-width1 {
    width: 60%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-2 h3.banner-text-width1 {
    width: 60%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-2 h3.banner-text-width1 {
    width: 60%;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 h3.banner-text-width1 {
    width: 98%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-2 h3.banner-text-width1 {
    width: 76%;
  }
}

.banner-wrap .banner-content-2 h3.banner-text-width2 {
  width: 54%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-2 h3.banner-text-width2 {
    width: 60%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-2 h3.banner-text-width2 {
    width: 60%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-2 h3.banner-text-width2 {
    width: 60%;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 h3.banner-text-width2 {
    width: 100%;
  }
}

.banner-wrap .banner-content-2 h4 {
  line-height: 1.4em;
  font-size: 28px;
  color: #FFD53D;
  margin: 10px 0 25px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-2 h4 {
    margin: 8px 0 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-2 h4 {
    margin: 8px 0 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-2 h4 {
    margin: 8px 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 h4 {
    margin: 2px 0 10px;
    font-size: 24px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-2 h4 {
    margin: 5px 0 20px;
  }
}

.banner-wrap .banner-content-3 h3 {
  font-size: 46px;
  font-weight: 700;
  line-height: 1.4em;
  color: #ffffff;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-3 h3 {
    font-size: 30px;
    line-height: 1.2em;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-3 h3 {
    font-size: 30px;
    line-height: 1.2em;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3 h3 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-3 h3 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 h3 {
    font-size: 19px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-3 h3 {
    font-size: 30px;
  }
}

.banner-wrap .banner-content-3 h3.banner-text-width3 {
  width: 90%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3 h3.banner-text-width3 {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 h3.banner-text-width3 {
    width: 98%;
  }
}

.banner-wrap .banner-content-3 h3 span.sale-off {
  font-size: 37px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3 h3 span.sale-off {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 h3 span.sale-off {
    font-size: 19px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-3 h3 span.sale-off {
    font-size: 30px;
  }
}

.banner-wrap .banner-content-3 h3 span.percentage {
  font-weight: 600;
}

.banner-wrap .banner-content-3 p {
  line-height: 28px;
  color: #ffffff;
  font-size: 16px;
  width: 80%;
  margin: 7px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3 p {
    line-height: 24px;
    font-size: 14px;
    width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 p {
    line-height: 24px;
    font-size: 14px;
    width: 98%;
  }
}

.banner-wrap .banner-content-4 {
  position: absolute;
  left: 40px;
  bottom: 55px;
  z-index: 9;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-4 {
    bottom: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-4 {
    left: 20px;
    bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-4 {
    left: 15px;
    bottom: 30px;
  }
}

.banner-wrap .banner-content-4 h3 {
  font-size: 26px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0 0 25px;
  width: 50%;
  line-height: 36px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-4 h3 {
    font-size: 22px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-4 h3 {
    font-size: 20px;
    line-height: 18px;
  }
}

.banner-wrap .banner-content-4 h2 {
  font-size: 100px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0 0 0px;
  font-family: "Roboto Condensed", sans-serif;
  font-style: italic;
  line-height: 80px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-4 h2 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-4 h2 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-4 h2 {
    font-size: 50px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-4 h2 {
    font-size: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-4 h2 {
    font-size: 50px;
    line-height: 40px;
  }
}

.banner-wrap .banner-content-5 {
  padding: 32px 70px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 99;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-5 {
    padding: 30px 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-5 {
    padding: 20px 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-5 {
    padding: 27px 45px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-5 {
    padding: 25px 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-5 {
    padding: 22px 30px;
  }
}

.banner-wrap .banner-content-5::before {
  content: '';
  width: 17px;
  height: 17px;
  position: absolute;
  left: 0;
  top: 0;
  border-left: 3px solid #fff;
  border-top: 3px solid #fff;
}

.banner-wrap .banner-content-5::after {
  content: '';
  width: 17px;
  height: 17px;
  position: absolute;
  right: 0;
  top: 0;
  border-right: 3px solid #fff;
  border-top: 3px solid #fff;
}

.banner-wrap .banner-content-5 h3 {
  color: #ffffff;
  margin: 0;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 2px;
  font-family: "Montserrat", sans-serif;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-5 h3 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-5 h3 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-5 h3 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-5 h3 {
    font-size: 18px;
  }
}

.banner-wrap .banner-content-5 h3::before {
  content: '';
  width: 17px;
  height: 17px;
  position: absolute;
  left: 0;
  bottom: 0;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
}

.banner-wrap .banner-content-5 h3::after {
  content: '';
  width: 17px;
  height: 17px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
}

.banner-wrap.overly-point-4-2-hover:before {
  opacity: .3;
  pointer-events: none;
  background-color: #181818;
  transition: all .35s ease 0s;
}

.banner-wrap .banner-content-9 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 52%;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-9 {
    width: 100%;
  }
}

.banner-wrap .banner-content-9 h3 {
  font-family: "Satisfy";
  font-size: 46px;
  margin: 0 0 8px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-9 h3 {
    font-size: 30px;
    margin: 0 0 5px;
  }
}

.banner-wrap .banner-content-9 h2 {
  font-family: "Satisfy";
  font-size: 56px;
  margin: 0 0 0px;
  color: #181818;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-9 h2 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-9 h2 {
    font-size: 30px;
  }
}

.banner-wrap .banner-content-10 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
}

.banner-wrap .banner-content-10 h3 {
  font-family: "Satisfy";
  font-size: 46px;
  margin: 0 0 0px;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-10 h3 {
    font-size: 35px;
  }
}

.banner-wrap .banner-content-11 {
  position: absolute;
  bottom: 20%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
  padding: 0 10px;
}

.banner-wrap .banner-content-11 h3 {
  font-family: "Satisfy";
  font-size: 46px;
  margin: 0 0 0px;
  line-height: 1.4em;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-11 h3 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-11 h3 {
    font-size: 35px;
  }
}

.banner-wrap .banner-content-12 {
  position: absolute;
  top: 9%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
  padding: 0 90px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-12 {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-12 {
    padding: 0 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-12 {
    padding: 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-12 {
    padding: 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-12 {
    padding: 0 40px;
  }
}

.banner-wrap .banner-content-12 h3 {
  font-family: "Satisfy";
  font-size: 46px;
  margin: 0 0 0px;
  line-height: 1.4em;
  color: #ffffff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-12 h3 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-12 h3 {
    font-size: 35px;
  }
}

.banner-wrap .sidebar-banner-content {
  position: absolute;
  left: 25px;
  bottom: 32px;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .sidebar-banner-content {
    left: 15px;
  }
}

.banner-wrap .sidebar-banner-content h4 {
  font-size: 32px;
  color: #ffffff;
  margin: 0 0 5px;
}

.banner-wrap .sidebar-banner-content a {
  font-size: 16px;
  color: #ffffff;
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .sidebar-banner-content a {
    font-size: 15px;
  }
}

.banner-wrap .sidebar-banner-content a:hover {
  color: #D98719;
}

.banner-wrap:hover .banner-zoom a img {
  transform: scale(1.1);
}

.banner-wrap:hover .btn-style-4 a {
  background-color: #e41515;
  color: #ffffff;
}

.banner-wrap:hover.default-overlay.overly-point-4-hover:before {
  opacity: .4;
  pointer-events: none;
  background-color: #000000;
  transition: all .35s ease 0s;
}

.banner-wrap:hover.default-overlay.overly-point-4-2-hover:before {
  opacity: .4;
}

.banner-wrap:hover.default-overlay-white.overly-point-4-hover-white:before {
  opacity: .2;
  pointer-events: none;
  background-color: #ffffff;
  transition: all .35s ease 0s;
}

.banner-wrap-2 .banner-img-2 a {
  display: block;
}

.banner-wrap-2 .banner-img-2 a img {
  max-width: 100%;
}

.banner-wrap-2 .banner-img-2 a img:hover {
  animation-name: elementor-animation-bob-float,elementor-animation-bob;
  animation-duration: .3s,1.5s;
  animation-delay: 0s,.3s;
  animation-timing-function: ease-out,ease-in-out;
  animation-iteration-count: 1,infinite;
  animation-fill-mode: forwards;
  animation-direction: normal,alternate;
}

@keyframes elementor-animation-bob {
  0% {
    transform: translateY(-8px);
  }
  50% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(-8px);
  }
}

@keyframes elementor-animation-bob-float {
  100% {
    transform: translateY(-8px);
  }
}

.banner-area-mrg-top {
  margin-top: -80px;
}

.furniture-categori-padding-10-1 {
  padding-right: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .furniture-categori-padding-10-1 {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .furniture-categori-padding-10-1 {
    padding-right: 0px;
  }
}

.furniture-categori-padding-10-4 {
  padding-right: 10px;
}

@media only screen and (max-width: 767px) {
  .furniture-categori-padding-10-4 {
    padding-right: 0px;
  }
}

.furniture-categori-padding-10-2 {
  padding-left: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .furniture-categori-padding-10-2 {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .furniture-categori-padding-10-2 {
    padding-left: 0px;
  }
}

.furniture-categori-padding-10-3 {
  padding-left: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .furniture-categori-padding-10-3 {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .furniture-categori-padding-10-3 {
    padding-left: 0px;
  }
}

.banner-content-6-position-1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 21%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-content-6-position-1 {
    right: 12%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-content-6-position-1 {
    right: 6%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-content-6-position-1 {
    right: 15%;
  }
}

@media only screen and (max-width: 767px) {
  .banner-content-6-position-1 {
    right: 13%;
  }
}

.banner-content-6-position-2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-content-6-position-2 {
    left: 10%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-content-6-position-2 {
    left: 6%;
  }
}

@media only screen and (max-width: 767px) {
  .banner-content-6-position-2 {
    left: 10%;
  }
}

.banner-content-6-position-3 {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 15%;
}

.banner-content-6-position-4 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
}

.banner-content-6 {
  z-index: 9;
}

.banner-content-6 span {
  display: block;
  font-size: 18px;
  color: #333;
  font-family: "Playfair Display", serif;
  font-style: italic;
}

@media only screen and (max-width: 767px) {
  .banner-content-6 span {
    font-size: 16px;
    color: #333;
  }
}

.banner-content-6 h3 {
  color: #181818;
  font-size: 24px;
  line-height: 33px;
  margin: 14px 0 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-content-6 h3 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-content-6 h3 {
    font-size: 20px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-content-6 h3 {
    font-size: 17px;
    line-height: 28px;
    margin: 10px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .banner-content-6 h3 {
    font-size: 17px;
    line-height: 24px;
    margin: 2px 0 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-content-6 h3 {
    font-size: 20px;
    line-height: 30px;
    margin: 10px 0 0;
  }
}

.banner-content-6 h3 a {
  color: #181818;
}

.banner-wrap-3 {
  position: relative;
}

.banner-wrap-3.banner-wrap-3-padding-1 {
  padding: 78px 0 88px;
}

.banner-wrap-3.banner-wrap-3-padding-2 {
  padding: 53px 0 53px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap-3.banner-wrap-3-padding-2 {
    padding: 0px 0 106px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap-3.banner-wrap-3-padding-2 {
    padding: 0px 0 106px;
  }
}

.banner-wrap-3.banner-wrap-3-border-1 {
  position: relative;
}

.banner-wrap-3.banner-wrap-3-border-1 a {
  z-index: 99;
  position: relative;
}

.banner-wrap-3.banner-wrap-3-border-1 a img {
  width: 188px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap-3.banner-wrap-3-border-1 a img {
    width: 120px;
  }
}

.banner-wrap-3.banner-wrap-3-border-1:before {
  position: absolute;
  content: "";
  left: 48%;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: #D8D8D8;
  z-index: 8;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap-3.banner-wrap-3-border-1:before {
    left: 58%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap-3.banner-wrap-3-border-1:before {
    left: 58%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap-3.banner-wrap-3-border-1:before {
    left: 58%;
  }
}

.banner-wrap-3.banner-wrap-3-border-2 {
  position: relative;
}

.banner-wrap-3.banner-wrap-3-border-2:after {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  height: 45px;
  width: 1px;
  background-color: #D8D8D8;
  z-index: 8;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap-3.banner-wrap-3-border-2:after {
    height: 91px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap-3.banner-wrap-3-border-2:after {
    height: 91px;
  }
}

.banner-wrap-3 .banner-content-7 h3 {
  font-size: 28px;
  font-weight: 500;
  font-family: "Noto Serif JP", serif;
  letter-spacing: -1px;
  margin: 0;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .banner-wrap-3 .banner-content-7 h3 {
    font-size: 18px;
    letter-spacing: 0px;
  }
}

.banner-wrap-3 .banner-content-7 span {
  font-size: 20px;
  letter-spacing: -1px;
  margin: 0;
  color: #333;
  text-transform: uppercase;
  margin: 12px 0 16px;
  display: block;
}

.banner-wrap-3 .banner-content-7 h2 {
  font-size: 80px;
  color: #FF7473;
  letter-spacing: 5px;
  margin: 10px 0 28px;
  line-height: 62px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap-3 .banner-content-7 h2 {
    font-size: 50px;
    letter-spacing: 3px;
    margin: 10px 0 18px;
  }
}

.banner-wrap-3 .banner-content-8-position-1 {
  position: absolute;
  top: 21%;
  left: 48%;
  padding: 15px 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap-3 .banner-content-8-position-1 {
    left: 57%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap-3 .banner-content-8-position-1 {
    left: 57%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap-3 .banner-content-8-position-1 {
    left: 58%;
  }
}

.banner-wrap-3 .banner-content-8-position-2 {
  margin: 18px 0 0;
}

.banner-wrap-3 .banner-content-8 {
  background-color: #F9F9F9;
  z-index: 9;
}

.banner-wrap-3 .banner-content-8 span {
  font-size: 14px;
  text-transform: uppercase;
  color: #333;
}

.banner-wrap-3 .banner-content-8 h4 {
  font-size: 16px;
  line-height: 28px;
  color: #181818;
  margin: 0px 0 9px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.banner-wrap-3 .banner-content-8 h4.b-content-8-width {
  margin: 0px auto 12px;
  width: 55%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap-3 .banner-content-8 h4.b-content-8-width {
    width: 75%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap-3 .banner-content-8 h4.b-content-8-width {
    width: 75%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap-3 .banner-content-8 h4.b-content-8-width {
    width: 75%;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap-3 .banner-content-8 h4.b-content-8-width {
    width: 80%;
  }
}

.banner-wrap-3 .banner-content-8 h3 {
  font-size: 24px;
  color: #FF7473;
  margin: 0;
}

.btn-style-13 a {
  display: inline-block;
  text-transform: uppercase;
  color: #ffffff;
  padding: 12px 38px;
  background-color: #181818;
}

.btn-style-13 a:hover {
  background-color: #FF7473;
}

.shop-banner-area {
  padding: 90px 0 100px;
}

@media only screen and (max-width: 767px) {
  .shop-banner-area {
    padding: 60px 0 60px;
  }
}

.shop-banner-content {
  margin: 0 0 0 -50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-banner-content {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-banner-content {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-banner-content {
    margin: 0 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-banner-content {
    margin: 0 0 0 0px;
  }
}

.shop-banner-content h1 {
  font-size: 66px;
  color: #ffffff;
  text-transform: uppercase;
  line-height: 1;
  margin: 0 0 13px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-banner-content h1 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-banner-content h1 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-banner-content h1 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-banner-content h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-banner-content h1 {
    font-size: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area.pb-90 {
    padding-bottom: 50px;
  }
  .banner-area.pb-95 {
    padding-bottom: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-area.pb-90 {
    padding-bottom: 25px;
  }
  .banner-area.pb-95 {
    padding-bottom: 30px;
  }
}

/*-------- 6. Section title style ---------*/
.section-title-padding {
  padding: 0 130px 0 140px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-title-padding {
    padding: 0 50px 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-title-padding {
    padding: 0 30px 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-padding {
    padding: 0 30px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-padding {
    padding: 0 20px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-padding {
    padding: 0 15px 0 15px;
  }
}

.section-title-1 h3 {
  color: #D98719;
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-size: 36px;
  letter-spacing: -1px;
  line-height: 30px;
  margin: 0px 0 0;
}

@media only screen and (max-width: 767px) {
  .section-title-1 h3 {
    font-size: 30px;
  }
}

.section-title-1 h2 {
  font-family: "Playfair Display", serif;
  font-size: 100px;
  font-style: italic;
  line-height: 1.3em;
  letter-spacing: -4px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-title-1 h2 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-title-1 h2 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-1 h2 {
    font-size: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-1 h2 {
    font-size: 40px;
    letter-spacing: 0;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-1 h2 {
    font-size: 34px;
    letter-spacing: 0;
  }
}

.section-title-1 h2.margin-1 {
  margin: 0px 0 42px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-1 h2.margin-1 {
    margin: 0px 0 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-1 h2.margin-1 {
    margin: 10px 0 25px;
    line-height: 1.2em;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-1 h2.margin-1 {
    margin: 10px 0 20px;
    line-height: 1.2em;
  }
}

.section-pattern {
  height: 2px;
}

.section-pattern.section-pattern-width-1 {
  width: 280px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pattern.section-pattern-width-1 {
    width: 160px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pattern.section-pattern-width-1 {
    width: 160px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pattern.section-pattern-width-1 {
    width: 160px;
  }
}

.section-pattern.section-pattern-margin-1 {
  margin: 0 0 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pattern.section-pattern-margin-1 {
    margin: 0px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pattern.section-pattern-margin-1 {
    margin: 0px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pattern.section-pattern-margin-1 {
    margin: 0px 0 0px;
  }
}

.section-pattern.section-pattern-margin-2 {
  margin: 0 auto;
}

.btn-style-2 a {
  color: #181818;
  font-size: 24px;
  display: inline-block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-2 a {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-2 a {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-2 a {
    font-size: 20px;
  }
}

.btn-style-2 a svg {
  color: #181818;
  width: 60px;
  margin-left: 25px;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-2 a svg {
    width: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-2 a svg {
    width: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-2 a svg {
    width: 34px;
    height: 34px;
    margin-left: 20px;
  }
}

.btn-style-2 a:hover {
  color: #D98719;
}

.btn-style-2 a:hover svg {
  color: #D98719;
}

.btn-style-2.btn-right {
  float: right;
}

@media only screen and (max-width: 767px) {
  .btn-style-2.btn-right {
    float: left;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .btn-style-2.btn-right {
    float: right;
  }
}

.btn-style-2.btn-mt-1 {
  margin-top: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-2.btn-mt-1 {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-2.btn-mt-1 {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-2.btn-mt-1 {
    margin-top: 0px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .btn-style-2.btn-mt-1 {
    margin-top: 10px;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-2 {
    margin-top: 15px;
  }
}

.section-title-2 h2 {
  font-family: "Playfair Display", serif;
  font-size: 80px;
  font-style: italic;
  line-height: 1;
  letter-spacing: -3px;
}

.section-title-2 h2.margin-1 {
  margin: 0px 0 32px;
}

@media only screen and (max-width: 767px) {
  .section-title-2 h2.margin-1 {
    margin: 0px 0 20px;
  }
}

.section-title-2 h2.margin-2 {
  margin: 0px 0 32px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-2 h2 {
    font-size: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-2 h2 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-2 h2 {
    font-size: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-2.mb-90 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-2.mb-50 {
    margin-bottom: 30px;
  }
  .section-title-2.mb-90 {
    margin-bottom: 30px;
  }
}

.section-title-3 h2 {
  font-size: 42px;
  font-weight: 600;
  letter-spacing: -1px;
  margin: 0;
  position: relative;
  padding-bottom: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-3 h2 {
    font-size: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-3 h2 {
    font-size: 30px;
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-3 h2 {
    font-size: 22px;
    padding-bottom: 20px;
  }
}

.section-title-3 h2:before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  background-color: #FFD53D;
  height: 5px;
  width: 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-3.mb-60 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-3.mb-60 {
    margin-bottom: 30px;
  }
}

.btn-style-5 a {
  display: block;
  float: right;
  color: #6D6D6D;
}

@media only screen and (max-width: 767px) {
  .btn-style-5 a {
    float: left;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .btn-style-5 a {
    float: right;
  }
}

.btn-style-5 a i {
  font-size: 14px;
  margin: 0 0 0 5px;
  position: relative;
  top: 4px;
}

.btn-style-5 a:hover {
  color: #FFD53D;
}

@media only screen and (max-width: 767px) {
  .btn-style-5.btn-style-5-responsive {
    margin: 13px 0 16px;
    display: block;
    overflow: hidden;
  }
}

.section-title-4 h2 {
  font-size: 36px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-4 h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-4 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-4 h2 {
    font-size: 20px;
    line-height: 1.3;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title-4 h2 {
    font-size: 25px;
  }
}

.section-title-4 h2.white {
  color: #ffffff;
}

.section-title-4 h2 span {
  color: #e41515;
}

.section-title-4 > span {
  color: #6D6D6D;
  font-size: 24px;
  display: block;
  margin: 10px 0 36px;
}

@media only screen and (max-width: 767px) {
  .section-title-4 > span {
    font-size: 17px;
    margin: 5px 0 20px;
  }
}

.section-title-4 .st-angle-down svg {
  color: #e41515;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-4.mb-60 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-4.mb-60 {
    margin-bottom: 30px;
  }
  .section-title-4.mb-55 {
    margin-bottom: 30px;
  }
}

.section-title-5 h2 {
  font-size: 50px;
  font-weight: 500;
  font-family: "Noto Serif JP", serif;
  letter-spacing: -2px;
  color: #D98719;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-5 h2 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-5 h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-5 h2 {
    font-size: 26px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title-5 h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-5.mb-55 {
    margin-bottom: 35px;
  }
  .section-title-5.mb-30 {
    margin-bottom: 25px;
  }
  .section-title-5.mb-40 {
    margin-bottom: 25px;
  }
  .section-title-5.mb-25 {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-5.mb-55 {
    margin-bottom: 25px;
  }
  .section-title-5.mb-30 {
    margin-bottom: 15px;
  }
  .section-title-5.mb-40 {
    margin-bottom: 15px;
  }
  .section-title-5.mb-25 {
    margin-bottom: 0px;
  }
}

.section-title-6 {
  position: relative;
  z-index: 9;
}

.section-title-6 h2 {
  font-size: 46px;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: -1px;
  font-family: "Playfair Display", serif;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .section-title-6 h2 {
    font-size: 24px;
    letter-spacing: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title-6 h2 {
    font-size: 35px;
  }
}

.section-title-6 h2.white {
  color: #ffffff;
}

.section-title-6 h2.font-size-dec {
  font-size: 36px;
  line-height: 1.2em;
}

@media only screen and (max-width: 767px) {
  .section-title-6 h2.font-size-dec {
    font-size: 24px;
  }
}

.section-title-6 a {
  color: #727272;
  font-size: 16px;
  margin: 17px 0 0;
  display: block;
}

.section-title-6 a i {
  font-size: 18px;
  color: #181818;
  margin-right: 10px;
  top: 1px;
  position: relative;
}

.section-title-6 p {
  color: #6D6D6D;
  font-size: 18px;
  margin: 17px 0 0;
  display: block;
}

.section-title-6 p.font-dec {
  font-size: 14px;
  line-height: 28px;
  margin: 16px auto 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-6 p.font-dec {
    margin: 12px auto 0;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-6 p.font-dec {
    margin: 10px auto 0;
    line-height: 24px;
  }
}

.section-title-6 p.st6-peragraph-width-1 {
  width: 30%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-title-6 p.st6-peragraph-width-1 {
    width: 37%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-title-6 p.st6-peragraph-width-1 {
    width: 42%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-6 p.st6-peragraph-width-1 {
    width: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-6 p.st6-peragraph-width-1 {
    width: 65%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-6 p.st6-peragraph-width-1 {
    width: 90%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-6.section-title-6-center {
    text-align: center;
    margin: 0 0 50px;
  }
  .section-title-6.mb-55 {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-6.mb-25 {
    margin-bottom: 5px;
  }
  .section-title-6.mb-40 {
    margin-bottom: 20px;
  }
  .section-title-6.mb-50 {
    margin-bottom: 15px;
  }
  .section-title-6.mb-55 {
    margin-bottom: 25px;
  }
  .section-title-6.section-title-6-center {
    text-align: center;
    margin: 0 0 30px;
  }
}

.section-title-7 h2 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 26px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 15px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .section-title-7 h2 {
    letter-spacing: 5px;
  }
}

.section-title-8 h2 {
  color: #ffffff;
  margin: 0;
  font-size: 36px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (max-width: 767px) {
  .section-title-8 h2 {
    font-size: 26px;
    letter-spacing: 1px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-8.mb-50 {
    margin-bottom: 30px;
  }
  .section-title-8.pb-55 {
    padding-bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-8.mb-50 {
    margin-bottom: 25px;
  }
  .section-title-8.pb-55 {
    padding-bottom: 30px;
  }
}

.section-title-9 img {
  max-width: 100%;
}

.section-title-9 h2 {
  font-size: 42px;
  font-family: "Pacifico";
  margin: 16px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-9 h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-9 h2 {
    font-size: 27px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-9.mb-60 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-9.mb-60 {
    margin-bottom: 35px;
  }
  .section-title-9.mb-45 {
    margin-bottom: 22px;
  }
}

.section-title-10 img {
  width: 175px;
}

@media only screen and (max-width: 767px) {
  .section-title-10 img {
    width: 125px;
  }
}

.section-title-10 h2 {
  font-size: 42px;
  font-style: italic;
  letter-spacing: -1px;
  font-family: "Playfair Display", serif;
  margin: 20px 0 27px;
}

@media only screen and (max-width: 767px) {
  .section-title-10 h2 {
    font-size: 30px;
    margin: 20px 0 14px;
    letter-spacing: 0px;
  }
}

.section-title-10 p {
  font-size: 18px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-10 p {
    font-size: 17px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-10 p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-10.mb-60 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-10.mb-60 {
    margin-bottom: 25px;
  }
}

.section-title-11 h2 {
  font-size: 56px;
  color: #181818;
  margin: 0;
  position: relative;
  font-family: "Playfair Display", serif;
  display: inline-block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-11 h2 {
    font-size: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-11 h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-11 h2 {
    font-size: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title-11 h2 {
    font-size: 30px;
  }
}

.section-title-11 h2:before {
  position: absolute;
  left: -240px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background-color: #969696;
  height: 1px;
  width: 200px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-11 h2:before {
    width: 100px;
    left: -140px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-11 h2:before {
    width: 40px;
    left: -50px;
  }
}

.section-title-11 h2:after {
  position: absolute;
  right: -240px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background-color: #969696;
  height: 1px;
  width: 200px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-11 h2:after {
    width: 100px;
    right: -140px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-11 h2:after {
    width: 40px;
    right: -50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-11.mb-60 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-11.mb-60 {
    margin-bottom: 28px;
  }
  .section-title-11.mb-55 {
    margin-bottom: 20px;
  }
}

.subscribe-title-2 h2 {
  font-size: 44px;
  color: #ffffff;
  font-family: "Playfair Display", serif;
  margin: 0 0 15px;
}

@media only screen and (max-width: 767px) {
  .subscribe-title-2 h2 {
    font-size: 37px;
  }
}

.subscribe-title-2 p {
  font-size: 16px;
  color: #ffffff;
  line-height: 28px;
  width: 70%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-title-2 p {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-title-2 p {
    margin: 0 auto;
    width: 90%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-title-2 {
    text-align: center;
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-title-2 {
    text-align: center;
    margin: 0 0 20px;
  }
}

.section-title-12 h2 {
  font-family: "Noto Serif JP", serif;
  font-size: 46px;
  font-weight: 500;
  line-height: 1.2em;
  letter-spacing: -1px;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-12 h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-12 h2 {
    font-size: 32px;
  }
}

.section-title-12 p {
  font-size: 18px;
  line-height: 36px;
  margin: 12px auto 0;
  width: 59%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-12 p {
    font-size: 16px;
    line-height: 34px;
    width: 80%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-12 p {
    font-size: 16px;
    line-height: 32px;
    width: 85%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-12 p {
    font-size: 15px;
    line-height: 30px;
    width: 92%;
  }
}

.section-title-12.letter-spacing-negative h2 {
  letter-spacing: -2px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-12.mb-35 {
    margin-bottom: 15px;
  }
  .section-title-12.mb-40 {
    margin-bottom: 20px;
  }
  .section-title-12.mb-45 {
    margin-bottom: 25px;
  }
  .section-title-12.mb-55 {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-12.mb-35 {
    margin-bottom: 15px;
  }
  .section-title-12.mb-40 {
    margin-bottom: 17px;
  }
  .section-title-12.mb-45 {
    margin-bottom: 25px;
  }
  .section-title-12.mb-55 {
    margin-bottom: 35px;
  }
}

.section-title-13 h2 {
  font-size: 54px;
  font-family: "Satisfy";
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-13 h2 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-13 h2 {
    font-size: 36px;
  }
}

.section-title-13 p {
  line-height: 28px;
  width: 56%;
  margin: 15px auto 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-13 p {
    width: 68%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-13 p {
    width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-13 p {
    width: 95%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-13.mb-60 {
    margin-bottom: 45px;
  }
  .section-title-13.mb-50 {
    margin-bottom: 35px;
  }
  .section-title-13.mb-45 {
    margin-bottom: 30px;
  }
  .section-title-13.mb-35 {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-13.mb-60 {
    margin-bottom: 20px;
  }
  .section-title-13.mb-50 {
    margin-bottom: 20px;
  }
  .section-title-13.mb-40 {
    margin-bottom: 20px;
  }
  .section-title-13.mb-45 {
    margin-bottom: 15px;
  }
  .section-title-13.mb-35 {
    margin-bottom: 5px;
  }
  .section-title-13.mb-25 {
    margin-bottom: 5px;
  }
}

.section-title-14 h1 {
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .section-title-14 h1 {
    font-size: 23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-14.mb-55 {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-14.mb-55 {
    margin-bottom: 25px;
  }
}

.section-title-15 h2 {
  font-size: 34px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0 0 28px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-15 h2 {
    margin: 0 0 12px;
    margin: 0 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-15 h2 {
    font-size: 30px;
    margin: 0 0 10px;
  }
}

.section-title-15 p {
  font-size: 18px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-15 {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-15 {
    text-align: center;
  }
  .section-title-15.mb-50 {
    margin-bottom: 30px;
  }
}

.section-title-16 h2 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .section-title-16.mb-55 {
    margin-bottom: 25px;
  }
}

.section-title-17 h2 {
  font-size: 32px;
  font-weight: 600;
  margin: 0;
  padding: 0 0 20px 0;
  position: relative;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .section-title-17 h2 {
    font-size: 26px;
    padding: 0 0 10px 0;
  }
}

.section-title-17 h2:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 3px;
  width: 88px;
  background-color: #D8D8D8;
  bottom: 0;
}

@media only screen and (max-width: 767px) {
  .section-title-17.mb-55 {
    margin-bottom: 30px;
  }
}

.section-title-18 h1 {
  font-size: 32px;
  margin: 0;
  line-height: 28px;
}

@media only screen and (max-width: 767px) {
  .section-title-18 h1 {
    font-size: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-18.mb-60 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-18.mb-60 {
    margin-bottom: 30px;
  }
}

/*-------- 7. Product style ---------*/
.product-wrap {
  position: relative;
}

.product-wrap .product-img {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.product-wrap .product-img > a > img {
  width: 100%;
}

.product-wrap .product-img > a > img.hover-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s ease, transform 2s cubic-bezier(0, 0, 0.44, 1.18), -webkit-transform 2s cubic-bezier(0, 0, 0.44, 1.18);
}

.product-wrap .product-img .product-action-wrap {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  bottom: 35px;
  z-index: 9;
}

.product-wrap .product-img .product-action-wrap > button {
  border: none;
  padding: 0;
  background-color: #ffffff;
  font-size: 20px;
  color: #181818;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
  position: relative;
  margin: 0 4px;
  opacity: 0;
  visibility: hidden;
  transform: translate(0, 40px);
  transition-delay: 100ms;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap .product-img .product-action-wrap > button {
    margin: 0 3px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-img .product-action-wrap > button {
    margin: 0 3px;
    font-size: 18px;
  }
}

@media only screen and (min-width: 410px) and (max-width: 575px) {
  .product-wrap .product-img .product-action-wrap > button {
    margin: 0 3px;
    font-size: 15px;
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
}

.product-wrap .product-img .product-action-wrap > button i {
  line-height: 40px;
}

@media only screen and (min-width: 410px) and (max-width: 575px) {
  .product-wrap .product-img .product-action-wrap > button i {
    line-height: 33px;
  }
}

.product-wrap .product-img .product-action-wrap > button:nth-child(1) {
  transition-delay: 100ms;
}

.product-wrap .product-img .product-action-wrap > button:nth-child(2) {
  transition-delay: 150ms;
}

.product-wrap .product-img .product-action-wrap > button:nth-child(3) {
  transition-delay: 200ms;
}

.product-wrap .product-img .product-action-wrap > button:nth-child(4) {
  transition-delay: 200ms;
}

.product-wrap .product-img .product-action-wrap > button:hover {
  background-color: #181818;
  color: #ffffff;
}

.product-wrap .product-img .product-action-wrap > button > span {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000000;
  padding: 0px 12px 4px;
  font-size: 11px;
  color: #ffffff;
  white-space: pre;
  line-height: 22px;
  opacity: 0;
  visibility: hidden;
}

.product-wrap .product-img .product-action-wrap > button:hover > span {
  opacity: 1;
  visibility: visible;
}

.product-wrap .product-img .product-action-wrap.product-action-border > button {
  border: 1px solid #ebebeb;
}

.product-wrap .product-img .product-action-wrap.product-action-border > button:hover {
  border: 1px solid #181818;
}

.product-wrap .product-img .shop-list-quickview {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  z-index: 99;
  margin-top: 20px;
  visibility: hidden;
  opacity: 0;
  transition: all .35s ease 0s;
}

.product-wrap .product-img .shop-list-quickview > button {
  border: none;
  padding: 0;
  width: 46px;
  height: 46px;
  border-radius: 100%;
  background-color: #fff;
  color: #181818;
  display: inline-block;
  transition: all .3s ease 0s;
  font-size: 22px;
}

.product-wrap .product-img .shop-list-quickview > button i {
  line-height: 46px;
}

.product-wrap .product-img .shop-list-quickview > button > span {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000000;
  padding: 0px 12px 0px;
  font-size: 9px;
  color: #ffffff;
  white-space: pre;
  line-height: 22px;
  opacity: 0;
  visibility: hidden;
}

.product-wrap .product-img .shop-list-quickview > button:hover {
  background-color: #181818;
  color: #fff;
}

.product-wrap .product-img .shop-list-quickview > button:hover > span {
  opacity: 1;
  visibility: visible;
}

.product-wrap .product-img.product-img-border {
  border: 1px solid #ebebeb;
}

.product-wrap .product-img.product-border-radius {
  border-radius: 32px 32px 32px 32px;
}

.product-wrap .product-img .product-badge {
  position: absolute;
  display: inline-block;
  font-size: 12px;
  color: #ffffff;
  z-index: 91;
  line-height: 1;
  padding: 4px 15px 6px;
}

.product-wrap .product-img .product-badge.bg-black {
  background-color: #1D1D1D;
}

.product-wrap .product-img .product-badge.pro-badge-right {
  right: 0;
}

.product-wrap .product-img .product-badge.pro-badge-top {
  top: 20px;
}

.product-wrap .product-img .product-price-5 {
  position: absolute;
  bottom: 6px;
  left: 15px;
}

.product-wrap .product-img .product-price-5 span {
  font-size: 20px;
  color: #FF7473;
  display: inline-block;
  font-family: "Noto Serif JP", serif;
}

.product-wrap .product-img .product-price-5 span.old-price {
  text-decoration: line-through;
  margin-left: 5px;
}

.product-wrap .product-img.product-list-overly::before {
  content: "";
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 250ms ease-out;
  z-index: 9;
  background-color: #181818;
  pointer-events: none;
}

.product-wrap .product-content h4 {
  font-size: 15px;
  margin: 0 0 10px;
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-content h4 {
    line-height: 1.4;
  }
}

.product-wrap .product-content h4.pro-content-noto-font {
  font-size: 18px;
  font-family: "Noto Serif JP", serif;
  line-height: 1.4;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-content h4.pro-content-noto-font {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-content h4.pro-content-noto-font {
    font-size: 16px;
  }
}

.product-wrap .product-content h4 a {
  color: #6D6D6D;
}

.product-wrap .product-content h4 a:hover {
  color: #D98719;
}

.product-wrap .product-content .product-price span {
  font-size: 16px;
  color: #181818;
}

.product-wrap .product-content .product-price span.old-price {
  text-decoration: line-through;
  color: #6D6D6D;
  margin-right: 5px;
}

.product-wrap .product-content.product-content-white h4 a {
  color: #ffffff;
}

.product-wrap .product-content.product-content-white h4 a:hover {
  color: #D98719;
}

.product-wrap .product-content.product-content-white .product-price span {
  color: #ffffff;
}

.product-wrap .product-content.product-content-white .product-price span.old-price {
  color: #ffffff;
}

.product-wrap .product-content-3 h4 {
  font-size: 18px;
  line-height: 1.4;
  margin: 0 0 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-content-3 h4 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 410px) and (max-width: 575px) {
  .product-wrap .product-content-3 h4 {
    font-size: 16px;
    margin: 0 0 6px;
  }
}

.product-wrap .product-content-3 h4 a {
  color: #211E1C;
}

.product-wrap .product-content-3 h4 a:hover {
  color: #FFD53D;
}

.product-wrap .product-content-3 h4 a:hover.pink {
  color: #FF7473;
}

.product-wrap .product-content-3 h4 a:hover.yellow {
  color: #D98719;
}

.product-wrap .product-content-3 .product-price-2 span {
  font-size: 18px;
  color: #181818;
  font-weight: 600;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-content-3 .product-price-2 span {
    font-size: 16px;
  }
}

@media only screen and (min-width: 410px) and (max-width: 575px) {
  .product-wrap .product-content-3 .product-price-2 span {
    font-size: 16px;
  }
}

.product-wrap .product-content-3 .product-price-2 span.old-price {
  color: #6D6D6D;
  font-weight: 400;
  text-decoration: line-through;
  margin-right: 5px;
}

.product-wrap .product-content-3 .product-price-2 span.font-width-400 {
  font-weight: 400;
}

.product-wrap .product-content-4 h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (min-width: 410px) and (max-width: 575px) {
  .product-wrap .product-content-4 h4 {
    font-size: 16px;
    line-height: 1.4;
  }
}

.product-wrap .product-content-4 h4 a {
  color: #211E1C;
}

.product-wrap .product-content-4 h4 a:hover {
  color: #e41515;
}

.product-wrap .product-content-4 .product-price-3 span {
  color: #e41515;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  display: inline-block;
  font-size: 16px;
}

.product-wrap .product-content-4 .product-price-3 span.old-price {
  color: #6D6D6D;
  font-weight: 400;
  text-decoration: line-through;
  margin-right: 2px;
}

.product-wrap .product-content-5 h4 {
  font-size: 16px;
  margin: 0 0 9px;
  color: #211E1C;
}

.product-wrap .product-content-5 h4 a {
  color: #211E1C;
}

.product-wrap .product-content-5 h4 a:hover {
  color: #FF7473;
}

.product-wrap .product-content-5 .product-price-4 span {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: #FF7473;
}

.product-wrap .product-content-5 .product-price-4 span.old-price {
  text-decoration: line-through;
  margin-right: 3px;
  color: #6d6d6d;
}

.product-wrap .product-content-7 h4 {
  font-size: 20px;
  margin: 0 0 9px;
  color: #211E1C;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-wrap .product-content-7 h4 {
    font-size: 17px;
  }
}

@media only screen and (min-width: 410px) and (max-width: 575px) {
  .product-wrap .product-content-7 h4 {
    font-size: 17px;
  }
}

.product-wrap .product-content-7 h4 a {
  color: #211E1C;
}

.product-wrap .product-content-7 h4 a:hover {
  color: #538165;
}

.product-wrap .product-content-7 .product-price-4 span {
  display: inline-block;
  font-size: 16px;
  color: #181818;
  font-weight: 600;
}

.product-wrap .product-content-7 .product-price-4 span.old-price {
  text-decoration: line-through;
  margin-right: 3px;
  color: #6d6d6d;
}

.product-wrap .product-content-9 h4 {
  font-size: 16px;
  line-height: 24px;
  font-family: "Noto Serif JP", serif;
  margin: 0;
  padding: 0 8px;
}

@media only screen and (min-width: 410px) and (max-width: 575px) {
  .product-wrap .product-content-9 h4 {
    font-size: 15px;
  }
}

.product-wrap .product-content-9 h4 a {
  color: #211E1C;
}

.product-wrap .product-content-9 h4 a:hover {
  color: #FF7473;
}

.product-wrap:hover .product-img a img.hover-img {
  opacity: 1;
}

.product-wrap:hover .product-img .product-action-wrap button {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}

.product-wrap:hover .product-img.product-list-overly::before {
  opacity: .5;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap.mb-50 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap.mb-50 {
    margin-bottom: 30px;
  }
}

.tooltip > .tooltip-inner {
  background-color: #000;
  padding: 2px 12px 5px;
  border-radius: 0px;
  font-size: 12px;
}

.bs-tooltip-top .arrow::before {
  display: none;
}

.product-wrap-2 {
  padding: 48px 20px 42px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
  border: 4px solid transparent;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap-2 {
    padding: 38px 10px 32px;
  }
}

.product-wrap-2 .product-content-2 h3 {
  font-size: 22px;
  text-transform: uppercase;
  line-height: 1;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap-2 .product-content-2 h3 {
    font-size: 18px;
  }
}

.product-wrap-2 .product-content-2 h3 a {
  color: #211E1C;
  position: relative;
  padding: 0 0 14px;
  display: block;
}

.product-wrap-2 .product-content-2 h3 a:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  background-color: #D3D3D3;
  width: 90px;
  height: 2px;
}

.product-wrap-2 .product-img-2 {
  text-align: center;
  margin-top: 39px;
}

.product-wrap-2 .product-img-2 a {
  display: inline-block;
}

.product-wrap-2 .product-img-2 a img {
  max-width: 100%;
}

.product-wrap-2:hover {
  border: 4px solid #FFD53D;
}

.product-categori-list {
  padding: 45px 45px 45px 45px;
  box-shadow: 0 2px 25px 0 #E7E7E7;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-categori-list {
    padding: 45px 25px 45px 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-categori-list {
    padding: 45px 25px 45px 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-categori-list {
    padding: 35px 20px 35px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-categori-list {
    padding: 35px 20px 35px 20px;
  }
}

.product-categori-list ul li a {
  font-size: 18px;
  color: #6D6D6D;
  display: block;
  padding: 18px 0px 20px;
  border-bottom: 1px solid #D8D8D8;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-categori-list ul li a {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-categori-list ul li a {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-categori-list ul li a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .product-categori-list ul li a {
    font-size: 16px;
  }
}

.product-categori-list ul li a.active {
  color: #FFD53D;
}

.product-categori-list ul li a:hover {
  color: #FFD53D;
}

.product-phn-content h2 {
  color: #ffffff;
  font-size: 46px;
  font-weight: 500;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-phn-content h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .product-phn-content h2 {
    font-size: 32px;
  }
}

.product-phn-content p {
  color: #ffffff;
  font-size: 18px;
  line-height: 36px;
  margin: 10px auto 28px;
  width: 70%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-phn-content p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-phn-content p {
    width: 100%;
    font-size: 16px;
    line-height: 30px;
    margin: 10px auto 10px;
  }
}

@media only screen and (max-width: 767px) {
  .product-phn-content p {
    width: 100%;
    font-size: 16px;
    line-height: 30px;
    margin: 10px auto 20px;
  }
}

.product-bg-area {
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .product-bg-area {
    padding: 50px 0 0;
  }
}

.product-phn-img {
  text-align: center;
  margin: 0 100px -83px;
  padding-top: 57px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-phn-img {
    margin: 0 0px -83px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-phn-img {
    margin: 0 0px -83px;
    padding-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .product-phn-img {
    margin: 0 0px -83px;
    padding-top: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-phn-img {
    margin: 0 50px -83px;
  }
}

.product-phn-img img {
  max-width: 100%;
}

.product-bg-area-2 {
  padding: 40px 0;
}

@media only screen and (max-width: 767px) {
  .product-bg-area-2 .product-radio-content {
    margin-bottom: 20px;
  }
}

.product-bg-area-2 .product-radio-content h2 {
  font-size: 44px;
  font-weight: 500;
  line-height: 1.4em;
  margin: 0 auto 31px;
  width: 70%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-bg-area-2 .product-radio-content h2 {
    width: 78%;
    font-size: 42px;
    line-height: 1.2em;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-bg-area-2 .product-radio-content h2 {
    width: 80%;
    font-size: 40px;
    line-height: 1.1em;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-bg-area-2 .product-radio-content h2 {
    width: 80%;
    font-size: 35px;
    line-height: 1.1em;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-bg-area-2 .product-radio-content h2 {
    font-size: 26px;
    line-height: 1.1em;
    margin: 0 auto 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-bg-area-2 .product-radio-content h2 {
    font-size: 26px;
    line-height: 1.2em;
    margin: 0 auto 12px;
    width: 100%;
  }
}

.product-bg-area-2 .product-radio-img {
  text-align: center;
}

.product-bg-area-2 .product-radio-img img {
  max-width: 100%;
}

.common-column {
  position: relative;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.common-column.product-width-82 {
  flex: 0 0 82%;
  max-width: 82%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .common-column.product-width-82 {
    flex: 0 0 70%;
    max-width: 70%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .common-column.product-width-82 {
    flex: 0 0 60%;
    max-width: 60%;
  }
}

@media only screen and (max-width: 767px) {
  .common-column.product-width-82 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.no-gutters > [class*="common-"] {
  padding-right: 0;
  padding-left: 0;
}

.product-auto-parts-wrap {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .product-auto-parts-wrap.mb-120 {
    margin-bottom: 60px;
  }
}

.product-auto-parts-wrap .product-auto-parts-img {
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  width: 18%;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-auto-parts-wrap .product-auto-parts-img {
    width: 30%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-auto-parts-wrap .product-auto-parts-img {
    width: 40%;
  }
}

@media only screen and (max-width: 767px) {
  .product-auto-parts-wrap .product-auto-parts-img {
    width: 100%;
    position: static;
  }
}

.product-auto-parts-wrap .product-auto-parts-img .auto-parts-banner-content {
  position: absolute;
  top: 15%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
  padding: 0 10px;
}

@media only screen and (max-width: 767px) {
  .product-auto-parts-wrap .product-auto-parts-img .auto-parts-banner-content {
    top: 10%;
  }
}

.product-auto-parts-wrap .product-auto-parts-img .auto-parts-banner-content h2 {
  color: #ffffff;
  font-size: 36px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1.4em;
  margin: 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-auto-parts-wrap .product-auto-parts-img .auto-parts-banner-content h2 {
    font-size: 27px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-auto-parts-wrap .product-auto-parts-img .auto-parts-banner-content h2 {
    font-size: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-auto-parts-wrap .product-auto-parts-img .auto-parts-banner-content h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-auto-parts-wrap .product-auto-parts-img .auto-parts-banner-content h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-auto-parts-wrap .product-auto-parts-img .auto-parts-banner-content h2 {
    font-size: 30px;
  }
}

.product-auto-parts-wrap .product-auto-parts-img img {
  height: 100%;
  transition: all 0.3s linear 0s;
  will-change: transform, top;
  z-index: 9;
  position: relative;
  width: 100%;
  object-fit: cover;
}

.tab-product-wrap {
  width: 100%;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  padding: 54px 50px 81px 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tab-product-wrap {
    padding: 54px 30px 81px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-product-wrap {
    padding: 44px 30px 71px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-product-wrap {
    padding: 54px 20px 54px 20px;
  }
}

.product-tab-list-1 a {
  color: #6D6D6D;
  font-size: 18px;
  display: inline-block;
  margin-right: 32px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-1 a {
    font-size: 16px;
    margin-right: 12px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-1 a {
    font-size: 16px;
    margin-right: 12px;
    margin-bottom: 5px;
  }
}

.product-tab-list-1 a:last-child {
  margin-right: 0;
}

.product-tab-list-1 a.active {
  color: #181818;
  text-decoration: underline;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-1.mb-40 {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-1.mb-40 {
    margin-bottom: 20px;
  }
}

.tab-content.jump .tab-pane {
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.tab-content.jump .tab-pane.active {
  display: block;
  height: auto;
  opacity: 1;
  overflow: visible;
  animation-name: moveUp;
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.26, 0.69, 0.37, 0.96);
  animation-play-state: running;
}

.product-slider-active-1 .slick-list {
  margin: 0 -15px;
}

.product-slider-active-1 .slick-list .product-wrap-lr-1 {
  padding: 0 15px;
}

.nav-style-3 .product-icon-1 {
  position: absolute;
  top: 33%;
  z-index: 9;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #979797;
  border-radius: 100%;
  text-align: center;
  color: #181818;
  font-size: 12px;
  transition: all .3s ease 0s;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
}

.nav-style-3 .product-icon-1:hover {
  color: #ffffff;
  border: 1px solid #181818;
  background-color: #181818;
}

.nav-style-3 .product-icon-1.product-icon-prev {
  left: -15px;
}

.nav-style-3 .product-icon-1.product-icon-next {
  right: -20px;
}

.nav-style-3:hover .product-icon-1 {
  opacity: 1;
  visibility: visible;
}

.trending-product-wrap {
  position: relative;
}

.trending-product-wrap .trending-product-img {
  position: relative;
  overflow: hidden;
}

.trending-product-wrap .trending-product-img a {
  display: block;
}

.trending-product-wrap .trending-product-img a img {
  width: 100%;
}

.trending-product-wrap .img-zoom a img {
  transition: all 2s;
  transform: scale(1);
}

.trending-product-wrap .trending-product-content {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 50px;
  z-index: 9;
  transition: all .3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .trending-product-wrap .trending-product-content {
    bottom: 20px;
  }
}

.trending-product-wrap .trending-product-content a {
  display: inline-block;
  background-color: #ffffff;
  color: #181818;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -1px;
  font-family: "Noto Serif JP", serif;
  padding: 25px 70px 28px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .trending-product-wrap .trending-product-content a {
    padding: 20px 50px 23px;
    font-size: 22px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .trending-product-wrap .trending-product-content a {
    padding: 20px 40px 23px;
    font-size: 22px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .trending-product-wrap .trending-product-content a {
    padding: 20px 30px 23px;
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .trending-product-wrap .trending-product-content a {
    padding: 20px 30px 23px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .trending-product-wrap .trending-product-content a {
    padding: 15px 30px 15px;
    font-size: 18px;
  }
}

.trending-product-wrap:hover .trending-product-content {
  bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .trending-product-wrap:hover .trending-product-content {
    bottom: 40px;
  }
}

.trending-product-wrap:hover .trending-product-content a {
  background-color: #D98719;
  color: #ffffff;
}

.trending-product-wrap:hover .img-zoom a img {
  transform: scale(1.1);
}

.trending-product-wrap:hover.default-overlay.overly-point-1-hover:before {
  opacity: .1;
  pointer-events: none;
  background-color: #000000;
  transition: all .35s ease 0s;
}

.product-tab-list-2 {
  justify-content: center;
}

.product-tab-list-2 a {
  color: #727272;
  font-size: 24px;
  margin-right: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-tab-list-2 a {
    font-size: 20px;
    margin-right: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-2 a {
    font-size: 20px;
    margin-right: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-2 a {
    font-size: 18px;
    margin-right: 15px;
    margin-bottom: 5px;
  }
}

.product-tab-list-2 a:last-child {
  margin-right: 0;
}

.product-tab-list-2 a.active, .product-tab-list-2 a:hover {
  color: #D98719;
}

@media only screen and (max-width: 767px) {
  .product-tab-list-2.mb-50 {
    margin-bottom: 25px;
  }
}

.product-deal-content {
  border: 1px solid #979797;
  padding: 0 15px;
}

.product-deal-content .hot-deal h1 {
  color: #D98719;
  font-family: "Noto Serif JP", serif;
  font-size: 50px;
  font-weight: 500;
  line-height: 1.4em;
  letter-spacing: -2px;
  display: inline-block;
  margin: -40px 0 0;
  padding: 0 100px;
  background-color: #F9F9F9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-deal-content .hot-deal h1 {
    font-size: 40px;
    line-height: 1.2em;
  }
}

@media only screen and (max-width: 767px) {
  .product-deal-content .hot-deal h1 {
    font-size: 30px;
    letter-spacing: 0px;
    padding: 0 20px;
  }
}

.product-deal-content h3 {
  font-size: 36px;
  font-family: "Noto Serif JP", serif;
  font-weight: 500;
  line-height: 1.4em;
  letter-spacing: -1px;
  margin: 24px 0 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-deal-content h3 {
    font-size: 35px;
    line-height: 1.2em;
    margin: 14px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-deal-content h3 {
    font-size: 20px;
    letter-spacing: 0px;
    margin: 10px 0 0px;
  }
}

.product-deal-content h2 {
  color: #A30F0F;
  font-family: "Noto Serif JP", serif;
  font-size: 46px;
  font-weight: 500;
  line-height: 1.4em;
  letter-spacing: -1px;
  display: block;
}

@media only screen and (max-width: 767px) {
  .product-deal-content h2 {
    font-size: 35px;
    letter-spacing: 0px;
  }
}

.product-deal-content p {
  color: #6D6D6D;
  font-size: 18px;
  line-height: 2em;
  margin: 25px 0 35px;
}

@media only screen and (max-width: 767px) {
  .product-deal-content p {
    font-size: 14px;
    margin: 20px 0 30px;
  }
}

.product-deal-content .deal-btn {
  display: inline-block;
  margin: 0px 0 -100px;
  padding: 0 100px;
  background-color: #F9F9F9;
}

@media only screen and (max-width: 767px) {
  .product-deal-content .deal-btn {
    margin: 0px 0 -20px;
    padding: 0 20px;
  }
}

.product-deal-img {
  margin: 0 -70px 0 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-deal-img {
    margin: 0 0px 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-deal-img {
    margin: 0 0px 30px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-deal-img {
    margin: 0 0px 30px 0px;
  }
}

.product-deal-img img {
  max-width: 100%;
}

.cosmetic-single-pro-img img {
  width: 100%;
}

.cosmetic-single-pro-img:hover {
  animation-name: elementor-animation-bob-float,elementor-animation-bob;
  animation-duration: .3s,1.5s;
  animation-delay: 0s,.3s;
  animation-timing-function: ease-out,ease-in-out;
  animation-iteration-count: 1,infinite;
  animation-fill-mode: forwards;
  animation-direction: normal,alternate;
}

.cosmetic-single-pro-content.cosmetic-single-pro-padding-1 {
  padding: 20px 20px 20px 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cosmetic-single-pro-content.cosmetic-single-pro-padding-1 {
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cosmetic-single-pro-content.cosmetic-single-pro-padding-1 {
    padding: 20px 0px 0px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .cosmetic-single-pro-content.cosmetic-single-pro-padding-1 {
    padding: 20px 0px 0px 0px;
  }
}

.cosmetic-single-pro-content.cosmetic-single-pro-padding-2 {
  padding: 20px 70px 20px 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cosmetic-single-pro-content.cosmetic-single-pro-padding-2 {
    padding: 20px 0px 0px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .cosmetic-single-pro-content.cosmetic-single-pro-padding-2 {
    padding: 20px 0px 0px 0px;
  }
}

.cosmetic-single-pro-content h3 {
  color: #FF7473;
  margin: 0;
  letter-spacing: -1px;
  font-size: 36px;
  font-style: italic;
  font-family: "Playfair Display", serif;
}

@media only screen and (max-width: 767px) {
  .cosmetic-single-pro-content h3 {
    font-size: 30px;
  }
}

.cosmetic-single-pro-content h2 {
  letter-spacing: -2px;
  font-size: 60px;
  font-family: "Playfair Display", serif;
  line-height: 1.33em;
  margin: 23px 0 66px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cosmetic-single-pro-content h2 {
    letter-spacing: -1px;
    font-size: 46px;
    margin: 20px 0 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cosmetic-single-pro-content h2 {
    letter-spacing: -1px;
    font-size: 39px;
    margin: 10px 0 18px;
  }
}

@media only screen and (max-width: 767px) {
  .cosmetic-single-pro-content h2 {
    letter-spacing: 0px;
    font-size: 28px;
    margin: 8px 0 25px;
  }
}

.btn-style-7 a {
  display: inline-block;
  line-height: 1;
  border: 1px solid #979797;
  font-size: 18px;
  color: #181818;
  padding: 28px 53px 30px;
}

.btn-style-7 a.padding-dec-1 {
  padding: 28px 44px 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-7 a.padding-dec-1 {
    padding: 20px 35px 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-7 a.padding-dec-1 {
    padding: 15px 30px 19px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-7 a.padding-dec-1 {
    font-size: 14px;
    padding: 12px 22px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-7 a {
    padding: 20px 40px 22px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-7 a {
    font-size: 14px;
    padding: 12px 22px 15px;
  }
}

.btn-style-7 a.white {
  border: 2px solid #ffffff;
  color: #ffffff;
}

.btn-style-7 a.white:hover {
  border: 2px solid #ffffff;
  background-color: #fff;
  color: #000;
}

.btn-style-7 a:hover {
  border: 1px solid #FF7473;
  background-color: #FF7473;
  color: #ffffff;
}

.cosmetic-img {
  position: relative;
}

.cosmetic-img img {
  width: 100%;
}

.cosmetic-img h5 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  font-size: 20px;
  margin: 0;
  color: #ffffff;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .cosmetic-img h5 {
    font-size: 16px;
  }
}

.cosmetic-img h5 a {
  color: #ffffff;
}

.cosmetic-img h5 a:hover {
  color: #181818;
}

.product-btn {
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .product-btn {
    text-align: center;
  }
}

.product-btn a {
  display: inline-block;
  color: #211E1C;
  float: right;
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .product-btn a {
    float: inherit;
  }
}

.product-btn a:hover {
  color: #FF7473;
}

.product-tab-list-3 {
  justify-content: center;
}

.product-tab-list-3 a {
  color: #181818;
  font-size: 36px;
  letter-spacing: 2px;
  font-family: "Montserrat", sans-serif;
  display: inline-block;
  border-bottom: 2px solid transparent;
  padding-bottom: 19px;
  margin: 0 63px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-tab-list-3 a {
    font-size: 26px;
    margin: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-3 a {
    font-size: 20px;
    margin: 0 15px;
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-3 a {
    font-size: 15px;
    margin: 0 10px 10px;
    padding-bottom: 3px;
    letter-spacing: 1px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-tab-list-3 a {
    font-size: 18px;
    margin: 0 15px 10px;
  }
}

.product-tab-list-3 a:hover {
  color: #e41515;
}

.product-tab-list-3 a.active {
  border-bottom: 2px solid #e41515;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-3.mb-60 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-3.mb-60 {
    margin-bottom: 20px;
  }
}

.product-tab-list-4 {
  justify-content: center;
}

.product-tab-list-4 a {
  font-size: 18px;
  color: #6d6d6d;
  margin-right: 15px;
  margin-right: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-tab-list-4 a {
    margin-right: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-4 a {
    margin-right: 18px;
    font-size: 17px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-4 a {
    margin-right: 13px;
    margin-bottom: 4px;
    font-size: 15px;
  }
}

.product-tab-list-4 a:last-child {
  margin-right: 0;
}

.product-tab-list-4 a.active {
  color: #FF7473;
}

@media only screen and (max-width: 767px) {
  .product-tab-list-4.mb-55 {
    margin-bottom: 20px;
  }
}

.product-btn-2 {
  text-align: center;
  margin-top: 13px;
}

@media only screen and (max-width: 767px) {
  .product-btn-2 {
    margin-top: 0px;
  }
}

.product-btn-2 a {
  color: #181818;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 2px;
  font-family: "Montserrat", sans-serif;
}

.product-btn-2 a:hover {
  color: #e41515;
}

.categori-product-wrap {
  position: relative;
  overflow: hidden;
  border-radius: 22px 22px 22px 22px;
}

.categori-product-wrap a {
  display: block;
}

.categori-product-wrap a img {
  width: 100%;
  transition: all 2s;
  transform: scale(1);
}

.categori-product-wrap .categori-title {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
}

.categori-product-wrap .categori-title h3 {
  font-size: 42px;
  font-family: "Pacifico";
  color: #ffffff;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categori-product-wrap .categori-title h3 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .categori-product-wrap .categori-title h3 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .categori-product-wrap .categori-title h3 {
    font-size: 25px;
  }
}

.categori-product-wrap .categori-title h3 a {
  color: #ffffff;
}

.categori-product-wrap:hover > a img {
  transform: scale(1.1);
}

.categori-product-wrap:hover.default-overlay.overly-point-1-hover:before {
  opacity: .1;
  pointer-events: none;
  background-color: #FF7473;
  transition: all .35s ease 0s;
}

.product-wrap-3-all {
  overflow: hidden;
}

.product-wrap-3-all .product-wrap-3 {
  transition: all .3s;
}

.product-wrap-3-all .product-wrap-3 > a {
  display: block;
}

.product-wrap-3-all .product-wrap-3 > a img {
  width: 100%;
}

.product-wrap-3-all .product-wrap-3 .product-content-6 {
  margin: 25px 0 0;
}

.product-wrap-3-all .product-wrap-3 .product-content-6 h3 {
  font-size: 26px;
  font-family: "Playfair Display", serif;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap-3-all .product-wrap-3 .product-content-6 h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap-3-all .product-wrap-3 .product-content-6 h3 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 410px) and (max-width: 575px) {
  .product-wrap-3-all .product-wrap-3 .product-content-6 h3 {
    font-size: 18px;
  }
}

.product-wrap-3-all .product-wrap-3 .product-content-6 h3 a {
  font-family: "Playfair Display", serif;
  color: #211E1C;
  position: relative;
}

.product-wrap-3-all .product-wrap-3 .product-content-6 h3 a::before {
  width: 1.6em;
  height: 1.6em;
  display: inline-block;
  content: '';
  mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 45 45' width='45' height='45' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='currentColor' stroke-width='3' fill='none' fill-rule='evenodd' stroke-linecap='square'%3E%3Cpath d='M12.5 14.5c8.5-8.5 30-12 30-12s-2.9 20.9-12 30c-9.9 9.9-21.3 3.3-21.3 3.3s-6.6-11.4 3.3-21.3zM24.5 20.5l-22 22'/%3E%3C/g%3E%3C/svg%3E") no-repeat 50% 50%;
  mask-size: cover;
  background-color: #538165;
  vertical-align: middle;
  margin-top: -.2em;
  margin-right: .7em;
}

.product-wrap-3-all .product-wrap-3 .product-content-6 h3 a:hover {
  color: #538165;
}

.product-wrap-3-all .product-wrap-3 .product-content-8 h3 {
  font-size: 26px;
  font-weight: 500;
  margin: 18px 0 0;
  font-family: "Noto Serif JP", serif;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-wrap-3-all .product-wrap-3 .product-content-8 h3 {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap-3-all .product-wrap-3 .product-content-8 h3 {
    font-size: 24px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap-3-all .product-wrap-3 .product-content-8 h3 {
    font-size: 19px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap-3-all .product-wrap-3 .product-content-8 h3 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap-3-all .product-wrap-3 .product-content-8 h3 {
    font-size: 19px;
  }
}

@media only screen and (min-width: 410px) and (max-width: 575px) {
  .product-wrap-3-all .product-wrap-3 .product-content-8 h3 {
    line-height: 1.4;
  }
}

.product-wrap-3-all .product-wrap-3 .product-content-8 h3 a {
  color: #211E1C;
  display: inline-block;
  position: relative;
}

.product-wrap-3-all .product-wrap-3 .product-content-8 h3 a:before {
  position: absolute;
  left: -20px;
  top: 50%;
  width: 10px;
  height: 10px;
  background-color: #211E1C;
  transform: translateY(-50%) rotate(45deg);
  margin-right: .5em;
  content: "";
}

.product-wrap-3-all .product-wrap-3 .product-content-8 h3 a:hover {
  color: #FF7473;
}

.product-wrap-3-all:hover .product-wrap-3 {
  transform: translate(0, -20px);
}

.product-categori-area {
  position: relative;
  overflow: hidden;
}

.product-categori-area .product-categori-banner {
  position: absolute;
  left: -56px;
  bottom: 100px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-categori-area .product-categori-banner {
    bottom: 200px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-categori-area .product-categori-banner {
    bottom: 0px;
    left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-categori-area .product-categori-banner {
    bottom: 0px;
    left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-categori-area .product-categori-banner {
    bottom: 0px;
    left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-categori-area .product-categori-banner {
    display: none;
  }
}

.product-categori-area .product-categori-banner img {
  width: 330px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-categori-area .product-categori-banner img {
    width: 130px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-categori-area .product-categori-banner img {
    width: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-categori-area .product-categori-banner img {
    width: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-categori-area .product-categori-banner img {
    width: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-categori-area.pb-110 {
    padding-bottom: 40px;
  }
  .product-categori-area.pb-80 {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .product-categori-area.pb-110 {
    padding-bottom: 25px;
  }
  .product-categori-area.pb-80 {
    padding-bottom: 20px;
  }
}

.product-btn-3 a {
  font-size: 20px;
  color: #181818;
}

@media only screen and (max-width: 767px) {
  .product-btn-3 a {
    font-size: 20px;
  }
}

.product-btn-3 a:hover {
  color: #538165;
  text-decoration: underline;
}

@media only screen and (max-width: 767px) {
  .product-btn-3.mt-10 {
    margin-top: 0px;
  }
}

.jewelry-single-product-content span {
  display: block;
  color: #181818;
  font-size: 18px;
  text-transform: uppercase;
}

.jewelry-single-product-content h2 {
  font-size: 46px;
  letter-spacing: -1px;
  font-family: "Noto Serif JP", serif;
  font-weight: 500;
  margin: 13px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .jewelry-single-product-content h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .jewelry-single-product-content h2 {
    font-size: 30px;
  }
}

.jewelry-single-product-content p {
  font-size: 18px;
  line-height: 36px;
  color: #6d6d6d;
  margin: 16px 0 68px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .jewelry-single-product-content p {
    margin: 16px 0 48px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .jewelry-single-product-content p {
    margin: 16px 0 48px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .jewelry-single-product-content p {
    font-size: 16px;
    line-height: 33px;
    margin: 16px 0 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .jewelry-single-product-content p {
    font-size: 15px;
    line-height: 30px;
    margin: 12px 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .jewelry-single-product-content p {
    font-size: 15px;
    line-height: 30px;
    margin: 12px 0 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .jewelry-single-product-content.pr-100 {
    padding-right: 50px;
  }
  .jewelry-single-product-content.pl-100 {
    padding-left: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .jewelry-single-product-content.pr-100 {
    padding-right: 0px;
  }
  .jewelry-single-product-content.pl-100 {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .jewelry-single-product-content.pr-100 {
    padding-right: 15px;
  }
  .jewelry-single-product-content.pl-100 {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .jewelry-single-product-content {
    margin-bottom: 30px;
  }
  .jewelry-single-product-content.pr-100 {
    padding-right: 0px;
  }
  .jewelry-single-product-content.pl-100 {
    padding-left: 0px;
  }
}

.btn-style-14 a {
  display: inline-block;
  font-size: 16px;
  color: #6d6d6d;
  letter-spacing: 2px;
  text-transform: uppercase;
  border: 1px solid #979797;
  padding: 26px 54px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-14 a {
    padding: 20px 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-14 a {
    padding: 14px 30px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-14 a {
    padding: 14px 30px;
    font-size: 14px;
  }
}

.btn-style-14 a:hover {
  border: 1px solid #FF7473;
  background-color: #FF7473;
  color: #ffffff;
}

.jewelry-single-product-img a {
  display: block;
}

.jewelry-single-product-img a img {
  width: 100%;
}

.jewelry-single-product-img:hover a img {
  animation-name: elementor-animation-bob-float,elementor-animation-bob;
  animation-duration: .3s,1.5s;
  animation-delay: 0s,.3s;
  animation-timing-function: ease-out,ease-in-out;
  animation-iteration-count: 1,infinite;
  animation-fill-mode: forwards;
  animation-direction: normal,alternate;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .jewelry-single-product-img.ml-45 {
    margin-left: 15px;
  }
  .jewelry-single-product-img.mr-45 {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .jewelry-single-product-img.ml-45 {
    margin-left: 0px;
  }
  .jewelry-single-product-img.mr-45 {
    margin-right: 0px;
  }
}

.jewelry-single-product-mb {
  margin-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .jewelry-single-product-mb {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .jewelry-single-product-mb {
    margin-bottom: 60px;
  }
}

.featured-product-contect h3 {
  font-size: 32px;
  font-weight: 600;
  margin: 0;
  line-height: 1.4em;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .featured-product-contect h3 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .featured-product-contect h3 {
    font-size: 23px;
  }
}

.featured-product-contect p {
  line-height: 28px;
  width: 80%;
  margin: 23px 0 51px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .featured-product-contect p {
    margin: 15px 0 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .featured-product-contect p {
    width: 100%;
    margin: 12px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .featured-product-contect p {
    width: 100%;
    margin: 12px 0 25px;
  }
}

.btn-style-15 a {
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  background-color: #F9792E;
  padding: 22px 50px;
  border-radius: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-15 a {
    font-size: 16px;
    padding: 14px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-15 a {
    font-size: 15px;
    padding: 12px 27px;
  }
}

.btn-style-15 a:hover {
  background-color: #171717;
}

.featured-product-img-wrap {
  margin-left: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .featured-product-img-wrap {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .featured-product-img-wrap {
    margin-left: 0px;
    margin-top: 30px;
  }
}

.featured-product-img-wrap .featured-product-img {
  position: relative;
}

.featured-product-img-wrap .featured-product-img > img {
  border-radius: 50%;
  max-width: 100%;
}

.featured-product-img-wrap .featured-product-img .sub-featured-img {
  position: absolute;
  left: -111px;
  top: 19%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .featured-product-img-wrap .featured-product-img .sub-featured-img {
    top: 25%;
    left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .featured-product-img-wrap .featured-product-img .sub-featured-img {
    left: auto;
    right: 0;
  }
}

.featured-product-img-wrap .featured-product-img .sub-featured-img img {
  width: 225px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .featured-product-img-wrap .featured-product-img .sub-featured-img img {
    width: 125px;
  }
}

@media only screen and (max-width: 767px) {
  .featured-product-img-wrap .featured-product-img .sub-featured-img img {
    width: 125px;
  }
}

.featured-product-img-wrap-2 {
  margin-right: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .featured-product-img-wrap-2 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .featured-product-img-wrap-2 {
    margin-right: 0px;
    margin-top: 30px;
  }
}

.featured-product-img-wrap-2 .featured-product-img-2 {
  position: relative;
}

.featured-product-img-wrap-2 .featured-product-img-2 > img {
  border-radius: 50%;
  max-width: 100%;
}

.featured-product-img-wrap-2 .featured-product-img-2 .sub-featured-img-2 {
  position: absolute;
  right: -145px;
  top: 28%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .featured-product-img-wrap-2 .featured-product-img-2 .sub-featured-img-2 {
    right: 0px;
    top: 36%;
  }
}

@media only screen and (max-width: 767px) {
  .featured-product-img-wrap-2 .featured-product-img-2 .sub-featured-img-2 {
    right: 0px;
  }
}

.featured-product-img-wrap-2 .featured-product-img-2 .sub-featured-img-2 img {
  width: 290px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .featured-product-img-wrap-2 .featured-product-img-2 .sub-featured-img-2 img {
    width: 160px;
  }
}

@media only screen and (max-width: 767px) {
  .featured-product-img-wrap-2 .featured-product-img-2 .sub-featured-img-2 img {
    width: 160px;
  }
}

.fp-content-2 {
  padding-left: 115px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fp-content-2 {
    padding-left: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fp-content-2 {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .fp-content-2 {
    padding-left: 0px;
  }
}

.fp-content-2 p {
  width: 94%;
}

@media only screen and (max-width: 767px) {
  .fp-content-2 p {
    width: 100%;
  }
}

.organic-featured-category-wrap .organic-featured-category-img {
  margin-bottom: 10px;
}

.organic-featured-category-wrap .organic-featured-category-img a {
  display: inline-block;
}

.organic-featured-category-wrap .organic-featured-category-img a img {
  max-width: 100%;
}

.organic-featured-category-wrap .organic-featured-category-content h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.featured-category-slider .slick-list {
  margin: 0 -10px;
}

.featured-category-slider .slick-list .featured-category-padding {
  padding: 10px;
}

.organic-deal-img {
  margin: 0 27px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .organic-deal-img {
    margin: 0 0px;
  }
}

.organic-deal-img a {
  display: block;
}

.organic-deal-img a img {
  width: 100%;
}

.organic-deal-content h3 {
  font-size: 32px;
  font-weight: 600;
  margin: 0;
}

.organic-deal-content p {
  line-height: 28px;
  margin: 21px 0 21px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .organic-deal-content p {
    margin: 12px 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .organic-deal-content p {
    margin: 12px 0 15px;
  }
}

.organic-deal-content h2 {
  font-size: 54px;
  color: #6BB33E;
  margin: 0 0 22px;
  font-family: "Satisfy";
}

@media only screen and (max-width: 767px) {
  .organic-deal-content h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .organic-deal-content {
    margin-top: 15px;
  }
}

.nav-style-6 > span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #000;
  font-size: 15px;
  background-color: transparent;
  border: 1px solid #979797;
  border-radius: 50px;
  z-index: 9;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-6 > span {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-6 > span {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-6 > span {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

.nav-style-6 > span.deal-pro-icon-prev {
  left: -168px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .nav-style-6 > span.deal-pro-icon-prev {
    left: -50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-6 > span.deal-pro-icon-prev {
    left: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-6 > span.deal-pro-icon-prev {
    left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-6 > span.deal-pro-icon-prev {
    left: -10px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-6 > span.deal-pro-icon-prev {
    left: 0px;
  }
}

.nav-style-6 > span.deal-pro-icon-next {
  right: -168px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .nav-style-6 > span.deal-pro-icon-next {
    right: -50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-6 > span.deal-pro-icon-next {
    right: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-6 > span.deal-pro-icon-next {
    right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-6 > span.deal-pro-icon-next {
    right: -10px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-6 > span.deal-pro-icon-next {
    right: 0px;
  }
}

.nav-style-6 > span:hover {
  background-color: #D98719;
  color: #ffffff;
  border: 1px solid #D98719;
}

.nav-style-6:hover span {
  opacity: 1;
  visibility: visible;
}

.organic-deal-slider {
  margin: 0 -15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .organic-deal-slider {
    margin: 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .organic-deal-slider {
    margin: 0 0px;
  }
}

.feature-product-mb {
  margin-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-product-mb {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-product-mb {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-area {
    padding: 50px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-area.pt-105 {
    padding-top: 35px;
  }
  .product-area.pt-75 {
    padding-top: 35px;
  }
  .product-area.pt-95 {
    padding-top: 55px;
  }
  .product-area.pb-110 {
    padding-bottom: 40px;
  }
  .product-area.pb-100 {
    padding-bottom: 25px;
  }
  .product-area.pb-105 {
    padding-bottom: 30px;
  }
  .product-area.pb-65 {
    padding-bottom: 20px;
  }
  .product-area.pb-70 {
    padding-bottom: 30px;
  }
  .product-area.pb-75 {
    padding-bottom: 35px;
  }
  .product-area.pb-80 {
    padding-bottom: 40px;
  }
  .product-area.pb-90 {
    padding-bottom: 50px;
  }
  .deal-area {
    padding: 50px 0 95px;
  }
  .categori-area.pb-115 {
    padding-bottom: 50px;
  }
  .categori-area.pb-85 {
    padding-bottom: 50px;
  }
  .deal-area-2.pb-110 {
    padding-bottom: 70px;
  }
  .featured-area.pt-125 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .product-area.pb-110 {
    padding-bottom: 20px;
  }
  .product-area.pb-100 {
    padding-bottom: 20px;
  }
  .product-area.pb-65 {
    padding-bottom: 20px;
  }
  .product-area.pt-75 {
    padding-top: 15px;
  }
  .product-area.pb-105 {
    padding-bottom: 20px;
  }
  .product-area.pt-105 {
    padding-top: 20px;
  }
  .product-area.pt-95 {
    padding-top: 35px;
  }
  .product-area.pb-70 {
    padding-bottom: 15px;
  }
  .product-area.pb-75 {
    padding-bottom: 20px;
  }
  .product-area.pb-80 {
    padding-bottom: 20px;
  }
  .product-area.pb-90 {
    padding-bottom: 35px;
  }
  .deal-area {
    padding: 50px 0 95px;
  }
  .deal-area-2.pb-85 {
    padding-bottom: 60px;
  }
  .categori-area.pb-115 {
    padding-bottom: 30px;
  }
  .categori-area.pb-85 {
    padding-bottom: 30px;
  }
  .single-organic-deal-product {
    text-align: center;
  }
  .deal-area-2.pb-110 {
    padding-bottom: 50px;
  }
  .featured-area.pt-125 {
    padding-top: 60px;
  }
  .featured-category-area.pb-105 {
    padding-bottom: 28px;
  }
}

@media only screen and (min-width: 410px) and (max-width: 575px) {
  .product-responsive .col-12 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

/*-------- 8. Testimonial style ---------*/
.quote-img {
  text-align: center;
}

.quote-img svg {
  width: 42px;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .quote-img svg {
    width: 38px;
  }
}

.quote-img svg img {
  width: 100%;
}

.single-testimonial p {
  color: #181818;
  font-family: "Playfair Display", serif;
  font-size: 36px;
  font-style: italic;
  line-height: 1.4em;
  letter-spacing: -1px;
  width: 63%;
  margin: 22px auto 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial p {
    width: 80%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial p {
    width: 85%;
    font-size: 30px;
    margin: 30px auto 30px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial p {
    width: 100%;
    font-size: 22px;
    line-height: 1.6em;
    margin: 20px auto 25px;
    letter-spacing: 0px;
  }
}

.single-testimonial .client-info {
  margin: 0 0 26px;
}

.single-testimonial .client-info img {
  display: inline-block;
  width: 70px;
  height: 70px;
}

.single-testimonial .client-info h3 {
  font-size: 18px;
  color: #6D6D6D;
  margin: 20px 0 0;
}

.single-testimonial-2 .testimonial-rating i {
  color: #D98719;
  font-size: 16px;
}

.single-testimonial-2 p {
  color: #fff;
  font-size: 20px;
  line-height: 40px;
  margin: 4px auto 0;
  width: 63%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-2 p {
    width: 75%;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-2 p {
    width: 100%;
    font-size: 14px;
    line-height: 30px;
  }
}

.single-testimonial-2 .client-info-2 {
  text-align: center;
  margin: 21px 0 0;
}

.single-testimonial-2 .client-info-2 img {
  display: inline-block;
  width: 50px;
}

.single-testimonial-2 .client-info-2 h3 {
  font-size: 22px;
  color: #ffffff;
  margin: 15px 0 0;
}

.dot-style-1 ul {
  display: flex;
  justify-content: center;
}

.dot-style-1 ul li button {
  width: 12px;
  height: 12px;
  border: none;
  padding: 0;
  font-size: 0;
  border-radius: 100%;
  margin: 0 10px;
  background-color: #d8d8d8;
  transition: all .3s ease 0s;
}

.dot-style-1 ul li.slick-active button {
  background-color: #3E3E3E;
}

.dot-style-1 ul li:hover button {
  background-color: #3E3E3E;
}

.dot-style-1.dot-active-yellow ul li.slick-active button {
  background-color: #D98719;
}

.dot-style-1.dot-active-yellow ul li:hover button {
  background-color: #D98719;
}

.dot-style-1.dot-style-1-white ul {
  margin-top: 28px;
}

.dot-style-1.dot-style-1-white ul li button {
  background-color: #fff;
}

.dot-style-1.dot-style-1-white ul li.slick-active button {
  background-color: #e41515;
}

.dot-style-1.dot-style-1-white ul li:hover button {
  background-color: #e41515;
}

.dot-style-1.dot-style-1-plants ul {
  margin-top: 18px;
}

.dot-style-1.dot-style-1-plants ul li button {
  background-color: rgba(255, 255, 255, 0.32);
}

.dot-style-1.dot-style-1-plants ul li.slick-active button {
  background-color: #ffffff;
}

.dot-style-1.dot-style-1-plants ul li:hover button {
  background-color: #ffffff;
}

.dot-style-1.dot-style-1-black ul li button {
  background-color: #181818;
}

.dot-style-1.dot-style-1-black ul li.slick-active button {
  background-color: #FF7473;
}

.dot-style-1.dot-style-1-black ul li:hover button {
  background-color: #FF7473;
}

.testimonial-active {
  position: relative;
  z-index: 9;
}

.testimonial-active-2 .slick-list {
  margin: 0 -15px;
}

.testimonial-active-2 .slick-list .testimonial-wrap-lr-1 {
  padding: 30px 15px;
}

.single-testimonial-3 {
  background: #ffffff;
  padding: 60px 45px 60px 45px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-testimonial-3 {
    padding: 60px 35px 60px 35px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-testimonial-3 {
    padding: 40px 35px 40px 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial-3 {
    padding: 25px 20px 35px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-3 {
    padding: 40px 20px 40px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-3 {
    padding: 40px 25px 40px 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-testimonial-3 {
    padding: 30px 25px 30px 25px;
  }
}

.single-testimonial-3 p {
  color: #181818;
  font-size: 18px;
  line-height: 2em;
  font-weight: 500;
  font-family: "Noto Serif JP", serif;
  margin: 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-testimonial-3 p {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-testimonial-3 p {
    font-size: 17px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial-3 p {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-3 p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-3 p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-testimonial-3 p {
    font-size: 14px;
  }
}

.single-testimonial-3 p.playfair {
  color: #181818;
  font-size: 18px;
  line-height: 33px;
  font-family: "Playfair Display", serif;
  font-weight: 400;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial-3 p.playfair {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-3 p.playfair {
    font-size: 17px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-testimonial-3 p.playfair {
    font-size: 15px;
  }
}

.single-testimonial-3 .testimonial-rating-2 {
  margin: 21px 0 16px;
}

.single-testimonial-3 .testimonial-rating-2 i {
  color: #F5A623;
  font-size: 13px;
}

.single-testimonial-3 .client-info-2 {
  display: flex;
  align-items: center;
}

.single-testimonial-3 .client-info-2 img {
  width: 50px;
}

.single-testimonial-3 .client-info-2 h3 {
  font-size: 18px;
  color: #6D6D6D;
  margin: 0 0 0px 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial-3 .client-info-2 h3 {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-3 .client-info-2 h3 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-3 .client-info-2 h3 {
    font-size: 16px;
  }
}

.single-testimonial-3 .client-info-2 h3.inc-font-size {
  color: #6D6D6D;
  font-size: 20px;
}

@media only screen and (max-width: 767px) {
  .single-testimonial-3 .client-info-2 h3.inc-font-size {
    font-size: 16px;
  }
}

.single-testimonial-4 .testimonial-rating-3 i {
  color: #D98719;
}

.single-testimonial-4 p {
  font-size: 24px;
  line-height: 40px;
  color: #ffffff;
  font-weight: 500;
  font-family: "Noto Serif JP", serif;
  margin: 20px auto 37px;
  width: 95%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-testimonial-4 p {
    width: 96%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial-4 p {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-4 p {
    width: 80%;
    font-size: 22px;
    margin: 15px auto 25px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-4 p {
    width: 98%;
    font-size: 19px;
    line-height: 33px;
    margin: 14px auto 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-testimonial-4 p {
    width: 87%;
  }
}

.single-testimonial-4 .client-info-3 h4 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 6px;
  margin: 0 0 8px;
}

.single-testimonial-4 .client-info-3 span {
  color: rgba(255, 255, 255, 0.72);
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 3px;
  display: block;
}

.testimonial-ptb {
  padding: 255px 0 207px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .testimonial-ptb {
    padding: 205px 0 157px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .testimonial-ptb {
    padding: 205px 0 157px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-ptb {
    padding: 100px 0 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-ptb {
    padding: 70px 0 22px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-ptb {
    padding: 55px 0 2px;
  }
}

.single-testimonial-5 .quote-img-2 svg {
  fill: #FF7473;
}

.single-testimonial-5 p {
  font-size: 20px;
  line-height: 38px;
  color: #6d6d6d;
  margin: 19px auto 20px;
  width: 53%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial-5 p {
    font-size: 20px;
    line-height: 46px;
    width: 83%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-5 p {
    width: 86%;
    font-size: 18px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-5 p {
    width: 98%;
    font-size: 15px;
    line-height: 30px;
  }
}

.single-testimonial-5 .client-info-4 h3 {
  font-size: 20px;
  font-family: "Pacifico";
  color: #181818;
  margin: 0 0 23px;
}

.testimonial-ptb-2 {
  padding: 220px 0 220px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .testimonial-ptb-2 {
    padding: 180px 0 180px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .testimonial-ptb-2 {
    padding: 150px 0 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-ptb-2 {
    padding: 100px 0 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-ptb-2 {
    padding: 100px 0 100px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-ptb-2 {
    padding: 90px 0 90px;
  }
}

.quote-img-3 {
  text-align: center;
}

.quote-img-3 svg {
  fill: #ffffff;
  width: 4.3em;
  height: 4.3em;
  display: inline-block;
}

.single-testimonial-6 {
  position: relative;
  z-index: 999;
}

.single-testimonial-6 p {
  font-size: 35px;
  line-height: 56px;
  font-family: "Playfair Display", serif;
  color: #ffffff;
  font-style: italic;
  width: 73%;
  margin: 30px auto 51px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial-6 p {
    font-size: 32px;
    width: 83%;
    margin: 25px auto 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-6 p {
    font-size: 28px;
    line-height: 50px;
    width: 100%;
    margin: 25px auto 35px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-6 p {
    font-size: 23px;
    line-height: 40px;
    width: 98%;
    margin: 15px auto 20px;
  }
}

.single-testimonial-6 .client-info-5 h5 {
  font-size: 18px;
  color: #ffffff;
  margin: 0 0 9px;
  color: #ddd;
}

.single-testimonial-6 .client-info-5 span {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.46);
  display: block;
}

.single-testimonial-7 .testimonial-icon {
  text-align: center;
}

.single-testimonial-7 .testimonial-icon img {
  display: inline-block;
}

.single-testimonial-7 .testimonial-icon svg {
  fill: #ffffff;
  color: #ffffff;
}

.single-testimonial-7 p {
  font-size: 20px;
  line-height: 36px;
  color: #ffffff;
  margin: 25px auto 35px;
  width: 69%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-7 p {
    width: 75%;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-7 p {
    font-size: 16px;
    line-height: 32px;
    margin: 15px auto 25px;
    width: 88%;
  }
}

.single-testimonial-7 .client-info-7 img {
  display: inline-block;
  width: 50px;
}

.single-testimonial-7 .client-info-7 h3 {
  font-size: 16px;
  color: #ffffff;
  margin: 17px 0 0;
}

@media only screen and (max-width: 767px) {
  .testimonial-area.pb-100 {
    padding-bottom: 40px;
  }
  .testimonial-area.pb-115 {
    padding-bottom: 60px;
  }
  .testimonial-area.pt-115 {
    padding-top: 60px;
  }
}

/*-------- 9. Video style ---------*/
.video-bg-ptb {
  padding: 200px 0 200px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-bg-ptb {
    padding: 150px 0 150px;
  }
}

@media only screen and (max-width: 767px) {
  .video-bg-ptb {
    padding: 120px 0 120px;
  }
}

.video-content {
  position: relative;
  z-index: 9;
}

.video-content .video-icon a {
  display: inline-block;
}

.video-content .video-icon a svg {
  color: #fff;
  width: 160px;
  height: 160px;
  transform: scale(1);
  transition: all .3s ease;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-content .video-icon a svg {
    width: 150px;
    height: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .video-content .video-icon a svg {
    width: 100px;
    height: 100px;
  }
}

.video-content .video-icon a svg:hover {
  transform: scale(0.9);
  color: #D98719;
}

.video-content h3 {
  margin: 30px 0 0;
  font-size: 26px;
  font-style: italic;
  color: #fff;
  font-family: "Playfair Display", serif;
}

@media only screen and (max-width: 767px) {
  .video-content h3 {
    margin: 25px 0 0;
    font-size: 24px;
  }
}

.video-content-2 {
  position: relative;
  z-index: 9;
}

.video-content-2 h2 {
  color: #ffffff;
  font-size: 80px;
  line-height: 1.3em;
  letter-spacing: -3px;
  font-family: "Playfair Display", serif;
  margin: 0 0 48px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .video-content-2 h2 {
    font-size: 55px;
    margin: 0 0 28px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .video-content-2 h2 {
    font-size: 55px;
    margin: 0 0 28px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-content-2 h2 {
    font-size: 40px;
    letter-spacing: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-content-2 h2 {
    font-size: 47px;
    letter-spacing: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .video-content-2 h2 {
    font-size: 25px;
    letter-spacing: 0px;
    margin: 0 0 30px;
  }
}

.video-content-2 .video-icon-2 a svg {
  width: 130px;
  height: 130px;
  color: #ffffff;
  transition: all .3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .video-content-2 .video-icon-2 a svg {
    width: 80px;
    height: 80px;
  }
}

.video-content-2 .video-icon-2 a svg:hover {
  color: #538165;
  animation-name: elementor-animation-bob-float,elementor-animation-bob;
  animation-duration: .3s,1.5s;
  animation-delay: 0s,.3s;
  animation-timing-function: ease-out,ease-in-out;
  animation-iteration-count: 1,infinite;
  animation-fill-mode: forwards;
  animation-direction: normal,alternate;
}

.video-content-3 {
  position: relative;
  z-index: 9;
}

.video-content-3 .video-icon-3 a svg {
  width: 150px;
  height: 150px;
  color: #ffffff;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .video-content-3 .video-icon-3 a svg {
    width: 180px;
    height: 180px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-content-3 .video-icon-3 a svg {
    width: 150px;
    height: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-content-3 .video-icon-3 a svg {
    width: 120px;
    height: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .video-content-3 .video-icon-3 a svg {
    width: 80px;
    height: 80px;
  }
}

.video-content-3 .video-icon-3 a svg:hover {
  color: #FF7473;
  animation-name: elementor-animation-bob-float,elementor-animation-bob;
  animation-duration: .3s,1.5s;
  animation-delay: 0s,.3s;
  animation-timing-function: ease-out,ease-in-out;
  animation-iteration-count: 1,infinite;
  animation-fill-mode: forwards;
  animation-direction: normal,alternate;
}

.video-content-3 h3 {
  color: #ffffff;
  font-family: "Noto Serif JP", serif;
  font-size: 28px;
  margin: 42px 0 0;
}

@media only screen and (max-width: 767px) {
  .video-content-3 h3 {
    margin: 22px 0 0;
  }
}

.video-bg-ptb-2 {
  padding: 201px 0 226px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-bg-ptb-2 {
    padding: 150px 0 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-bg-ptb-2 {
    padding: 150px 0 150px;
  }
}

@media only screen and (max-width: 767px) {
  .video-bg-ptb-2 {
    padding: 80px 0 80px;
  }
}

.video-bg-ptb-3 {
  padding: 166px 0 166px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-bg-ptb-3 {
    padding: 100px 0 100px;
  }
}

@media only screen and (max-width: 767px) {
  .video-bg-ptb-3 {
    padding: 80px 0 80px;
  }
}

.video-content-4 {
  padding: 224px 0 244px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-content-4 {
    padding: 174px 0 194px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-content-4 {
    padding: 124px 0 144px;
  }
}

@media only screen and (max-width: 767px) {
  .video-content-4 {
    padding: 100px 0 100px;
  }
}

.video-content-4 a {
  color: #ffffff;
  font-size: 40px;
  position: relative;
  z-index: 9;
}

.video-content-4 a:hover {
  color: #D98719;
}

.video-content-4 h3 {
  margin: 15px 0 0;
  font-size: 26px;
  font-family: "Playfair Display", serif;
  font-style: italic;
  color: #ffffff;
  position: relative;
  z-index: 9;
}

/*-------- 10. Blog style ---------*/
.blog-wrap span a {
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #D98719;
}

.blog-wrap span a:hover {
  text-decoration: underline;
}

.blog-wrap h3 {
  font-size: 32px;
  line-height: 42px;
  font-family: "Playfair Display", serif;
  letter-spacing: -1px;
  margin: 4px 0 22px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap h3 {
    font-size: 23px;
    line-height: 35px;
    margin: 4px 0 20px;
    margin: 4px 0 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap h3 {
    font-size: 22px;
    margin: 4px 0 10px;
    line-height: 33px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap h3 {
    font-size: 22px;
    line-height: 33px;
    margin: 4px 0 10px;
  }
}

.blog-wrap h3 a {
  color: #211E1C;
}

.blog-wrap h3 a:hover {
  color: #D98719;
}

.blog-wrap p {
  font-size: 16px;
  line-height: 32px;
  color: #6D6D6D;
  margin: 0 0 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap p {
    margin: 0 0 10px;
    font-size: 15px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap p {
    font-size: 14px;
    line-height: 28px;
    margin: 0 0 8px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap p {
    font-size: 14px;
    line-height: 28px;
    margin: 0 0 18px;
  }
}

.blog-wrap .blog-btn a {
  display: inline-block;
  color: #D98719;
  font-size: 16px;
}

.blog-wrap .blog-btn a:hover {
  text-decoration: underline;
}

.blog-wrap.blog-transform {
  transition: transform .3s;
}

.blog-wrap.blog-transform:hover {
  transform: translateY(-20px);
}

.blog-wrap-2 {
  display: flex;
  flex-wrap: wrap;
}

.blog-wrap-2 .blog-img {
  flex: 0 0 44%;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .blog-wrap-2 .blog-img {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-wrap-2 .blog-img {
    flex: 0 0 44%;
  }
}

.blog-wrap-2 .blog-img a {
  display: block;
}

.blog-wrap-2 .blog-img a img {
  width: 100%;
  transform: scale(1);
  transition: all 2s ease-out;
}

.blog-wrap-2 .blog-img:hover a img {
  transform: scale(1.2);
}

.blog-wrap-2 .blog-content-2 {
  flex: 0 0 56%;
  padding: 0 50px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .blog-wrap-2 .blog-content-2 {
    padding: 0 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-wrap-2 .blog-content-2 {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap-2 .blog-content-2 {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-2 .blog-content-2 {
    flex: 0 0 100%;
    padding: 20px 0px 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-wrap-2 .blog-content-2 {
    flex: 0 0 56%;
    padding: 0px 20px;
  }
}

.blog-wrap-2 .blog-content-2 span a {
  color: #181818;
}

.blog-wrap-2 .blog-content-2 span a:hover {
  color: #FFD53D;
  text-decoration: underline;
}

.blog-wrap-2 .blog-content-2 h3 {
  font-size: 24px;
  font-weight: 600;
  margin: 4px 0 13px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .blog-wrap-2 .blog-content-2 h3 {
    font-size: 21px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-wrap-2 .blog-content-2 h3 {
    font-size: 21px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-2 .blog-content-2 h3 {
    font-size: 21px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-wrap-2 .blog-content-2 h3 {
    font-size: 19px;
  }
}

.blog-wrap-2 .blog-content-2 h3 a {
  color: #211E1C;
}

.blog-wrap-2 .blog-content-2 h3 a:hover {
  color: #FFD53D;
}

.blog-wrap-2 .blog-content-2 .post-meta ul {
  display: flex;
}

.blog-wrap-2 .blog-content-2 .post-meta ul li {
  color: #181818;
}

.blog-wrap-2 .blog-content-2 .post-meta ul li:not(:first-child)::before {
  content: "|";
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  opacity: .5;
  position: relative;
  top: -1px;
}

.blog-wrap-2 .blog-content-2 .post-meta ul li a {
  color: #181818;
}

.blog-wrap-2 .blog-content-2 p {
  line-height: 28px;
  color: #6D6D6D;
  margin: 11px 0 28px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .blog-wrap-2 .blog-content-2 p {
    margin: 11px 0 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-wrap-2 .blog-content-2 p {
    margin: 11px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-2 .blog-content-2 p {
    margin: 11px 0 18px;
  }
}

.blog-wrap-2 .blog-content-2 .blog-btn-2 a {
  display: inline-block;
  line-height: 1;
  color: #181818;
  border: 1px solid #ebebeb;
  padding: 18px 30px;
  font-weight: 600;
}

.blog-wrap-2 .blog-content-2 .blog-btn-2 a:hover {
  border: 1px solid #FFD53D;
  background-color: #FFD53D;
  color: #ffffff;
}

.blog-wrap-2 .blog-content-2 .blog-btn-2.blog-btn-2-red a:hover {
  border: 1px solid #e41515;
  background-color: #e41515;
}

.blog-all-mrg {
  margin: 0 -25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-all-mrg {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-all-mrg {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-all-mrg {
    margin: 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-all-mrg {
    margin: 0 0px;
  }
}

.blog-wrap-3 {
  transition: all .3s ease 0s;
}

.blog-wrap-3 .blog-img-2 {
  overflow: hidden;
  margin: 0 0 21px;
  box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2);
}

.blog-wrap-3 .blog-img-2 a {
  display: block;
}

.blog-wrap-3 .blog-img-2 a img {
  width: 100%;
  transition: all 2s ease-out;
}

.blog-wrap-3 .blog-img-2:hover a img {
  transform: scale(1.2);
}

.blog-wrap-3 .blog-content-3 span a {
  color: #6D6D6D;
  text-transform: uppercase;
  display: inline-block;
}

.blog-wrap-3 .blog-content-3 span a:hover {
  text-decoration: underline;
}

.blog-wrap-3 .blog-content-3 h4 {
  font-size: 20px;
  margin: 6px 0 0;
  line-height: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap-3 .blog-content-3 h4 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap-3 .blog-content-3 h4 {
    font-size: 18px;
    line-height: 27px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-3 .blog-content-3 h4 {
    font-size: 18px;
    line-height: 27px;
  }
}

@media only screen and (min-width: 410px) and (max-width: 575px) {
  .blog-wrap-3 .blog-content-3 h4 {
    font-size: 17px;
  }
}

.blog-wrap-3 .blog-content-3 h4 a {
  color: #211E1C;
}

.blog-wrap-3 .blog-content-3 h4 a:hover {
  color: #FF7473;
}

.blog-wrap-3 .blog-content-3 h4.green a {
  color: #211E1C;
}

.blog-wrap-3 .blog-content-3 h4.green a:hover {
  color: #538165;
}

.blog-wrap-3 .blog-content-3 span {
  color: #181818;
  display: block;
  margin: 16px 0 0;
}

.blog-wrap-3.blog-wrap-3-mrg {
  margin: 0 25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-wrap-3.blog-wrap-3-mrg {
    margin: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap-3.blog-wrap-3-mrg {
    margin: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap-3.blog-wrap-3-mrg {
    margin: 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-3.blog-wrap-3-mrg {
    margin: 0 0px 30px;
  }
}

.blog-wrap-3:hover {
  transform: translateY(-20px);
}

@media only screen and (max-width: 767px) {
  .blog-wrap-3:hover {
    transform: translateY(-10px);
  }
}

.blog-wrap-4 {
  position: relative;
}

.blog-wrap-4 .blog-img-3 {
  position: relative;
}

.blog-wrap-4 .blog-img-3::after {
  content: '';
  background-image: linear-gradient(180deg, transparent 42%, #181818 100%);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.blog-wrap-4 .blog-img-3 a img {
  width: 100%;
}

.blog-wrap-4 .blog-content-4 {
  position: absolute;
  left: 30px;
  bottom: 32px;
}

@media only screen and (max-width: 767px) {
  .blog-wrap-4 .blog-content-4 {
    left: 15px;
    bottom: 15px;
  }
}

.blog-wrap-4 .blog-content-4 > a span {
  background-color: #fff;
  color: #181818;
  padding: 5px 20px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  transition: all .3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .blog-wrap-4 .blog-content-4 > a span {
    padding: 2px 15px;
  }
}

.blog-wrap-4 .blog-content-4 > a span:hover {
  text-decoration: underline;
  color: #e41515;
}

.blog-wrap-4 .blog-content-4 h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin: 20px 0 0;
  width: 70%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .blog-wrap-4 .blog-content-4 h3 {
    width: 90%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-wrap-4 .blog-content-4 h3 {
    width: 90%;
    font-size: 20px;
    line-height: 34px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap-4 .blog-content-4 h3 {
    width: 90%;
    font-size: 20px;
    line-height: 34px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap-4 .blog-content-4 h3 {
    width: 80%;
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-4 .blog-content-4 h3 {
    font-size: 15px;
    line-height: 26px;
    margin: 15px 0 0;
    width: 98%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-wrap-4 .blog-content-4 h3 {
    font-size: 17px;
  }
}

.blog-wrap-4 .blog-content-4 h3 a {
  color: #ffffff;
}

.blog-wrap-4 .blog-content-4 h3 a:hover {
  color: #e41515;
}

.blog-slider-active .slick-list {
  padding: 0 377px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .blog-slider-active .slick-list {
    padding: 0 220px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-slider-active .slick-list {
    padding: 0 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-slider-active .slick-list {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-slider-active .slick-list {
    padding: 0 150px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-slider-active .slick-list {
    padding: 0 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-slider-active .slick-list {
    padding: 0 100px;
  }
}

.blog-sidebar-search .blog-search-form {
  position: relative;
}

.blog-sidebar-search .blog-search-form input {
  background-color: transparent;
  border: 1px solid #ebebeb;
  height: 60px;
  padding: 2px 50px 2px 20px;
  color: #181818;
}

.blog-sidebar-search .blog-search-form input:focus {
  border: 1px solid #bbb;
}

.blog-sidebar-search .blog-search-form button {
  border: none;
  padding: 0;
  background-color: transparent;
  font-size: 18px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.blog-sidebar-search .blog-search-form button:hover {
  color: #D98719;
}

.blog-page-area {
  padding: 120px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-page-area {
    padding: 80px 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog-page-area {
    padding: 60px 0;
  }
}

.blog-wrap-5 {
  padding-bottom: 60px;
  margin-bottom: 60px;
  border-bottom: 1px solid #ebebeb;
}

@media only screen and (max-width: 767px) {
  .blog-wrap-5 {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}

.blog-wrap-5 .blog-img-5 {
  margin: 0 0 18px;
  overflow: hidden;
}

.blog-wrap-5 .blog-img-5 a {
  display: block;
}

.blog-wrap-5 .blog-img-5 a img {
  width: 100%;
  transform: scale(1);
  transition: all 2s ease-out;
}

.blog-wrap-5 .blog-img-5:hover a img {
  transform: scale(1.2);
}

.blog-wrap-5 .blog-content-5 .blog-category-5 > a {
  letter-spacing: .5px;
  display: inline-block;
  color: #D98719;
}

.blog-wrap-5 .blog-content-5 .blog-category-5 > a:hover {
  text-decoration: underline;
}

.blog-wrap-5 .blog-content-5 h1 {
  font-size: 28px;
  font-family: "Playfair Display", serif;
  margin: 7px 0 12px;
  color: #211e1c;
}

@media only screen and (max-width: 767px) {
  .blog-wrap-5 .blog-content-5 h1 {
    font-size: 24px;
  }
}

.blog-wrap-5 .blog-content-5 h1 a {
  color: #211e1c;
}

.blog-wrap-5 .blog-content-5 h1 a:hover {
  color: #D98719;
}

.blog-wrap-5 .blog-content-5 .blog-meta-5 ul {
  display: flex;
}

.blog-wrap-5 .blog-content-5 .blog-meta-5 ul li {
  color: #6d6d6d;
  text-transform: uppercase;
}

.blog-wrap-5 .blog-content-5 .blog-meta-5 ul li a {
  color: #6d6d6d;
}

.blog-wrap-5 .blog-content-5 .blog-meta-5 ul li a:hover {
  color: #D98719;
}

.blog-wrap-5 .blog-content-5 .blog-meta-5 ul li:not(:first-child)::before {
  content: "|";
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  opacity: .5;
  position: relative;
  top: 1px;
}

.blog-wrap-5 .blog-content-5 p {
  line-height: 28px;
  margin: 17px 0 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap-5 .blog-content-5 p {
    margin: 10px 0 13px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-5 .blog-content-5 p {
    margin: 10px 0 15px;
  }
}

.blog-wrap-5 .blog-content-5 .blog-btn-5 a {
  font-size: 14px;
  display: inline-block;
  color: #181818;
  border: 1px solid #ebebeb;
  padding: 9px 22px 10px;
}

.blog-wrap-5 .blog-content-5 .blog-btn-5 a:hover {
  background-color: #D98719;
  border: 1px solid #D98719;
  color: #ffffff;
}

.blog-wrap-5.blog-custom-padding {
  border: none;
  margin: 0;
  padding: 50px 140px 50px 140px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .blog-wrap-5.blog-custom-padding {
    padding: 50px 80px 50px 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-wrap-5.blog-custom-padding {
    padding: 50px 60px 50px 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap-5.blog-custom-padding {
    padding: 10px 30px 10px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap-5.blog-custom-padding {
    padding: 40px 30px 50px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-5.blog-custom-padding {
    padding: 30px 20px 40px 20px;
  }
}

.blog-sidebar-mrg {
  margin-right: -10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-sidebar-mrg {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-sidebar-mrg {
    margin-right: 0px;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-sidebar-mrg {
    margin-right: 0px;
    margin-top: 50px;
  }
}

.blog-page-wrap-padding {
  padding-left: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-page-wrap-padding {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-page-wrap-padding {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-page-wrap-padding {
    padding-left: 0px;
  }
}

.blog-sidebar-mrg-2 {
  margin-left: -10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-sidebar-mrg-2 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-sidebar-mrg-2 {
    margin-left: 0px;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-sidebar-mrg-2 {
    margin-left: 0px;
    margin-top: 50px;
  }
}

.blog-page-wrap-padding-2 {
  padding-right: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-page-wrap-padding-2 {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-page-wrap-padding-2 {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-page-wrap-padding-2 {
    padding-right: 0px;
  }
}

.pro-pagination-style-2 ul {
  display: flex;
}

.pro-pagination-style-2 ul li {
  margin-right: 8px;
}

.pro-pagination-style-2 ul li:last-child {
  margin-right: 0;
}

.pro-pagination-style-2 ul li a {
  display: inline-block;
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  color: #979797;
  border: 1px solid #ebebeb;
  border-radius: 100%;
}

.pro-pagination-style-2 ul li a.active, .pro-pagination-style-2 ul li a:hover {
  border: 1px solid #181818;
  background-color: #181818;
  color: #ffffff;
}

.pro-pagination-style-2.pagination-style-2-center ul {
  justify-content: center;
}

.blog-details-wrap .blog-details-img {
  margin: 0 0 18px;
  overflow: hidden;
}

.blog-details-wrap .blog-details-img img {
  width: 100%;
  transform: scale(1);
  transition: all 2s ease-out;
}

.blog-details-wrap .blog-details-img:hover img {
  transform: scale(1.2);
}

.blog-details-wrap .blog-details-category > a {
  font-size: 12px;
  display: inline-block;
  color: #D98719;
}

.blog-details-wrap .blog-details-category > a:hover {
  text-decoration: underline;
}

.blog-details-wrap > h1 {
  font-size: 28px;
  font-family: "Playfair Display", serif;
  margin: 7px 0 12px;
  color: #211e1c;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap > h1 {
    font-size: 20px;
  }
}

.blog-details-wrap .blog-details-meta {
  margin: 0 0 18px;
}

.blog-details-wrap .blog-details-meta ul {
  display: flex;
}

.blog-details-wrap .blog-details-meta ul li {
  font-size: 12px;
  color: #6d6d6d;
  text-transform: uppercase;
}

.blog-details-wrap .blog-details-meta ul li a {
  color: #6d6d6d;
}

.blog-details-wrap .blog-details-meta ul li a:hover {
  color: #D98719;
}

.blog-details-wrap .blog-details-meta ul li:not(:first-child)::before {
  content: "|";
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  opacity: .5;
  position: relative;
  top: 1px;
}

.blog-details-wrap .blog-details-peragraph p {
  font-size: 14px;
  line-height: 30px;
  margin: 0 0 20px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-details-peragraph p {
    font-size: 14px;
    line-height: 30px;
    margin: 0 0 15px;
  }
}

.blog-details-wrap .blog-details-peragraph p:last-child {
  margin: 0 0 0;
}

.blog-details-wrap .blog-details-peragraph h2 {
  font-size: 28px;
  font-family: "Playfair Display", serif;
  margin: 0px 0 20px;
  color: #211e1c;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-details-peragraph h2 {
    font-size: 20px;
    line-height: 30px;
  }
}

.blog-details-wrap .blog-details-video {
  margin: 41px 0 35px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-details-video {
    margin: 21px 0 15px;
  }
}

.blog-details-wrap .blog-details-video a {
  display: block;
  position: relative;
}

.blog-details-wrap .blog-details-video a::before {
  font-family: 'LaStudioIcons';
  display: inline-block;
  vertical-align: middle;
  text-rendering: auto;
  content: "\ea45";
  position: absolute;
  width: 68px;
  height: 68px;
  line-height: 68px;
  background: #fff;
  text-align: center;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  margin-left: -34px;
  margin-top: -34px;
  transition: all .5s;
  z-index: 2;
  font-size: 18px;
  color: #6d6d6d;
}

.blog-details-wrap .blog-details-video a img {
  width: 100%;
}

.blog-details-wrap .blog-details-video:hover a::before {
  transform: scale(1.2);
  color: #D98719;
}

.blog-details-wrap blockquote {
  background-color: #F9F9F9;
  border-width: 0;
  text-align: center;
  padding: 60px 40px;
  margin: 40px 0;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap blockquote {
    padding: 60px 20px;
    margin: 20px 0;
  }
}

.blog-details-wrap blockquote::before {
  content: '\ea7a';
  font-family: LaStudioIcons;
  font-size: 200px;
  line-height: 1;
  color: #3E3E3E;
  opacity: .1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap blockquote::before {
    font-size: 150px;
  }
}

.blog-details-wrap blockquote h3 {
  font-size: 26px;
  color: #3E3E3E;
  line-height: 1.4;
  font-family: "Playfair Display", serif;
  width: 357px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  max-width: 100%;
  font-weight: 700;
  font-style: italic;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap blockquote h3 {
    font-size: 20px;
  }
}

.blog-details-wrap blockquote span {
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-size: 16px;
  position: relative;
}

.blog-details-wrap blockquote span:before {
  position: absolute;
  content: "";
  width: 50px;
  height: 2px;
  background-color: #D8D8D8;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -9px;
}

.blog-details-wrap .blog-details-tag-social {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 14px 0;
  margin: 68px 0 27px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-details-tag-social .blog-details-tag {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-details-wrap .blog-details-tag-social .blog-details-tag {
    margin-bottom: 0px;
  }
}

.blog-details-wrap .blog-details-tag-social .blog-details-tag ul {
  display: flex;
}

.blog-details-wrap .blog-details-tag-social .blog-details-tag ul li {
  margin-right: 4px;
}

.blog-details-wrap .blog-details-tag-social .blog-details-tag ul li:last-child {
  margin-right: 0;
}

.blog-details-wrap .blog-details-tag-social .blog-details-tag ul li a {
  color: #181818;
  font-style: italic;
}

.blog-details-wrap .blog-details-tag-social .blog-details-tag ul li a:hover {
  color: #D98719;
}

.blog-details-wrap .blog-details-tag-social .blog-details-social ul {
  display: flex;
}

.blog-details-wrap .blog-details-tag-social .blog-details-social ul li {
  margin-left: 10px;
}

.blog-details-wrap .blog-details-tag-social .blog-details-social ul li:first-child {
  margin-left: 0;
}

.blog-details-wrap .blog-details-tag-social .blog-details-social ul li a {
  display: inline-block;
  width: 27px;
  height: 27px;
  line-height: 27px;
  border-radius: 100%;
  color: #fff;
  text-align: center;
}

.blog-details-wrap .blog-details-tag-social .blog-details-social ul li a.facebook {
  background-color: #272EAE;
}

.blog-details-wrap .blog-details-tag-social .blog-details-social ul li a.twitter {
  background-color: #1590D8;
}

.blog-details-wrap .blog-details-tag-social .blog-details-social ul li a.pinterest {
  background-color: #D0021B;
}

.blog-details-wrap .blog-details-tag-social .blog-details-social ul li i {
  line-height: 27px;
}

.blog-details-wrap .next-wrap {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  margin: 0 0 51px;
}

.blog-details-wrap .next-wrap .next-conent h5 {
  font-size: 18px;
  line-height: 21px;
  margin: 0 0 13px;
  font-family: "Playfair Display", serif;
}

.blog-details-wrap .next-wrap .next-conent h5 > a {
  color: #181818;
}

.blog-details-wrap .next-wrap .next-conent h5 > a:hover {
  color: #D98719;
}

.blog-details-wrap .next-wrap .next-conent a {
  color: #6d6d6d;
}

.blog-details-wrap .next-wrap .next-conent a:hover {
  color: #D98719;
}

.blog-details-wrap .blog-details-comment > h4 {
  margin: 0;
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-details-comment > h4 {
    font-size: 20px;
  }
}

.blog-details-wrap .blog-details-comment .single-blog-comment {
  display: flex;
  margin: 32px 0 0;
}

.blog-details-wrap .blog-details-comment .single-blog-comment .blog-comment-img {
  flex: 0 0 75px;
  margin-right: 25px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-details-comment .single-blog-comment .blog-comment-img {
    margin-right: 15px;
  }
}

.blog-details-wrap .blog-details-comment .single-blog-comment .blog-comment-img img {
  width: 100%;
}

.blog-details-wrap .blog-details-comment .single-blog-comment .blog-comment-content {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 25px 30px;
  box-shadow: 0 0 6px 0 rgba(226, 226, 226, 0.5);
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-details-comment .single-blog-comment .blog-comment-content {
    padding: 25px 15px;
  }
}

.blog-details-wrap .blog-details-comment .single-blog-comment .blog-comment-content .blog-comment-top {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 18px;
  padding-bottom: 18px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-details-comment .single-blog-comment .blog-comment-content .blog-comment-top {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
}

.blog-details-wrap .blog-details-comment .single-blog-comment .blog-comment-content .blog-comment-top .comment-author h3 {
  font-weight: 600;
  font-size: 14px;
  margin: 0 0 16px;
}

.blog-details-wrap .blog-details-comment .single-blog-comment .blog-comment-content .blog-comment-top .comment-author h3 a {
  color: #211e1c;
}

.blog-details-wrap .blog-details-comment .single-blog-comment .blog-comment-content .blog-comment-top .comment-author h3 a:hover {
  color: #D98719;
}

.blog-details-wrap .blog-details-comment .single-blog-comment .blog-comment-content .blog-comment-top .comment-author h3 span {
  font-size: 12px;
  color: #979797;
  margin-left: 5px;
  display: inline-block;
}

.blog-details-wrap .blog-details-comment .single-blog-comment .blog-comment-content .blog-comment-top p {
  line-height: 25px;
}

.blog-details-wrap .blog-details-comment .single-blog-comment .blog-comment-content .blog-comment-top p a {
  color: #6d6d6d;
}

.blog-details-wrap .blog-details-comment .single-blog-comment .blog-comment-content .blog-comment-top p a:hover {
  color: #D98719;
}

.blog-details-wrap .blog-details-comment .single-blog-comment .blog-comment-content .blog-comment-bottom a {
  font-size: 12px;
  font-weight: bold;
  color: #6d6d6d;
}

.blog-details-wrap .blog-details-comment .single-blog-comment .blog-comment-content .blog-comment-bottom a i {
  font-size: 12px;
  margin-right: 3px;
  position: relative;
  top: 3px;
}

.blog-details-wrap .blog-details-comment .single-blog-comment .blog-comment-content .blog-comment-bottom a:hover {
  color: #D98719;
}

.blog-details-wrap .comment-form {
  margin: 51px 0 0;
}

.blog-details-wrap .comment-form h4 {
  font-size: 24px;
  margin: 0 0 33px;
}

.blog-details-wrap .comment-form form .leave-form {
  margin-bottom: 28px;
}

.blog-details-wrap .comment-form form .leave-form input, .blog-details-wrap .comment-form form .leave-form textarea {
  height: 60px;
  border: 1px solid #ebebeb;
  color: #6d6d6d;
  padding: 2px 25px;
  background-color: transparent;
}

.blog-details-wrap .comment-form form .leave-form input:focus, .blog-details-wrap .comment-form form .leave-form textarea:focus {
  border: 1px solid #bbb;
}

.blog-details-wrap .comment-form form .leave-form textarea {
  height: 150px;
  padding: 20px 25px;
}

.blog-details-wrap .comment-form form .save-comment-info {
  display: flex;
  align-items: center;
}

.blog-details-wrap .comment-form form .save-comment-info input {
  width: auto;
  height: auto;
}

.blog-details-wrap .comment-form form .save-comment-info span {
  margin-left: 14px;
  color: #6d6d6d;
  display: inline-block;
}

.blog-details-wrap .comment-form form .text-submit {
  margin: 30px 0 0;
}

.blog-details-wrap .comment-form form .text-submit input {
  width: auto;
  height: auto;
  background-color: transparent;
  background-color: #181818;
  color: #fff;
  text-transform: uppercase;
  border: none;
  padding: 16px 37px;
}

.blog-details-wrap .comment-form form .text-submit input:hover {
  background-color: #D98719;
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-area.pb-105 {
    padding-bottom: 35px;
  }
  .blog-area.pb-115 {
    padding-bottom: 50px;
  }
  .blog-area.pb-120 {
    padding-bottom: 50px;
  }
  .blog-area.pb-75 {
    padding-bottom: 35px;
  }
  .blog-area.pb-85 {
    padding-bottom: 45px;
  }
  .blog-area.pb-90 {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-area.pb-105 {
    padding-bottom: 20px;
  }
  .blog-area.pb-140 {
    padding-bottom: 30px;
  }
  .blog-area.pb-115 {
    padding-bottom: 30px;
  }
  .blog-area.pb-120 {
    padding-bottom: 30px;
  }
  .blog-area.pt-135 {
    padding-top: 50px;
  }
  .blog-area.pb-75 {
    padding-bottom: 15px;
  }
  .blog-area.pb-85 {
    padding-bottom: 25px;
  }
  .blog-area.pb-90 {
    padding-bottom: 30px;
  }
}

/*-------- 11. Footer style ---------*/
.footer-column {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.footer-column.footer-width-30 {
  flex: 0 0 25%;
  max-width: 25%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-30 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-30 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-30 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.footer-column.footer-width-14 {
  flex: 0 0 15%;
  max-width: 15%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-14 {
    flex: 0 0 14%;
    max-width: 14%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-14 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-14 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-14 {
    flex: 0 0 26%;
    max-width: 26%;
  }
}

.footer-column.footer-width-16 {
  flex: 0 0 18%;
  max-width: 18%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-16 {
    flex: 0 0 18%;
    max-width: 18%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-16 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-16 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-16 {
    flex: 0 0 37%;
    max-width: 37%;
  }
}

.footer-column.footer-width-15 {
  flex: 0 0 17%;
  max-width: 17%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-15 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-15 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-15 {
    flex: 0 0 37%;
    max-width: 37%;
  }
}

.footer-column.footer-width-25 {
  flex: 0 0 25%;
  max-width: 25%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-25 {
    flex: 0 0 26%;
    max-width: 26%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-25 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-25 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.footer-column.footer-width-25-2 {
  flex: 0 0 21%;
  max-width: 21%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .footer-column.footer-width-25-2 {
    flex: 0 0 23%;
    max-width: 23%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-column.footer-width-25-2 {
    flex: 0 0 23%;
    max-width: 23%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-25-2 {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-25-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-25-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-25-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.footer-column.footer-width-13 {
  flex: 0 0 15%;
  max-width: 15%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-13 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-13 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-13 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-13 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.footer-column.footer-width-14-2 {
  flex: 0 0 16%;
  max-width: 16%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-14-2 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-14-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-14-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-14-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.footer-column.footer-width-18 {
  flex: 0 0 20%;
  max-width: 20%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .footer-column.footer-width-18 {
    flex: 0 0 18%;
    max-width: 18%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-column.footer-width-18 {
    flex: 0 0 18%;
    max-width: 18%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-18 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-18 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-18 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-18 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.footer-column.footer-width-28 {
  flex: 0 0 28%;
  max-width: 28%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-column.footer-width-28 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-28 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-28 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.footer-column.footer-width-30-2 {
  flex: 0 0 30%;
  max-width: 30%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-30-2 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-30-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-30-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.footer-column.footer-width-14-3 {
  flex: 0 0 14%;
  max-width: 14%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-14-3 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-14-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-14-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.footer-column.footer-width-16-2 {
  flex: 0 0 16%;
  max-width: 16%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-16-2 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-16-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-16-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.footer-column.footer-width-15-2 {
  flex: 0 0 15%;
  max-width: 15%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-15-2 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-15-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-15-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.footer-column.footer-width-25-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-25-3 {
    flex: 0 0 66%;
    max-width: 66%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-25-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-25-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.footer-column.footer-width-28-2 {
  flex: 0 0 28%;
  max-width: 28%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-28-2 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-28-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-28-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.footer-column.footer-width-16-3 {
  flex: 0 0 16%;
  max-width: 16%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-16-3 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-16-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-16-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.footer-column.footer-width-20 {
  flex: 0 0 20%;
  max-width: 20%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-20 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-20 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-20 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.footer-column.footer-width-17 {
  flex: 0 0 17%;
  max-width: 17%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-17 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-17 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-17 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.footer-column.footer-width-18-2 {
  flex: 0 0 18%;
  max-width: 18%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-column.footer-width-18-2 {
    flex: 0 0 66%;
    max-width: 66%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-column.footer-width-18-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-column.footer-width-18-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.logo-width-2 a {
  display: block;
}

.logo-width-2 a img {
  width: 136px;
}

.footer-about {
  margin-right: 80px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .footer-about {
    margin-right: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-about {
    margin-right: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-about {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-about {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-about {
    margin-right: 0px;
  }
}

.footer-about p {
  color: #A6A6A6;
  font-size: 18px;
  line-height: 1.8em;
  font-family: "Playfair Display", serif;
  margin: 24px 0 24px;
}

.footer-widget .footer-title {
  margin: 0 0 35px;
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-title {
    margin: 0 0 25px;
  }
}

.footer-widget .footer-title h3 {
  color: #ffffff;
  margin: 0;
  line-height: 1;
  font-size: 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget .footer-title h3 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .footer-title h3 {
    font-size: 17px;
  }
}

.footer-widget .footer-title-2 {
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget .footer-title-2 {
    margin-bottom: 20px;
  }
}

.footer-widget .footer-title-2 h3 {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin: 0;
}

.footer-widget .footer-title-3 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-title-3 {
    margin-bottom: 15px;
  }
}

.footer-widget .footer-title-3 h3 {
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: .5px;
}

.footer-widget .footer-title-4 {
  margin-bottom: 22px;
}

.footer-widget .footer-title-4 h3 {
  font-size: 18px;
  font-weight: 900;
  line-height: 1.4em;
  font-family: "Noto Serif JP", serif;
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-title-4 h3 {
    font-size: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-widget .footer-title-4 h3 {
    font-size: 18px;
  }
}

.footer-widget .footer-title-5 {
  margin-bottom: 22px;
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-title-5 {
    margin-bottom: 15px;
  }
}

.footer-widget .footer-title-5 h3 {
  font-size: 18px;
  font-weight: 900;
  line-height: 1.4em;
  font-family: "Playfair Display", serif;
}

.footer-widget .footer-title-6 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-title-6 {
    margin-bottom: 20px;
  }
}

.footer-widget .footer-title-6 h3 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.4em;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .footer-title-6 h3 {
    font-size: 13px;
  }
}

.footer-widget .footer-title-7 {
  margin-bottom: 20px;
}

.footer-widget .footer-title-7 h3 {
  font-family: "Pacifico";
  font-size: 24px;
  line-height: 1.4em;
  margin: 0;
}

@media only screen and (min-width: 410px) and (max-width: 575px) {
  .footer-widget .footer-title-7 h3 {
    font-size: 21px;
  }
}

.footer-widget .footer-title-8 {
  margin-bottom: 30px;
}

.footer-widget .footer-title-8 h3 {
  font-family: "Playfair Display", serif;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.footer-widget .footer-list ul li {
  padding-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-list ul li {
    padding-bottom: 10px;
  }
}

.footer-widget .footer-list ul li:last-child {
  padding-bottom: 0;
}

.footer-widget .footer-list ul li a {
  color: #B4B4B4;
}

.footer-widget .footer-list ul li a:hover {
  color: #fff;
}

.footer-widget .footer-list-2 ul li {
  padding-bottom: 14px;
  color: #6D6D6D;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-list-2 ul li {
    padding-bottom: 10px;
  }
}

.footer-widget .footer-list-2 ul li:last-child {
  padding-bottom: 0;
}

.footer-widget .footer-list-2 ul li a {
  color: #6D6D6D;
  display: flex;
}

.footer-widget .footer-list-2 ul li a i {
  font-size: 22px;
  position: relative;
  top: 4px;
  margin-right: 20px;
  transition: all .3s ease 0s;
  display: none;
}

.footer-widget .footer-list-2 ul li a:hover {
  color: #181818;
}

.footer-widget .footer-list-2 ul li a:hover i {
  color: #FFD53D;
}

.footer-widget .footer-list-2 ul li a:hover i.green {
  color: #538165;
}

.footer-widget .footer-list-2 ul li i {
  font-size: 22px;
  margin-right: 20px;
  display: none;
}

.footer-widget .footer-list-2.footer-contect ul li {
  padding-bottom: 22px;
}

.footer-widget .footer-list-2.footer-contect ul li:last-child {
  padding-bottom: 0;
}

.footer-widget .footer-list-3 ul li {
  padding-bottom: 13px;
  display: block;
  color: #6D6D6D;
  font-size: 14px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .footer-list-3 ul li {
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-list-3 ul li {
    padding-bottom: 10px;
  }
}

.footer-widget .footer-list-3 ul li:last-child {
  padding-bottom: 0;
}

.footer-widget .footer-list-3 ul li a {
  color: #6D6D6D;
  font-size: 14px;
  display: block;
}

.footer-widget .footer-list-3 ul li a:hover {
  color: #181818;
}

.contact-page-map #contact-map {
  height: 255px;
}

.subscribe-form form .mc-form {
  position: relative;
}

.subscribe-form form .mc-form input {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  height: 50px;
  color: #fff;
  padding: 2px 112px 2px 15px;
}

.subscribe-form form .mc-form input.black {
  border: none;
  background-color: #363636;
}

.subscribe-form form .mc-form input.gray {
  border: none;
  background-color: rgba(83, 83, 83, 0.5);
}

.subscribe-form form .mc-form input::-moz-input-placeholder {
  color: #fff;
  opacity: 1;
}

.subscribe-form form .mc-form input::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}

.subscribe-form form .mc-form .mc-news {
  display: none;
}

.subscribe-form form .mc-form .clear {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.subscribe-form form .mc-form .clear input {
  width: auto;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  border: none;
  padding: 0px;
}

.copyright p {
  color: #ddd;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.copyright p a {
  color: #ddd;
}

.copyright p a:hover {
  color: #fff;
}

.copyright-2 p {
  color: #848484;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.copyright-2 p a {
  color: #848484;
}

.copyright-2 p a:hover {
  color: #FFD53D;
}

.footer-two-area {
  display: block;
  width: 100%;
  overflow: hidden;
}

.footer-about-2 {
  margin-top: 50px;
}

@media only screen and (max-width: 767px) {
  .footer-about-2 {
    margin-top: 0px;
  }
}

.footer-about-4 p {
  line-height: 1.8em;
  margin: 23px 0 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-about-4 p {
    width: 90%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-about-4 p {
    width: 80%;
  }
}

.footer-logo-2 a img {
  width: 230px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .footer-logo-2 a img {
    width: 210px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-logo-2 a img {
    width: 210px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-logo-2 a img {
    width: 210px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-logo-2 a img {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-logo-2 a img {
    width: 220px;
  }
}

.footer-logo-3 a img {
  width: 188px;
}

@media only screen and (max-width: 767px) {
  .footer-logo-3 a img {
    width: 150px;
  }
}

.social-icon-style-3 a {
  font-size: 16px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  border-radius: 100%;
  display: inline-block;
  border: 1px solid #E1E1E1;
  text-align: center;
  color: #777;
  margin-right: 13px;
}

.social-icon-style-3 a:last-child {
  margin-right: 0;
}

.social-icon-style-3 a:hover {
  color: #1B1A1A;
}

.footer-leather-logo a {
  display: inline-block;
}

.footer-leather-logo a img {
  width: 165px;
}

.footer-logo p {
  color: #6D6D6D;
  font-size: 18px;
  font-weight: 500;
  font-family: "Noto Serif JP", serif;
  margin: 16px 0 0;
}

.footer-cosmetic-logo {
  margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-cosmetic-logo {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-cosmetic-logo {
    margin-top: 0px;
  }
}

.footer-cosmetic-logo a {
  display: inline-block;
}

.footer-cosmetic-logo a img {
  width: 188px;
}

.footer-cosmetic-logo p {
  color: #6D6D6D;
  font-size: 18px;
  font-family: "Playfair Display", serif;
  margin: 8px 0 0;
}

.footer-bottom-ptb-1 {
  padding: 15px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-top.pb-65 {
    padding-bottom: 25px;
  }
  .footer-top.pb-70 {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-area.pt-100 {
    padding-top: 60px;
  }
  .footer-area.pt-80 {
    padding-top: 60px;
  }
  .footer-area.pt-75 {
    padding-top: 60px;
  }
  .footer-top.pb-65 {
    padding-bottom: 10px;
  }
  .footer-top.pb-70 {
    padding-bottom: 15px;
  }
  .footer-top.pb-35 {
    padding-bottom: 15px;
  }
  .footer-top.pb-40 {
    padding-bottom: 20px;
  }
}

/*-------- 12. Instafeed style ---------*/
.footer-instagram .insta-icon {
  margin: 0 0 16px;
}

.footer-instagram .insta-icon i {
  font-size: 18px;
  color: #fff;
}

.instagram-wrap-1 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}

.instagram-wrap-1 .single-instafeed-wrap {
  flex: 0 0 25%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}

.single-instafeed {
  overflow: hidden;
  position: relative;
}

.single-instafeed:before {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  content: '';
  pointer-events: none;
  z-index: 8;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
  background-color: rgba(84, 89, 95, 0.6);
}

.single-instafeed > a {
  display: block;
}

.single-instafeed > a img {
  width: 100%;
}

.single-instafeed .insta-icon-digit {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
}

.single-instafeed .insta-icon-digit > a {
  color: #fff;
  font-size: 14px;
  margin-right: 3px;
}

.single-instafeed .insta-icon-digit > a:last-child {
  margin-right: 0;
}

.single-instafeed .insta-icon-digit > a i {
  position: relative;
  top: 3px;
}

.single-instafeed:hover:before {
  opacity: 1;
  visibility: visible;
}

.single-instafeed:hover .insta-icon-digit {
  opacity: 1;
  visibility: visible;
}

.single-instafeed-wrap-2 {
  margin: 0 15px;
}

.instagram-active .slick-list {
  padding: 0 260px;
  margin: 0 0 33px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .instagram-active .slick-list {
    padding: 0 160px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .instagram-active .slick-list {
    padding: 0 130px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .instagram-active .slick-list {
    padding: 0 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram-active .slick-list {
    padding: 0 110px;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-active .slick-list {
    padding: 0 60px;
  }
}

/*-------- 13. Others style ---------*/
.support-lists {
  position: fixed;
  top: 70%;
  right: 50px;
  list-style: none;
  width: 60px;
  background: #FFFFFF;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.16);
  border-radius: 30px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 15px;
  z-index: 99;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .support-lists {
    right: 15px;
    width: 40px;
    top: 75%;
  }
}

@media only screen and (max-width: 767px) {
  .support-lists {
    right: 15px;
    width: 50px;
    top: 75%;
    display: none;
  }
}

.support-lists ul li {
  display: block;
}

.support-lists ul li a {
  font-size: 20px;
  height: 40px;
  line-height: 40px;
  color: #6D6D6D;
}

.support-lists ul li a:hover {
  color: #D98719;
}

.view-demo-area {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0;
  z-index: 9999;
}

.view-demo-area > a {
  color: #fff;
  background: #282828;
  font-size: 14px;
  line-height: 18px;
  border: none;
  width: 76px;
  height: 76px;
  text-align: left;
  padding: 20px 10px 10px;
  position: absolute;
  right: 520px;
  top: 60%;
  transition: all 400ms ease-out;
  transform: translate(520px, 0);
}

@media only screen and (max-width: 767px) {
  .view-demo-area > a {
    right: 200px;
    transform: translate(200px, 0);
    font-size: 12px;
    width: 60px;
    height: 60px;
    padding: 10px 10px 10px;
    top: 52%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .view-demo-area > a {
    right: 400px;
    transform: translate(400px, 0);
  }
}

.view-demo-area .view-demo-aside-active {
  position: absolute;
  width: 520px;
  background: #fff;
  top: 0;
  right: 0;
  height: 100%;
  padding: 50px 50px;
  box-shadow: 0 1px 26px 0 rgba(0, 0, 0, 0.13);
  visibility: hidden;
  opacity: 0;
  transition: all 400ms ease-out;
  transform: translate(520px, 0);
  overflow: auto;
}

@media only screen and (max-width: 767px) {
  .view-demo-area .view-demo-aside-active {
    transform: translate(200px, 0);
    width: 200px;
    padding: 40px 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .view-demo-area .view-demo-aside-active {
    transform: translate(400px, 0);
    width: 400px;
  }
}

.view-demo-area.demo-visible > a {
  transform: translate(0px, 0);
}

.view-demo-area.demo-visible .view-demo-aside-active {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

.demo-item {
  transition: all .3s ease 0s;
}

.demo-item .demo-item-img {
  position: relative;
}

.demo-item .demo-item-img a {
  display: block;
}

.demo-item .demo-item-img a img {
  width: 100%;
}

.demo-item .demo-item-content h4 {
  font-size: 13px;
  margin: 0;
  color: #282828;
  padding: 8px 0 15px 0;
  transition: all .3s ease 0s;
}

.demo-item .demo-item-content h4 a {
  color: #282828;
}

.demo-item:hover {
  box-shadow: 0 1px 26px 0 rgba(0, 0, 0, 0.13);
}

.demo-item:hover .demo-item-content h4 {
  padding: 8px 0 15px 12px;
  font-weight: bold;
}

.discover-more-btn a {
  display: inline-block;
  color: #fff;
  background-color: #181818;
  line-height: 1;
  padding: 18px 30px;
}

.discover-more-btn a:hover {
  background-color: #D98719;
}

.modal-backdrop.show {
  opacity: .7;
}

.modal-dialog {
  margin: 7% auto 8%;
  max-width: 1220px;
  width: 1220px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .modal-dialog {
    width: 1120px;
    max-width: 1120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modal-dialog {
    width: 920px;
    max-width: 920px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-dialog {
    width: 700px;
    max-width: 700px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-dialog {
    width: 100%;
    max-width: 100%;
    padding: 35px 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .modal-dialog {
    width: 80%;
    max-width: 80%;
  }
}

.modal-dialog .modal-content {
  border-radius: 0;
}

.modal-dialog .modal-content .modal-header {
  border: none;
  padding: 0;
  right: -22px;
  top: -24px;
  position: absolute;
  z-index: 9999;
}

@media only screen and (max-width: 767px) {
  .modal-dialog .modal-content .modal-header {
    right: 0px;
    top: -34px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .modal-dialog .modal-content .modal-header {
    right: -22px;
    top: -24px;
  }
}

.modal-dialog .modal-content .modal-header .close {
  color: #fff;
  cursor: pointer;
  opacity: 1;
  padding: 0;
  margin: 0;
  width: 50px;
  height: 50px;
  line-height: 53px;
  background-color: #292929;
  border-radius: 100%;
  font-size: 18px;
  text-shadow: none;
  font-weight: 300;
  transition: all .3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .modal-dialog .modal-content .modal-header .close {
    width: 34px;
    height: 34px;
    line-height: 34px;
    font-size: 15px;
  }
}

.modal-dialog .modal-content .modal-header .close:hover {
  color: #D98719;
  transform: rotate(90deg);
}

.modal-dialog .modal-content .modal-body {
  padding: 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-dialog .modal-content .modal-body {
    overflow-y: auto;
    max-height: 500px;
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-dialog .modal-content .modal-body {
    overflow-y: auto;
    max-height: 462px;
    height: 462px;
  }
}

.quickview-slider-active .single-quickview-slider a img {
  width: 100%;
  height: 100%;
  display: block;
}

.quickview-content-padding {
  padding: 40px 35px 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quickview-content-padding {
    padding: 35px 20px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quickview-content-padding {
    padding: 40px 35px 45px;
  }
}

@media only screen and (max-width: 767px) {
  .quickview-content-padding {
    padding: 30px 15px 35px;
  }
}

.social-border {
  position: relative;
  display: flex;
  justify-content: center;
}

.social-border:after {
  position: absolute;
  left: 0;
  top: 12px;
  content: "";
  width: 100%;
  height: 1px;
  background-color: #D8D8D8;
  z-index: 5;
}

.social-icon-style a {
  font-size: 18px;
  color: #B4B4B4;
  display: inline-block;
  margin-right: 32px;
}

.social-icon-style a:last-child {
  margin-right: 0;
}

.social-icon-style a:hover {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .social-icon-style.mt-35 {
    margin-top: 20px;
  }
}

.social-icon-style.social-icon-black a {
  color: #181818;
}

.social-icon-style.social-icon-black a:hover {
  color: #D98719;
}

.social-icon-style.social-icon-leather {
  margin: 0px 0 12px;
  padding: 0 20px;
  display: inline-block;
  background-color: #fff;
  position: relative;
  z-index: 9;
}

.order-wrap form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .order-wrap form {
    justify-content: center;
  }
}

.order-wrap form select {
  margin-right: 20px;
  border: 1px solid #C1C1C1;
  height: 80px;
  font-size: 18px;
  color: #181818;
  padding: 0 25px 0 20px;
  flex: 0 0 20.5%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .order-wrap form select {
    flex: 0 0 19%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .order-wrap form select {
    flex: 0 0 19%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .order-wrap form select {
    flex: 0 0 17.5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .order-wrap form select {
    flex: 0 0 30%;
    margin-bottom: 20px;
    margin-right: 10px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .order-wrap form select {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .order-wrap form select {
    padding: 0 25px 0 10px;
  }
}

.order-wrap form button.order-btn {
  color: #ffffff;
  background-color: #e41515;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  padding: 10px 60px;
  border: none;
  display: inline-block;
  transition: all .3s ease 0s;
  height: 80px;
}

.order-wrap form button.order-btn:hover {
  background-color: #181818;
}

.app-area {
  position: relative;
  padding: 75px 0 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .app-area {
    padding: 60px 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .app-area {
    padding: 50px 0 40px;
  }
}

.app-area .organic-app-shape {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.app-area .organic-app-shape img {
  max-width: 100%;
}

.app-content h2 {
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  margin: 0;
  width: 70%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .app-content h2 {
    font-size: 28px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .app-content h2 {
    font-size: 25px;
    width: 100%;
  }
}

.app-content p {
  line-height: 28px;
  width: 77%;
  margin: 15px 0 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .app-content p {
    width: 100%;
    margin: 15px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .app-content p {
    width: 100%;
    margin: 10px 0 20px;
  }
}

.app-img {
  position: relative;
  z-index: 9;
  float: right;
  margin-right: -70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .app-img {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .app-img {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .app-img {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .app-img {
    margin-right: 0px;
    margin-top: 10px;
  }
}

.app-img img {
  max-width: 100%;
}

.team-wrap-3 .team-img-3 > a {
  display: block;
}

.team-wrap-3 .team-img-3 > a img {
  width: 100%;
}

.team-wrap-3 .team-img-3 .team-social-wrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  margin-top: 20px;
  z-index: 99;
  transition: all .35s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.team-wrap-3 .team-content-3 .team-info-3 {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .team-wrap-3 .team-content-3 .team-info-3 {
    display: block;
  }
}

.team-wrap-3 .team-content-3 .team-info-3 h4 {
  margin: 0 10px 0 0px;
  font-size: 18px;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .team-wrap-3 .team-content-3 .team-info-3 h4 {
    margin: 0px 10px 5px 0px;
  }
}

.team-wrap-3 .team-content-3 .team-info-3 h4 a {
  color: #211e1c;
}

.team-wrap-3 .team-content-3 .team-info-3 h4 a:hover {
  color: #D98719;
}

.team-wrap-3 .team-content-3 .team-info-3 span {
  font-size: 14px;
  color: #6d6d6d;
  display: inline-block;
  font-family: "Playfair Display", serif;
  font-style: italic;
}

.team-wrap-3 .team-content-3 p {
  line-height: 25px;
  margin: 10px 0 15px;
}

.team-wrap-3 .team-content-3.team-content-3-bg {
  background-color: #F9F9F9;
  padding: 40px 40px 37px 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .team-wrap-3 .team-content-3.team-content-3-bg {
    padding: 30px 10px 27px 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team-wrap-3 .team-content-3.team-content-3-bg {
    padding: 30px 10px 27px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-wrap-3 .team-content-3.team-content-3-bg {
    padding: 30px 20px 27px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .team-wrap-3 .team-content-3.team-content-3-bg {
    padding: 20px 15px 17px 15px;
  }
}

.team-wrap-3 .team-content-3.team-content-3-bg .team-info-3 h4 {
  font-size: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team-wrap-3 .team-content-3.team-content-3-bg .team-info-3 h4 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-wrap-3 .team-content-3.team-content-3-bg .team-info-3 h4 {
    font-size: 17px;
  }
}

.team-wrap-3 .team-content-3.team-content-3-bg .team-info-3 span {
  font-size: 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team-wrap-3 .team-content-3.team-content-3-bg .team-info-3 span {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-wrap-3 .team-content-3.team-content-3-bg .team-info-3 span {
    font-size: 15px;
  }
}

.team-wrap-3 .team-content-3.team-content-3-bg p {
  margin: 10px 0 0px;
}

.team-wrap-3:hover .team-img-3.hover-overly-point-4:before {
  opacity: .4;
  background-color: #212121;
  pointer-events: none;
}

.team-wrap-3:hover .team-img-3 .team-social-wrap {
  margin-top: 0px;
  opacity: 1;
  visibility: visible;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team-wrap-3.mb-80 {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-wrap-3.mb-80 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .team-wrap-3.mb-65 {
    margin-bottom: 35px;
  }
  .team-wrap-3.mb-80 {
    margin-bottom: 30px;
  }
}

.team-social a {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 100%;
  display: inline-block;
  color: #3E3E3E;
  border: 1px solid #E1E1E1;
  margin-right: 8px;
}

.team-social a:last-child {
  margin-right: 0;
}

.team-social a:hover {
  background-color: #D98719;
  color: #ffffff;
  border: 1px solid #D98719;
}

.team-social.team-social-2 a {
  color: #fff;
  border: 1px solid #fff;
}

.team-social.team-social-2 a:last-child {
  margin-right: 0;
}

.team-social.team-social-2 a:hover {
  background-color: #D98719;
  color: #ffffff;
  border: 1px solid #D98719;
}

.error-area {
  padding: 120px 0;
}

@media only screen and (max-width: 767px) {
  .error-area {
    padding: 60px 0;
  }
}

.error-content img {
  max-width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .error-content img {
    max-width: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-content img {
    max-width: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .error-content img {
    max-width: 280px;
  }
}

.error-content h1 {
  font-size: 46px;
  font-family: "Playfair Display", serif;
  font-style: italic;
  margin: 10px 0 36px;
}

@media only screen and (max-width: 767px) {
  .error-content h1 {
    font-size: 35px;
    margin: 10px 0 25px;
  }
}

.error-content .error-btn a {
  display: inline-block;
  color: #444;
  line-height: 1;
  border: 1px solid #aaa;
  padding: 22px 55px;
}

@media only screen and (max-width: 767px) {
  .error-content .error-btn a {
    padding: 15px 25px;
  }
}

.error-content .error-btn a:hover {
  color: #ffffff;
  background-color: #D98719;
  border: 1px solid #D98719;
}

.coming-soon-area {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .coming-soon-area {
    padding: 100px 0;
  }
}

.coming-soon-area .coming-soon-content {
  width: 100%;
  position: relative;
  z-index: 99;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-area .coming-soon-content {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-area .coming-soon-content {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-area .coming-soon-content {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-area .coming-soon-content {
    margin-top: 50px;
  }
}

.coming-soon-area .coming-soon-content h1 {
  font-size: 130px;
  letter-spacing: 8px;
  color: #6d6d6d;
  text-transform: uppercase;
  margin: 0;
  line-height: 97px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-area .coming-soon-content h1 {
    font-size: 80px;
    line-height: 80px;
    letter-spacing: 6px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-area .coming-soon-content h1 {
    font-size: 80px;
    line-height: 80px;
    letter-spacing: 6px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-area .coming-soon-content h1 {
    font-size: 70px;
    line-height: 60px;
    letter-spacing: 6px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-area .coming-soon-content h1 {
    font-size: 65px;
    line-height: 60px;
    letter-spacing: 4px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-area .coming-soon-content h1 {
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 3px;
  }
}

.coming-soon-area .coming-soon-content p {
  font-size: 23px;
  letter-spacing: 2px;
  color: #6d6d6d;
  text-transform: uppercase;
  margin: 51px 0 44px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-area .coming-soon-content p {
    margin: 25px 0 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-area .coming-soon-content p {
    margin: 25px 0 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-area .coming-soon-content p {
    margin: 20px 0 20px;
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-area .coming-soon-content p {
    margin: 20px 0 20px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-area .coming-soon-content p {
    font-size: 20px;
    letter-spacing: 1px;
    margin: 12px 0 10px;
  }
}

.coming-soon-area .coming-soon-content form .coming-soon-input {
  margin: 0 15px 30px;
}

@media only screen and (max-width: 767px) {
  .coming-soon-area .coming-soon-content form .coming-soon-input {
    margin: 0 0px 15px;
  }
}

.coming-soon-area .coming-soon-content form .coming-soon-input input {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #6d6d6d;
  color: #6d6d6d;
  font-weight: 600;
  font-size: 18px;
  padding: 2px 0;
  height: 55px;
}

@media only screen and (max-width: 767px) {
  .coming-soon-area .coming-soon-content form .coming-soon-input input {
    font-size: 16px;
    height: 50px;
  }
}

.coming-soon-area .coming-soon-content form .coming-soon-btn button {
  border: 1px solid #6D6D6D;
  color: #6D6D6D;
  font-size: 24px;
  background-color: transparent;
  padding: 32px 100px 36px;
  transition: all .35s ease 0s;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-area .coming-soon-content form .coming-soon-btn button {
    padding: 25px 60px 29px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-area .coming-soon-content form .coming-soon-btn button {
    padding: 25px 60px 29px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-area .coming-soon-content form .coming-soon-btn button {
    padding: 20px 50px 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-area .coming-soon-content form .coming-soon-btn button {
    padding: 20px 50px 24px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-area .coming-soon-content form .coming-soon-btn button {
    padding: 12px 40px 16px;
    font-size: 20px;
  }
}

.coming-soon-area .coming-soon-content form .coming-soon-btn button:hover {
  background-color: #181818;
  color: #ffffff;
  border: 1px solid #181818;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-area .coming-soon-content form {
    margin: 0 30px;
  }
}

.coming-soon-area .coming-soon-img-1 {
  position: absolute;
  top: 5% !important;
  left: 66% !important;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .coming-soon-area .coming-soon-img-1 {
    top: 15% !important;
    left: 50% !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coming-soon-area .coming-soon-img-1 {
    left: 65% !important;
  }
}

.coming-soon-area .coming-soon-img-1 img {
  width: 480px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-area .coming-soon-img-1 img {
    width: 320px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-area .coming-soon-img-1 img {
    width: 320px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-area .coming-soon-img-1 img {
    width: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-area .coming-soon-img-1 img {
    width: 280px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-area .coming-soon-img-1 img {
    width: 150px;
  }
}

.coming-soon-social {
  margin: 80px 0 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-social {
    margin: 50px 0 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-social {
    margin: 50px 0 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-social {
    margin: 40px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-social {
    margin: 30px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-social {
    margin: 30px 0 0;
  }
}

.coming-soon-social a {
  border: 1px solid #E1E1E1;
  font-size: 30px;
  margin-right: 30px;
  width: 62px;
  height: 62px;
  line-height: 62px;
  display: inline-block;
  color: #6D6D6D;
  border-radius: 50%;
  text-align: center;
}

.coming-soon-social a:last-child {
  margin-right: 0;
}

.coming-soon-social a:hover {
  background-color: #181818;
  color: #ffffff;
  border: 1px solid #181818;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-social a {
    font-size: 20px;
    margin-right: 20px;
    width: 52px;
    height: 52px;
    line-height: 52px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-social a {
    font-size: 20px;
    margin-right: 20px;
    width: 52px;
    height: 52px;
    line-height: 52px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-social a {
    font-size: 18px;
    margin-right: 10px;
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
}

.coming-soon-social.cs-social-white {
  margin: 105px 0 80px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-social.cs-social-white {
    margin: 50px 0 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-social.cs-social-white {
    margin: 40px 0 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-social.cs-social-white {
    margin: 40px 0 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-social.cs-social-white {
    margin: 30px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-social.cs-social-white {
    margin: 30px 0 20px;
  }
}

.coming-soon-social.cs-social-white a {
  border: 1px solid #fff;
  color: #fff;
}

.coming-soon-social.cs-social-white a:hover {
  background-color: #181818;
  color: #ffffff;
  border: 1px solid #181818;
}

.coming-soon-area-2 {
  height: 100vh;
  display: flex;
  align-items: center;
}

.coming-soon-area-2 .coming-soon-content-2 {
  width: 100%;
}

.coming-soon-area-2 .coming-soon-content-2 h1 {
  font-size: 66px;
  letter-spacing: 4px;
  margin: 0 0 0px;
  color: #ffffff;
  line-height: 60px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-area-2 .coming-soon-content-2 h1 {
    font-size: 48px;
    line-height: 44px;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-area-2 .coming-soon-content-2 h1 {
    font-size: 46px;
    line-height: 44px;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-area-2 .coming-soon-content-2 h1 {
    font-size: 42px;
    line-height: 40px;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-area-2 .coming-soon-content-2 h1 {
    font-size: 42px;
    line-height: 40px;
    letter-spacing: 2px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-area-2 .coming-soon-content-2 h1 {
    font-size: 25px;
    line-height: 28px;
    letter-spacing: 0px;
  }
}

.coming-soon-area-2 .coming-soon-content-2 p {
  font-size: 18px;
  line-height: 36px;
  margin: 23px 0 75px;
  color: #ffffff;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-area-2 .coming-soon-content-2 p {
    margin: 20px 0 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-area-2 .coming-soon-content-2 p {
    margin: 20px 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-area-2 .coming-soon-content-2 p {
    margin: 20px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-area-2 .coming-soon-content-2 p {
    margin: 20px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-area-2 .coming-soon-content-2 p {
    margin: 10px 0 25px;
    line-height: 28px;
    font-size: 15px;
  }
}

.coming-soon-area-2 .coming-soon-content-2 .coming-soon-btn-2 button {
  display: inline-block;
  font-size: 24px;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.25);
  border: none;
  padding: 35px 110px 36px;
  transition: all .35s ease 0s;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-area-2 .coming-soon-content-2 .coming-soon-btn-2 button {
    padding: 30px 80px 31px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-area-2 .coming-soon-content-2 .coming-soon-btn-2 button {
    padding: 30px 80px 31px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-area-2 .coming-soon-content-2 .coming-soon-btn-2 button {
    padding: 30px 80px 31px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-area-2 .coming-soon-content-2 .coming-soon-btn-2 button {
    padding: 22px 50px 23px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-area-2 .coming-soon-content-2 .coming-soon-btn-2 button {
    padding: 12px 30px 13px;
    font-size: 18px;
  }
}

.coming-soon-area-2 .coming-soon-content-2 .coming-soon-btn-2 button:hover {
  background-color: #181818;
}

.coming-soon-area-2 .coming-soon-content-2 .cs-copyright > p {
  color: #ffffff;
  font-size: 14px;
  margin: 0;
  line-height: 24px;
}

.coming-soon-area-2 .coming-soon-content-2 .cs-copyright > p a {
  color: #ffffff;
}

.coming-soon-area-2 .coming-soon-content-2 .cs-copyright > p a:hover {
  text-decoration: underline;
}

.coming-soon-logo {
  position: absolute;
  left: 120px;
  top: 60px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-logo {
    top: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-logo {
    top: 40px;
    left: 45px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-logo {
    top: 40px;
    left: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-logo {
    top: 30px;
    left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-logo {
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.coming-soon-logo a {
  display: block;
}

.coming-soon-logo a img {
  width: 144px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-logo a img {
    width: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-logo a img {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-logo a img {
    width: 120px;
  }
}

.coming-soon-logo-2 {
  margin-bottom: 145px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-logo-2 {
    margin-bottom: 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-logo-2 {
    margin-bottom: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-logo-2 {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-logo-2 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-logo-2 {
    margin-bottom: 30px;
  }
}

.coming-soon-logo-2 a {
  display: block;
}

.coming-soon-logo-2 a img {
  width: 144px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-logo-2 a img {
    width: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-logo-2 a img {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-logo-2 a img {
    width: 120px;
  }
}

.coming-soon-area-3 {
  height: 100vh;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .coming-soon-area-3 .coming-soon-logo-2 {
    text-align: center;
  }
}

.coming-soon-content-3 h1 {
  font-size: 100px;
  color: #fff;
  margin: 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-content-3 h1 {
    font-size: 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-content-3 h1 {
    font-size: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-content-3 h1 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-content-3 h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-content-3 h1 {
    font-size: 27px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coming-soon-content-3 h1 {
    font-size: 35px;
  }
}

.coming-soon-content-3 .timer-style-1 {
  margin: 45px 0 39px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-content-3 .timer-style-1 {
    margin: 35px 0 39px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-content-3 .timer-style-1 {
    margin: 35px 0 39px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-content-3 .timer-style-1 {
    margin: 30px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-content-3 .timer-style-1 {
    margin: 30px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-content-3 .timer-style-1 {
    margin: 20px 0 30px;
  }
}

.coming-soon-content-3 .timer-style-1 div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.coming-soon-content-3 .timer-style-1 div > span {
  margin: 0 38px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-content-3 .timer-style-1 div > span {
    margin: 0 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-content-3 .timer-style-1 div > span {
    margin: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-content-3 .timer-style-1 div > span {
    margin: 0 8px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coming-soon-content-3 .timer-style-1 div > span {
    margin: 0 15px;
  }
}

.coming-soon-content-3 .timer-style-1 div > span > span {
  font-size: 49px;
  color: #fff;
  display: block;
  line-height: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-content-3 .timer-style-1 div > span > span {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-content-3 .timer-style-1 div > span > span {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-content-3 .timer-style-1 div > span > span {
    font-size: 30px;
  }
}

.coming-soon-content-3 .timer-style-1 div > span > p {
  font-size: 18px;
  color: #fff;
  margin: 10px 0 0;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .coming-soon-content-3 .timer-style-1 div > span > p {
    font-size: 16px;
    margin: 7px 0 0;
  }
}

.coming-soon-content-3 .coming-soon-btn-3 button {
  border: 1px solid #fff;
  background-color: transparent;
  font-size: 24px;
  padding: 37px 110px;
  color: #fff;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-content-3 .coming-soon-btn-3 button {
    padding: 30px 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-content-3 .coming-soon-btn-3 button {
    padding: 30px 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-content-3 .coming-soon-btn-3 button {
    padding: 25px 70px;
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-content-3 .coming-soon-btn-3 button {
    padding: 22px 60px;
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-content-3 .coming-soon-btn-3 button {
    padding: 12px 30px;
    font-size: 17px;
  }
}

.coming-soon-content-3 .coming-soon-btn-3 button:hover {
  background-color: #181818;
  border: 1px solid #181818;
}

.coming-soon-3-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 185px 0 0 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .coming-soon-3-footer {
    margin: 80px 0 0 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .coming-soon-3-footer {
    margin: 80px 0 0 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coming-soon-3-footer {
    margin: 60px 0 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-3-footer {
    margin: 50px 0 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-3-footer {
    display: block;
    text-align: center;
    margin: 30px 0 0 0;
  }
}

.coming-soon-3-footer .cs-copyright-2 > p {
  color: #ffffff;
  font-size: 14px;
  margin: 0;
  line-height: 24px;
}

.coming-soon-3-footer .cs-copyright-2 > p a {
  color: #ffffff;
}

.coming-soon-3-footer .cs-copyright-2 > p a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 767px) {
  .coming-soon-3-footer .coming-soon-social-2 {
    margin: 15px 0 0;
  }
}

.coming-soon-3-footer .coming-soon-social-2 a {
  border: 1px solid #fff;
  font-size: 20px;
  margin-right: 12px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  display: inline-block;
  color: #fff;
  border-radius: 50%;
  text-align: center;
}

.coming-soon-3-footer .coming-soon-social-2 a:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 767px) {
  .coming-soon-3-footer .coming-soon-social-2 a {
    font-size: 18px;
  }
}

.coming-soon-3-footer .coming-soon-social-2 a:hover {
  background-color: #181818;
  color: #ffffff;
  border: 1px solid #181818;
}

.shop-locator-area {
  padding: 115px 0 70px;
}

@media only screen and (max-width: 767px) {
  .shop-locator-area {
    padding: 55px 0 10px;
  }
}

.single-store h3 {
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 28px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .single-store h3 {
    font-size: 20px;
    margin-bottom: 12px;
  }
}

.single-store ul li {
  margin-bottom: 5px;
}

.single-store ul li:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-store.mb-50 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .single-store.mb-50 {
    margin-bottom: 30px;
  }
}

.team-details-img img {
  width: 100%;
}

.team-details-content {
  margin: 0 0 0 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team-details-content {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-details-content {
    margin: 0 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .team-details-content {
    margin: 23px 0 0 0px;
  }
}

.team-details-content h2 {
  font-size: 20px;
  margin: 0 0 0px;
  font-weight: 600;
}

.team-details-content > span {
  font-size: 16px;
  color: #333;
  font-family: "Playfair Display", serif;
  font-style: italic;
  display: block;
  margin: 10px 0 25px;
}

@media only screen and (max-width: 767px) {
  .team-details-content > span {
    margin: 5px 0 15px;
  }
}

.team-details-content p {
  line-height: 25px;
  margin: 0 0 15px;
}

.team-details-content .team-details-icon {
  position: relative;
  overflow: hidden;
  margin: 40px 0 42px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team-details-content .team-details-icon {
    margin: 20px 0 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-details-content .team-details-icon {
    margin: 20px 0 22px;
  }
}

@media only screen and (max-width: 767px) {
  .team-details-content .team-details-icon {
    margin: 20px 0 22px;
  }
}

.team-details-content .team-details-icon::before {
  background: #eaeaea none repeat scroll 0 0;
  top: 14px;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}

.team-details-content .team-details-icon ul {
  float: right;
  display: inline-block;
  background-color: #fff;
  position: relative;
}

.team-details-content .team-details-icon ul li {
  margin-left: 10px;
  display: inline-block;
}

.team-details-content .team-details-icon ul li a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  border-radius: 100%;
  border: 1px solid transparent;
}

.team-details-content .team-details-icon ul li a.facebook {
  background-color: #3b5999;
}

.team-details-content .team-details-icon ul li a.twitter {
  background-color: #55acee;
}

.team-details-content .team-details-icon ul li a.instagram {
  background-color: #e4405f;
}

.team-details-content .team-details-icon ul li a.google-plus {
  background-color: #dd4b39;
}

.team-details-content .team-details-icon ul li a:hover.facebook {
  color: #3b5999;
  border: 1px solid #3b5999;
  background-color: transparent;
}

.team-details-content .team-details-icon ul li a:hover.twitter {
  color: #55acee;
  border: 1px solid #55acee;
  background-color: transparent;
}

.team-details-content .team-details-icon ul li a:hover.instagram {
  color: #e4405f;
  border: 1px solid #e4405f;
  background-color: transparent;
}

.team-details-content .team-details-icon ul li a:hover.google-plus {
  color: #dd4b39;
  border: 1px solid #dd4b39;
  background-color: transparent;
}

.team-details-content .team-details-info ul li {
  margin: 0 0 15px;
  color: #333;
}

.team-details-content .team-details-info ul li:last-child {
  margin-bottom: 0;
}

.team-details-content .team-details-info ul li i {
  font-size: 18px;
  color: #D98719;
  min-width: 30px;
}

@media only screen and (max-width: 767px) {
  .team-details-content .team-details-info ul li i {
    font-size: 16px;
    min-width: 25px;
  }
}

.team-details-content .team-details-info ul li span {
  color: #211E1C;
  font-weight: 500;
  min-width: 70px;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .team-details-content .team-details-info ul li span {
    min-width: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team-area.pb-120 {
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-area.pb-120 {
    padding-bottom: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .team-area.pt-120 {
    padding-top: 60px;
  }
  .team-area.pb-55 {
    padding-bottom: 25px;
  }
  .team-area.pb-120 {
    padding-bottom: 55px;
  }
}

/*-------- 14. Newsletter style ---------*/
.b-modal.__b-popup1__ {
  background-color: #333 !important;
  opacity: .9 !important;
}

.newletter-popup {
  width: 100%;
  background: #fff none repeat scroll 0 0;
  left: 0;
  margin: auto;
  position: fixed !important;
  right: 0;
  top: 22% !important;
  width: 770px;
  display: none;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .newletter-popup {
    top: 15% !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .newletter-popup {
    top: 15% !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .newletter-popup {
    width: 800px;
    top: 15% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newletter-popup {
    width: 700px;
    top: 15% !important;
  }
}

@media only screen and (max-width: 767px) {
  .newletter-popup {
    width: 290px;
    top: 10% !important;
    height: 300px !important;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .newletter-popup {
    width: 400px;
    height: 400px !important;
    overflow-y: auto;
  }
}

.newletter-popup > #popup2 {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #181818;
  font-size: 24px;
  cursor: pointer;
  transition: all 250ms ease-out;
}

@media only screen and (max-width: 767px) {
  .newletter-popup > #popup2 {
    left: 20px;
    right: auto;
    top: 20px;
    font-size: 18px;
    color: #fff;
  }
}

.newletter-popup > #popup2:hover {
  transform: rotate(90deg);
  color: #D98719;
}

.newletter-popup .popup-wrapper-all {
  display: flex;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .newletter-popup .popup-wrapper-all {
    display: block;
  }
}

.newletter-popup .popup-wrapper-all .popup-subscribe-content {
  flex: 0 0 65%;
  padding: 65px 50px 30px 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .newletter-popup .popup-wrapper-all .popup-subscribe-content {
    padding: 50px 30px 33px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newletter-popup .popup-wrapper-all .popup-subscribe-content {
    padding: 20px 30px 20px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .newletter-popup .popup-wrapper-all .popup-subscribe-content {
    padding: 15px 15px 15px 15px;
    flex: 0 0 100%;
  }
}

.newletter-popup .popup-wrapper-all .popup-subscribe-content h3 {
  font-size: 24px;
  color: #181818;
  font-weight: 600;
  margin: 0;
  line-height: 1.2;
}

@media only screen and (max-width: 767px) {
  .newletter-popup .popup-wrapper-all .popup-subscribe-content h3 {
    font-size: 18px;
  }
}

.newletter-popup .popup-wrapper-all .popup-subscribe-content p {
  font-size: 14px;
  color: #181818;
  margin: 20px 0 40px;
  line-height: 1.375em;
}

@media only screen and (max-width: 767px) {
  .newletter-popup .popup-wrapper-all .popup-subscribe-content p {
    margin: 12px 0 12px;
  }
}

.newletter-popup .popup-wrapper-all .popup-subscribe-content .subscribe-newletter-popup form .mc-form-2 input {
  background-color: transparent;
  border: none;
  padding: 2px 0;
  border-bottom: 2px solid #D8D8D8;
  color: #6D6D6D;
}

.newletter-popup .popup-wrapper-all .popup-subscribe-content .subscribe-newletter-popup form .mc-news-2 {
  display: none;
}

.newletter-popup .popup-wrapper-all .popup-subscribe-content .subscribe-newletter-popup form .clear-2 {
  margin: 20px 0 0;
}

.newletter-popup .popup-wrapper-all .popup-subscribe-content .subscribe-newletter-popup form .clear-2 input {
  background-color: #181818;
  color: #fff;
  border: none;
  height: auto;
  padding: 13px 10px 17px;
}

.newletter-popup .popup-wrapper-all .popup-subscribe-content .subscribe-newletter-popup form .clear-2 input:hover {
  background-color: #D98719;
}

.newletter-popup .popup-wrapper-all .popup-subscribe-content .dont-show {
  display: flex;
  align-items: center;
}

.newletter-popup .popup-wrapper-all .popup-subscribe-content .dont-show input {
  width: auto;
  height: auto;
}

.newletter-popup .popup-wrapper-all .popup-subscribe-content .dont-show label {
  font-size: 14px;
  color: #6D6D6D;
  margin: 0 0 0 10px;
}

.newletter-popup .popup-wrapper-all .popup-image {
  flex: 0 0 35%;
}

@media only screen and (max-width: 767px) {
  .newletter-popup .popup-wrapper-all .popup-image {
    display: block;
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .newletter-popup .popup-wrapper-all .popup-image {
    display: block;
  }
}

.newletter-popup .popup-wrapper-all .popup-image img {
  width: 100%;
}

/*-------- 15. Services style ---------*/
.service-wrap .service-icon i {
  color: #EAB700;
  font-size: 30px;
}

.service-wrap .service-icon.service-icon-red i {
  color: #e41515;
}

.service-wrap h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 6px 0 9px;
}

.service-wrap p {
  font-size: 14px;
  color: #6D6D6D;
  line-height: 2em;
  margin: 0 auto;
  width: 87%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .service-wrap p {
    width: 98%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .service-wrap p {
    width: 90%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-wrap p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-wrap p {
    width: 73%;
  }
}

@media only screen and (max-width: 767px) {
  .service-wrap p {
    width: 100%;
  }
}

.service-wrap-2 .service-icon-2 i {
  font-size: 46px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-wrap-2 .service-icon-2 i {
    font-size: 40px;
  }
}

.service-wrap-2 .service-icon-2.font-size-dec i {
  font-size: 30px;
}

.service-wrap-2 .service-icon-2.icon-roted i {
  transform: rotateY(190deg);
  display: inline-block;
}

.service-wrap-2 h3 {
  font-size: 18px;
  font-weight: 500;
  font-family: "Noto Serif JP", serif;
  margin: 12px 0 13px;
}

.service-wrap-2 h3.playfair {
  font-family: "Playfair Display", serif;
  font-weight: 400;
}

.service-wrap-2 p {
  color: #777;
  line-height: 25px;
  width: 80%;
  margin: 0 auto;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .service-wrap-2 p {
    width: 98%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .service-wrap-2 p {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-wrap-2 p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-wrap-2 p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .service-wrap-2 p {
    width: 100%;
  }
}

.service-3-mrg {
  margin: 0 30px;
}

@media only screen and (max-width: 767px) {
  .service-3-mrg {
    margin: 0 0px;
  }
}

.service-wrap-3 .service-3-icon i {
  display: inline-block;
  color: #181818;
  font-size: 33px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 100%;
  border: 1px solid #818a91;
  transition: all .3s ease 0s;
}

.service-wrap-3 .service-3-icon i i {
  line-height: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-wrap-3 .service-3-icon i {
    font-size: 30px;
    width: 75px;
    height: 75px;
    line-height: 75px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-wrap-3 .service-3-icon i {
    font-size: 30px;
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .service-wrap-3 .service-3-icon i {
    font-size: 25px;
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
}

.service-wrap-3 h3 {
  font-size: 20px;
  font-weight: 500;
  margin: 30px 0 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-wrap-3 h3 {
    margin: 20px 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .service-wrap-3 h3 {
    margin: 20px 0 10px;
  }
}

.service-wrap-3 p {
  font-size: 16px;
  line-height: 28px;
  width: 90%;
  margin: 0 auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .service-wrap-3 p {
    width: 100%;
    font-size: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-wrap-3 p {
    width: 100%;
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-wrap-3 p {
    font-size: 15px;
    line-height: 28px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .service-wrap-3 p {
    font-size: 15px;
    line-height: 28px;
    width: 100%;
  }
}

.service-wrap-3:hover .service-3-icon i {
  color: #fff;
  border: 1px solid #181818;
  background-color: #181818;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-area.pb-75 {
    padding-bottom: 40px;
  }
  .service-area.pb-85 {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .service-area.pt-115 {
    padding-top: 60px;
  }
  .service-area.pt-110 {
    padding-top: 55px;
  }
  .service-area.pb-80 {
    padding-bottom: 40px;
  }
  .service-area.pb-85 {
    padding-bottom: 25px;
  }
  .service-area.pb-75 {
    padding-bottom: 20px;
  }
}

/*-------- 16. Brand logo style ---------*/
.single-brand-logo {
  display: flex;
  justify-content: center;
}

.single-brand-logo img {
  width: 160px;
  opacity: .5;
  transition: all .25s ease-out;
  filter: grayscale(100);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-brand-logo img {
    width: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .single-brand-logo img {
    width: 100px;
  }
}

.single-brand-logo.sb-logo-modify a img {
  opacity: inherit;
  filter: grayscale(60);
}

.single-brand-logo:hover img {
  opacity: 1;
  filter: grayscale(0);
}

.single-brand-logo-2 {
  display: flex;
  justify-content: center;
  display: block;
}

.single-brand-logo-2 img {
  max-width: 100%;
}

.single-brand-logo-3 {
  text-align: center;
}

.single-brand-logo-3 img {
  max-width: 100%;
}

.single-brand-logo-4 {
  text-align: center;
}

.single-brand-logo-4 img {
  width: 140px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-brand-logo-4 img {
    width: 120px;
  }
}

.brand-logo-ptb-1 {
  padding: 108px 0 85px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-logo-ptb-1 {
    padding: 70px 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .brand-logo-ptb-1 {
    padding: 50px 0 30px;
  }
}

.single-brand-logo-5 {
  display: flex;
  justify-content: center;
}

.single-brand-logo-5 a {
  display: block;
}

.single-brand-logo-5 a img {
  max-width: 100%;
  transition: all .25s ease-out;
  opacity: .6;
}

.single-brand-logo-5:hover a img {
  opacity: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-logo-area.pb-60 {
    padding-bottom: 20px;
  }
  .brand-logo-area.pb-90 {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .brand-logo-area.pb-85 {
    padding-bottom: 30px;
  }
  .brand-logo-area.pb-60 {
    padding-bottom: 0px;
  }
  .brand-logo-area.pb-90 {
    padding-bottom: 30px;
  }
  .brand-logo-area.pb-110 {
    padding-bottom: 30px;
  }
  .brand-logo-area.pt-100 {
    padding-top: 50px;
  }
  .brand-logo-area.pt-110 {
    padding-top: 50px;
  }
}

/*---------- 17. Subscribe style ------------*/
.subscribe-area {
  position: relative;
}

.subscribe-area .subscribe-shape-top {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  top: -1px;
  transform: rotate(180deg);
  z-index: 2;
  pointer-events: none;
}

.subscribe-area .subscribe-shape-top svg {
  width: calc(100% + 1.3px);
  height: 48px;
  transform: translateX(-50%) rotateY(180deg);
  display: block;
  position: relative;
  left: 50%;
}

.subscribe-area .subscribe-shape-top svg path {
  fill: #fff;
  transform-origin: center;
  transform: rotateY(0deg);
}

.subscribe-area .subscribe-shape-bottom {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  bottom: -1px;
  transform: rotate(180deg);
  z-index: 2;
  pointer-events: none;
}

.subscribe-area .subscribe-shape-bottom svg {
  width: calc(100% + 1.3px);
  height: 48px;
  transform: translateX(-50%) rotateY(180deg);
  display: block;
  position: relative;
  left: 50%;
}

.subscribe-area .subscribe-shape-bottom svg path {
  fill: #fff;
  transform-origin: center;
  transform: rotateY(0deg);
}

.subscribe-title h2 {
  font-size: 32px;
  color: #211E1C;
  line-height: 1.4em;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-title h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-title h2 {
    font-size: 23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-title {
    margin-bottom: 30px;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-title {
    margin-bottom: 20px;
    text-align: center;
  }
}

.subscribe-form-3 {
  margin-left: 10px;
}

.subscribe-form-3 form {
  position: relative;
}

.subscribe-form-3 form .mc-form-3 input {
  height: 80px;
  background-color: #ffffff;
  color: #181818;
  font-size: 16px;
  border: none;
  padding: 3px 150px 3px 30px;
}

.subscribe-form-3 form .mc-form-3 input::-webkit-input-placeholder {
  color: #181818;
  opacity: 1;
}

.subscribe-form-3 form .mc-form-3 input::-moz-input-placeholder {
  color: #181818;
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .subscribe-form-3 form .mc-form-3 input {
    height: 60px;
    padding: 3px 120px 3px 14px;
  }
}

.subscribe-form-3 form .mc-form-3 .mc-news-3 {
  display: none;
}

.subscribe-form-3 form .mc-form-3 .clear-3 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.subscribe-form-3 form .mc-form-3 .clear-3 input {
  width: auto;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  background-color: #181818;
  padding: 3px 30px;
}

.subscribe-form-3 form .mc-form-3 .clear-3 input::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.subscribe-form-3 form .mc-form-3 .clear-3 input::-moz-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.subscribe-form-3 form .mc-form-3 .clear-3 input:hover {
  background-color: #e41515;
}

@media only screen and (max-width: 767px) {
  .subscribe-form-3 form .mc-form-3 .clear-3 input {
    padding: 3px 15px;
  }
}

.subscribe-form-4 {
  margin: 0 -10px;
}

@media only screen and (max-width: 767px) {
  .subscribe-form-4 {
    margin: 0 0px;
  }
}

.subscribe-form-4 form .mc-form-4 {
  position: relative;
}

.subscribe-form-4 form .mc-form-4 input {
  color: #211E1C;
  background-color: transparent;
  border: none;
  height: 50px;
  border-bottom: 1px solid #211E1C;
  padding: 2px 100px 2px 0;
}

.subscribe-form-4 form .mc-form-4 input.subscribe-4-modify-input {
  font-size: 16px;
}

.subscribe-form-4 form .mc-form-4 .mc-news-4 {
  display: none;
}

.subscribe-form-4 form .mc-form-4 .clear-4 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.subscribe-form-4 form .mc-form-4 .clear-4 input {
  width: auto;
  padding: 0;
  letter-spacing: .5px;
  color: #211E1C;
  font-weight: 600;
}

.subscribe-form-4 form .mc-form-4 .clear-4 input.subscribe-4-modify-button {
  font-weight: bold;
  font-size: 16px;
}

.subscribe-form-4 form .mc-form-4 .clear-4 input.subscribe-4-modify-button:hover {
  color: #FF7473;
}

.subscribe-form-5 form .mc-form-5 {
  position: relative;
}

.subscribe-form-5 form .mc-form-5 input {
  height: 90px;
  color: #181818;
  background-color: #ffffff;
  border-radius: 50px;
  border: none;
  font-size: 16px;
  padding: 2px 150px 2px 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form-5 form .mc-form-5 input {
    height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-form-5 form .mc-form-5 input {
    height: 60px;
    font-size: 14px;
    padding: 2px 125px 2px 20px;
  }
}

.subscribe-form-5 form .mc-form-5 input::-moz-input-placeholder {
  color: #181818;
  opacity: 1;
}

.subscribe-form-5 form .mc-form-5 input::-webkit-input-placeholder {
  color: #181818;
  opacity: 1;
}

.subscribe-form-5 form .mc-form-5 .mc-news-5 {
  display: none;
}

.subscribe-form-5 form .mc-form-5 .clear-5 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
}

.subscribe-form-5 form .mc-form-5 .clear-5 input {
  padding: 0;
  width: auto;
  color: #262626;
  font-size: 16px;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .subscribe-form-5 form .mc-form-5 .clear-5 input {
    font-size: 15px;
  }
}

.subscribe-form-5 form .mc-form-5 .clear-5 input:hover {
  color: #FF7473;
}

.subscribe-form-6 form .mc-form-6 {
  position: relative;
}

.subscribe-form-6 form .mc-form-6 input {
  font-size: 18px;
  background-color: #E9EFEB;
  color: #181818;
  height: 80px;
  padding: 2px 150px 2px 30px;
  border: none;
}

@media only screen and (max-width: 767px) {
  .subscribe-form-6 form .mc-form-6 input {
    padding: 2px 110px 2px 20px;
    font-size: 15px;
  }
}

.subscribe-form-6 form .mc-form-6 .mc-news-6 {
  display: none;
}

.subscribe-form-6 form .mc-form-6 .clear-6 {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .subscribe-form-6 form .mc-form-6 .clear-6 {
    right: 20px;
  }
}

.subscribe-form-6 form .mc-form-6 .clear-6 input {
  color: #262626;
  text-transform: uppercase;
  padding: 0;
}

.subscribe-form-6 form .mc-form-6 .clear-6 input:hover {
  color: #538165;
}

@media only screen and (max-width: 767px) {
  .subscribe-area.pt-60 {
    padding-top: 30px;
  }
  .subscribe-area.pt-80 {
    padding-top: 55px;
  }
  .subscribe-area.pb-80 {
    padding-bottom: 60px;
  }
  .subscribe-area.pt-100 {
    padding-top: 52px;
  }
  .subscribe-area.pb-110 {
    padding-bottom: 60px;
  }
}

/*-------- 18. About us style ---------*/
.about-us-area {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .about-us-area.pt-135 {
    padding-top: 55px;
  }
}

.about-us-img {
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  width: 50%;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-img {
    position: relative;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-img {
    position: relative;
    width: 100%;
  }
}

.about-us-img > img {
  height: 100%;
  transition: all 0.3s linear 0s;
  will-change: transform, top;
  z-index: 9;
  position: relative;
  width: 100%;
  object-fit: cover;
}

.about-us-img .about-us-position-img {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
  padding: 10px 10px;
  transform: translateY(-50%);
}

.about-us-img .about-us-position-img img {
  width: 304px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-img .about-us-position-img img {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-img .about-us-position-img img {
    width: 120px;
  }
}

.about-us-content {
  padding: 170px 145px 174px 145px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .about-us-content {
    padding: 100px 100px 104px 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .about-us-content {
    padding: 80px 50px 84px 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content {
    padding: 50px 20px 60px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content {
    padding: 50px 50px 70px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content {
    padding: 50px 20px 60px 20px;
  }
}

.about-us-content h2 {
  font-size: 80px;
  font-weight: 500;
  line-height: 1.2em;
  letter-spacing: -3px;
  font-family: "Noto Serif JP", serif;
  margin: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .about-us-content h2 {
    font-size: 70px;
    letter-spacing: -2px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content h2 {
    font-size: 55px;
    letter-spacing: -1px;
    line-height: 1.1em;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content h2 {
    font-size: 60px;
    letter-spacing: 0px;
    line-height: 1.1em;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content h2 {
    font-size: 35px;
    letter-spacing: 0px;
    line-height: 1.1em;
  }
}

.about-us-content P {
  font-size: 18px;
  line-height: 36px;
  color: #6D6D6D;
  margin: 48px 0 33px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content P {
    font-size: 16px;
    line-height: 34px;
    margin: 28px 0 23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content P {
    margin: 28px 0 23px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content P {
    font-size: 15px;
    line-height: 30px;
    margin: 28px 0 23px;
  }
}

.team-wrap .team-img {
  margin: 0 0 25px;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-wrap .team-img {
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .team-wrap .team-img {
    margin: 0 0 10px;
  }
}

.team-wrap .team-img img {
  max-width: 100%;
}

.team-wrap .team-img:hover {
  transform: translateY(-8px);
}

.team-wrap .team-content h3 {
  font-size: 24px;
  font-family: "Playfair Display", serif;
  margin: 0 0 8px;
}

@media only screen and (max-width: 767px) {
  .team-wrap .team-content h3 {
    font-size: 20px;
    margin: 0 0 5px;
  }
}

.team-wrap .team-content span {
  font-size: 16px;
  color: #444;
  display: block;
  font-family: "Playfair Display", serif;
}

.about-us-content-2 {
  padding: 240px 0 330px 15px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .about-us-content-2 {
    padding: 180px 0 270px 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .about-us-content-2 {
    padding: 180px 0 270px 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content-2 {
    padding: 110px 0 200px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content-2 {
    padding: 100px 0 200px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content-2 {
    padding: 80px 0 160px 10px;
  }
}

.about-us-content-2 > h2 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 15px;
  display: block;
}

@media only screen and (max-width: 767px) {
  .about-us-content-2 > h2 {
    font-size: 22px;
    letter-spacing: 7px;
  }
}

.about-us-content-2 h1 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 100px;
  font-weight: 900;
  text-transform: uppercase;
  font-style: italic;
  line-height: 1.2em;
  letter-spacing: 3px;
  margin: 25px 0 46px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .about-us-content-2 h1 {
    font-size: 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .about-us-content-2 h1 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content-2 h1 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content-2 h1 {
    font-size: 40px;
    margin: 20px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content-2 h1 {
    font-size: 22px;
    line-height: 1.2em;
    margin: 12px 0 20px;
  }
}

.about-us-content-3 {
  padding: 348px 0 358px 16px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .about-us-content-3 {
    padding: 248px 0 258px 16px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .about-us-content-3 {
    padding: 248px 0 258px 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content-3 {
    padding: 148px 0 158px 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content-3 {
    padding: 170px 0 170px 16px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content-3 {
    padding: 80px 0 85px 0px;
  }
}

.about-us-content-3 h3 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 46px;
  font-weight: 900;
  text-transform: uppercase;
  font-style: italic;
  letter-spacing: 2px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .about-us-content-3 h3 {
    font-size: 22px;
    letter-spacing: 1px;
  }
}

.about-us-content-3 h1 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 150px;
  font-weight: 900;
  text-transform: uppercase;
  font-style: italic;
  line-height: 115px;
  letter-spacing: 4px;
  margin: 28px 0 48px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content-3 h1 {
    font-size: 140px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content-3 h1 {
    font-size: 106px;
    margin: 15px 0 35px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content-3 h1 {
    font-size: 50px;
    letter-spacing: 4px;
    margin: 15px 0 30px;
    line-height: 40px;
  }
}

.about-us-content-4 p {
  color: #6d6d6d;
  font-size: 17px;
  line-height: 34px;
  margin: 0 auto 23px;
  width: 59%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content-4 p {
    width: 95%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content-4 p {
    width: 98%;
    margin: 0 auto 15px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content-4 p {
    font-size: 15px;
    width: 95%;
    margin: 0 auto 15px;
    line-height: 29px;
  }
}

.btn-style-12 {
  position: relative;
}

.btn-style-12:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 1px;
  width: 100%;
  background-color: #D8D8D8;
  z-index: 8;
}

.btn-style-12 a {
  display: inline-block;
  font-size: 18px;
  color: #181818;
  background-color: #ffffff;
  padding: 0 20px;
  position: relative;
  z-index: 9;
}

.btn-style-12 a:hover {
  color: #FF7473;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-12.mt-85 {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-12.mt-85 {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-12.mt-85 {
    margin-top: 20px;
  }
}

.about-us-ptb {
  padding: 120px 0 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-ptb {
    padding: 80px 0 80px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-ptb {
    padding: 60px 0 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content-5 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content-5 {
    margin-bottom: 30px;
  }
}

.about-us-content-5 h2 {
  font-size: 34px;
  letter-spacing: 2px;
  margin: 0 0 25px;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content-5 h2 {
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content-5 h2 {
    margin: 0 0 10px;
    font-size: 28px;
    letter-spacing: 1px;
  }
}

.about-us-content-5 p {
  font-size: 18px;
  line-height: 36px;
  width: 85%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content-5 p {
    font-size: 17px;
    line-height: 35px;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content-5 p {
    font-size: 17px;
    line-height: 35px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content-5 p {
    font-size: 15px;
    line-height: 29px;
    width: 100%;
  }
}

.about-video img {
  width: 100%;
}

.about-video .about-video-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
}

.about-video .about-video-icon a {
  display: inline-block;
}

.about-video .about-video-icon a svg {
  color: #fff;
}

.team-wrap-2 {
  position: relative;
}

.team-wrap-2 .team-img-2 {
  overflow: hidden;
}

.team-wrap-2 .team-img-2 a {
  display: block;
}

.team-wrap-2 .team-img-2 a img {
  width: 100%;
  transform: scale(1);
  transition: all .35s ease 0s;
}

.team-wrap-2 .team-content-2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: all .35s ease 0s;
  z-index: 9;
}

.team-wrap-2 .team-content-2 h4 {
  margin: 0;
  font-size: 20px;
  color: #ffffff;
  transform: translate(0, 50px);
  transition: all .35s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team-wrap-2 .team-content-2 h4 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .team-wrap-2 .team-content-2 h4 {
    font-size: 18px;
  }
}

.team-wrap-2 .team-content-2 h4 a {
  color: #ffffff;
}

.team-wrap-2 .team-content-2 h4 a:hover {
  color: #D98719;
}

.team-wrap-2 .team-content-2 span {
  display: block;
  color: #fff;
  font-size: 14px;
  font-style: italic;
  font-family: "Playfair Display", serif;
  transform: translate(0, 60px);
  transition: all .45s ease 0s;
  margin: 8px 0 0;
  letter-spacing: 1px;
}

@media only screen and (max-width: 767px) {
  .team-wrap-2 .team-content-2 span {
    letter-spacing: 0px;
  }
}

.team-wrap-2:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  background: #000;
  transition: all .35s ease 0s;
  pointer-events: none;
}

.team-wrap-2:hover:before {
  opacity: .5;
}

.team-wrap-2:hover .team-img-2 a img {
  transform: scale(1.1);
}

.team-wrap-2:hover .team-content-2 {
  opacity: 1;
  visibility: visible;
}

.team-wrap-2:hover .team-content-2 h4 {
  transform: translate(0, 0px);
}

.team-wrap-2:hover .team-content-2 span {
  transform: translate(0, 0px);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-area.pb-90 {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .team-area.pt-120 {
    padding-top: 60px;
  }
  .team-area.pb-90 {
    padding-bottom: 30px;
  }
}

/*-------- 19. Contact us style ---------*/
.contact-area {
  position: relative;
}

.contact-us-social {
  margin: 60px 0 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .contact-us-social {
    margin: 30px 0 30px;
  }
}

.contact-us-social a {
  font-size: 58px;
  color: #FF7473;
  margin-right: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-us-social a {
    font-size: 45px;
    margin-right: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-us-social a {
    font-size: 30px;
    margin-right: 30px;
  }
}

.contact-us-social a:last-child {
  margin-right: 0;
}

.contact-us-social a:hover {
  color: #000;
}

.contact-us-content p {
  font-size: 18px;
  color: #6D6D6D;
  line-height: 36px;
  margin: 0 auto;
  width: 38%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-us-content p {
    width: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-us-content p {
    width: 70%;
  }
}

@media only screen and (max-width: 767px) {
  .contact-us-content p {
    width: 100%;
    font-size: 16px;
    line-height: 30px;
  }
}

.contact-form-wrap form .single-contact-form input, .contact-form-wrap form .single-contact-form textarea {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #181818;
  color: #181818;
  height: 46px;
  padding: 2px 0;
}

.contact-form-wrap form .single-contact-form input::-moz-input-placeholder, .contact-form-wrap form .single-contact-form textarea::-moz-input-placeholder {
  color: #181818;
  opacity: 1;
}

.contact-form-wrap form .single-contact-form input::-webkit-input-placeholder, .contact-form-wrap form .single-contact-form textarea::-webkit-input-placeholder {
  color: #181818;
  opacity: 1;
}

.contact-form-wrap form .single-contact-form textarea {
  height: 150px;
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-form-wrap form .single-contact-form textarea {
    height: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-wrap form .single-contact-form textarea {
    height: 100px;
  }
}

.contact-form-wrap form .single-contact-form button {
  font-size: 18px;
  background-color: #181818;
  color: #ffffff;
  display: inline-block;
  line-height: 1;
  padding: 27px 35px 30px;
  border: none;
}

@media only screen and (max-width: 767px) {
  .contact-form-wrap form .single-contact-form button {
    padding: 20px 35px 23px;
  }
}

.contact-form-wrap form .single-contact-form button:hover {
  background-color: #FF7473;
}

.contact-form-wrap form .single-contact-form button:hover.yellow {
  background-color: #D98719;
}

#contact-map-2 {
  height: 400px;
}

@media only screen and (max-width: 767px) {
  #contact-map-2 {
    height: 250px;
  }
}

#contact-map-3 {
  height: 100%;
}

#contact-map-4 {
  height: 410px;
}

@media only screen and (max-width: 767px) {
  #contact-map-4 {
    height: 250px;
  }
}

.contact-map-area-3 {
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  width: 50%;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-map-area-3 {
    position: static;
    width: 100%;
    height: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-map-area-3 {
    position: static;
    width: 100%;
    height: 250px;
  }
}

.contact-form-padding {
  padding: 182px 100px 170px 100px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .contact-form-padding {
    padding: 132px 50px 120px 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .contact-form-padding {
    padding: 112px 50px 100px 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-form-padding {
    padding: 80px 50px 100px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-padding {
    padding: 80px 50px 100px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-padding {
    padding: 50px 20px 60px 20px;
  }
}

.contact-form-wrap-2 {
  margin-right: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-wrap-2 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-wrap-2 {
    margin-right: 0px;
  }
}

.contact-form-wrap-2 .contact-form-title {
  margin: 0 0 35px;
}

@media only screen and (max-width: 767px) {
  .contact-form-wrap-2 .contact-form-title {
    margin: 0 0 25px;
  }
}

.contact-form-wrap-2 .contact-form-title h1 {
  font-size: 32px;
  font-weight: 600;
  margin: 0 0 17px;
  line-height: 25px;
}

@media only screen and (max-width: 767px) {
  .contact-form-wrap-2 .contact-form-title h1 {
    margin: 0 0 8px;
    font-size: 27px;
  }
}

.contact-form-wrap-2 .contact-form-style-2 input, .contact-form-wrap-2 .contact-form-style-2 select, .contact-form-wrap-2 .contact-form-style-2 textarea {
  height: 80px;
  border: 1px solid #ebebeb;
  font-size: 16px;
  padding: 2px 30px;
  color: #6d6d6d;
  background-color: transparent;
  margin-bottom: 20px;
}

.contact-form-wrap-2 .contact-form-style-2 input:focus, .contact-form-wrap-2 .contact-form-style-2 select:focus, .contact-form-wrap-2 .contact-form-style-2 textarea:focus {
  border: 1px solid #bbb;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-wrap-2 .contact-form-style-2 input, .contact-form-wrap-2 .contact-form-style-2 select, .contact-form-wrap-2 .contact-form-style-2 textarea {
    height: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-wrap-2 .contact-form-style-2 input, .contact-form-wrap-2 .contact-form-style-2 select, .contact-form-wrap-2 .contact-form-style-2 textarea {
    height: 60px;
    font-size: 15px;
    padding: 2px 20px;
  }
}

.contact-form-wrap-2 .contact-form-style-2 textarea {
  height: 200px;
  padding: 25px 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-wrap-2 .contact-form-style-2 textarea {
    height: 170px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-wrap-2 .contact-form-style-2 textarea {
    height: 100px;
    padding: 15px 20px;
  }
}

.contact-form-wrap-2 .contact-form-style-2 button.submit {
  border: none;
  background-color: #181818;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  width: 100%;
  padding: 28px 10px;
  transition: all .35s ease 0s;
}

@media only screen and (max-width: 767px) {
  .contact-form-wrap-2 .contact-form-style-2 button.submit {
    padding: 20px 10px;
  }
}

.contact-form-wrap-2 .contact-form-style-2 button.submit:hover {
  background-color: #D98719;
}

.contact-info-area {
  margin-left: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-info-area {
    margin-top: 50px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info-area {
    margin-top: 30px;
    margin-left: 0px;
  }
}

.contact-info-area ul li {
  display: flex;
  align-items: center;
  margin: 0 0 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-info-area ul li {
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info-area ul li {
    margin: 0 0 20px;
  }
}

.contact-info-area ul li:last-child {
  margin: 0 0 0px;
}

.contact-info-area ul li i {
  font-size: 29px;
  margin-right: 30px;
  color: #DD6B33;
}

@media only screen and (max-width: 767px) {
  .contact-info-area ul li i {
    font-size: 25px;
    margin-right: 20px;
  }
}

.contact-info-area ul li span {
  font-size: 16px;
  color: #6d6d6d;
  line-height: 20px;
}

.contact-info-area ul li a {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #6d6d6d;
}

.contact-info-area ul li a span {
  color: #6d6d6d;
}

.contact-info-area ul li a span:hover {
  color: #DD6B33;
}

.contact-info-social {
  margin: 62px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-info-social {
    margin: 30px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info-social {
    margin: 20px 0 0;
  }
}

.contact-info-social a {
  font-size: 18px;
  color: #000;
  margin: 0 20px 0px 0;
  display: inline-block;
}

.contact-info-social a:last-child {
  margin: 0 0px 0px 0;
}

.contact-info-social a:hover {
  color: #DD6B33;
}

.contact-form-wrap-3 {
  padding: 130px 100px 150px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .contact-form-wrap-3 {
    padding: 80px 50px 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .contact-form-wrap-3 {
    padding: 70px 40px 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-form-wrap-3 {
    padding: 50px 30px 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-wrap-3 {
    padding: 50px 30px 80px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-wrap-3 {
    padding: 60px 15px 60px;
  }
}

.contact-form-wrap-3 .contact-form-style-3 input, .contact-form-wrap-3 .contact-form-style-3 select, .contact-form-wrap-3 .contact-form-style-3 textarea {
  height: 54px;
  border: none;
  border-bottom: 2px solid #181818;
  font-size: 18px;
  color: #6d6d6d;
  padding: 0;
  background-color: transparent;
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .contact-form-wrap-3 .contact-form-style-3 input, .contact-form-wrap-3 .contact-form-style-3 select, .contact-form-wrap-3 .contact-form-style-3 textarea {
    margin: 0 0 20px;
    font-size: 15px;
    height: 45px;
  }
}

.contact-form-wrap-3 .contact-form-style-3 textarea {
  height: 150px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-form-wrap-3 .contact-form-style-3 textarea {
    height: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-wrap-3 .contact-form-style-3 textarea {
    height: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-wrap-3 .contact-form-style-3 textarea {
    height: 100px;
  }
}

.contact-form-wrap-3 .contact-form-style-3 button.submit-2 {
  border: none;
  background-color: #181818;
  font-size: 18px;
  color: #fff;
  text-align: center;
  width: 100%;
  padding: 28px 10px;
  transition: all .35s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-form-wrap-3 .contact-form-style-3 button.submit-2 {
    padding: 20px 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-wrap-3 .contact-form-style-3 button.submit-2 {
    padding: 20px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-wrap-3 .contact-form-style-3 button.submit-2 {
    padding: 18px 10px;
    font-size: 16px;
  }
}

.contact-form-wrap-3 .contact-form-style-3 button.submit-2:hover {
  background-color: #D98719;
}

.contact-form-wrap-3 .contact-info-social-2 {
  display: flex;
  justify-content: center;
  margin: 80px 0 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .contact-form-wrap-3 .contact-info-social-2 {
    margin: 40px 0 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .contact-form-wrap-3 .contact-info-social-2 {
    margin: 40px 0 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-form-wrap-3 .contact-info-social-2 {
    margin: 40px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-wrap-3 .contact-info-social-2 {
    margin: 30px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-wrap-3 .contact-info-social-2 {
    margin: 30px 0 0;
  }
}

.contact-form-wrap-3 .contact-info-social-2 a {
  font-size: 24px;
  margin-right: 30px;
  color: #000;
}

.contact-form-wrap-3 .contact-info-social-2 a:last-child {
  margin-right: 0;
}

.contact-form-wrap-3 .contact-info-social-2 a:hover {
  color: #DD6B33;
}

.contact-info-area-2 {
  padding: 120px 0 87px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-info-area-2 {
    padding: 80px 0 47px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info-area-2 {
    padding: 60px 0 27px;
  }
}

.single-contact-info-2 .contact-info-2-icon {
  margin: 0 0 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-contact-info-2 .contact-info-2-icon {
    margin: 0 0 12px;
  }
}

@media only screen and (max-width: 767px) {
  .single-contact-info-2 .contact-info-2-icon {
    margin: 0 0 7px;
  }
}

.single-contact-info-2 .contact-info-2-icon i {
  font-size: 40px;
  color: #D98719;
}

@media only screen and (max-width: 767px) {
  .single-contact-info-2 .contact-info-2-icon i {
    font-size: 30px;
  }
}

.single-contact-info-2 .contact-info-2-content h4 {
  font-size: 18px;
  margin: 0 0 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-contact-info-2 .contact-info-2-content h4 {
    margin: 0 0 12px;
  }
}

@media only screen and (max-width: 767px) {
  .single-contact-info-2 .contact-info-2-content h4 {
    margin: 0 0 7px;
  }
}

.single-contact-info-2 .contact-info-2-content p {
  font-size: 16px;
  line-height: 28px;
  margin: 0 0 0px;
}

@media only screen and (max-width: 767px) {
  .contact-area.pt-85 {
    padding-top: 60px;
  }
  .contact-area.pb-100 {
    padding-bottom: 60px;
  }
  .contact-us-area.pt-120 {
    padding-top: 60px;
  }
  .contact-page-map.pt-120 {
    padding-top: 60px;
  }
}

/*-------- 20. Breadcrumb style ---------*/
.breadcrumb-ptb-1 {
  padding: 51px 0 55px;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-ptb-1 {
    padding: 41px 0 45px;
  }
}

.breadcrumb-ptb-2 {
  padding: 135px 0 135px;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-ptb-2 {
    padding: 41px 0 45px;
  }
}

.breadcrumb-ptb-3 {
  padding: 235px 0 160px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .breadcrumb-ptb-3 {
    padding: 200px 0 125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .breadcrumb-ptb-3 {
    padding: 200px 0 125px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-ptb-3 {
    padding: 175px 0 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-ptb-3 {
    padding: 155px 0 100px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-ptb-3 {
    padding: 105px 0 52px;
  }
}

.breadcrumb-bg-1 {
  background-image: url(../../assets/images/bg/breadcrumb-bg-1.jpg);
  background-repeat: repeat;
  background-position: left top;
  background-size: cover;
  background-color: transparent;
}

.breadcrumb-bg-2 {
  background-image: url(../../assets/images/product/shop-collection-8.jpg);
  background-repeat: repeat;
  background-position: left top;
  background-size: cover;
  background-color: transparent;
}

.breadcrumb-content {
  position: relative;
  z-index: 9;
}

.breadcrumb-content .breadcrumb-title {
  margin: 0 0 10px;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content .breadcrumb-title {
    margin: 0 0 5px;
  }
}

.breadcrumb-content .breadcrumb-title h2 {
  font-size: 32px;
  letter-spacing: 2px;
  color: #181818;
  margin: 0;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content .breadcrumb-title h2 {
    font-size: 24px;
    letter-spacing: 1;
  }
}

.breadcrumb-content ul li {
  display: inline-block;
  margin: 0 18px 0 0;
  text-transform: capitalize;
  color: #1a1a1a;
  position: relative;
  font-size: 16px;
}

.breadcrumb-content ul li:last-child {
  margin: 0;
}

.breadcrumb-content ul li::before {
  position: absolute;
  width: 13px;
  height: 1px;
  background-color: #6d6d6d;
  content: "";
  right: -18px;
  top: 13px;
  z-index: 99;
  transform: rotate(106deg);
}

.breadcrumb-content ul li:last-child::before {
  display: none;
}

.breadcrumb-content ul li a {
  text-transform: capitalize;
  color: #6d6d6d;
}

.breadcrumb-content ul li a:hover {
  color: #D98719;
}

.breadcrumb-content.breadcrumb-content-white .breadcrumb-title h2 {
  color: #fff;
}

.breadcrumb-content.breadcrumb-content-white ul li {
  color: #fff;
}

.breadcrumb-content.breadcrumb-content-white ul li::before {
  background-color: #fff;
}

.breadcrumb-content.breadcrumb-content-white ul li a {
  color: #fff;
}

.breadcrumb-content.breadcrumb-content-white ul li a:hover {
  color: #D98719;
}

/*-------- 21. Sidebar style ---------*/
.shop-sidebar-padding-right {
  padding-right: 35px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-sidebar-padding-right {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-sidebar-padding-right {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-sidebar-padding-right {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-sidebar-padding-right {
    padding-right: 0px;
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-sidebar-padding-right {
    padding-right: 0px;
    margin-top: 60px;
  }
}

.shop-sidebar-padding-left {
  padding-left: 35px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-sidebar-padding-left {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-sidebar-padding-left {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-sidebar-padding-left {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-sidebar-padding-left {
    padding-left: 0px;
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-sidebar-padding-left {
    padding-left: 0px;
    margin-top: 60px;
  }
}

.sidebar-widget h4.pro-sidebar-title {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0;
  font-weight: 500;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-widget h4.pro-sidebar-title {
    letter-spacing: 1px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-widget h4.pro-sidebar-title {
    letter-spacing: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget h4.pro-sidebar-title {
    letter-spacing: 0px;
    font-size: 20px;
  }
}

.sidebar-widget .sidebar-widget-categories .widget-categories-active > li {
  display: block;
  position: relative;
  border-bottom: 1px solid #ebebeb;
  padding: 14px 0;
}

.sidebar-widget .sidebar-widget-categories .widget-categories-active > li:last-child {
  border: none;
}

.sidebar-widget .sidebar-widget-categories .widget-categories-active > li.menu-item-has-children .menu-expand {
  top: -2px;
  left: 94%;
  width: 30px;
  position: absolute;
  height: 100%;
  text-align: center;
  cursor: pointer;
}

.sidebar-widget .sidebar-widget-categories .widget-categories-active > li.menu-item-has-children .menu-expand i {
  display: block;
  position: relative;
  width: 12px;
  margin-top: 25px;
  border-bottom: 1px solid #6D6D6D;
  transition: all 250ms ease-out;
}

.sidebar-widget .sidebar-widget-categories .widget-categories-active > li.menu-item-has-children .menu-expand i::before {
  top: 0;
  width: 100%;
  content: "";
  display: block;
  position: absolute;
  transform: rotate(90deg);
  border-bottom: 1px solid #6D6D6D;
}

.sidebar-widget .sidebar-widget-categories .widget-categories-active > li.menu-item-has-children.active > .menu-expand i::before {
  transform: rotate(0);
}

.sidebar-widget .sidebar-widget-categories .widget-categories-active > li a {
  font-size: 16px;
  text-transform: capitalize;
  position: relative;
  display: inline-block;
  color: #6D6D6D;
}

.sidebar-widget .sidebar-widget-categories .widget-categories-active > li ul {
  padding-left: 18px;
  padding-top: 15px;
}

.sidebar-widget .sidebar-widget-categories .widget-categories-active > li ul li {
  border-bottom: 1px solid #ebebeb;
  padding: 8px 0;
}

.sidebar-widget .sidebar-widget-categories .widget-categories-active > li ul li:last-child {
  border-bottom: none;
}

.sidebar-widget .sidebar-widget-categories .widget-categories-active > li ul li a {
  font-size: 16px;
  color: #6d6d6d;
}

.sidebar-widget .sidebar-widget-categories .widget-categories-active > li ul li a:hover {
  color: #D98719;
}

.sidebar-widget .sidebar-widget-categories .widget-categories-active > li:hover > a {
  color: #D98719;
}

.sidebar-widget .price-filter #slider-range {
  background: #eee none repeat scroll 0 0;
  border: medium none;
  border-radius: 50px;
  height: 5px;
  margin-bottom: 24px;
  margin-left: auto;
}

.sidebar-widget .price-filter #slider-range span {
  transition: all .0s ease 0s;
}

.sidebar-widget .price-filter #slider-range .ui-slider-range {
  position: absolute;
  display: block;
  border: 0;
  background: #D98719;
  border: none;
  transition: all .0s ease 0s;
}

.sidebar-widget .price-filter #slider-range .ui-slider-handle {
  background: #fff none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  height: 18px;
  margin-left: 0;
  width: 18px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
  top: -7px;
  cursor: ew-resize;
}

.sidebar-widget .price-filter .price-slider-amount {
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .price-filter .price-slider-amount {
    display: block;
  }
}

.sidebar-widget .price-filter .price-slider-amount .label-input {
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .price-filter .price-slider-amount .label-input {
    margin: 0 0 7px;
  }
}

.sidebar-widget .price-filter .price-slider-amount .label-input span {
  font-size: 14px;
  color: #181818 !important;
  margin-right: 5px;
}

.sidebar-widget .price-filter .price-slider-amount .label-input input {
  background: transparent;
  padding: 0;
  border: none;
  font-size: 14px;
  font-weight: 400;
  width: 60%;
  height: auto;
  color: #181818 !important;
}

.sidebar-widget .price-filter .price-slider-amount button {
  background-color: transparent;
  padding: 7px 37px 6px;
  border: 2px solid #181818;
  font-size: 12px;
  font-weight: 400;
  order: 2;
  color: #181818;
  transition: all 0.3s;
  text-transform: uppercase;
  display: inline-block;
}

.sidebar-widget .price-filter .price-slider-amount button:hover {
  background-color: #D98719;
  color: #fff;
  border: 2px solid #D98719;
}

.sidebar-widget .sidebar-widget-list-wrap ul li {
  display: block;
  margin-bottom: 15px;
}

.sidebar-widget .sidebar-widget-list-wrap ul li:last-child {
  margin-bottom: 0;
}

.sidebar-widget .sidebar-widget-list-wrap ul li .sidebar-widget-list {
  display: flex;
  align-items: center;
}

.sidebar-widget .sidebar-widget-list-wrap ul li .sidebar-widget-list .widget-list-checkbox {
  position: relative;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.sidebar-widget .sidebar-widget-list-wrap ul li .sidebar-widget-list .widget-list-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 999;
  width: 15px;
  height: 15px;
}

.sidebar-widget .sidebar-widget-list-wrap ul li .sidebar-widget-list .widget-list-checkbox input:checked ~ .checkmark {
  background-color: #D98719;
  border: 1px solid #D98719;
}

.sidebar-widget .sidebar-widget-list-wrap ul li .sidebar-widget-list .widget-list-checkbox input:checked ~ .checkmark::after {
  display: block;
}

.sidebar-widget .sidebar-widget-list-wrap ul li .sidebar-widget-list .widget-list-checkbox .checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid #979797;
  border-radius: 0px;
}

.sidebar-widget .sidebar-widget-list-wrap ul li .sidebar-widget-list .widget-list-checkbox .checkmark:before {
  font-family: LaStudioIcons;
  position: absolute;
  left: 2px;
  font-size: 10px;
  margin-top: 1px;
  color: #fff;
  content: '\ea20';
  line-height: 1;
}

.sidebar-widget .sidebar-widget-list-wrap ul li .sidebar-widget-list .widget-list-content a {
  color: #181818;
}

.sidebar-widget .sidebar-widget-list-wrap ul li .sidebar-widget-list .widget-list-content a:hover {
  color: #D98719;
}

.sidebar-widget .sidebar-widget-list-wrap ul li .sidebar-widget-list .widget-list-content span {
  color: #181818;
}

.sidebar-widget .sidebar-widget-list-wrap ul li .sidebar-widget-list:hover .widget-list-checkbox .checkmark {
  background-color: #D98719;
  border: 1px solid #D98719;
}

.sidebar-widget .sidebar-widget-list-wrap ul li .sidebar-widget-list:hover .widget-list-checkbox .checkmark:before {
  font-family: LaStudioIcons;
  position: absolute;
  left: 2px;
  font-size: 10px;
  margin-top: 1px;
  color: #fff;
  content: '\ea20';
  line-height: 1;
}

.sidebar-widget .sidebar-widge-tag ul li {
  display: inline-block;
  margin: 0 6px 10px 0;
}

.sidebar-widget .sidebar-widge-tag ul li a {
  display: inline-block;
  border: 1px solid #ebebeb;
  color: #181818;
  padding: 6px 15px;
}

.sidebar-widget .sidebar-widge-tag ul li a:hover {
  color: #ffffff;
  background-color: #181818;
  border: 1px solid #181818;
}

.sidebar-widget.sidebar-subscribe-form form {
  position: relative;
}

.sidebar-widget.sidebar-subscribe-form form input {
  border: 1px solid #181818;
  color: #181818;
  height: 65px;
  padding: 2px 60px 2px 20px;
  background-color: transparent;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget.sidebar-subscribe-form form input {
    padding: 2px 50px 2px 10px;
  }
}

.sidebar-widget.sidebar-subscribe-form form a {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  display: inline-block;
  line-height: 1;
  color: #181818;
}

.sidebar-widget.sidebar-subscribe-form form a:hover {
  color: #D98719;
}

.sidebar-widget.sidebar-subscribe-form form a i {
  font-size: 24px;
}

.sidebar-widget .sidebar-widget-categories-2 ul li {
  display: block;
  margin: 0 0 20px;
}

.sidebar-widget .sidebar-widget-categories-2 ul li:last-child {
  margin-bottom: 0;
}

.sidebar-widget .sidebar-widget-categories-2 ul li a {
  color: #211e1c;
}

.sidebar-widget .sidebar-widget-categories-2 ul li a:hover {
  color: #D98719;
}

.sidebar-widget .sidebar-widget-categories-2 ul li span {
  float: right;
  color: #6d6d6d;
}

.sidebar-widget .sidebar-post-wrap .single-sidebar-post {
  display: flex;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.sidebar-widget .sidebar-post-wrap .single-sidebar-post:last-child {
  border-bottom: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-img {
  flex: 0 0 80px;
  margin-right: 20px;
}

.sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-img a {
  display: block;
}

.sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-img a img {
  width: 100%;
}

.sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-content h4 {
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 3px;
}

.sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-content h4 a {
  color: #181818;
}

.sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-content h4 a:hover {
  color: #D98719;
}

.sidebar-widget .sidebar-post-wrap .single-sidebar-post .sidebar-post-content span {
  display: block;
  font-size: 12px;
  color: #666;
}

.sidebar-widget .archives-wrap select {
  height: 50px;
  border: 1px solid #ebebeb;
  color: #181818;
  padding: 2px 25px 2px 15px;
}

.sidebar-widget .sidebar-widge-tag-2 a {
  display: inline-block;
  color: #181818;
  margin: 0 0 8px;
}

.sidebar-widget .sidebar-widge-tag-2 a:hover {
  color: #D98719;
}

.sidebar-widge-color ul li {
  display: inline-block;
  margin-right: 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widge-color ul li {
    margin-right: 5px;
  }
}

.sidebar-widge-color ul li:last-child {
  margin-right: 0;
}

.sidebar-widge-color ul li a {
  display: inline-block;
  position: relative;
}

.sidebar-widge-color ul li a:hover:after {
  content: '\ea20';
  font-family: LaStudioIcons;
  position: absolute;
  top: 12px;
  font-size: 16px;
  line-height: 1;
  left: 7px;
  color: #fff;
}

.sidebar-widge-color ul li a .swatch-anchor {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.sidebar-widge-color ul li a .swatch-anchor::before {
  content: attr(title);
  position: absolute;
  bottom: 80%;
  text-indent: 0;
  background: #000;
  padding: 4px 10px 6px;
  line-height: 1;
  border-radius: 4px;
  margin-bottom: 10px;
  min-width: 50px;
  color: #fff;
  left: 50%;
  white-space: pre;
  text-align: center;
  text-transform: none;
  transform: translate(-50%, 0);
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
  font-size: 12px;
}

.sidebar-widge-color ul li a .swatch-anchor::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 80%;
  border-width: 4px 4px 0 4px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
  margin-bottom: 6px;
  margin-left: -4px;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
}

.sidebar-widge-color ul li a .swatch-anchor:hover::before {
  bottom: 100%;
  opacity: 1;
  visibility: visible;
}

.sidebar-widge-color ul li a .swatch-anchor:hover::after {
  bottom: 100%;
  opacity: 1;
  visibility: visible;
}

.sidebar-widge-color ul li a .swatch-anchor.blue {
  background-color: #5a7eba;
}

.sidebar-widge-color ul li a .swatch-anchor.gray {
  background-color: #d8d8d8;
}

.sidebar-widge-color ul li a .swatch-anchor.pink {
  background-color: #e8856f;
}

@media only screen and (max-width: 767px) {
  .shop-area.pt-115 {
    padding-top: 55px;
  }
  .shop-area.pb-110 {
    padding-bottom: 50px;
  }
}

/*-------- 22. Shop style ---------*/
.shop-pl-35 {
  padding-left: 35px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-pl-35 {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-pl-35 {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-pl-35 {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-pl-35 {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-pl-35 {
    padding-left: 0px;
  }
}

.shop-pr-35 {
  padding-right: 35px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-pr-35 {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-pr-35 {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-pr-35 {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-pr-35 {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-pr-35 {
    padding-right: 0px;
  }
}

.shop-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0px 0 40px;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar {
    display: block;
    text-align: center;
  }
}

.shop-top-bar .shop-filter {
  line-height: 1;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-filter {
    display: inline-block;
  }
}

.shop-top-bar .shop-filter a {
  color: #181818;
}

.shop-top-bar .shop-filter a i {
  font-size: 12px;
  position: relative;
  top: 3px;
  color: #6d6d6d;
  margin-left: 12px;
  transition: all .4s ease 0s;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-filter a i {
    font-size: 10px;
    top: 1px;
    margin-left: 3px;
  }
}

.shop-top-bar .shop-filter a i.angle-up {
  position: absolute;
  right: 1px;
  top: 3px;
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-filter a.active {
  color: #D98719;
}

.shop-top-bar .shop-filter a.active i {
  color: #D98719;
}

.shop-top-bar .shop-filter a.active i.angle-down {
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-filter a.active i.angle-up {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-filter.ml-50 {
    margin-left: 0px;
  }
}

.shop-top-bar .shop-top-bar-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left {
    display: block;
    padding-bottom: 16px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left {
    padding-bottom: 0px;
  }
}

.shop-top-bar .shop-top-bar-left .shop-top-show {
  line-height: 1;
}

.shop-top-bar .shop-top-bar-left .shop-top-show span {
  display: block;
  line-height: 1;
  color: #181818;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-left .shop-top-show span {
    margin: 0 0 5px;
  }
}

.shop-top-bar .shop-top-bar-right {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-right {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-right {
    display: flex;
    justify-content: space-between;
  }
}

.shop-top-bar .shop-top-bar-right .shop-short-by {
  position: relative;
  padding: 0px 0 5px;
  line-height: 1;
}

.shop-top-bar .shop-top-bar-right .shop-short-by > span {
  color: #181818;
  position: relative;
  cursor: pointer;
}

.shop-top-bar .shop-top-bar-right .shop-short-by > span i {
  color: #6d6d6d;
  margin-left: 12px;
  transition: all .4s ease 0s;
  font-size: 12px;
  position: relative;
  top: 2px;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-right .shop-short-by > span i {
    margin-left: 3px;
    font-size: 10px;
  }
}

.shop-top-bar .shop-top-bar-right .shop-short-by > span i.angle-up {
  position: absolute;
  right: 2px;
  top: 4px;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-right .shop-short-by > span i.angle-up {
    top: 5px;
  }
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul {
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  position: absolute;
  background-color: #fff;
  z-index: 11;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-out;
  margin-top: 30px;
  border: 1px solid #ebebeb;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul.wd-1 {
  width: 112px;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul.wd-2 {
  width: 160px;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li {
  display: block;
  border-bottom: 1px solid #ebebeb;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li:last-child {
  border-bottom: none;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li a {
  padding: 8px 15px;
  white-space: pre;
  display: block;
  color: #181818;
  line-height: 24px;
  font-size: .875em;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li a:hover {
  color: #D98719;
  background-color: #f1f0f0;
}

.shop-top-bar .shop-top-bar-right .shop-short-by ul li.active a {
  color: #D98719;
  background-color: #f1f0f0;
}

.shop-top-bar .shop-top-bar-right .shop-short-by:hover span i.angle-down {
  opacity: 0;
  visibility: hidden;
}

.shop-top-bar .shop-top-bar-right .shop-short-by:hover span i.angle-up {
  opacity: 1;
  visibility: visible;
}

.shop-top-bar .shop-top-bar-right .shop-short-by:hover ul {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-right .shop-short-by {
    padding: 0px 12px 16px 0;
    display: inline-block;
  }
  .shop-top-bar .shop-top-bar-right .shop-short-by.ml-50 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-right .shop-short-by {
    padding: 16px 20px 16px 0;
  }
}

.shop-top-bar .shop-top-bar-right .shop-tab a {
  color: #181818;
  font-size: 20px;
  margin-right: 20px;
}

.shop-top-bar .shop-top-bar-right .shop-tab a:last-child {
  margin-right: 0;
}

.shop-top-bar .shop-top-bar-right .shop-tab a.active {
  color: #D98719;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .shop-top-bar-right .shop-tab {
    justify-content: center;
  }
  .shop-top-bar .shop-top-bar-right .shop-tab.ml-50 {
    margin-left: 0px;
  }
}

.shop-list-wrap .shop-list-content {
  margin-left: -10px;
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap .shop-list-content {
    margin-left: 0px;
    margin-top: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-list-wrap .shop-list-content {
    margin-left: 0px;
    margin-top: 0px;
  }
}

@media only screen and (min-width: 410px) and (max-width: 575px) {
  .shop-list-wrap .shop-list-content {
    margin-left: 0px;
    margin-top: 0px;
  }
}

.shop-list-wrap .shop-list-content h3 {
  margin: 0;
  font-size: 18px;
}

.shop-list-wrap .shop-list-content h3 a {
  color: #211E1C;
}

.shop-list-wrap .shop-list-content h3 a:hover {
  color: #D98719;
}

.shop-list-wrap .shop-list-content .pro-list-price {
  margin: 10px 0 14px;
}

.shop-list-wrap .shop-list-content .pro-list-price span {
  font-size: 16px;
  color: #181818;
  display: block;
}

.shop-list-wrap .shop-list-content p {
  font-size: 16px;
  line-height: 28px;
  width: 98%;
  margin: 0 0 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-list-wrap .shop-list-content p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap .shop-list-content p {
    font-size: 14px;
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-list-wrap .shop-list-content p {
    width: 100%;
  }
}

.shop-list-wrap .shop-list-content .product-list-action {
  display: flex;
  align-items: center;
}

.shop-list-wrap .shop-list-content .product-list-action .pro-list-actioncart a {
  display: inline-block;
  text-transform: uppercase;
  background-color: #181818;
  color: #ffffff;
  line-height: 1;
  padding: 17px 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-list-wrap .shop-list-content .product-list-action .pro-list-actioncart a {
    padding: 14px 25px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap .shop-list-content .product-list-action .pro-list-actioncart a {
    font-size: 12px;
    padding: 14px 15px;
  }
}

.shop-list-wrap .shop-list-content .product-list-action .pro-list-actioncart a:hover {
  background-color: #D98719;
}

.shop-list-wrap .shop-list-content .product-list-action > a {
  font-size: 18px;
  color: #181818;
  display: inline-block;
  line-height: 1;
  margin-left: 25px;
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap .shop-list-content .product-list-action > a {
    margin-left: 20px;
  }
}

.shop-list-wrap .shop-list-content .product-list-action > a:hover {
  color: #D98719;
}

.shop-list-wrap:hover .product-wrap .product-img .shop-list-quickview {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}

.pro-pagination-style ul li {
  display: inline-block;
  margin: 0 3px;
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style ul li {
    margin: 0 4px;
  }
}

.pro-pagination-style ul li a {
  border: 1px solid #ebebeb;
  color: #979797;
  font-size: 16px;
  text-align: center;
  display: inline-block;
  width: 38px;
  height: 38px;
  line-height: 38px;
  border-radius: 50%;
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

.pro-pagination-style ul li a.active, .pro-pagination-style ul li a:hover {
  background-color: #181818;
  color: #fff;
  border: 1px solid #181818;
}

.pro-pagination-style ul li a i {
  line-height: 38px;
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style ul li a i {
    line-height: 40px;
  }
}

.pro-pagination-style ul li:last-child {
  position: relative;
  top: 2px;
}

.filter-widget-mrg-1 {
  padding-right: 80px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .filter-widget-mrg-1 {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .filter-widget-mrg-1 {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .filter-widget-mrg-1 {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .filter-widget-mrg-1 {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .filter-widget-mrg-1 {
    padding-right: 0px;
  }
}

.product-filter-wrapper {
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  padding: 48px 40px 18px;
  display: none;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-filter-wrapper {
    padding: 40px 25px 34px;
  }
}

@media only screen and (max-width: 767px) {
  .product-filter-wrapper {
    padding: 40px 25px 34px;
  }
}

.shop-collections-padding {
  padding: 50px 110px 50px 140px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-collections-padding {
    padding: 50px 80px 50px 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-collections-padding {
    padding: 50px 60px 50px 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-collections-padding {
    padding: 20px 30px 20px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-collections-padding {
    padding: 50px 30px 50px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-collections-padding {
    padding: 25px 20px 50px 20px;
  }
}

.shop-collections-padding-2 {
  padding: 50px 140px 50px 110px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-collections-padding-2 {
    padding: 50px 80px 50px 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-collections-padding-2 {
    padding: 50px 60px 50px 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-collections-padding-2 {
    padding: 20px 30px 20px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-collections-padding-2 {
    padding: 50px 30px 50px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-collections-padding-2 {
    padding: 25px 20px 50px 20px;
  }
}

.shop-collections-content h1 {
  font-size: 34px;
  letter-spacing: 2px;
  margin: 0;
  text-transform: uppercase;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-collections-content h1 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-collections-content h1 {
    font-size: 21px;
    letter-spacing: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-collections-content h1 {
    font-size: 26px;
  }
}

.shop-collections-content p {
  font-size: 18px;
  line-height: 36px;
  margin: 22px 0 30px;
}

.shop-collections-content p.mrg-2 {
  margin: 22px 0 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-collections-content p.mrg-2 {
    margin: 18px 0 26px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-collections-content p.mrg-2 {
    margin: 13px 0 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-collections-content p {
    font-size: 16px;
    line-height: 34px;
    margin: 15px 0 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-collections-content p {
    font-size: 16px;
    line-height: 34px;
    margin: 15px 0 24px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-collections-content p {
    font-size: 15px;
    line-height: 28px;
    margin: 15px 0 20px;
  }
}

.shop-collections-img a {
  display: block;
}

.shop-collections-img a img {
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .shop-collections-img.mb-35 {
    margin-bottom: 20px;
  }
}

.btn-style-16 a {
  display: inline-block;
  line-height: 1;
  color: #ffffff;
  background-color: #181818;
  font-size: 14px;
  padding: 13px 32px 16px;
}

.btn-style-16 a:hover {
  background-color: #D98719;
}

.shop-parallax-content-1 h1 {
  font-size: 46px;
  text-transform: uppercase;
  line-height: 1.2em;
  letter-spacing: 5px;
  margin: 0 0 45px;
  line-height: 1;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .shop-parallax-content-1 h1 {
    margin: 0 0 25px;
    font-size: 28px;
    letter-spacing: 3px;
    line-height: 1.3;
  }
}

@media only screen and (max-width: 767px) {
  .shop-parallax-content-1 {
    padding: 150px 0;
  }
}

.vendor-content {
  position: relative;
  padding: 35px 0 35px 350px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .vendor-content {
    padding: 25px 0 35px 250px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .vendor-content {
    padding: 25px 0 35px 220px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .vendor-content {
    padding: 15px 0 35px 220px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vendor-content {
    padding: 15px 0 35px 200px;
  }
}

@media only screen and (max-width: 767px) {
  .vendor-content {
    padding: 40px 0 35px 0px;
  }
}

.vendor-content .vendor-img {
  position: absolute;
  left: 0;
  top: -87px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .vendor-content .vendor-img {
    top: -30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .vendor-content .vendor-img {
    top: -30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .vendor-content .vendor-img {
    top: -30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vendor-content .vendor-img {
    top: -30px;
  }
}

@media only screen and (max-width: 767px) {
  .vendor-content .vendor-img {
    position: static;
    margin-top: 20px;
  }
}

.vendor-content .vendor-img img {
  width: 270px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .vendor-content .vendor-img img {
    width: 200px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .vendor-content .vendor-img img {
    width: 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .vendor-content .vendor-img img {
    width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vendor-content .vendor-img img {
    width: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .vendor-content .vendor-img img {
    width: 100%;
  }
}

.vendor-content h1 {
  font-size: 36px;
  font-family: "Playfair Display", serif;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .vendor-content h1 {
    font-size: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vendor-content h1 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .vendor-content h1 {
    font-size: 28px;
  }
}

.vendor-content .shop-vendor-info {
  margin: 11px 0 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .vendor-content .shop-vendor-info {
    margin: 9px 0 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vendor-content .shop-vendor-info {
    margin: 9px 0 12px;
  }
}

.vendor-content .shop-vendor-info ul li {
  margin-right: 25px;
  display: inline-block;
  align-items: center;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .vendor-content .shop-vendor-info ul li {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .vendor-content .shop-vendor-info ul li {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .vendor-content .shop-vendor-info ul li {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vendor-content .shop-vendor-info ul li {
    margin-right: 10px;
  }
}

.vendor-content .shop-vendor-info ul li > i {
  margin-right: 8px;
  color: #6d6d6d;
}

.vendor-content .shop-vendor-info ul li span {
  font-size: 16px;
  color: #6d6d6d;
  display: inline-block;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .vendor-content .shop-vendor-info ul li span {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .vendor-content .shop-vendor-info ul li span {
    font-size: 14px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .vendor-content .shop-vendor-info ul li span {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vendor-content .shop-vendor-info ul li span {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .vendor-content .shop-vendor-info ul li span {
    font-size: 14px;
  }
}

.vendor-content .shop-vendor-info ul li a {
  font-size: 16px;
  color: #6d6d6d;
  display: inline-block;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .vendor-content .shop-vendor-info ul li a {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .vendor-content .shop-vendor-info ul li a {
    font-size: 14px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .vendor-content .shop-vendor-info ul li a {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vendor-content .shop-vendor-info ul li a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .vendor-content .shop-vendor-info ul li a {
    font-size: 14px;
  }
}

.vendor-content .shop-vendor-info ul li a:hover {
  color: #D98719;
}

.vendor-content .shop-vendor-info ul li .ratting {
  display: inline-block;
  font-size: 12px;
  color: #D98719;
  margin-right: 3px;
}

.vendor-content .shop-vendor-search-wrap {
  display: flex;
}

.vendor-content .shop-vendor-search-wrap .shop-vendor-btn a {
  display: inline-block;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #F7F7F7;
  color: #6d6d6d;
  padding: 16px 30px;
}

@media only screen and (max-width: 767px) {
  .vendor-content .shop-vendor-search-wrap .shop-vendor-btn a {
    padding: 16px 20px;
  }
}

.vendor-content .shop-vendor-search-wrap .shop-vendor-btn a:hover {
  background-color: #D98719;
  color: #ffffff;
}

.vendor-content .shop-vendor-search-wrap .shop-vendor-search {
  width: 100%;
}

.vendor-content .shop-vendor-search-wrap .shop-vendor-search form {
  position: relative;
}

.vendor-content .shop-vendor-search-wrap .shop-vendor-search form input {
  height: 56px;
  color: #6d6d6d;
  background-color: #F7F7F7;
  border: none;
  padding: 2px 50px 2px 20px;
}

.vendor-content .shop-vendor-search-wrap .shop-vendor-search form button {
  font-size: 18px;
  color: #6d6d6d;
  padding: 0;
  border: none;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1;
  transition: all .3s ease 0s;
}

.vendor-content .shop-vendor-search-wrap .shop-vendor-search form button:hover {
  color: #D98719;
}

.shop-instagram-top {
  margin-bottom: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-instagram-top {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-instagram-top {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-instagram-top {
    margin-bottom: 20px;
  }
}

.shop-instagram-top p {
  font-size: 18px;
  line-height: 36px;
  margin: 0 auto;
  width: 48%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-instagram-top p {
    width: 60%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-instagram-top p {
    width: 70%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-instagram-top p {
    width: 98%;
  }
}

@media only screen and (max-width: 767px) {
  .shop-instagram-top p {
    width: 98%;
    line-height: 34px;
    font-size: 16px;
  }
}

.shop-carousel-title h1 {
  margin: 0;
  font-size: 32px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-carousel-title h1 {
    font-size: 28px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-carousel-title h1 {
    font-size: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-carousel-title h1 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-carousel-title h1 {
    font-size: 30px;
    margin: 0 0 5px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-carousel-title h1 {
    font-size: 26px;
    margin: 0 0 10px;
  }
}

.shop-carousel-title p {
  font-size: 14px;
  line-height: 30px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-carousel-title p {
    font-size: 15px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-carousel-title p {
    font-size: 15px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-carousel-title p {
    font-size: 15px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-carousel-title p {
    font-size: 16px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-carousel-title p {
    font-size: 16px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-carousel-title.mb-50 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-carousel-title.mb-50 {
    margin-bottom: 20px;
  }
}

.shop-carousel-active .slick-list {
  margin: 0 -30px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-carousel-active .slick-list {
    margin: 0 -10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-carousel-active .slick-list {
    margin: 0 -10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-carousel-active .slick-list {
    margin: 0 -10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-carousel-active .slick-list {
    margin: 0 -15px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-carousel-active .slick-list {
    margin: 0 -15px;
  }
}

.shop-carousel-active .slick-list .shop-product-plr {
  padding: 0 30px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-carousel-active .slick-list .shop-product-plr {
    padding: 0 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-carousel-active .slick-list .shop-product-plr {
    padding: 0 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-carousel-active .slick-list .shop-product-plr {
    padding: 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-carousel-active .slick-list .shop-product-plr {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-carousel-active .slick-list .shop-product-plr {
    padding: 0 15px;
  }
}

.shop-carousel-wrap {
  border-bottom: 1px solid #D8D8D8;
  padding-bottom: 55px;
  margin-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .shop-carousel-wrap {
    padding-bottom: 25px;
    margin-bottom: 30px;
  }
}

.shop-carousel-wrap:last-child {
  margin-bottom: 0px;
}

.product-tab-list-5 {
  justify-content: center;
}

.product-tab-list-5 a {
  display: inline-block;
  font-size: 24px;
  color: #6d6d6d;
  margin: 0 28px;
  padding: 0 22px 20px;
  border-bottom: 1px solid transparent;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-tab-list-5 a {
    margin: 0 15px;
    padding: 0 17px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-5 a {
    margin: 0 12px;
    padding: 0 10px 10px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-5 a {
    margin: 0 8px 8px;
    font-size: 18px;
    padding: 0 10px 8px;
  }
}

.product-tab-list-5 a.active {
  color: #181818;
  border-bottom: 1px solid #D8D8D8;
}

@media only screen and (max-width: 767px) {
  .product-tab-list-5.mb-60 {
    margin-bottom: 12px;
  }
}

.shop-custom-layout-active .slick-list {
  padding: 0 330px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-custom-layout-active .slick-list {
    padding: 0 230px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-custom-layout-active .slick-list {
    padding: 0 140px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-custom-layout-active .slick-list {
    padding: 0 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-custom-layout-active .slick-list {
    padding: 0 80px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-custom-layout-active .slick-list {
    padding: 0 30px;
  }
}

.shop-custom-layout-active .slick-list .shop-product-mlr-2 {
  margin: 0 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-custom-layout-active .slick-list .shop-product-mlr-2 {
    margin: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-custom-layout-active .slick-list .shop-product-mlr-2 {
    margin: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-custom-layout-active .slick-list .shop-product-mlr-2 {
    margin: 0 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-custom-layout-active .slick-list .shop-product-mlr-2 {
    margin: 0 15px;
  }
}

.nav-style-7 > span {
  color: #181818;
  position: absolute;
  left: 17%;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  line-height: 20px;
  background-color: #ffffff;
  font-size: 10px;
  text-align: center;
  box-shadow: 0 0 1px 0 #020101;
  border-radius: 10px 10px 10px 10px;
  transition: all .3s ease 0s;
  z-index: 9;
  cursor: pointer;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-7 > span {
    left: 11%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-7 > span {
    left: 11%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-7 > span {
    left: 9%;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-7 > span {
    left: 6%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .nav-style-7 > span {
    left: 4%;
  }
}

.nav-style-7 > span.shop-next {
  right: 17%;
  left: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-7 > span.shop-next {
    right: 11%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-7 > span.shop-next {
    right: 11%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-7 > span.shop-next {
    right: 9%;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-7 > span.shop-next {
    right: 6%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .nav-style-7 > span.shop-next {
    right: 4%;
  }
}

.nav-style-7 > span:hover {
  color: #ffffff;
  background-color: #181818;
}

.shop-collections-wrap-2 {
  position: relative;
}

.shop-collections-wrap-2:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  content: "";
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
  pointer-events: none;
}

.shop-collections-wrap-2 a {
  display: block;
}

.shop-collections-wrap-2 a img {
  width: 100%;
}

.shop-collections-wrap-2 .shop-collections-content-2 {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  z-index: 9;
}

.shop-collections-wrap-2 .shop-collections-content-2 h3 {
  margin: 0px 0 0;
  color: #ffffff;
  font-size: 28px;
  font-family: "Playfair Display", serif;
  opacity: 0;
  visibility: hidden;
  transition: all .35s ease 0s;
  transform: translate(0, 50px);
}

.shop-collections-wrap-2 .shop-collections-content-2 h3 a {
  color: #ffffff;
}

.shop-collections-wrap-2 .shop-collections-content-2 h3 a:hover {
  color: #D98719;
}

.shop-collections-wrap-2:hover:before {
  opacity: .4;
  visibility: visible;
}

.shop-collections-wrap-2:hover .shop-collections-content-2 h3 {
  transform: translate(0, 0px);
  opacity: 1;
  visibility: visible;
}

.custom-layout-2-title {
  margin: -93px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-layout-2-title {
    text-align: center;
    margin: 0px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .custom-layout-2-title {
    text-align: center;
    margin: 0px 0 30px;
  }
}

.custom-layout-2-title h1 {
  font-size: 46px;
  color: #ffffff;
  letter-spacing: 5px;
  text-transform: uppercase;
  line-height: 64px;
  margin: 0 0 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-layout-2-title h1 {
    margin: 0 0 30px;
    font-size: 40px;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-layout-2-title h1 {
    margin: 0 0 20px;
    font-size: 35px;
    letter-spacing: 1px;
    line-height: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .custom-layout-2-title h1 {
    margin: 0 0 12px;
    font-size: 28px;
    letter-spacing: 1px;
    line-height: 35px;
  }
}

.custom-layout-2-title h1.width-1 {
  width: 70%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-layout-2-title h1.width-1 {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-layout-2-title h1.width-1 {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-layout-2-title h1.width-1 {
    width: 100%;
  }
}

.custom-layout-2-title.cl-2-title-padding {
  padding: 0 0 0px 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-layout-2-title.cl-2-title-padding {
    padding: 0 0 0px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-layout-2-title.cl-2-title-padding {
    padding: 0 0 0px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .custom-layout-2-title.cl-2-title-padding {
    padding: 0 0 0px 0px;
  }
}

.custom-layout-2-ptb {
  padding: 205px 0 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-layout-2-ptb {
    padding: 165px 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .custom-layout-2-ptb {
    padding: 125px 0 20px;
  }
}

.custom-layout-2-ptb-2 {
  padding: 120px 0 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-layout-2-ptb-2 {
    padding: 100px 0 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-layout-2-ptb-2 {
    padding: 100px 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .custom-layout-2-ptb-2 {
    padding: 60px 0 20px;
  }
}

.shop-vendor-pt-85 {
  padding-top: 85px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-vendor-pt-85 {
    padding-top: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-vendor-pt-85 {
    padding-top: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-area.pt-110 {
    padding-top: 70px;
  }
  .shop-area.pb-120 {
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-area.pt-110 {
    padding-top: 70px;
  }
  .shop-area.pb-120 {
    padding-bottom: 80px;
  }
  .shop-area.pb-90 {
    padding-bottom: 50px;
  }
  .shop-area.pb-80 {
    padding-bottom: 40px;
  }
  .shop-collections-wrap.mb-100 {
    margin-bottom: 80px;
  }
  .shop-banner-2 {
    display: inline-block;
  }
}

@media only screen and (max-width: 767px) {
  .shop-area.pt-110 {
    padding-top: 50px;
  }
  .shop-area.pb-120 {
    padding-bottom: 60px;
  }
  .shop-area.pb-90 {
    padding-bottom: 30px;
  }
  .shop-area.pb-80 {
    padding-bottom: 20px;
  }
  .shop-banner-2 {
    display: inline-block;
  }
  .shop-collections-area.pt-120 {
    padding-top: 60px;
  }
  .shop-collections-area.pt-115 {
    padding-top: 55px;
  }
  .shop-collections-area.pb-90 {
    padding-bottom: 30px;
  }
  .shop-collections-wrap.mb-100 {
    margin-bottom: 60px;
  }
  .single-shop-parallax.height-100vh {
    height: auto !important;
  }
}

/*-------- 23. Faq style ---------*/
.faq-accordion {
  border: 1px solid #e3e3e3;
  background-color: #fff;
}

.faq-accordion .panel-heading h4 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  background-color: #fff;
}

.faq-accordion .panel-heading h4 a {
  color: #D98719;
  display: block;
  position: relative;
  padding: 22px 30px 23px 30px;
}

@media only screen and (max-width: 767px) {
  .faq-accordion .panel-heading h4 a {
    padding: 22px 30px 23px 10px;
    line-height: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .faq-accordion .panel-heading h4 a {
    padding: 22px 30px 23px 30px;
    line-height: inherit;
  }
}

.faq-accordion .panel-heading h4 a.collapsed {
  position: relative;
  color: #181818;
}

.faq-accordion .panel-heading h4 a.collapsed::after {
  content: "\ea67";
  position: absolute;
  color: #6d6d6d;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  transition: all .3s ease 0s;
  font-family: LaStudioIcons;
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .faq-accordion .panel-heading h4 a.collapsed::after {
    right: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .faq-accordion .panel-heading h4 a.collapsed::after {
    right: 10px;
  }
}

.faq-accordion .panel-heading h4 a.collapsed::before {
  position: absolute;
  right: 30px;
  color: #6d6d6d;
  top: 50%;
  transform: translateY(-50%);
  content: '\ea68';
  transition: all .3s ease 0s;
  font-family: LaStudioIcons;
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .faq-accordion .panel-heading h4 a.collapsed::before {
    right: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .faq-accordion .panel-heading h4 a.collapsed::before {
    right: 10px;
  }
}

.faq-accordion .panel-heading h4 a::before {
  position: absolute;
  right: 30px;
  color: #D98719;
  top: 50%;
  transform: translateY(-50%);
  content: '\ea68';
  transition: all .3s ease 0s;
  font-family: LaStudioIcons;
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .faq-accordion .panel-heading h4 a::before {
    right: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .faq-accordion .panel-heading h4 a::before {
    right: 10px;
  }
}

.faq-accordion.actives {
  box-shadow: 0 0 87px 0 rgba(41, 44, 58, 0.1);
}

.faq-accordion .panel-collapse .panel-body p {
  line-height: 28px;
  font-size: 14px;
  margin: 0;
  padding: 0px 30px 30px 30px;
}

@media only screen and (max-width: 767px) {
  .faq-accordion .panel-collapse .panel-body p {
    padding: 0px 10px 30px 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .faq-accordion .panel-collapse .panel-body p {
    padding: 0px 30px 30px 30px;
  }
}

.single-faq-wrap h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 23px;
}

@media only screen and (max-width: 767px) {
  .single-faq-wrap h3 {
    margin: 0 0 15px;
    line-height: 28px;
  }
}

.single-faq-wrap p {
  line-height: 28px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .single-faq-wrap.mb-50 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .faq-wrap-1.mb-75 {
    margin-bottom: 30px;
  }
  .faq-wrap-1.mb-55 {
    margin-bottom: 20px;
  }
  .faq-area.pt-120 {
    padding-top: 60px;
  }
  .faq-area.pt-110 {
    padding-top: 50px;
  }
  .faq-area.pb-100 {
    padding-bottom: 40px;
  }
  .faq-area.pb-65 {
    padding-bottom: 30px;
  }
}

/*-------- 24. Product details style ---------*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content {
    padding-top: 20px;
  }
}

.product-details-content .pro-details-next-prev a {
  color: #6D6D6D;
  font-size: 14PX;
  display: inline-block;
}

.product-details-content .pro-details-next-prev a:hover {
  color: #D98719;
}

.product-details-content .pro-details-next-prev-2 {
  text-align: right;
  margin: -43px 3px 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .pro-details-next-prev-2 {
    margin: 0px 3px 15px;
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-next-prev-2 {
    margin: 0px 3px 15px;
    text-align: left;
  }
}

.product-details-content .pro-details-next-prev-2 a {
  font-size: 20px;
  color: #6D6D6D;
  display: inline-block;
}

.product-details-content .pro-details-next-prev-2 a:hover {
  color: #D98719;
}

.product-details-content h3 {
  font-size: 24px;
  margin: 0 0 23px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content h3 {
    margin: 0 0 18px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content h3 {
    margin: 0 0 15px;
    font-size: 22px;
  }
}

.product-details-content h4 {
  font-size: 20px;
  margin: 0 0 0px;
  color: #181818;
}

.product-details-content .pro-details-price-rating-wrap {
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content .pro-details-price-rating-wrap {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-price-rating-wrap {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .pro-details-price-rating-wrap {
    display: flex;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-price-rating-wrap .pro-details-price {
    margin: 0 0 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .pro-details-price-rating-wrap .pro-details-price {
    margin: 0 0 0px;
  }
}

.product-details-content .pro-details-price-rating-wrap .pro-details-price h4 {
  font-size: 20px;
  margin: 0 0 0px;
  color: #181818;
  font-weight: 600;
}

.product-details-content .pro-details-price-rating-wrap .pro-details-rating-stock-wrap {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content .pro-details-price-rating-wrap .pro-details-rating-stock-wrap {
    margin: 10px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-price-rating-wrap .pro-details-rating-stock-wrap {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .pro-details-price-rating-wrap .pro-details-rating-stock-wrap {
    display: flex;
    margin: 0px 0 0;
  }
}

.product-details-content .pro-details-price-rating-wrap .pro-details-rating-stock-wrap .product-details-rating-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 15px 0 0;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-price-rating-wrap .pro-details-rating-stock-wrap .product-details-rating-wrap {
    margin: 10px 15px 10px 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .pro-details-price-rating-wrap .pro-details-rating-stock-wrap .product-details-rating-wrap {
    margin: 0px 15px 0px 0;
  }
}

.product-details-content .pro-details-price-rating-wrap .pro-details-rating-stock-wrap .product-details-rating-wrap .product-details-ratting {
  display: flex;
}

.product-details-content .pro-details-price-rating-wrap .pro-details-rating-stock-wrap .product-details-rating-wrap .product-details-ratting i {
  color: #F5A623;
  font-size: 12px;
  margin: 1px 0;
}

.product-details-content .pro-details-price-rating-wrap .pro-details-rating-stock-wrap .product-details-rating-wrap a {
  margin-left: 5px;
  color: #6d6d6d;
}

.product-details-content .pro-details-price-rating-wrap .pro-details-rating-stock-wrap .product-details-stock span {
  font-weight: bold;
  color: #181818;
}

.product-details-content .pro-details-price-rating-wrap .pro-details-rating-stock-wrap .product-details-stock span i {
  color: #86BA45;
  font-size: 15px;
  margin-right: 2px;
}

.product-details-content .product-details-peragraph {
  margin: 30px 0 33px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content .product-details-peragraph {
    margin: 15px 0 22px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-peragraph {
    margin: 15px 0 20px;
  }
}

.product-details-content .product-details-peragraph p {
  line-height: 2;
  width: 94%;
}

.product-details-content .product-details-peragraph p.font-inc {
  line-height: 28px;
  font-size: 14px;
}

.product-details-content .product-details-peragraph p.width-100 {
  width: 100%;
}

.product-details-content .product-details-action-top {
  display: flex;
  align-items: center;
}

.product-details-content .product-details-action-top .product-details-cart {
  margin-left: 15px;
}

.product-details-content .product-details-action-top .product-details-cart.mrg-none {
  margin-left: 0;
}

.product-details-content .product-details-action-top .product-details-cart a {
  display: inline-block;
  font-size: 16px;
  color: #181818;
  line-height: 1;
  padding: 21px 77px 20px;
  border: 1px solid #181818;
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-action-top .product-details-cart a {
    padding: 21px 30px 20px;
  }
}

.product-details-content .product-details-action-top .product-details-cart a:hover {
  border: 1px solid #D98719;
  color: #ffffff;
  background-color: #D98719;
}

.product-details-content .product-details-action-top .product-details-cart.affiliate-btn a {
  padding: 21px 44px 20px;
}

.product-details-content .product-details-action-bottom {
  display: flex;
  align-items: center;
  margin: 20px 0 0;
}

.product-details-content .product-details-action-bottom .pro-details-wishlist-compare {
  margin-right: 20px;
}

.product-details-content .product-details-action-bottom .pro-details-wishlist-compare:last-child {
  margin-right: 0;
}

.product-details-content .product-details-action-bottom .pro-details-wishlist-compare a {
  line-height: 1;
  color: #181818;
  display: flex;
  align-items: center;
}

.product-details-content .product-details-action-bottom .pro-details-wishlist-compare a i {
  font-size: 16px;
  color: #181818;
  margin-right: 10px;
  transition: all .3s ease 0s;
}

.product-details-content .product-details-action-bottom .pro-details-wishlist-compare a:hover {
  color: #D98719;
}

.product-details-content .product-details-action-bottom .pro-details-wishlist-compare a:hover i {
  color: #D98719;
}

.product-details-content .product-details-meta {
  margin: 37px 0 34px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content .product-details-meta {
    margin: 25px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-meta {
    margin: 25px 0 20px;
  }
}

.product-details-content .product-details-meta span {
  color: #6D6D6D;
  display: block;
  line-height: 1;
  margin: 0 0 17px;
}

.product-details-content .product-details-meta span a {
  color: #181818;
}

.product-details-content .product-details-meta span a:hover {
  text-decoration: underline;
}

.product-details-content .social-icon-style-2 a {
  font-size: 16px;
  border: 1px solid #ebebeb;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 100%;
  color: #6D6D6D;
  display: inline-block;
  margin-right: 10px;
}

.product-details-content .social-icon-style-2 a i {
  line-height: 32px;
}

.product-details-content .social-icon-style-2 a:last-child {
  margin-right: 0;
}

.product-details-content .social-icon-style-2 a:hover {
  color: #ffffff;
  background-color: #D98719;
  border: 1px solid #D98719;
}

.product-details-content.quickview-content-padding h3 {
  margin: 11px 0 23px;
}

@media only screen and (max-width: 767px) {
  .product-details-content.quickview-content-padding h3 {
    margin: 11px 0 23px;
    font-size: 22px;
  }
}

.product-details-quality .cart-plus-minus {
  width: 83px;
  height: 60px;
  position: relative;
}

.product-details-quality .cart-plus-minus .qtybutton {
  position: absolute;
  font-size: 18px;
  color: #6D6D6D;
  top: 50%;
  transform: translateY(-50%);
}

.product-details-quality .cart-plus-minus .qtybutton.dec {
  left: 8px;
}

.product-details-quality .cart-plus-minus .qtybutton.inc {
  right: 8px;
}

.product-details-quality .cart-plus-minus input {
  height: 60px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: transparent;
  padding: 2px 25px;
}

@media only screen and (max-width: 767px) {
  .product-details-quality.quality-border-none {
    display: flex;
    justify-content: center;
  }
}

.product-details-quality.quality-border-none .cart-plus-minus input {
  border: none;
}

.easyzoom-style {
  position: relative;
}

.easyzoom-style a.easyzoom-pop-up {
  position: absolute;
  right: 25px;
  bottom: 20px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  text-align: center;
  border-radius: 100%;
  z-index: 999;
}

.easyzoom-style a.easyzoom-pop-up i {
  line-height: 50px;
}

.easyzoom-style a.easyzoom-pop-up:hover {
  color: #D98719;
}

.easyzoom-style .easyzoom {
  display: block;
}

.easyzoom-style .easyzoom > a {
  transition: none;
}

.easyzoom-style .easyzoom > a img {
  width: 100%;
  transition: none;
}

.easyzoom-style .easyzoom-popup > a img {
  width: 100%;
}

.product-dec-small-style1 {
  width: 420px;
  margin: 13px auto 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-dec-small-style1 {
    width: 400px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-small-style1 {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-small-style1 {
    width: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-small-style1 {
    width: 100%;
  }
}

.product-dec-small-style1 .product-dec-small {
  margin: 0 5px 0px 5px;
  cursor: pointer;
}

.product-dec-small-style1 .product-dec-small img {
  width: 100%;
  opacity: .8;
}

.product-dec-small-style1 .product-dec-small.slick-active {
  border: 1px solid transparent;
}

.product-dec-small-style1 .product-dec-small.slick-active.slick-current {
  border: 1px solid #D98719;
}

.product-dec-small-style1 .product-dec-small.slick-active.slick-current img {
  opacity: 1;
}

.product-dec-small-style1 .product-dec-small:last-child {
  margin: 0 0 0px;
}

.product-dec-small-style1 span {
  color: #262626;
  font-size: 10px;
  position: absolute;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 9;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border: 1px solid #979797;
  color: #1E1E1E;
  text-align: center;
  border-radius: 100%;
}

.product-dec-small-style1 span:hover {
  color: #ffffff;
  border: 1px solid #D98719;
  background-color: #D98719;
}

.product-dec-small-style1 span.pro-dec-prev {
  left: -5%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-small-style1 span.pro-dec-prev {
    left: -4%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-small-style1 span.pro-dec-prev {
    left: -4%;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-small-style1 span.pro-dec-prev {
    left: -2%;
  }
}

.product-dec-small-style1 span.pro-dec-next {
  left: auto;
  right: -4.7%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-small-style1 span.pro-dec-next {
    right: -4%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-small-style1 span.pro-dec-next {
    right: -4%;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-small-style1 span.pro-dec-next {
    right: -2%;
  }
}

.product-dec-small-style2 {
  text-align: center;
}

.product-dec-small-style2 .product-dec-small {
  margin: 5px 0px 5px 0px;
  cursor: pointer;
}

.product-dec-small-style2 .product-dec-small img {
  width: 100%;
  opacity: .8;
}

.product-dec-small-style2 .product-dec-small.slick-active {
  border: 1px solid transparent;
}

.product-dec-small-style2 .product-dec-small.slick-active.slick-current {
  border: 1px solid #D98719;
}

.product-dec-small-style2 .product-dec-small.slick-active.slick-current img {
  opacity: 1;
}

.product-dec-small-style2 .product-dec-small:last-child {
  margin: 0 0 0px;
}

.product-dec-small-style2 span {
  color: #262626;
  font-size: 10px;
  line-height: 1;
  cursor: pointer;
  z-index: 9;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border: 1px solid #979797;
  color: #1E1E1E;
  text-align: center;
  border-radius: 100%;
  display: inline-block;
}

.product-dec-small-style2 span:hover {
  color: #ffffff;
  border: 1px solid #D98719;
  background-color: #D98719;
}

.product-dec-small-style1 .slick-slide, .product-dec-small-style2 .slick-slide {
  border: 1px solid transparent;
}

.description-review-topbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 0 18px;
  border-bottom: 1px solid #ebebeb;
  margin: 0 0 65px;
}

@media only screen and (max-width: 767px) {
  .description-review-topbar {
    margin: 0 0 35px;
    padding: 0 0 13px;
  }
}

.description-review-topbar a {
  font-size: 16px;
  color: #6d6d6d;
  display: inline-block;
  margin: 0 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .description-review-topbar a {
    margin: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .description-review-topbar a {
    margin: 0 15px 5px;
  }
}

@media only screen and (max-width: 767px) {
  .description-review-topbar a {
    margin: 0 6px 7px;
  }
}

.description-review-topbar a.active {
  color: #181818;
}

.pro-details-banner img {
  max-width: 100%;
}

.product-description-content {
  margin: 0 37px 0 -37px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-description-content {
    margin: 0 0px 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-description-content {
    margin: 0 0px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-description-content {
    margin: 30px 0px 0 0px;
  }
}

.product-description-content h2 {
  font-size: 28px;
  margin: 0 0 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-description-content h2 {
    margin: 0 0 12px;
  }
}

@media only screen and (max-width: 767px) {
  .product-description-content h2 {
    margin: 0 0 12px;
    font-size: 23px;
  }
}

.product-description-content p {
  font-size: 15px;
  line-height: 28px;
  margin: 0 0 22px;
}

.product-description-content p:last-child {
  margin: 0 0 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-description-content p {
    font-size: 15px;
    line-height: 27px;
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-description-content p {
    font-size: 15px;
    line-height: 27px;
    margin: 0 0 15px;
  }
}

.specification-wrap table {
  width: 100%;
}

.specification-wrap table tbody {
  border: 1px solid #ebebeb;
  width: 100%;
}

.specification-wrap table tbody tr {
  border-bottom: 1px solid #ebebeb;
}

.specification-wrap table tbody tr td {
  border-right: 1px solid #ebebeb;
  font-size: 16px;
  padding: 12px 10px;
}

@media only screen and (max-width: 767px) {
  .specification-wrap table tbody tr td {
    padding: 12px 15px;
  }
}

.specification-wrap table tbody tr td.width1 {
  width: 215px;
}

@media only screen and (max-width: 767px) {
  .specification-wrap table tbody tr td.width1 {
    width: auto;
  }
}

.review-wrapper h3 {
  font-size: 24px;
  margin: 0 0 37px;
  line-height: 20px;
}

@media only screen and (max-width: 767px) {
  .review-wrapper h3 {
    font-size: 18px;
    margin: 0 0 18px;
  }
}

.single-review {
  margin: 0 0 60px;
  display: flex;
  border-bottom: 1px solid #ebebeb;
  padding: 0 0 34px;
}

@media only screen and (max-width: 767px) {
  .single-review {
    display: block;
    margin: 0 0 20px;
    padding: 0 0 20px;
  }
}

.single-review .review-img img {
  width: 100px;
}

.single-review .review-content {
  margin-left: 30px;
}

@media only screen and (max-width: 767px) {
  .single-review .review-content {
    margin-left: 0px;
  }
}

.single-review .review-content h5 {
  font-size: 12px;
  margin: 11px 0 8px;
  color: #6d6d6d;
}

.single-review .review-content h5 span {
  font-weight: bold;
  font-size: 14px;
  color: #6d6d6d;
}

.single-review .review-content .review-rating {
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .single-review .review-content .review-rating {
    margin: 10px 0 0px;
  }
}

.single-review .review-content .review-rating i {
  color: #F5A623;
  font-size: 12px;
  margin: 0px 1px;
}

.single-review .review-content p {
  line-height: 25px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .single-review .review-content p {
    font-size: 15px;
  }
}

.ratting-form-wrapper > h3 {
  display: block;
  line-height: 1;
  font-size: 24px;
  color: #211E1C;
}

.ratting-form-wrapper > p {
  margin: 35px 0 0;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 767px) {
  .ratting-form-wrapper > p {
    margin: 15px 0 0;
  }
}

.ratting-form-wrapper .your-rating-wrap {
  display: flex;
  align-items: center;
  margin: 52px 0 0px;
}

@media only screen and (max-width: 767px) {
  .ratting-form-wrapper .your-rating-wrap {
    margin: 20px 0 0px;
  }
}

.ratting-form-wrapper .your-rating-wrap span {
  font-size: 16px;
  color: #6d6d6d;
  display: inline-block;
  margin-right: 20px;
}

.ratting-form-wrapper .your-rating-wrap .your-rating i {
  color: #F5A623;
  font-size: 14px;
}

.ratting-form-wrapper .ratting-form {
  margin: 19px 0 0;
}

.ratting-form-wrapper .ratting-form .rating-form-style label {
  margin: 0 0 14px;
  color: #6d6d6d;
  font-size: 16px;
  display: block;
}

.ratting-form-wrapper .ratting-form .rating-form-style input, .ratting-form-wrapper .ratting-form .rating-form-style textarea {
  background: transparent;
  border: 1px solid #ebebeb;
  height: 50px;
  padding: 5px 10px;
}

.ratting-form-wrapper .ratting-form .rating-form-style input:focus, .ratting-form-wrapper .ratting-form .rating-form-style textarea:focus {
  border: 1px solid #e9322d;
}

.ratting-form-wrapper .ratting-form .rating-form-style textarea {
  height: 150px;
}

@media only screen and (max-width: 767px) {
  .ratting-form-wrapper .ratting-form .rating-form-style textarea {
    height: 100px;
  }
}

.ratting-form-wrapper .ratting-form .save-email-option > p {
  display: flex;
  align-items: center;
  margin: 0 0 5px;
}

.ratting-form-wrapper .ratting-form .save-email-option > p input {
  width: auto;
  height: auto;
}

.ratting-form-wrapper .ratting-form .save-email-option label {
  margin: 0 0 0 16px;
}

.ratting-form-wrapper .ratting-form .form-submit {
  margin-top: 9px;
}

.ratting-form-wrapper .ratting-form .form-submit input {
  padding: 15px 65px;
  color: #fff;
  display: inline-block;
  width: auto;
  height: auto;
  font-size: 14px;
  text-transform: uppercase;
  background: #181818;
}

.ratting-form-wrapper .ratting-form .form-submit input:hover {
  background: #D98719;
}

.about-brand-wrap p {
  font-size: 15px;
  line-height: 28px;
  margin: 0 0 11px;
}

@media only screen and (max-width: 767px) {
  .about-brand-wrap p {
    font-size: 15px;
    line-height: 27px;
  }
}

.about-brand-wrap p:last-child {
  margin: 0 0 0;
}

.vendor-info-content h3 {
  font-size: 24px;
  margin: 0 0 33px;
}

@media only screen and (max-width: 767px) {
  .vendor-info-content h3 {
    margin: 0 0 15px;
  }
}

.vendor-info-content ul li {
  font-size: 15px;
  color: #6d6d6d;
  margin: 0 0 6px;
  line-height: 28px;
}

@media only screen and (max-width: 767px) {
  .vendor-info-content ul li {
    font-size: 14px;
    line-height: 25px;
  }
}

.vendor-info-content ul li span {
  display: inline-block;
  margin-right: 5px;
  line-height: 1;
}

.vendor-info-content ul li span i {
  color: #F5A623;
  font-size: 12px;
  margin: 0px 1px;
}

.vendor-info-content ul li.rating {
  font-size: 15px;
}

.related-product-slider .slick-list {
  margin: 0 -15px;
}

.related-product-slider .slick-list .related-product-plr {
  padding: 0 15px;
}

.configurable-wrap .configurable-color {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0 17px;
}

.configurable-wrap .configurable-color > span {
  color: #181818;
  display: inline-block;
  min-width: 80px;
  margin-right: 20px;
}

.configurable-wrap .configurable-color.sidebar-widge-color ul li {
  margin-right: 8px;
}

.configurable-wrap .configurable-color.sidebar-widge-color ul li:last-child {
  margin-right: 0;
}

.configurable-wrap .configurable-color.sidebar-widge-color ul li a .swatch-anchor {
  width: 22px;
  height: 22px;
  line-height: 22px;
}

.configurable-wrap .configurable-color.sidebar-widge-color ul li a:hover::after {
  display: none;
}

.configurable-wrap .configurable-size {
  display: flex;
  align-items: center;
}

.configurable-wrap .configurable-size > span {
  color: #181818;
  display: inline-block;
  min-width: 80px;
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .configurable-wrap .configurable-size > span {
    margin-right: 10px;
  }
}

.configurable-wrap .configurable-size select {
  padding: 0 20px 5px 0;
  border-bottom: 1px solid #ebebeb;
}

.configurable-wrap .configurable-brand-img {
  display: flex;
  align-items: center;
  margin: 0 0 25px;
}

.configurable-wrap .configurable-brand-img > span {
  color: #181818;
  display: inline-block;
  min-width: 80px;
  margin-right: 20px;
}

.configurable-wrap .configurable-brand-img a {
  display: block;
}

.configurable-wrap .configurable-brand-img a img {
  width: 60px;
}

.pro-details-group {
  margin-bottom: 30px;
}

.pro-details-group .single-group {
  display: flex;
  border-bottom: 1px solid #ebebeb;
  padding: 0 0 14px;
}

.pro-details-group .single-group:first-child {
  border-top: 1px solid #ebebeb;
}

@media only screen and (max-width: 767px) {
  .pro-details-group .single-group {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .pro-details-group .single-group {
    display: flex;
  }
}

.pro-details-group .single-group .product-details-quality {
  flex: 0 0 13%;
}

.pro-details-group .single-group .product-details-quality .cart-plus-minus input {
  border: none;
}

.pro-details-group .single-group .pro-dec-group-details {
  flex: 0 0 87%;
  margin: 18px 0 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .pro-details-group .single-group .pro-dec-group-details {
    flex: 0 0 85%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pro-details-group .single-group .pro-dec-group-details {
    flex: 0 0 80%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pro-details-group .single-group .pro-dec-group-details {
    flex: 0 0 80%;
  }
}

@media only screen and (max-width: 767px) {
  .pro-details-group .single-group .pro-dec-group-details {
    margin: 0px 0 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .pro-details-group .single-group .pro-dec-group-details {
    flex: 0 0 80%;
    margin: 18px 0 0;
  }
}

.pro-details-group .single-group .pro-dec-group-details .pro-group-title h5 {
  font-size: 14px;
  margin: 0 0 5px;
}

.pro-details-group .single-group .pro-dec-group-details .pro-group-title h5 a {
  color: #181818;
}

.pro-details-group .single-group .pro-dec-group-details .pro-group-title h5 a:hover {
  color: #D98719;
}

.pro-details-group .single-group .pro-dec-group-details .pro-dec-group-stock {
  display: flex;
  justify-content: space-between;
}

.pro-details-group .single-group .pro-dec-group-details .pro-dec-group-stock .pro-dec-group-price span {
  display: inline-block;
  line-height: 1;
}

.pro-details-group .single-group .pro-dec-group-details .pro-dec-group-stock .pro-dec-group-price span.old-price {
  text-decoration: line-through;
  margin-right: 3px;
  color: #666;
}

.pro-details-group .single-group .pro-dec-group-details .pro-dec-group-stock .pro-dec-group-stock span {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.pro-details-group .single-group .pro-dec-group-details .pro-dec-group-stock .pro-dec-group-stock span i {
  color: #86BA45;
  font-size: 15px;
  margin-right: 5px;
}

.sidebar-active.col-lg-6 {
  padding-left: 15px;
  padding-right: 15px;
}

.sidebar-active.col-lg-6.is-affixed {
  padding-left: 0px;
  padding-right: 0px;
}

.sidebar-active.col-lg-6.is-affixed .inner-wrapper-sticky {
  padding-left: 15px;
  padding-right: 15px;
}

.product-dec-left {
  position: relative;
  float: left;
  width: 160px;
  padding-right: 20px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-dec-left {
    width: 125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-dec-left {
    width: 140px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-left {
    width: 110px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-left {
    width: 135px;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-left {
    width: 90px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-dec-left {
    width: 100px;
  }
}

.product-dec-right {
  float: right;
  position: relative;
  width: calc(100% - 160px);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-dec-right {
    width: calc(100% - 125px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-dec-right {
    width: calc(100% - 140px);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-right {
    width: calc(100% - 110px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-right {
    width: calc(100% - 135px);
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-right {
    width: calc(100% - 90px);
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-dec-right {
    width: calc(100% - 100px);
  }
}

.product-details-custom-slider {
  margin-bottom: 115px;
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-custom-slider {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-custom-slider {
    margin-bottom: 20px;
  }
}

.product-details-custom-slider .slick-list {
  padding: 0 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-custom-slider .slick-list {
    padding: 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-custom-slider .slick-list {
    padding: 0 50px;
  }
}

.product-details-custom-slider .slick-list .pd-custom-slider-mlr {
  margin: 0 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-custom-slider .slick-list .pd-custom-slider-mlr {
    margin: 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-custom-slider .slick-list .pd-custom-slider-mlr {
    margin: 0 10px;
  }
}

.product-details-custom-slider > span.pro-dec-custom-icon {
  color: #181818;
  position: absolute;
  left: 6%;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: #ffffff;
  font-size: 10px;
  text-align: center;
  border: 1px solid #ebebeb;
  border-radius: 100%;
  transition: all .3s ease 0s;
  z-index: 9;
  cursor: pointer;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-details-custom-slider > span.pro-dec-custom-icon {
    left: 8%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-details-custom-slider > span.pro-dec-custom-icon {
    left: 9%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-custom-slider > span.pro-dec-custom-icon {
    left: 12%;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-custom-slider > span.pro-dec-custom-icon {
    left: 10%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-custom-slider > span.pro-dec-custom-icon {
    left: 5%;
  }
}

.product-details-custom-slider > span.pro-dec-custom-icon.pro-details-next {
  right: 6%;
  left: auto;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-details-custom-slider > span.pro-dec-custom-icon.pro-details-next {
    right: 8%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-details-custom-slider > span.pro-dec-custom-icon.pro-details-next {
    right: 9%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-custom-slider > span.pro-dec-custom-icon.pro-details-next {
    right: 12%;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-custom-slider > span.pro-dec-custom-icon.pro-details-next {
    right: 10%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-custom-slider > span.pro-dec-custom-icon.pro-details-next {
    right: 5%;
  }
}

.product-details-custom-slider > span.pro-dec-custom-icon:hover {
  color: #ffffff;
  background-color: #181818;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-tab {
    margin-bottom: 50px;
  }
  .description-review-area.pb-120 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-tab {
    margin-bottom: 30px;
  }
  .related-product.pt-120, .product-details-area.pt-120 {
    padding-top: 60px;
  }
  .related-product.pb-115, .product-details-area.pb-115 {
    padding-bottom: 55px;
  }
  .description-review-area.pb-120 {
    padding-bottom: 60px;
  }
}

/*-------- 25. Order tracking style ---------*/
.order-tracking-area {
  position: relative;
}

.order-tracking-area .order-tracking-img {
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  width: 50%;
  z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .order-tracking-area .order-tracking-img {
    width: 100%;
    position: relative;
  }
}

@media only screen and (max-width: 767px) {
  .order-tracking-area .order-tracking-img {
    width: 100%;
    position: relative;
  }
}

.order-tracking-area .order-tracking-img .order-tracking-banner-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
}

.order-tracking-area .order-tracking-img .order-tracking-banner-content h2 {
  color: #ffffff;
  font-size: 110px;
  margin: 0 0 35px;
  font-family: "Playfair Display", serif;
  font-style: italic;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .order-tracking-area .order-tracking-img .order-tracking-banner-content h2 {
    font-size: 90px;
    margin: 0 0 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .order-tracking-area .order-tracking-img .order-tracking-banner-content h2 {
    font-size: 85px;
    margin: 0 0 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .order-tracking-area .order-tracking-img .order-tracking-banner-content h2 {
    font-size: 65px;
    margin: 0 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .order-tracking-area .order-tracking-img .order-tracking-banner-content h2 {
    font-size: 85px;
    margin: 0 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .order-tracking-area .order-tracking-img .order-tracking-banner-content h2 {
    font-size: 45px;
    margin: 0 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .order-tracking-area .order-tracking-img .order-tracking-banner-content h2 {
    font-size: 55px;
    margin: 0 0 15px;
  }
}

.order-tracking-area .order-tracking-img .order-tracking-banner-content .order-track-btn-2 a {
  display: inline-block;
  color: #ffffff;
  font-size: 16px;
  background-color: #181818;
  padding: 18px 40px 18px;
}

.order-tracking-area .order-tracking-img .order-tracking-banner-content .order-track-btn-2 a:hover {
  background-color: #D98719;
}

@media only screen and (max-width: 767px) {
  .order-tracking-area .order-tracking-img .order-tracking-banner-content .order-track-btn-2 a {
    padding: 13px 35px 15px;
    font-size: 15px;
  }
}

.order-tracking-area .order-tracking-img img {
  height: 100%;
  width: 100%;
  transition: all 0.3s linear 0s;
  will-change: transform, top;
  z-index: 0;
  object-fit: cover;
}

.order-tracking-content {
  padding: 173px 220px 180px 70px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .order-tracking-content {
    padding: 127px 110px 135px 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .order-tracking-content {
    padding: 102px 100px 108px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .order-tracking-content {
    padding: 80px 50px 80px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .order-tracking-content {
    padding: 50px 30px 60px;
  }
}

@media only screen and (max-width: 767px) {
  .order-tracking-content {
    padding: 40px 15px 60px;
  }
}

.order-tracking-content p {
  line-height: 25px;
  margin: 0;
  text-align: center;
  width: 98%;
  color: #6d6d6d;
}

.order-tracking-content .order-tracking-form {
  margin: 30px 0 0;
  text-align: left;
}

.order-tracking-content .order-tracking-form .sin-order-tracking {
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .order-tracking-content .order-tracking-form .sin-order-tracking {
    margin: 0 0 20px;
  }
}

.order-tracking-content .order-tracking-form .sin-order-tracking label {
  font-size: 14px;
  font-weight: bold;
  color: #181818;
  margin: 0 0 9px;
  display: block;
}

.order-tracking-content .order-tracking-form .sin-order-tracking input {
  background: transparent;
  border: 1px solid #ebebeb;
  height: 70px;
  padding: 10px 20px;
  color: #6d6d6d;
  font-size: 14px;
}

.order-tracking-content .order-tracking-form .sin-order-tracking input:focus {
  border: 1px solid #bbb;
}

@media only screen and (max-width: 767px) {
  .order-tracking-content .order-tracking-form .sin-order-tracking input {
    height: 60px;
  }
}

.order-tracking-content .order-tracking-form .order-track-btn {
  text-align: center;
}

.order-tracking-content .order-tracking-form .order-track-btn a {
  display: block;
  color: #fff;
  background-color: #181818;
  line-height: 1;
  padding: 22px 40px 23px;
  text-transform: uppercase;
}

.order-tracking-content .order-tracking-form .order-track-btn a:hover {
  background-color: #D98719;
}

/*-------- 26. Cart style ---------*/
@media only screen and (max-width: 767px) {
  .cart-main-area.pb-100 {
    padding-bottom: 60px;
  }
}

h3.cart-page-title {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 15px;
}

.cart-table-content table {
  width: 100%;
}

.cart-table-content table thead > tr {
  border-bottom: 1px solid #DBDBDB;
}

.cart-table-content table thead > tr th {
  border-top: medium none;
  font-size: 16px;
  text-transform: capitalize;
  vertical-align: middle;
  white-space: nowrap;
  color: #211e1c;
  font-weight: 600;
  text-transform: capitalize;
  padding: 0 0 9px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-table-content table thead > tr th {
    padding: 0 0 33px 0;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content table thead > tr th {
    padding: 0 40px 12px 40px;
  }
}

.cart-table-content table tbody > tr td {
  font-size: 14px;
  padding: 20px 0px 20px;
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td {
    padding: 20px 0px 20px;
    text-align: center;
  }
}

.cart-table-content table tbody > tr td.product-remove {
  width: 30px;
}

.cart-table-content table tbody > tr td.product-remove a {
  color: #211E1C;
}

.cart-table-content table tbody > tr td.product-remove a:hover {
  color: #D98719;
}

.cart-table-content table tbody > tr td.product-img {
  width: 90px;
}

.cart-table-content table tbody > tr td.product-img a {
  display: block;
  padding-right: 20px;
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td.product-img a {
    padding-right: 0px;
  }
}

.cart-table-content table tbody > tr td.product-img a img {
  max-width: 100%;
}

.cart-table-content table tbody > tr td.product-name {
  width: 325px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content table tbody > tr td.product-name {
    width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td.product-name {
    width: 200px;
    padding-right: 0px;
  }
}

.cart-table-content table tbody > tr td.product-name a {
  font-size: 16px;
  display: block;
}

.cart-table-content table tbody > tr td.product-name a:hover {
  color: #D98719;
}

.cart-table-content table tbody > tr td.product-name span {
  display: block;
  color: #6d6d6d;
}

.cart-table-content table tbody > tr td.product-price {
  width: 90px;
}

.cart-table-content table tbody > tr td.product-price span {
  font-size: 16px;
}

.cart-table-content table tbody > tr td.cart-quality {
  width: 110px;
}

.cart-table-content table tbody > tr td.product-total {
  width: 70px;
}

.cart-table-content table tbody > tr td.product-total span {
  color: #181818;
  font-size: 16px;
}

.cart-table-content table tbody > tr td.product-wishlist-cart > a {
  background-color: #232323;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  padding: 7px 8px;
  text-transform: capitalize;
}

.cart-table-content table tbody > tr td.product-wishlist-cart > a:hover {
  background-color: #D98719;
}

.cart-shiping-update-wrapper {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #DBDBDB;
  padding: 18px 0 0;
  margin: 20px 0 0;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper {
    display: block;
  }
}

.cart-shiping-update-wrapper .discount-code {
  position: relative;
  flex: 0 0 50%;
}

.cart-shiping-update-wrapper .discount-code input {
  padding: 0 110px 0 0;
  border: none;
  height: 34px;
  font-size: 14px;
  color: #211E1C;
  background-color: transparent;
  border-bottom: 1px solid #D8D8D8;
}

.cart-shiping-update-wrapper .discount-code button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  padding: 0;
  background-color: transparent;
  font-weight: 400;
  font-size: 14px;
  color: #181818;
  transition: all .3s ease 0s;
}

.cart-shiping-update-wrapper .discount-code button:hover {
  color: #D98719;
}

.cart-shiping-update-wrapper .cart-clear a {
  display: inline-block;
  margin: 3px 0 0 0px;
  color: #181818;
}

.cart-shiping-update-wrapper .cart-clear a:hover {
  color: #D98719;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper .cart-clear {
    margin-top: 20px;
  }
}

.grand-total-wrap {
  background: #F9F9F9;
  padding: 40px 30px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .grand-total-wrap {
    padding: 40px 15px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grand-total-wrap {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .grand-total-wrap {
    margin-top: 40px;
    padding: 40px 20px 0;
  }
}

.grand-total-wrap > h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.grand-total-wrap .grand-total-content {
  margin: 24px 0 6px;
}

.grand-total-wrap .grand-total-content .single-grand-total {
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px;
}

.grand-total-wrap .grand-total-content .single-grand-total:last-child {
  margin: 0 0 0;
}

.grand-total-wrap .grand-total-content .single-grand-total .single-grand-total-left {
  margin-right: 34px;
}

.grand-total-wrap .grand-total-content .single-grand-total .single-grand-total-left span {
  color: #181818;
}

.grand-total-wrap .grand-total-content .single-grand-total .single-grand-total-right > span {
  color: #2f2f2f;
}

.grand-total-wrap .grand-total-content .single-grand-total .single-grand-total-right > ul li {
  display: flex;
  font-size: 14px;
  color: #2f2f2f;
  margin: 0 0 8px;
  justify-content: flex-end;
}

.grand-total-wrap .grand-total-content .single-grand-total .single-grand-total-right > ul li:last-child {
  margin: 0 0 0;
}

.grand-total-wrap .grand-total-content .single-grand-total .single-grand-total-right > ul li span {
  font-weight: 600;
  margin-left: 3px;
}

@media only screen and (max-width: 767px) {
  .grand-total-wrap .grand-total-content .single-grand-total .single-grand-total-right > ul li span {
    margin-left: 2px;
  }
}

.grand-total-wrap .grand-total-content .single-grand-total .single-grand-total-right > ul li a {
  color: #2f2f2f;
}

.grand-total-wrap .cart-total-wrap {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #DBDBDB;
  padding: 20px 0 0 0;
  margin: 20px 0 37px 0;
}

.grand-total-wrap .cart-total-wrap .single-cart-total-left {
  margin-right: 34px;
}

.grand-total-wrap .cart-total-wrap .single-cart-total-left span {
  color: #181818;
}

.grand-total-wrap .cart-total-wrap .single-cart-total-right span {
  color: #2f2f2f;
}

.grand-total-wrap .grand-btn {
  margin-left: -30px;
  margin-right: -30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .grand-total-wrap .grand-btn {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .grand-total-wrap .grand-btn {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.grand-total-wrap .grand-btn a {
  background-color: #181818;
  color: #fff;
  text-align: center;
  font-size: 14px;
  display: block;
  padding: 18px 10px 19px;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .grand-total-wrap .grand-btn a {
    padding: 14px 10px 15px;
  }
}

.grand-total-wrap .grand-btn a:hover {
  background-color: #D98719;
}

.cart-free-shipping {
  position: relative;
  margin: 0 0 22px;
  background-color: #ff7433;
}

.cart-free-shipping:before {
  display: block;
  height: 100%;
  position: absolute;
  color: #fff;
  width: 50%;
  background: #3c3;
  z-index: 5;
  content: "";
  left: 0;
}

.cart-free-shipping span {
  color: #fff;
  display: block;
  position: relative;
  z-index: 7;
  padding: 3px 10px 5px;
}

.cart-free-shipping span svg {
  width: 1.5em;
  height: 1.5em;
  margin-right: 5px;
}

.cart-free-shipping span > img {
  width: 21px;
  margin-right: 5px;
}

.cart-area {
  padding: 120px 0 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-area {
    padding: 80px 0 80px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-area {
    padding: 60px 0 60px;
  }
}

/*-------- 27. Checkout style ---------*/
.customer-zone {
  margin: 0 0 55px;
}

@media only screen and (max-width: 767px) {
  .customer-zone {
    margin: 0 0 35px;
  }
}

.customer-zone > h4 {
  margin: 0;
  color: #181818;
  font-size: 24px;
  font-family: "Playfair Display", serif;
  border-bottom: 1px solid #ebebeb;
  padding: 0 0 22px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .customer-zone > h4 {
    font-size: 20px;
  }
}

.customer-zone > h4 a {
  color: #181818;
  font-style: italic;
}

.customer-zone > h4 a:hover {
  color: #D98719;
}

.checkout-login-info-2 {
  display: block;
  margin-top: 30px;
  margin-bottom: 55px;
  margin: 21px 260px 0;
  text-align: center;
}

.checkout-login-info-2 p {
  font-size: 16px;
  color: #6d6d6d;
  margin: 0 0 13px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .checkout-login-info-2 {
    margin: 21px 100px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .checkout-login-info-2 {
    margin: 21px 50px 0;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-login-info-2 {
    margin: 21px 0px 0;
  }
}

.checkout-login-info-2 form input[type="text"] {
  border: 1px solid #ebebeb;
  height: 50px;
  background-color: transparent;
  width: 54%;
  color: #6d6d6d;
  font-size: 14px;
  padding: 0 20px;
}

.checkout-login-info-2 form input[type="text"]:focus {
  border: 1px solid #262626;
}

.checkout-login-info-2 form input[type="submit"] {
  background: #181818 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  height: 50px;
  margin-left: 3px;
  padding: 5px 30px 7px;
  text-transform: capitalize;
  width: inherit;
  font-size: 14px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .checkout-login-info-2 form input[type="submit"] {
    padding: 5px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-login-info-2 form input[type="submit"] {
    padding: 5px 10px;
    margin-left: 5px;
  }
}

.checkout-login-info-2 form input[type="submit"]:hover {
  background-color: #D98719;
}

.billing-info-wrap h3 {
  font-size: 28px;
  position: relative;
  margin: 0 0 33px;
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap h3 {
    font-size: 24px;
    margin: 0 0 20px;
  }
}

.billing-info-wrap .billing-info label {
  display: block;
  margin: 0 0 10px;
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap .billing-info label {
    margin: 0 0 5px;
  }
}

.billing-info-wrap .billing-info input {
  border: 1px solid #ebebeb;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #6d6d6d;
}

.billing-info-wrap .billing-info input:focus {
  border: 1px solid #bbb;
}

.billing-info-wrap .billing-info input.billing-address {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap .billing-info.mb-25 {
    margin-bottom: 15px;
  }
}

.billing-info-wrap .billing-select label {
  display: block;
  margin: 0 0 10px;
  color: #181818;
}

.billing-info-wrap .billing-select .select2-container {
  display: block;
}

.billing-info-wrap .billing-select .select2-container .select2-choice {
  border: 1px solid #ebebeb;
  -moz-appearance: none;
  height: 50px;
  padding: 2px 20px;
  color: #6d6d6d;
  cursor: pointer;
}

.billing-info-wrap .billing-select .select2-container .select2-choice .select2-chosen {
  height: 50px;
}

.billing-info-wrap .additional-info-wrap {
  margin: 26px 0 0;
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap .additional-info-wrap {
    margin: 16px 0 0;
  }
}

.billing-info-wrap .additional-info-wrap label {
  display: block;
  margin: 0 0 10px;
  padding: 5px 0 0;
}

.billing-info-wrap .additional-info-wrap textarea {
  min-height: 150px;
  background-color: transparent;
  border: 1px solid #ebebeb;
  padding: 20px;
  color: #6d6d6d;
}

.select2-container--default .select2-selection--single {
  border: 1px solid #e6e6e6;
  border-radius: 0px;
  cursor: pointer;
  display: block;
  height: 50px;
  user-select: none;
  -webkit-user-select: none;
  line-height: 50px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  height: 50px;
  color: #262626;
  line-height: 50px;
  padding: 0px 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 50px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  height: 34px;
  background-color: transparent;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #0073aa;
  color: #fff;
}

.your-order-area {
  margin: 68px 0 0;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .your-order-area {
    margin: 30px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .your-order-area {
    margin: 30px 0 0;
  }
}

.your-order-area h3 {
  font-size: 28px;
  position: relative;
  margin: 0 0 23px;
}

@media only screen and (max-width: 767px) {
  .your-order-area h3 {
    margin: 0 0 20px;
  }
}

.your-order-area .your-order-wrap .your-order-info {
  border-bottom: 1px solid #e7e7e7;
}

.your-order-area .your-order-wrap .your-order-info ul li {
  color: #181818;
  font-size: 16px;
}

.your-order-area .your-order-wrap .your-order-info ul li.order-info-product {
  padding: 0 0 10px;
}

.your-order-area .your-order-wrap .your-order-info ul li span {
  float: right;
  color: #181818;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal ul li span {
  font-size: 16px;
}

.your-order-area .your-order-wrap .your-order-info.order-total {
  padding: 12px 0 12px;
}

.your-order-area .your-order-wrap .your-order-info.order-total ul li span {
  font-size: 24px;
  color: #181818;
  font-weight: 600;
}

.your-order-area .your-order-wrap .your-order-middle {
  border-bottom: 1px solid #e7e7e7;
  padding: 16px 0 17px;
}

.your-order-area .your-order-wrap .your-order-middle .single-order-middle {
  display: flex;
  justify-content: space-between;
  padding: 0 0 16px;
  margin: 0 0 14px;
  border-bottom: 1px solid #ebebeb;
}

.your-order-area .your-order-wrap .your-order-middle .single-order-middle:last-child {
  margin: 0 0 0px;
  border-bottom: none;
  padding: 0 0 0px;
}

.your-order-area .your-order-wrap .your-order-middle .single-order-middle .single-order-content h5 {
  margin: 0;
  color: #181818;
  font-size: 16px;
}

.your-order-area .your-order-wrap .your-order-middle .single-order-middle .single-order-content h5 > span {
  color: #181818;
  font-size: 14px;
}

.your-order-area .your-order-wrap .your-order-middle .single-order-middle .single-order-content > span {
  color: #181818;
  font-size: 12px;
  display: block;
  line-height: 1;
  margin: 6px 0 0;
}

.your-order-area .your-order-wrap .your-order-middle .single-order-middle .single-order-price span {
  color: #181818;
  font-size: 16px;
}

.your-order-area .your-order-wrap .checkout-shipping-content {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e7e7e7;
  margin-top: 13px;
  padding-bottom: 7px;
}

.your-order-area .your-order-wrap .checkout-shipping-content .shipping-content-left {
  margin-right: 34px;
}

.your-order-area .your-order-wrap .checkout-shipping-content .shipping-content-right ul li {
  display: flex;
  color: #262626;
  margin: 0 0 5px;
  text-transform: uppercase;
  justify-content: flex-end;
}

.your-order-area .your-order-wrap .checkout-shipping-content .shipping-content-right ul li input {
  width: auto;
  height: auto;
  margin-right: 5px;
}

.your-order-area .your-order-wrap .payment-method {
  margin: 34px 0 0;
}

.your-order-area .your-order-wrap .payment-method .sin-payment {
  margin-bottom: 18px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment:last-child {
  margin-bottom: 0;
}

.your-order-area .your-order-wrap .payment-method .sin-payment input {
  width: auto;
  display: inline-block;
  float: left;
  height: auto;
  margin-top: 5px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment label {
  font-size: 16px;
  text-transform: capitalize;
  color: #181818;
  margin: 0px 0 0 21px;
  display: flex;
  align-items: center;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .your-order-area .your-order-wrap .payment-method .sin-payment label {
    display: block;
  }
}

.your-order-area .your-order-wrap .payment-method .sin-payment label img {
  max-width: 140px;
  display: inline-block;
  margin: 0 5px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment label a {
  text-decoration: underline;
}

.your-order-area .your-order-wrap .payment-method .sin-payment .payment-box {
  margin: 6px 0 0;
  display: none;
}

.your-order-area .your-order-wrap .payment-method .sin-payment .payment-box p {
  font-size: 13px;
  margin: 0;
  color: #181818;
}

.your-order-area .your-order-wrap .payment-method .sin-payment.sin-payment-3 input {
  margin-top: 18px;
}

.your-order-area .your-order-wrap .condition-wrap {
  margin: 30px 0 0;
}

.your-order-area .your-order-wrap .condition-wrap p {
  color: #181818;
  line-height: 25px;
  margin: 0;
}

.your-order-area .your-order-wrap .condition-wrap p a {
  color: #181818;
}

.your-order-area .your-order-wrap .condition-wrap p a:hover {
  color: #D98719;
}

.your-order-area .Place-order {
  margin-top: 27px;
}

.your-order-area .Place-order a {
  background-color: #181818;
  display: block;
  cursor: pointer;
  padding: 17px 50px 17px;
  width: 100%;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

.your-order-area .Place-order a:hover {
  background-color: #D98719;
}

.checkout-account {
  margin: 25px 0 0;
}

@media only screen and (max-width: 767px) {
  .checkout-account {
    margin: 10px 0 0;
  }
}

.checkout-account input {
  border: 1px solid #bbb;
  display: inline-block;
  float: left;
  height: 10px;
  width: 10px;
  position: relative;
  top: 6px;
}

.checkout-account span {
  color: #181818;
  font-weight: 400;
  margin: 0 0 0 12px;
}

.different-address {
  display: none;
}

.checkout-main-area {
  padding: 120px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .checkout-main-area {
    padding: 80px 0;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-main-area {
    padding: 60px 0;
  }
}

/*-------- 28. Compare style ---------*/
.compare-page-area {
  padding: 120px 0;
}

@media only screen and (max-width: 767px) {
  .compare-page-area {
    padding: 60px 0;
  }
}

.compare-table .table tbody tr th {
  vertical-align: middle;
}

.compare-table .table tbody tr th.first-column {
  min-width: 205px;
  margin: 0;
  padding: 15px 15px;
  font-size: 15px;
  font-weight: 600;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr th.first-column {
    min-width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr th.first-column {
    min-width: 150px;
    padding: 10px 20px;
  }
}

.compare-table .table tbody tr:first-child th {
  vertical-align: bottom;
}

.compare-table .table tbody tr td {
  text-align: center;
  padding: 10px 15px;
  vertical-align: middle;
  border-color: #ebebeb;
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 293px;
  vertical-align: bottom;
  padding: 10px 10px 19px;
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 220px;
  }
}

.compare-table .table tbody tr td.product-image-title .compare-remove a {
  font-size: 12px;
  color: #181818;
  text-transform: capitalize;
}

.compare-table .table tbody tr td.product-image-title .compare-remove a i {
  padding-right: 2px;
  position: relative;
  top: 2px;
}

.compare-table .table tbody tr td.product-image-title .compare-remove a:hover {
  color: #D98719;
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin: 10px 0 6px;
  display: block;
}

.compare-table .table tbody tr td.product-image-title .image img {
  max-width: 100%;
}

.compare-table .table tbody tr td.product-image-title .category {
  float: left;
  clear: both;
  color: #D98719;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.compare-table .table tbody tr td.product-image-title .pro-title {
  display: block;
  overflow: hidden;
}

.compare-table .table tbody tr td.product-image-title .pro-title > a {
  float: left;
  clear: both;
  font-size: 14px;
  text-transform: capitalize;
  margin-top: 5px;
  display: block;
  width: 100%;
  line-height: 1;
}

.compare-table .table tbody tr td.product-image-title .pro-title > a:hover {
  color: #D98719;
}

.compare-table .table tbody tr td.product-image-title .compare-btn {
  margin: 11px 0 0;
}

.compare-table .table tbody tr td.product-image-title .compare-btn a {
  background-color: #181818;
  padding: 5px 14px 6px;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}

.compare-table .table tbody tr td.product-image-title .compare-btn a:hover {
  background-color: #D98719;
}

.compare-table .table tbody tr td.pro-desc p {
  margin: 0;
  font-size: 14px;
  line-height: 25px;
  color: #181818;
}

.compare-table .table tbody tr td.pro-price {
  font-size: 14px;
  font-weight: 400;
  color: #181818;
}

.compare-table .table tbody tr td.pro-sku, .compare-table .table tbody tr td.pro-weight {
  font-size: 14px;
  font-weight: 400;
  color: #181818;
}

.compare-table .table tbody tr td.pro-stock p {
  font-size: 14px;
  font-weight: 400;
  color: #181818;
  display: inline-block;
  line-height: 1;
}

.compare-table .table tbody tr td.pro-dimensions {
  font-size: 14px;
  font-weight: 400;
  color: #181818;
}

/*------ end Compare Page Wrapper -----*/
/*-------- 29. My account style ---------*/
.my-account-area {
  padding: 120px 0 117px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .my-account-area {
    padding: 80px 0 77px;
  }
}

@media only screen and (max-width: 767px) {
  .my-account-area {
    padding: 60px 0 57px;
  }
}

.myaccount-tab-menu {
  display: flex;
  justify-content: center;
  width: 100%;
  background: #f7f7f7;
}

@media only screen and (max-width: 767px) {
  .myaccount-tab-menu {
    padding: 12px 10px 7px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .myaccount-tab-menu {
    padding: 15px 10px 5px;
  }
}

.myaccount-tab-menu a {
  font-weight: 600;
  font-size: 16px;
  display: inline-block;
  padding: 13px 30px 12px;
  text-transform: capitalize;
  float: left;
  margin: 0 3px 0 3px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .myaccount-tab-menu a {
    padding: 13px 25px 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .myaccount-tab-menu a {
    font-size: 14px;
    padding: 10px 13px 9px;
  }
}

@media only screen and (max-width: 767px) {
  .myaccount-tab-menu a {
    padding: 4px 6px;
    font-size: 14px;
    margin: 0 2px 3px 2px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .myaccount-tab-menu a {
    font-size: 13px;
    padding: 4px 10px;
    margin: 0 2px 5px 2px;
  }
}

.myaccount-tab-menu a:hover, .myaccount-tab-menu a.active {
  background-color: #D98719;
  border-color: #D98719;
  color: #ffffff;
}

#myaccountContent {
  margin-top: 30px;
}

.myaccount-content p {
  color: #535353;
}

.myaccount-content form {
  margin-top: -20px;
}

.myaccount-content .welcome {
  margin: 0 0 29px;
}

.myaccount-content .welcome p {
  color: #535353;
}

.myaccount-content .welcome p a {
  color: #535353;
}

.myaccount-content .welcome p a:hover {
  color: #D98719;
}

.myaccount-content .welcome strong {
  color: #535353;
}

.myaccount-content > p {
  color: #535353;
}

.myaccount-content fieldset {
  margin-top: 20px;
}

.myaccount-content fieldset legend {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.myaccount-content .account-details-form {
  margin-top: 50px;
}

.myaccount-content .account-details-form .single-input-item {
  margin-bottom: 25px;
}

.myaccount-content .account-details-form .single-input-item label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.myaccount-content .account-details-form .single-input-item label span {
  color: red;
}

.myaccount-content .account-details-form .single-input-item input {
  border: 1px solid #CDCDCD;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #262626;
  font-size: 13px;
}

.myaccount-content .account-details-form .single-input-item input:focus {
  border: 1px solid #262626;
}

.myaccount-content .account-details-form .single-input-item button {
  border: none;
  background-color: #262626;
  padding: 17px 32px;
  color: #fff;
  font-size: 14px;
}

.myaccount-content .account-details-form .single-input-item button:hover {
  background-color: #D98719;
}

.myaccount-table {
  white-space: nowrap;
  font-size: 14px;
}

.myaccount-table table th,
.myaccount-table .table th {
  padding: 10px;
  font-weight: 600;
  background-color: #f8f8f8;
  border-color: #ccc;
  border-bottom: 0;
  color: #1f2226;
}

.myaccount-table table td,
.myaccount-table .table td {
  padding: 10px;
  vertical-align: middle;
  border-color: #ccc;
}

.saved-message {
  background-color: #fff;
  border-top: 3px solid #D98719;
  font-size: 14px;
  padding: 20px 0;
  color: #333;
}

/*-------- 30. Login register style ---------*/
.login-register-wrap {
  margin: 0 0 31px;
}

.login-register-wrap:last-child {
  margin-bottom: 0;
}

.login-register-wrap h3 {
  font-size: 24px;
  margin: 0;
  font-family: "Playfair Display", serif;
  cursor: pointer;
}

.login-register-wrap h3 i {
  margin-right: 5px;
  position: relative;
  top: 2px;
}

.login-register-wrap .login-register-form {
  margin: 15px 0 0;
}

@media only screen and (max-width: 767px) {
  .login-register-wrap .login-register-form {
    margin: 10px 0 0;
  }
}

.login-register-wrap .login-register-form .sin-login-register {
  margin: 0 0 16px;
}

.login-register-wrap .login-register-form .sin-login-register label {
  font-size: 16px;
  color: #6d6d6d;
  margin: 0 0 10px;
  display: block;
}

.login-register-wrap .login-register-form .sin-login-register input {
  background: transparent;
  border: 1px solid #ebebeb;
  height: 50px;
  padding: 5px 10px;
}

.login-register-wrap .login-register-form .sin-login-register input:focus {
  border: 1px solid #bbb;
}

.login-register-wrap .login-register-form .login-register-remember input {
  width: 16px;
  height: auto;
  position: relative;
  top: 1px;
}

.login-register-wrap .login-register-form .login-register-remember label {
  line-height: 1;
  margin: 0 0 0 9px;
  font-size: 16px;
  color: #6d6d6d;
}

.login-register-wrap .login-register-form .lost-password > a {
  font-size: 16px;
  color: #D98719;
}

.login-register-wrap .login-register-form .lost-password > a:hover {
  text-decoration: underline;
}

.login-register-wrap .login-register-form p {
  color: #989898;
  font-size: 12px;
  margin: 0 0 16px;
}

.login-register-wrap .login-register-form p a {
  color: #D98719;
}

.login-register-wrap .login-register-form p a:hover {
  text-decoration: underline;
}

.login-register-btn {
  margin: 10px 0 18px;
}

.login-register-btn button {
  border: none;
  background-color: #181818;
  color: #fff;
  font-size: 14px;
  padding: 18px 40px 18px;
  line-height: 1;
  display: block;
  padding: 18px 40px 20px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .login-register-btn button {
    padding: 14px 30px 17px;
  }
}

.login-register-btn button:hover {
  background-color: #D98719;
}

.vendor-customar-wrap {
  margin: 0 0 23px;
}

.vendor-customar-wrap label {
  display: flex;
  margin: 0 0 22px;
  color: #6d6d6d;
  font-size: 16px;
  line-height: 1;
}

.vendor-customar-wrap label input {
  width: auto;
  height: auto;
  margin-right: 5px;
}

.register-wrap .login-register-form {
  display: none;
}

.register-wrap .login-register-form p {
  font-size: 14px;
  line-height: 28px;
  margin: 30px 0 15px;
  color: #6d6d6d;
}

.register-wrap .login-register-form p a {
  color: #6d6d6d;
}

.register-wrap .login-register-form p a:hover {
  color: #D98719;
}

.login-register-area {
  padding: 120px 0;
}

@media only screen and (max-width: 767px) {
  .login-register-area {
    padding: 60px 0;
  }
}

.login-register-mrg {
  margin: 0 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login-register-mrg {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-register-mrg {
    margin: 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .login-register-mrg {
    margin: 0 0px;
  }
}

.vendor-customar-active {
  display: none;
}

/*-------- 31. Wishlist style ---------*/
.wishlist-table-content table {
  width: 100%;
}

.wishlist-table-content table thead > tr {
  border-bottom: 1px solid #BEBEBE;
}

.wishlist-table-content table thead > tr th {
  border-top: medium none;
  font-size: 16px;
  text-transform: capitalize;
  vertical-align: middle;
  white-space: nowrap;
  font-weight: 600;
  text-transform: capitalize;
  padding: 0 0 9px 0;
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content table thead > tr th {
    padding: 0 50px 12px 50px;
  }
}

.wishlist-table-content table tbody > tr {
  border-bottom: 1px solid #BEBEBE;
}

.wishlist-table-content table tbody > tr td {
  font-size: 14px;
  padding: 40px 0px 40px;
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content table tbody > tr td {
    padding: 20px 0px 20px;
    text-align: center;
  }
}

.wishlist-table-content table tbody > tr td.product-remove-2 {
  width: 30px;
}

.wishlist-table-content table tbody > tr td.product-remove-2 a {
  color: #181818;
}

.wishlist-table-content table tbody > tr td.product-remove-2 a:hover {
  color: #D98719;
}

.wishlist-table-content table tbody > tr td.product-img-2 {
  width: 90px;
}

.wishlist-table-content table tbody > tr td.product-img-2 a {
  display: block;
  padding-right: 20px;
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content table tbody > tr td.product-img-2 a {
    padding-right: 0px;
  }
}

.wishlist-table-content table tbody > tr td.product-img-2 a img {
  max-width: 100%;
}

.wishlist-table-content table tbody > tr td.product-name-2 {
  width: 340px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .wishlist-table-content table tbody > tr td.product-name-2 {
    width: 240px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content table tbody > tr td.product-name-2 {
    width: 180px;
  }
}

.wishlist-table-content table tbody > tr td.product-name-2 a {
  font-size: 16px;
  display: block;
}

.wishlist-table-content table tbody > tr td.product-name-2 a:hover {
  color: #D98719;
}

.wishlist-table-content table tbody > tr td.product-name-2 span {
  display: block;
  color: #181818;
}

.wishlist-table-content table tbody > tr td.product-stock {
  width: 242px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content table tbody > tr td.product-stock {
    width: 140px;
  }
}

.wishlist-table-content table tbody > tr td.product-stock span {
  color: #181818;
  font-size: 16px;
}

.wishlist-table-content table tbody > tr td.product-price-2 {
  width: 123px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content table tbody > tr td.product-price-2 {
    width: 100px;
  }
}

.wishlist-table-content table tbody > tr td.product-price-2 span {
  color: #181818;
  font-size: 16px;
}

.wishlist-table-content table tbody > tr td.product-wishlist-cart {
  text-align: right;
}

.wishlist-table-content table tbody > tr td.product-wishlist-cart > a {
  background-color: #181818;
  color: #fff;
  font-size: 14px;
  line-height: 1;
  padding: 11px 17px;
  text-transform: capitalize;
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content table tbody > tr td.product-wishlist-cart > a {
    font-size: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content table tbody > tr td.product-wishlist-cart > a {
    font-size: 12px;
    padding: 11px 10px;
  }
}

.wishlist-table-content table tbody > tr td.product-wishlist-cart > a:hover {
  background-color: #D98719;
}

.wishlist-area {
  padding: 112px 0 120px;
}

@media only screen and (max-width: 767px) {
  .wishlist-area {
    padding: 60px 0 60px;
  }
}
