
/*-------- 7. Product style ---------*/


.product-wrap {
    position: relative;
    .product-img {
        position: relative;
        overflow: hidden;
        z-index: 2;
        > a {
            > img {
                width: 100%;
                &.hover-img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 1;
                    opacity: 0;
                    transition: opacity 0.5s ease, transform 2s cubic-bezier(0, 0, 0.44, 1.18), -webkit-transform 2s cubic-bezier(0, 0, 0.44, 1.18);
                }
            }
        }
        .product-action-wrap {
            position: absolute;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            bottom: 35px;
            z-index: 9;
            > button {
                border: none;
                padding: 0;
                background-color: $white;
                font-size: 20px;
                color: #181818;
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                border-radius: 100%;
                position: relative;
                margin: 0 4px;
                opacity: 0;
                visibility: hidden;
                transform: translate(0,40px);
                transition-delay: 100ms;
                @media #{$xl-layout} {
                    margin: 0 3px;
                }
                @media #{$lg-layout} {
                    margin: 0 3px;
                    font-size: 18px;
                }
                @media #{$esm-layout} {
                    margin: 0 3px;
                    font-size: 15px;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                }
                i {
                    line-height: 40px;
                    @media #{$esm-layout} {
                        line-height: 33px;
                    }
                }
                &:nth-child(1) {
                    transition-delay: 100ms;
                }
                &:nth-child(2) {
                    transition-delay: 150ms;
                }
                &:nth-child(3) {
                    transition-delay: 200ms;
                }
                &:nth-child(4) {
                    transition-delay: 200ms;
                }
                &:hover {
                    background-color: #181818;
                    color: $white;
                }
                > span {
                    position: absolute;
                    top: -30px;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: $black;
                    padding: 0px 12px 4px;
                    font-size: 11px;
                    color: $white;
                    white-space: pre;
                    line-height: 22px;
                    opacity: 0;
                    visibility: hidden;
                }
                &:hover {
                    > span {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            &.product-action-border {
                > button {
                    border: 1px solid #ebebeb;
                    &:hover {
                        border: 1px solid #181818;
                    }
                }
            }
        }
        .shop-list-quickview {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            text-align: center;
            z-index: 99;
            margin-top: 20px;
            visibility: hidden;
            opacity: 0;
            transition: all .35s ease 0s;
            > button {
                border: none;
                padding: 0;
                width: 46px;
                height: 46px;
                border-radius: 100%;
                background-color: #fff;
                color: #181818;
                display: inline-block;
                transition: all .3s ease 0s;
                font-size: 22px;
                i {
                    line-height: 46px;
                }
                > span {
                    position: absolute;
                    top: -30px;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: $black;
                    padding: 0px 12px 0px;
                    font-size: 9px;
                    color: $white;
                    white-space: pre;
                    line-height: 22px;
                    opacity: 0;
                    visibility: hidden;
                }
                &:hover {
                    background-color: #181818;
                    color: #fff;
                    > span {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
        &.product-img-border {
            border: 1px solid #ebebeb;
        }
        &.product-border-radius {
            border-radius: 32px 32px 32px 32px;
        }
        .product-badge {
            position: absolute;
            display: inline-block;
            font-size: 12px;
            color: $white;
            z-index: 91;
            line-height: 1;
            padding: 4px 15px 6px;
            &.bg-black {
                background-color: #1D1D1D;
            }
            &.pro-badge-right {
                right: 0;
            }
            &.pro-badge-top {
                top: 20px;
            }
        }
        .product-price-5 {
            position: absolute;
            bottom: 6px;
            left: 15px;
            span {
                font-size: 20px;
                color: $theme-color-pink;
                display: inline-block;
                font-family: $noto;
                &.old-price {
                    text-decoration: line-through;
                    margin-left: 5px;
                }
            }
        }
        &.product-list-overly {
            &::before {
                content: "";
                opacity: 0;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                transition: all 250ms ease-out;
                z-index: 9;
                background-color: #181818;
                pointer-events: none;
            }
        }
    }
    .product-content {
        h4 {
            font-size: 15px;
            margin: 0 0 10px;
            @media #{$xs-layout} {
                line-height: 1.4;
            }
            &.pro-content-noto-font {
                font-size: 18px;
                font-family: $noto;
                line-height: 1.4;
                @media #{$lg-layout} {
                    font-size: 16px;
                }
                @media #{$xs-layout} {
                    font-size: 16px;
                }
            }
            a {
                color: #6D6D6D;
                &:hover {
                    color: $theme-color-yellow;
                }
            }
        }
        .product-price {
            span {
                font-size: 16px;
                color: #181818;
                &.old-price {
                    text-decoration: line-through;
                    color: #6D6D6D;
                    margin-right: 5px;
                }
            } 
        }
        &.product-content-white {
            h4 {
                a {
                    color: $white;
                    &:hover {
                        color: $theme-color-yellow;
                    }
                }
            }
            .product-price {
                span {
                    color: $white;
                    &.old-price {
                        color: $white;
                    }
                } 
            }
        }
    }
    .product-content-3 {
        h4 {
            font-size: 18px;
            line-height: 1.4;
            margin: 0 0 10px;
            @media #{$lg-layout} {
                font-size: 16px;
            }
            @media #{$esm-layout} {
                font-size: 16px;
                margin: 0 0 6px;
            }
            a {
                color: #211E1C;
                &:hover {
                    color: $theme-color-yellow-2;
                    &.pink {
                        color: $theme-color-pink;
                    }
                    &.yellow {
                        color: $theme-color-yellow;
                    }
                }
            }
        }
        .product-price-2 {
            span {
                font-size: 18px;
                color: #181818;
                font-weight: 600;
                @media #{$lg-layout} {
                    font-size: 16px;
                }
                @media #{$esm-layout} {
                    font-size: 16px;
                }
                &.old-price {
                    color: #6D6D6D;
                    font-weight: 400;
                    text-decoration: line-through;
                    margin-right: 5px;
                }
                &.font-width-400 {
                    font-weight: 400;
                }
            }
        }
    }
    .product-content-4 {
        h4 {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 10px;
            font-family: $montserrat;
            @media #{$esm-layout} {
                font-size: 16px;
                line-height: 1.4;
            }
            a {
                color: #211E1C;
                &:hover {
                    color: $theme-color-red;
                }
            }
        }
        .product-price-3 {
            span {
                color: $theme-color-red;
                font-weight: 600;
                font-family: $montserrat;
                display: inline-block;
                font-size: 16px;
                &.old-price {
                    color: #6D6D6D;
                    font-weight: 400;
                    text-decoration: line-through;
                    margin-right: 2px;
                }
            }
        }
    }
    .product-content-5 {
        h4 {
            font-size: 16px;
            margin: 0 0 9px;
            color: #211E1C;
            a {
                color: #211E1C;
                &:hover {
                    color: $theme-color-pink;
                }
            }
        }
        .product-price-4 {
            span {
                display: inline-block;
                font-size: 16px;
                font-weight: 600;
                color: $theme-color-pink;
                &.old-price {
                    text-decoration: line-through;
                    margin-right: 3px;
                    color: #6d6d6d;
                }
            }
        }
    }
    .product-content-7 {
        h4 {
            font-size: 20px;
            margin: 0 0 9px;
            color: #211E1C;
            @media #{$sm-layout} {
                font-size: 17px;
            }
            @media #{$esm-layout} {
                font-size: 17px;
            }
            a {
                color: #211E1C;
                &:hover {
                    color: $theme-color-green;
                }
            }
        }
        .product-price-4 {
            span {
                display: inline-block;
                font-size: 16px;
                color: #181818;
                font-weight: 600;
                &.old-price {
                    text-decoration: line-through;
                    margin-right: 3px;
                    color: #6d6d6d;
                }
            }
        }
    }
    .product-content-9 {
        h4 {
            font-size: 16px;
            line-height: 24px;
            font-family: $noto;
            margin: 0;
            padding: 0 8px;
            @media #{$esm-layout} {
                font-size: 15px;
            }
            a {
                color: #211E1C;
                &:hover {
                    color: $theme-color-pink;
                }
            }
        }
    }
    &:hover {
        .product-img {
            a {
                img {
                    &.hover-img {
                        opacity: 1;
                    }
                }
            }
            .product-action-wrap { 
                button {
                    opacity: 1;
                    visibility: visible;
                    transform: translate(0,0);
                }
            }
            &.product-list-overly {
                &::before {
                    opacity: .5;
                }
            }
        }
    }
    @media #{$md-layout} {
        &.mb-50 {
            margin-bottom: 30px;
        }
    }
    @media #{$xs-layout} {
        &.mb-50 {
            margin-bottom: 30px;
        }
    }
}

.tooltip > {
    .tooltip-inner {
        background-color: #000;
        padding: 2px 12px 5px;
        border-radius: 0px;
        font-size: 12px;
    }
}
.bs-tooltip-top {
    .arrow::before {
        display: none;
    }
}

.product-wrap-2 {
    padding: 48px 20px 42px;
    box-shadow: 0 0 16px 0 rgba(0,0,0,.2);
    border: 4px solid transparent;
    transition: all .3s ease 0s;
    @media #{$lg-layout} {
        padding: 38px 10px 32px;
    }
    .product-content-2 {
        h3 {
            font-size: 22px;
            text-transform: uppercase;
            line-height: 1;
            margin: 0;
            @media #{$lg-layout} {
                font-size: 18px;
            }
            a {
                color: #211E1C;
                position: relative;
                padding: 0 0 14px;
                display: block;
                &:before {
                    position: absolute;
                    content: "";
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: 0 auto;
                    background-color: #D3D3D3;
                    width: 90px;
                    height: 2px;
                }
            }
        }
    }
    .product-img-2 {
        text-align: center;
        margin-top: 39px;
        a {
            display: inline-block;
            img {
                max-width: 100%;
            }
        }
    }
    &:hover {
        border: 4px solid $theme-color-yellow-2;
    }
}

.product-categori-list {
    padding: 45px 45px 45px 45px;
    box-shadow: 0 2px 25px 0 #E7E7E7;
    @media #{$xx-layout} {
        padding: 45px 25px 45px 25px;
    }
    @media #{$xl-layout} {
        padding: 45px 25px 45px 25px;
    }
    @media #{$lg-layout} {
        padding: 35px 20px 35px 20px;
    }
    @media #{$xs-layout} {
        padding: 35px 20px 35px 20px;
    }
    ul {
        li {
            a {
                font-size: 18px;
                color: #6D6D6D;
                display: block;
                padding: 18px 0px 20px;
                border-bottom: 1px solid #D8D8D8;
                @media #{$xx-layout} {
                    font-size: 16px;
                }
                @media #{$xl-layout} {
                    font-size: 16px;
                }
                @media #{$lg-layout} {
                    font-size: 16px;
                }
                @media #{$xs-layout} {
                    font-size: 16px;
                }
                &.active {
                    color: $theme-color-yellow-2;
                }
                &:hover {
                    color: $theme-color-yellow-2;
                }
            }
        }
    }
}

.product-phn-content {
    h2 {
        color: $white;
        font-size: 46px;
        font-weight: 500;
        margin: 0;
        @media #{$md-layout} {
            font-size: 35px;
        }
        @media #{$xs-layout} {
            font-size: 32px;
        }
    }
    p {
        color: $white;
        font-size: 18px;
        line-height: 36px;
        margin: 10px auto 28px;
        width: 70%;
        @media #{$lg-layout} {
            width: 100%;
        }
        @media #{$md-layout} {
            width: 100%;
            font-size: 16px;
            line-height: 30px;
            margin: 10px auto 10px;
        }
        @media #{$xs-layout} {
            width: 100%;
            font-size: 16px;
            line-height: 30px;
            margin: 10px auto 20px;
        }
    }
}
.product-bg-area {
    overflow: hidden;
    @media #{$xs-layout} {
        padding: 50px 0 0;
    }
}

.product-phn-img {
    text-align: center;
    margin: 0 100px -83px;
    padding-top: 57px;
    @media #{$lg-layout} {
        margin: 0 0px -83px;
    }
    @media #{$md-layout} {
        margin: 0 0px -83px;
        padding-top: 40px;
    }
    @media #{$xs-layout} {
        margin: 0 0px -83px;
        padding-top: 40px;
    }
    @media #{$sm-layout} {
        margin: 0 50px -83px;
    }
    img {
        max-width: 100%;
    }
}

.product-bg-area-2 {
	padding: 40px 0;
    .product-radio-content {
        @media #{$xs-layout} {
            margin-bottom: 20px;
        }
        h2 {
            font-size: 44px;
            font-weight: 500;
            line-height: 1.4em;
            margin: 0 auto 31px;
            width: 70%;
            @media #{$xx-layout} {
                width: 78%;
                font-size: 42px;
                line-height: 1.2em;
            }
            @media #{$xl-layout} {
                width: 80%;
                font-size: 40px;
                line-height: 1.1em;
            }
            @media #{$lg-layout} {
                width: 80%;
                font-size: 35px;
                line-height: 1.1em;
            }
            @media #{$md-layout} {
                font-size: 26px;
                line-height: 1.1em;
                margin: 0 auto 20px;
            }
            @media #{$xs-layout} {
                font-size: 26px;
                line-height: 1.2em;
                margin: 0 auto 12px;
                width: 100%;
            }
        }
    }
    .product-radio-img {
        text-align: center;
        img {
            max-width: 100%;
        }
    }
}
.common-column {
	position: relative;
	display: flex;
	padding-left: 15px;
	padding-right: 15px;
    width: 100%;
    &.product-width-82 {
        flex: 0 0 82%;
        max-width: 82%;
        @media #{$lg-layout} {
            flex: 0 0 70%;
            max-width: 70%;
        }
        @media #{$md-layout} {
            flex: 0 0 60%;
            max-width: 60%;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}
.no-gutters > [class*="common-"] {
	padding-right: 0;
	padding-left: 0;
}
.product-auto-parts-wrap {
	position: relative;
    @media #{$xs-layout} {
        &.mb-120 {
            margin-bottom: 60px;
        }
    }
    .product-auto-parts-img {
        height: 100%;
        padding: 0;
        position: absolute;
        top: 0;
        width: 18%;
        z-index: 9;
        @media #{$lg-layout} {
            width: 30%;
        }
        @media #{$md-layout} {
            width: 40%;
        }
        @media #{$xs-layout} {
            width: 100%;
            position: static;
        }
        .auto-parts-banner-content {
            position: absolute;
            top: 15%;
            left: 0;
            right: 0;
            text-align: center;
            z-index: 9;
            padding: 0 10px;
            @media #{$xs-layout} {
                top: 10%;
            }
            h2 {
                color: $white;
                font-size: 36px;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 1px;
                line-height: 1.4em;
                margin: 0;
                @media #{$xx-layout} {
                    font-size: 27px;
                }
                @media #{$xl-layout} {
                    font-size: 25px;
                }
                @media #{$lg-layout} {
                    font-size: 30px;
                }
                @media #{$md-layout} {
                    font-size: 30px;
                }
                @media #{$xs-layout} {
                    font-size: 30px;
                }
            }
        }
        img {
            height: 100%;
            transition: all 0.3s linear 0s;
            will-change: transform, top;
            z-index: 9;
            position: relative;
            width: 100%;
            object-fit: cover;
        }
    }
}

.tab-product-wrap {
	width: 100%;
    box-shadow: 0 0 8px 0 rgba(0,0,0,.2);
    padding: 54px 50px 81px 50px;
    @media #{$lg-layout} {
        padding: 54px 30px 81px 30px;
    }
    @media #{$md-layout} {
        padding: 44px 30px 71px 30px;
    }
    @media #{$xs-layout} {
        padding: 54px 20px 54px 20px;
    }
}

.product-tab-list-1 {
    a {
        color: #6D6D6D;
        font-size: 18px;
        display: inline-block;
        margin-right: 32px;
        @media #{$md-layout} {
            font-size: 16px;
            margin-right: 12px;
            margin-bottom: 5px;
        }
        @media #{$xs-layout} {
            font-size: 16px;
            margin-right: 12px;
            margin-bottom: 5px;
        }
        &:last-child {
            margin-right: 0;
        }
        &.active {
            color: #181818;
            text-decoration: underline;
        }
    }
    @media #{$md-layout} {
        &.mb-40 {
            margin-bottom: 20px;
        }
    }
    @media #{$xs-layout} {
        &.mb-40 {
            margin-bottom: 20px;
        }
    }
}

.tab-content{
    &.jump {
        .tab-pane {
            display: block;
            height: 0;
            opacity: 0;
            overflow: hidden;
            &.active {
                display: block;
                height: auto;
                opacity: 1;
                overflow: visible;
                animation-name: moveUp;
                animation-duration: 500ms;
                animation-timing-function: cubic-bezier(0.26, 0.69, 0.37, 0.96);
                animation-play-state: running;
            }
        }
    }
}
.product-slider-active-1 {
    .slick-list {
        margin: 0 -15px;
        .product-wrap-lr-1 {
            padding: 0 15px;
        }
    }
}

.nav-style-3 {
    .product-icon-1 {
        position: absolute;
        top: 33%;
        z-index: 9;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #979797;
        border-radius: 100%;
        text-align: center;
        color: #181818;
        font-size: 12px;
        transition: all .3s ease 0s;
        opacity: 0;
        visibility: hidden;
        cursor: pointer;
        &:hover {
            color: $white;
            border: 1px solid #181818;
            background-color: #181818;
        }
        &.product-icon-prev {
            left: -15px;
        }
        &.product-icon-next {
            right: -20px;
        }
    }
    &:hover {
        .product-icon-1 {
            opacity: 1;
            visibility: visible;
        }
    }
}

.trending-product-wrap {
    position: relative;
    .trending-product-img {
        position: relative;
        overflow: hidden;
        a {
            display: block;
            img {
                width: 100%;
            }
        }
    }
    .img-zoom {
        a {
            img {
                transition: all 2s;
                transform: scale(1);
            }
        }
    }
    .trending-product-content {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        bottom: 50px;
        z-index: 9;
        transition: all .3s ease 0s;
        @media #{$xs-layout} {
            bottom: 20px;
        }
        a {
            display: inline-block;
            background-color: $white;
            color: #181818;
            font-size: 24px;
            font-weight: 500;
            letter-spacing: -1px;
            font-family: $noto;
            padding: 25px 70px 28px;
            @media #{$xx-layout} {
                padding: 20px 50px 23px;
                font-size: 22px;
            }
            @media #{$xl-layout} {
                padding: 20px 40px 23px;
                font-size: 22px;
            }
            @media #{$lg-layout} {
                padding: 20px 30px 23px;
                font-size: 20px;
            }
            @media #{$md-layout} {
                padding: 20px 30px 23px;
                font-size: 20px;
            }
            @media #{$xs-layout} {
                padding: 15px 30px 15px;
                font-size: 18px;
            }
        }
    }
    &:hover {
        .trending-product-content {
            bottom: 70px;
            @media #{$xs-layout} {
                bottom: 40px;
            }
            a {
                background-color: $theme-color-yellow;
                color: $white;
            }
        }
        .img-zoom {
            a {
                img {
                    transform: scale(1.1);
                }
            }
        }
        &.default-overlay {
            &.overly-point-1-hover {
                &:before {
                    opacity: .1;
                    pointer-events: none;
                    background-color: $black;
                    transition: all .35s ease 0s;
                }
            }
        }
    }
}

.product-tab-list-2 {
    justify-content: center;
    a {
        color: #727272;
        font-size: 24px;
        margin-right: 60px;
        @media #{$lg-layout} {
            font-size: 20px;
            margin-right: 40px;
        }
        @media #{$md-layout} {
            font-size: 20px;
            margin-right: 35px;
        }
        @media #{$xs-layout} {
            font-size: 18px;
            margin-right: 15px;
            margin-bottom: 5px;
        }
        &:last-child {
            margin-right: 0;
        }
        &.active , &:hover {
            color: $theme-color-yellow;
        }
    }
    @media #{$xs-layout} {
        &.mb-50 {
            margin-bottom: 25px;
        }
    }
}

.product-deal-content {
    border: 1px solid #979797;
    padding: 0 15px;
    .hot-deal {
        h1 {
            color: #D98719;
            font-family: $noto;
            font-size: 50px;
            font-weight: 500;
            line-height: 1.4em;
            letter-spacing: -2px;
            display: inline-block;
            margin: -40px 0 0;
            padding: 0 100px;
            background-color: #F9F9F9;
            @media #{$lg-layout} {
                font-size: 40px;
                line-height: 1.2em;
            }
            @media #{$xs-layout} {
                font-size: 30px;
                letter-spacing: 0px;
                padding: 0 20px;
            }
        }
    }
    h3 {
        font-size: 36px;
        font-family: $noto;
        font-weight: 500;
        line-height: 1.4em;
        letter-spacing: -1px;
        margin: 24px 0 0px;
        @media #{$lg-layout} {
            font-size: 35px;
            line-height: 1.2em;
            margin: 14px 0 0px;
        }
        @media #{$xs-layout} {
            font-size: 20px;
            letter-spacing: 0px;
            margin: 10px 0 0px;
        }
    }
    h2 {
        color: #A30F0F;
        font-family: $noto;
        font-size: 46px;
        font-weight: 500;
        line-height: 1.4em;
        letter-spacing: -1px;
        display: block;
        @media #{$xs-layout} {
            font-size: 35px;
            letter-spacing: 0px;
        }
    }
    p {
        color: #6D6D6D;
        font-size: 18px;
        line-height: 2em;
        margin: 25px 0 35px;
        @media #{$xs-layout} {
            font-size: 14px;
            margin: 20px 0 30px;
        }
    }
    .deal-btn {
        display: inline-block;
        margin: 0px 0 -100px;
        padding: 0 100px;
        background-color: #F9F9F9;
        @media #{$xs-layout} {
            margin: 0px 0 -20px;
            padding: 0 20px;
        }
    }
}

.product-deal-img {
	margin: 0 -70px 0 70px;
    @media #{$lg-layout} {
        margin: 0 0px 0 0px;
    }
    @media #{$md-layout} {
        margin: 0 0px 30px 0px;
    }
    @media #{$xs-layout} {
        margin: 0 0px 30px 0px;
    }
    img {
        max-width: 100%;
    }
}

.cosmetic-single-pro-img {
    img {
        width: 100%; 
    }
    &:hover {
        animation-name: elementor-animation-bob-float,elementor-animation-bob;
        animation-duration: .3s,1.5s;
        animation-delay: 0s,.3s;
        animation-timing-function: ease-out,ease-in-out;
        animation-iteration-count: 1,infinite;
        animation-fill-mode: forwards;
        animation-direction: normal,alternate;
    }
}

.cosmetic-single-pro-content {
    &.cosmetic-single-pro-padding-1 {
        padding: 20px 20px 20px 70px;
        @media #{$lg-layout} {
            padding: 0px 0px 0px 0px; 
        }
        @media #{$md-layout} {
            padding: 20px 0px 0px 0px;  
        }
        @media #{$xs-layout} {
            padding: 20px 0px 0px 0px;  
        }
    }
    &.cosmetic-single-pro-padding-2 {
        padding: 20px 70px 20px 0px;
        @media #{$md-layout} {
            padding: 20px 0px 0px 0px;  
        }
        @media #{$xs-layout} {
            padding: 20px 0px 0px 0px;  
        }
    }
    h3 {
        color: $theme-color-pink;
        margin: 0;
        letter-spacing: -1px;
        font-size: 36px;
        font-style: italic;
        font-family: $playfair;
        @media #{$xs-layout} {
            font-size: 30px;
        }
    }
    h2 {
        letter-spacing: -2px;
        font-size: 60px; 
        font-family: $playfair;
        line-height: 1.33em;
        margin: 23px 0 66px;
        @media #{$lg-layout} {
            letter-spacing: -1px;
            font-size: 46px;
            margin: 20px 0 40px;
        }
        @media #{$md-layout} {
            letter-spacing: -1px;
            font-size: 39px; 
            margin: 10px 0 18px;
        }
        @media #{$xs-layout} {
            letter-spacing: 0px;
            font-size: 28px;
            margin: 8px 0 25px;
        }
    }
}

.btn-style-7 {
    a {
        display: inline-block;
        line-height: 1;
        border: 1px solid #979797;
        font-size: 18px;
        color: #181818;
        padding: 28px 53px 30px;
        &.padding-dec-1 {
            padding: 28px 44px 30px;
            @media #{$lg-layout} {
                padding: 20px 35px 22px;
            }
            @media #{$md-layout} {
                padding: 15px 30px 19px;
            }
            @media #{$xs-layout} {
                font-size: 14px;
                padding: 12px 22px 15px;
            }
        }
        @media #{$md-layout} {
            padding: 20px 40px 22px;
        }
        @media #{$xs-layout} {
            font-size: 14px;
            padding: 12px 22px 15px;
        }
        &.white {
            border: 2px solid $white;
            color: $white;
            &:hover {
                border: 2px solid $white;
                background-color: #fff;
                color: #000; 
            }
        }
        &:hover {
            border: 1px solid $theme-color-pink;
            background-color: $theme-color-pink;
            color: $white;
        }
    }
}

.cosmetic-img {
    position: relative;
    img {
        width: 100%;
    }
    h5 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        text-align: center;
        font-size: 20px;
        margin: 0;
        color: $white;
        font-weight: 500;
        @media #{$xs-layout} {
            font-size: 16px;
        }
        a {
            color: $white;
            &:hover {
                color: #181818;
            }
        }
    }
}

.product-btn {
    overflow: hidden;
    @media #{$xs-layout} {
        text-align: center;
    }
    a {
        display: inline-block;
        color: #211E1C;
        float: right;
        font-size: 16px;
        @media #{$xs-layout} {
            float: inherit;
        }
        &:hover {
            color: $theme-color-pink;
        }
    }
}

.product-tab-list-3 {
    justify-content: center;
    a {
        color: #181818;
        font-size: 36px;
        letter-spacing: 2px;
        font-family: $montserrat;
        display: inline-block;
        border-bottom: 2px solid transparent;
        padding-bottom: 19px;
        margin: 0 63px;
        @media #{$lg-layout} {
            font-size: 26px;
            margin: 0 30px;
        }
        @media #{$md-layout} {
            font-size: 20px;
            margin: 0 15px;
            padding-bottom: 10px;
        }
        @media #{$xs-layout} {
            font-size: 15px;
            margin: 0 10px 10px;
            padding-bottom: 3px;
            letter-spacing: 1px;
        }
        @media #{$sm-layout} {
            font-size: 18px;
            margin: 0 15px 10px;
        }
        &:hover {
            color: $theme-color-red;
        }
        &.active {
            border-bottom: 2px solid $theme-color-red;
        }
    }
    @media #{$md-layout} {
        &.mb-60 {
            margin-bottom: 30px;
        }
    }
    @media #{$xs-layout} {
        &.mb-60 {
            margin-bottom: 20px;
        }
    }
}

.product-tab-list-4 {
    justify-content: center;
    a {
        font-size: 18px;
        color: #6d6d6d;
        margin-right: 15px;
        margin-right: 60px;
        @media #{$lg-layout} {
            margin-right: 40px;
        }
        @media #{$md-layout} {
            margin-right: 18px;
            font-size: 17px;
        }
        @media #{$xs-layout} {
            margin-right: 13px;
            margin-bottom: 4px;
            font-size: 15px; 
        }
        &:last-child {
            margin-right: 0;
        }
        &.active {
            color: $theme-color-pink;
        }
    }
    @media #{$xs-layout} {
        &.mb-55 {
            margin-bottom: 20px; 
        }
    }
}

.product-btn-2 {
    text-align: center;
    margin-top: 13px;
    @media #{$xs-layout} {
        margin-top: 0px;
    }
    a {
        color: #181818;
        display: inline-block;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 2px;
        font-family: $montserrat;
        &:hover {
            color: $theme-color-red;
        }
    }
}

.categori-product-wrap {
    position: relative;
    overflow: hidden;
    border-radius: 22px 22px 22px 22px;
    a {
        display: block;
        img {
            width: 100%;
            transition: all 2s;
            transform: scale(1);
        }
    }
    .categori-title {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        text-align: center;
        z-index: 9;
        h3 {
            font-size: 42px;
            font-family: $pacifico;
            color: $white;
            margin: 0;
            @media #{$md-layout} {
                font-size: 35px;
            }
            @media #{$xs-layout} {
                font-size: 30px;
            }
            @media #{$sm-layout} {
                font-size: 25px;
            }
            a {
                color: $white;
            }
        }
    }
    &:hover {
        > a {
            img {
                transform: scale(1.1);
            }
        }
        &.default-overlay {
            &.overly-point-1-hover {
                &:before {
                    opacity: .1;
                    pointer-events: none;
                    background-color: $theme-color-pink;
                    transition: all .35s ease 0s;
                }
            }
        }
    }
}

.product-wrap-3-all {
    overflow: hidden;
    .product-wrap-3 {
        transition: all .3s;
        > a {
            display: block;
            img {
                width: 100%;
            }
        }
        .product-content-6 {
            margin: 25px 0 0;
            h3 {
                font-size: 26px;
                font-family: $playfair;
                margin: 0;
                @media #{$md-layout} {
                    font-size: 20px;
                }
                @media #{$xs-layout} {
                    font-size: 22px;
                }
                @media #{$esm-layout} {
                    font-size: 18px;
                }
                a {
                    font-family: $playfair;
                    color: #211E1C;
                    position: relative;
                    &::before {
                        width: 1.6em;
                        height: 1.6em;
                        display: inline-block;
                        content: '';
                        mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 45 45' width='45' height='45' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='currentColor' stroke-width='3' fill='none' fill-rule='evenodd' stroke-linecap='square'%3E%3Cpath d='M12.5 14.5c8.5-8.5 30-12 30-12s-2.9 20.9-12 30c-9.9 9.9-21.3 3.3-21.3 3.3s-6.6-11.4 3.3-21.3zM24.5 20.5l-22 22'/%3E%3C/g%3E%3C/svg%3E") no-repeat 50% 50%;
                        mask-size: cover;
                        background-color: $theme-color-green;
                        vertical-align: middle;
                        margin-top: -.2em;
                        margin-right: .7em;
                    }
                    &:hover { 
                        color: $theme-color-green;
                    }
                }
            }
        }
        .product-content-8 {
            h3 {
                font-size: 26px;
                font-weight: 500;
                margin: 18px 0 0;
                font-family: $noto;
                @media #{$xx-layout} {
                    font-size: 24px;
                }
                @media #{$xl-layout} {
                    font-size: 24px;
                }
                @media #{$lg-layout} {
                    font-size: 19px;
                }
                @media #{$md-layout} {
                    font-size: 22px;
                }
                @media #{$xs-layout} {
                    font-size: 19px;
                }
                @media #{$esm-layout} {
                    line-height: 1.4;
                }
                a {
                    color: #211E1C;
                    display: inline-block;
                    position: relative;
                    &:before {
                        position: absolute;
                        left: -20px;
                        top: 50%;
                        width: 10px;
                        height: 10px;
                        background-color: #211E1C;
                        transform: translateY(-50%) rotate(45deg);
                        margin-right: .5em;
                        content: "";
                    }
                    &:hover {
                        color: $theme-color-pink;
                    }
                }
            }
        }
    }
    &:hover {
        .product-wrap-3 {
            transform: translate(0,-20px);
        }
    }
}    

.product-categori-area {
    position: relative;
    overflow: hidden;
    .product-categori-banner {
        position: absolute;
        left: -56px;
        bottom: 100px;
        @media #{$xx-layout} {
            bottom: 200px; 
        }
        @media #{$xl-layout} {
            bottom: 0px;
            left: 0px;
        }
        @media #{$lg-layout} {
            bottom: 0px;
            left: 0px;
        }
        @media #{$md-layout} {
            bottom: 0px;
            left: 0px;
        }
        @media #{$xs-layout} {
            display: none;
        }
        img {
            width: 330px;
            @media #{$xx-layout} {
                width: 130px;
            }
            @media #{$xl-layout} {
                width: 70px;
            }
            @media #{$lg-layout} {
                width: 70px;
            }
            @media #{$md-layout} {
                width: 70px;
            }
        }
    }
    @media #{$md-layout} {
        &.pb-110 {
            padding-bottom: 40px;
        }
        &.pb-80 {
            padding-bottom: 40px;
        }
    }
    @media #{$xs-layout} {
        &.pb-110 {
            padding-bottom: 25px;
        }
        &.pb-80 {
            padding-bottom: 20px;
        }
    }
}
.product-btn-3 {
    a {
        font-size: 20px;
        color: #181818;
        @media #{$xs-layout} {
            font-size: 20px;
        }
        &:hover {
            color: $theme-color-green;
            text-decoration: underline;
        }
    }
    @media #{$xs-layout} {
        &.mt-10 {
            margin-top: 0px;
        }
    }
}

.jewelry-single-product-content {
    span {
        display: block;
        color: #181818;
        font-size: 18px;
        text-transform: uppercase;
    }
    h2 {
        font-size: 46px;
        letter-spacing: -1px;
        font-family: $noto;
        font-weight: 500;
        margin: 13px 0 0;
        @media #{$md-layout} {
            font-size: 40px;
        }
        @media #{$xs-layout} {
            font-size: 30px;
        }
    }
    p {
        font-size: 18px;
        line-height: 36px;
        color: #6d6d6d; 
        margin: 16px 0 68px;
        @media #{$xx-layout} {
            margin: 16px 0 48px;
        }
        @media #{$xl-layout} {
            margin: 16px 0 48px;
        }
        @media #{$lg-layout} {
            font-size: 16px;
            line-height: 33px;
            margin: 16px 0 40px;
        }
        @media #{$md-layout} {
            font-size: 15px;
            line-height: 30px;
            margin: 12px 0 25px;
        }
        @media #{$xs-layout} {
            font-size: 15px;
            line-height: 30px;
            margin: 12px 0 25px;
        }
    }
    @media #{$xl-layout} {
        &.pr-100 {
            padding-right: 50px;
        }
        &.pl-100 {
            padding-left: 50px;
        }
    }
    @media #{$lg-layout} {
        &.pr-100 {
            padding-right: 0px;
        }
        &.pl-100 {
            padding-left: 0px;
        }
    }
    @media #{$md-layout} {
        &.pr-100 {
            padding-right: 15px;
        }
        &.pl-100 {
            padding-left: 15px;
        }
    }
    @media #{$xs-layout} {
        margin-bottom: 30px;
        &.pr-100 {
            padding-right: 0px;
        }
        &.pl-100 {
            padding-left: 0px;
        }
    }
}

.btn-style-14 {
    a {
        display: inline-block;
        font-size: 16px;
        color: #6d6d6d;
        letter-spacing: 2px;
        text-transform: uppercase;
        border: 1px solid #979797;
        padding: 26px 54px;
        @media #{$lg-layout} {
            padding: 20px 40px;
        }
        @media #{$md-layout} {
            padding: 14px 30px;
            font-size: 14px
        }
        @media #{$xs-layout} {
            padding: 14px 30px;
            font-size: 14px
        }
        &:hover {
            border: 1px solid $theme-color-pink;
            background-color: $theme-color-pink;
            color: $white;
        }
    }
}

.jewelry-single-product-img {
    a {
        display: block;
        img {
            width: 100%;
        }
    }
    &:hover {
        a {
            img {
                animation-name: elementor-animation-bob-float,elementor-animation-bob;
                animation-duration: .3s,1.5s;
                animation-delay: 0s,.3s;
                animation-timing-function: ease-out,ease-in-out;
                animation-iteration-count: 1,infinite;
                animation-fill-mode: forwards;
                animation-direction: normal,alternate;
            }
        }
    }
    @media #{$md-layout} {
        &.ml-45 {
            margin-left: 15px;
        }
        &.mr-45 {
            margin-right: 15px;
        }
    }
    @media #{$xs-layout} {
        &.ml-45 {
            margin-left: 0px;
        }
        &.mr-45 {
            margin-right: 0px;
        }
    }
}

.jewelry-single-product-mb  {
    margin-bottom: 120px;
    @media #{$md-layout} {
        margin-bottom: 80px;
    }
    @media #{$xs-layout} {
        margin-bottom: 60px;
    }
}

.featured-product-contect {
    h3 {
        font-size: 32px;
        font-weight: 600;
        margin: 0;
        line-height: 1.4em;
        @media #{$md-layout} {
            font-size: 28px;
        }
        @media #{$xs-layout} {
            font-size: 23px;
        }
    }
    p {
        line-height: 28px;
        width: 80%;
        margin: 23px 0 51px;
        @media #{$lg-layout} {
            margin: 15px 0 35px;
        }
        @media #{$md-layout} {
            width: 100%;
            margin: 12px 0 30px; 
        }
        @media #{$xs-layout} {
            width: 100%;
            margin: 12px 0 25px;
        }
    }
}

.btn-style-15 {
    a {
        display: inline-block;
        font-weight: 600;
        font-size: 18px;
        color: $white;
        background-color: #F9792E;
        padding: 22px 50px;
        border-radius: 5px;
        @media #{$md-layout} {
            font-size: 16px;
            padding: 14px 30px;
        }
        @media #{$xs-layout} {
            font-size: 15px;
            padding: 12px 27px;
        }
        &:hover {
            background-color: #171717;
        }
    }
}

.featured-product-img-wrap {
    margin-left: 100px;
    @media #{$md-layout} {
        margin-left: 0px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
        margin-top: 30px;
    }
    .featured-product-img {
        position: relative;
        > img {
            border-radius: 50%;
            max-width: 100%;
        }
        .sub-featured-img {
            position: absolute;
            left: -111px;
            top: 19%;
            @media #{$md-layout} {
                top: 25%;
                left: 0px;
            }
            @media #{$xs-layout} {
                left: auto;
                right: 0;
            }
            img {
                width: 225px;
                @media #{$md-layout} {
                    width: 125px;
                }
                @media #{$xs-layout} {
                    width: 125px;
                }
            }
        }
    }
}

.featured-product-img-wrap-2 {
    margin-right: 100px;
    @media #{$md-layout} {
        margin-right: 0px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
        margin-top: 30px;
    }
    .featured-product-img-2 {
        position: relative;
        > img {
            border-radius: 50%;
            max-width: 100%;
        }
        .sub-featured-img-2 {
            position: absolute;
            right: -145px;
            top: 28%;
            @media #{$md-layout} {
                right: 0px;
                top: 36%;
            }
            @media #{$xs-layout} {
                right: 0px;
            }
            img {
                width: 290px;
                @media #{$md-layout} {
                    width: 160px;
                }
                @media #{$xs-layout} {
                    width: 160px;
                }
            }
        }
    }
}

.fp-content-2 {
    padding-left: 115px;
    @media #{$lg-layout} {
        padding-left: 70px;
    }
    @media #{$md-layout} {
        padding-left: 0px;
    }
    @media #{$xs-layout} {
        padding-left: 0px;
    }
    p {
        width: 94%;
        @media #{$xs-layout} {
            width: 100%;
        }
    }
}

.organic-featured-category-wrap {
    .organic-featured-category-img {
        margin-bottom: 10px;
        a {
            display: inline-block;
            img {
                max-width: 100%;
            }
        }
    }
    .organic-featured-category-content {
        h3 {
            font-size: 16px;
            font-weight: 600;
            margin: 0;
        }
    }
}

.featured-category-slider {
    .slick-list {
        margin: 0 -10px;
        .featured-category-padding {
            padding: 10px;
        }
    }
}

.organic-deal-img {
	margin: 0 27px;
    @media #{$md-layout} {
        margin: 0 0px;
    }
    a {
        display: block;
        img {
            width: 100%;
        }
    }
}

.organic-deal-content {
    h3 {
        font-size: 32px;
        font-weight: 600;
        margin: 0;
    }
    p {
        line-height: 28px;
        margin: 21px 0 21px;
        @media #{$md-layout} {
            margin: 12px 0 15px;
        }
        @media #{$xs-layout} {
            margin: 12px 0 15px;
        }
    }
    h2 {
        font-size: 54px;
        color: $theme-color-green-2;
        margin: 0 0 22px;
        font-family: $satisfy;
        @media #{$xs-layout} {
            font-size: 40px;
        }
    }
    @media #{$xs-layout} {
        margin-top: 15px;
    }
}

.nav-style-6 {
    > span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        color: #000;
        font-size: 15px;
        background-color: transparent;
        border: 1px solid #979797;
        border-radius: 50px;
        z-index: 9;
        cursor: pointer;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease 0s;
        @media #{$lg-layout} {
            width: 40px;
            height: 40px;
            line-height: 40px;
        }
        @media #{$md-layout} {
            width: 40px;
            height: 40px;
            line-height: 40px;
        }
        @media #{$xs-layout} {
            width: 40px;
            height: 40px;
            line-height: 40px;
        }
        &.deal-pro-icon-prev {
            left: -168px;
            @media #{$xx-layout} {
                left: -50px;
            }
            @media #{$xl-layout} {
                left: 10px;
            }
            @media #{$lg-layout} {
                left: 0px;
            }
            @media #{$md-layout} {
                left: -10px;
            }
            @media #{$xs-layout} {
                left: 0px;
            }
        }
        &.deal-pro-icon-next {
            right: -168px;
            @media #{$xx-layout} {
                right: -50px;
            }
            @media #{$xl-layout} {
                right: 10px;
            }
            @media #{$lg-layout} {
                right: 0px;
            }
            @media #{$md-layout} {
                right: -10px;
            }
            @media #{$xs-layout} {
                right: 0px;
            }
        }
        &:hover {
            background-color: $theme-color-yellow;
            color: $white;
            border: 1px solid $theme-color-yellow;
        }
    }
    &:hover {
        span {
            opacity: 1;
            visibility: visible;
        }
    }
}

.organic-deal-slider {
	margin: 0 -15px;
    @media #{$xl-layout} {
        margin: 0 0px;
    }
    @media #{$xs-layout} {
        margin: 0 0px;
    }
}
.feature-product-mb {
    margin-bottom: 120px;
    @media #{$md-layout} {
        margin-bottom: 80px;
    }
    @media #{$xs-layout} {
        margin-bottom: 60px;
    }
}

@media #{$lg-layout} {
    .deal-area {
        padding: 50px 0;
    }
}
@media #{$md-layout} {
    .product-area {
        &.pt-105 {
            padding-top: 35px;
        }
        &.pt-75 {
            padding-top: 35px;
        }
        &.pt-95 {
            padding-top: 55px;
        }
        &.pb-110 {
            padding-bottom: 40px;
        }
        &.pb-100 {
            padding-bottom: 25px;
        }
        &.pb-105 {
            padding-bottom: 30px;
        }
        &.pb-65 {
            padding-bottom: 20px;
        }
        &.pb-70 {
            padding-bottom: 30px;
        }
        &.pb-75 {
            padding-bottom: 35px;
        }
        &.pb-80 {
            padding-bottom: 40px;
        }
        &.pb-90 {
            padding-bottom: 50px;
        }
    }
    .deal-area {
        padding: 50px 0 95px;
    }
    .categori-area {
        &.pb-115 {
            padding-bottom: 50px;
        }
        &.pb-85 {
            padding-bottom: 50px;
        }
    }
    .deal-area-2 {
        &.pb-110 {
            padding-bottom: 70px;
        }
    }
    .featured-area {
        &.pt-125 {
            padding-top: 80px;
        }
    }
}

@media #{$xs-layout} {
    .product-area {
        &.pb-110 {
            padding-bottom: 20px;
        }
        &.pb-100 {
            padding-bottom: 20px;
        }
        &.pb-65 {
            padding-bottom: 20px;
        }
        &.pt-75 {
            padding-top: 15px;
        }
        &.pb-105 {
            padding-bottom: 20px;
        }
        &.pt-105 {
            padding-top: 20px;
        }
        &.pt-95 {
            padding-top: 35px;
        }
        &.pb-70 {
            padding-bottom: 15px;
        }
        &.pb-75 {
            padding-bottom: 20px;
        }
        &.pb-80 {
            padding-bottom: 20px;
        }
        &.pb-90 {
            padding-bottom: 35px;
        }
    }
    .deal-area {
        padding: 50px 0 95px;
    }
    .deal-area-2 {
        &.pb-85 {
            padding-bottom: 60px;
        }
    }
    .categori-area {
        &.pb-115 {
            padding-bottom: 30px;
        }
        &.pb-85 {
            padding-bottom: 30px;
        }
    }
    .single-organic-deal-product {
        text-align: center;
    }
    .deal-area-2 {
        &.pb-110 {
            padding-bottom: 50px; 
        }
    }
    .featured-area {
        &.pt-125 {
            padding-top: 60px;
        }
    }
    .featured-category-area {
        &.pb-105 {
            padding-bottom: 28px;
        }
    }
}

@media #{$esm-layout} {
    .product-responsive {
        .col-12 {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}

























