/*---------- 17. Subscribe style ------------*/

.subscribe-area {
    position: relative;
    .subscribe-shape-top {
        overflow: hidden;
        position: absolute;
        left: 0;
        width: 100%;
        line-height: 0;
        direction: ltr;
        top: -1px;
        transform: rotate(180deg);
        z-index: 2;
        pointer-events: none;
        svg {
            width: calc(100% + 1.3px);
            height: 48px;
            transform: translateX(-50%) rotateY(180deg);
            display: block;
            position: relative;
            left: 50%;
            path {
                fill: #fff;
                transform-origin: center;
                transform: rotateY(0deg);
            }
        }
    }
    .subscribe-shape-bottom {
        overflow: hidden;
        position: absolute;
        left: 0;
        width: 100%;
        line-height: 0;
        direction: ltr;
        bottom: -1px;
        transform: rotate(180deg);
        z-index: 2;
        pointer-events: none;
        svg {
            width: calc(100% + 1.3px);
            height: 48px;
            transform: translateX(-50%) rotateY(180deg);
            display: block;
            position: relative;
            left: 50%;
            path {
                fill: #fff;
                transform-origin: center;
                transform: rotateY(0deg);
            }
        }
    }
}

.subscribe-title {
    h2 {
        font-size: 32px;
        color: #211E1C;
        line-height: 1.4em;
        margin: 0;
        @media #{$lg-layout} {
            font-size: 25px;
        }
        @media #{$xs-layout} {
            font-size: 23px;
        }
    }
    @media #{$md-layout} {
        margin-bottom: 30px;
        text-align: center;
    }
    @media #{$xs-layout} {
        margin-bottom: 20px;
        text-align: center;
    }
}

.subscribe-form-3 {
    margin-left: 10px;
    form {
        position: relative;
        .mc-form-3 {
            input {
                height: 80px;
                background-color: $white;
                color: #181818;
                font-size: 16px;
                border: none;
                padding: 3px 150px 3px 30px;
                &::-webkit-input-placeholder {
                    color: #181818;
                    opacity: 1;
                }
                &::-moz-input-placeholder {
                    color: #181818;
                    opacity: 1;
                }
                @media #{$xs-layout} {
                    height: 60px;
                    padding: 3px 120px 3px 14px;
                }
            }
            .mc-news-3 {
                display: none;
            }
            .clear-3 {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                input {
                    width: auto;
                    font-weight: 600;
                    font-size: 16px;
                    color: $white;
                    background-color: #181818;
                    padding: 3px 30px;
                    &::-webkit-input-placeholder {
                        color: $white;
                        opacity: 1;
                    }
                    &::-moz-input-placeholder {
                        color: $white;
                        opacity: 1;
                    }
                    &:hover {
                        background-color: $theme-color-red;
                    }
                    @media #{$xs-layout} {
                        padding: 3px 15px;
                    }
                }
            }
        }
    }
}

.subscribe-form-4 {
    margin: 0 -10px;
    @media #{$xs-layout} {
        margin: 0 0px;
    }
    form {
        .mc-form-4 {
            position: relative;
            input {
                color: #211E1C;
                background-color: transparent;
                border: none;
                height: 50px;
                border-bottom: 1px solid #211E1C;
                padding: 2px 100px 2px 0;
                &.subscribe-4-modify-input {
                    font-size: 16px;
                }
            }
            .mc-news-4 {
                display: none;
            }
            .clear-4 {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                input {
                    width: auto;
                    padding: 0;
                    letter-spacing: .5px;
                    color: #211E1C;
                    font-weight: 600;
                    &.subscribe-4-modify-button {
                        font-weight: bold; 
                        font-size: 16px;
                        &:hover {
                            color: $theme-color-pink;
                        }
                    }
                }
            }
        }
    }
}

.subscribe-form-5 {
    form {
        .mc-form-5 {
            position: relative;
            input {
                height: 90px;
                color: #181818;
                background-color: $white;
                border-radius: 50px;
                border: none;
                font-size: 16px;
                padding: 2px 150px 2px 30px;
                @media #{$md-layout} {
                    height: 60px;
                }
                @media #{$xs-layout} {
                    height: 60px;
                    font-size: 14px;
                    padding: 2px 125px 2px 20px;
                }
                &::-moz-input-placeholder {
                    color: #181818;
                    opacity: 1;
                }
                &::-webkit-input-placeholder {
                    color: #181818;
                    opacity: 1;
                }
            }
            .mc-news-5 {
                display: none;
            }
            .clear-5 {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 30px;
                input {
                    padding: 0;
                    width: auto;
                    color: #262626;
                    font-size: 16px;
                    font-weight: 700;
                    @media #{$xs-layout} {
                        font-size: 15px;
                    }
                    &:hover {
                        color: $theme-color-pink;
                    }
                }
            }
        }
    }
}

.subscribe-form-6 {
    form {
        .mc-form-6 {
            position: relative;
            input {
                font-size: 18px;
                background-color: #E9EFEB;
                color: #181818;
                height: 80px;
                padding: 2px 150px 2px 30px;
                border: none;
                @media #{$xs-layout} {
                    padding: 2px 110px 2px 20px;
                    font-size: 15px;
                }
            }
            .mc-news-6 {
                display: none;
            }
            .clear-6 {
                position: absolute;
                right: 30px;
                top: 50%;
                transform: translateY(-50%);
                @media #{$xs-layout} {
                    right: 20px;
                }
                input {
                    color: #262626;
                    text-transform: uppercase;
                    padding: 0;
                    &:hover {
                        color: $theme-color-green;
                    }
                }
            }
        }
    }
}





@media #{$xs-layout} {
    .subscribe-area {
        &.pt-60 {
            padding-top: 30px;
        }
        &.pt-80 {
            padding-top: 55px;
        }
        &.pb-80 {
            padding-bottom: 60px;
        }
        &.pt-100 {
            padding-top: 52px;
        }
        &.pb-110 {
            padding-bottom: 60px;
        }
    }
}





















