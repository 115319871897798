/*-------- 26. Cart style ---------*/

.cart-main-area {
    @media #{$xs-layout}{
        &.pb-100 {
            padding-bottom: 60px;
        }
    }
}
h3.cart-page-title {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 15px;
}

.cart-table-content {
    table {
        width: 100%;
        thead > tr {
            border-bottom: 1px solid #DBDBDB;
            th {
                border-top: medium none;
                font-size: 16px;
                text-transform: capitalize;
                vertical-align: middle; 
                white-space: nowrap;
                color: #211e1c;
                font-weight: 600;
                text-transform: capitalize;
                padding: 0 0 9px 0;
                @media #{$lg-layout}{
                    padding: 0 0 33px 0;
                }
                @media #{$xs-layout}{
                    padding: 0 40px 12px 40px;
                }
            }
        }
        tbody > tr {
            td {
                font-size: 14px;
                padding: 20px 0px 20px;
                @media #{$xs-layout}{
                    padding: 20px 0px 20px; 
                    text-align: center; 
                }
            }
            td.product-remove {
                width: 30px;
                a {
                    color: #211E1C;
                    &:hover {
                        color: $theme-color-yellow;
                    }
                }
            }
            td.product-img {
                width: 90px;
                a {
                    display: block;
                    padding-right: 20px;
                    @media #{$xs-layout}{
                        padding-right: 0px;
                    }
                    img {
                        max-width: 100%; 
                    }
                }
            }
            td.product-name {
                width: 325px;
                @media #{$md-layout}{
                    width: 250px;
                }
                @media #{$xs-layout}{
                    width: 200px;
                    padding-right: 0px;
                }
                a {
                    font-size: 16px;
                    display: block;
                    &:hover {
                        color: $theme-color-yellow;
                    }
                }
                span {
                    display: block;
                    color: #6d6d6d;
                }
            }
            td.product-price {
                width: 90px;
                span {
                    font-size: 16px;
                }
            }
            td.cart-quality {
                width: 110px;
                
            }
            td.product-total {
                width: 70px;
                span {
                    color: #181818;
                    font-size: 16px;
                }
            }
            td.product-wishlist-cart {
                > a {
                    background-color: #232323;
                    color: #fff;
                    font-size: 12px;
                    line-height: 1;
                    padding: 7px 8px;
                    text-transform: capitalize;
                    &:hover {
                        background-color: $theme-color-yellow;
                    }
                }
            }
        }
    }
}
.cart-shiping-update-wrapper {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #DBDBDB;
    padding: 18px 0 0;
    margin: 20px 0 0;
    @media #{$xs-layout}{
        display: block;
    }
    .discount-code {
        position: relative;
        flex: 0 0 50%;
        input {
            padding: 0 110px 0 0;
            border: none;
            height: 34px;
            font-size: 14px;
            color: #211E1C;
            background-color: transparent;
            border-bottom: 1px solid #D8D8D8;
        }
        button {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            border: none;
            padding: 0;
            background-color: transparent;
            font-weight: 400;
            font-size: 14px;
            color: #181818;
            transition: all .3s ease 0s;
            &:hover {
                color: $theme-color-yellow;
            }
            
        }
    }
    .cart-clear {
        a {
            display: inline-block;
            margin: 3px 0 0 0px;
            color: #181818;
            &:hover {
                color: $theme-color-yellow;
            }
        }
        @media #{$xs-layout}{
            margin-top: 20px;
        }
    }
}

.grand-total-wrap {
    background: #F9F9F9;
    padding: 40px 30px 0;
    @media #{$lg-layout}{
        padding: 40px 15px 0;
    }
    @media #{$md-layout}{
        margin-top: 40px;
    }
    @media #{$xs-layout}{
        margin-top: 40px;
        padding: 40px 20px 0;
    }
    > h4 {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
    }
    .grand-total-content {
        margin: 24px 0 6px;
        .single-grand-total {
            display: flex;
            justify-content: space-between;
            margin: 0 0 20px;
            &:last-child {
                margin: 0 0 0;
            }
            .single-grand-total-left {
                margin-right: 34px;
                span {
                    color: #181818;
                }
            }
            .single-grand-total-right {
                > span {
                    color: #2f2f2f;
                }
                > ul {
                    li {
                        display: flex;
                        font-size: 14px;
                        color: #2f2f2f;
                        margin: 0 0 8px;
                        justify-content: flex-end;
                        &:last-child {
                            margin: 0 0 0;
                        }
                        span {
                            font-weight: 600;
                            margin-left: 3px;
                            @media #{$xs-layout}{
                                margin-left: 2px;
                            }
                        }
                        a {
                            color: #2f2f2f;
                        }
                    }
                }
            }
        }
    }
    .cart-total-wrap {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #DBDBDB;
        padding: 20px 0 0 0;
        margin: 20px 0 37px 0;
        .single-cart-total-left {
            margin-right: 34px;
            span {
                color: #181818;
            }
        }
        .single-cart-total-right {
            span {
                color: #2f2f2f;
            }
        }
    }
    .grand-btn {
        margin-left: -30px;
        margin-right: -30px;
        @media #{$lg-layout}{
            margin-left: -15px; 
            margin-right: -15px;
        }
        @media #{$xs-layout}{
            margin-left: -20px; 
            margin-right: -20px;
        }
        a {
            background-color: #181818;
            color: #fff;
            text-align: center;
            font-size: 14px;
            display: block;
            padding: 18px 10px 19px;
            text-transform: uppercase;
            @media #{$xs-layout}{
                padding: 14px 10px 15px;
            }
            &:hover {
                background-color: $theme-color-yellow;
            }
        }
    }
}

.cart-free-shipping {
    position: relative;
    margin: 0 0 22px;
    background-color: #ff7433;
    &:before {
        display: block;
        height: 100%;
        position: absolute;
        color: #fff;
        width: 50%;
        background: #3c3;
        z-index: 5;
        content: "";
        left: 0;
    }
    span {
        color: #fff;
        display: block;
        position: relative;
        z-index: 7;
        padding: 3px 10px 5px;
        svg {
            width: 1.5em;
            height: 1.5em;
            margin-right: 5px;
        }
        > img {
            width: 21px;
            margin-right: 5px;
        }
    }
}

.cart-area {
    padding: 120px 0 120px;
    @media #{$md-layout}{
        padding: 80px 0 80px;
    }
    @media #{$xs-layout}{
        padding: 60px 0 60px;
    }
}






