
/*-------- 19. Contact us style ---------*/

.contact-area {
	position: relative;
}

.contact-us-social {
    margin: 60px 0 40px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media #{$xs-layout} {
        margin: 30px 0 30px;
    }
    a {
        font-size: 58px;
        color: $theme-color-pink;
        margin-right: 100px;
        @media #{$md-layout} {
            font-size: 45px;
            margin-right: 70px;
        }
        @media #{$xs-layout} {
            font-size: 30px;
            margin-right: 30px;
        }
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            color: #000;
        }
    }
}

.contact-us-content {
    p {
        font-size: 18px;
        color: #6D6D6D;
        line-height: 36px;
        margin: 0 auto;
        width: 38%;
        @media #{$lg-layout} {
            width: 50%;
        }
        @media #{$md-layout} {
            width: 70%;
        }
        @media #{$xs-layout} {
            width: 100%;
            font-size: 16px;
            line-height: 30px;
        }
    }
}

.contact-form-wrap {
    form {
        .single-contact-form {
            input , textarea {
                background-color: transparent;
                border: none;
                border-bottom: 1px solid #181818;
                color: #181818;
                height: 46px;
                padding: 2px 0;
                &::-moz-input-placeholder {
                    color: #181818;
                    opacity: 1;
                }
                &::-webkit-input-placeholder {
                    color: #181818;
                    opacity: 1;
                }
            }
            textarea {
                height: 150px; 
                margin-bottom: 30px;
                @media #{$lg-layout} {
                    height: 100px; 
                }
                @media #{$xs-layout} {
                    height: 100px; 
                }
            }
            button {
                font-size: 18px;
                background-color: #181818;
                color: $white;
                display: inline-block;
                line-height: 1;
                padding: 27px 35px 30px;
                border: none;
                @media #{$xs-layout} {
                    padding: 20px 35px 23px;
                }
                &:hover {
                    background-color: $theme-color-pink;
                    &.yellow {
                        background-color: $theme-color-yellow;
                    }
                }
            }
        }
    }
}

#contact-map-2 {
    height: 400px;
    @media #{$xs-layout} {
        height: 250px;
    }
}

#contact-map-3 {
    height: 100%
}

#contact-map-4 {
    height: 410px;
    @media #{$xs-layout} {
        height: 250px;
    }
}

.contact-map-area-3 {
	height: 100%;
	padding: 0;
	position: absolute;
	top: 0;
	width: 50%;
	z-index: 9;
    @media #{$md-layout} {
        position: static;
        width: 100%;
        height: 400px;
    }
    @media #{$xs-layout} {
        position: static;
        width: 100%;
        height: 250px;
    }
}

.contact-form-padding {
    padding: 182px 100px 170px 100px;
    @media #{$xx-layout} {
        padding: 132px 50px 120px 50px;
    }
    @media #{$xl-layout} {
        padding: 112px 50px 100px 50px;
    }
    @media #{$lg-layout} {
        padding: 80px 50px 100px 50px;
    }
    @media #{$md-layout} {
        padding: 80px 50px 100px 50px;
    }
    @media #{$xs-layout} {
        padding: 50px 20px 60px 20px;
    }
}

.contact-form-wrap-2 {
    margin-right: 25px;
    @media #{$md-layout} {
        margin-right: 0px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
    }
    .contact-form-title {
        margin: 0 0 35px;
        @media #{$xs-layout} {
            margin: 0 0 25px;
        }
        h1 {
            font-size: 32px;
            font-weight: 600;
            margin: 0 0 17px;
            line-height: 25px;
            @media #{$xs-layout} {
                margin: 0 0 8px;
                font-size: 27px;
            }
        }
    }
    .contact-form-style-2 {
        input , select , textarea {
            height: 80px;
            border: 1px solid #ebebeb;
            font-size: 16px;
            padding: 2px 30px;
            color: #6d6d6d;
            background-color: transparent;
            margin-bottom: 20px;
            &:focus {
                border: 1px solid #bbb;
            }
            @media #{$md-layout} {
                height: 70px;
            }
            @media #{$xs-layout} {
                height: 60px;
                font-size: 15px;
                padding: 2px 20px;
            }
        }
        textarea {
            height: 200px;
            padding: 25px 30px;
            @media #{$md-layout} {
                height: 170px;
            }
            @media #{$xs-layout} {
                height: 100px;
                padding: 15px 20px;
            }
        }
        button {
            &.submit {
                border: none;
                background-color: #181818;
                text-transform: uppercase;
                color: #fff;
                text-align: center;
                width: 100%;
                padding: 28px 10px;
                transition: all .35s ease 0s;
                @media #{$xs-layout} {
                    padding: 20px 10px;
                }
                &:hover {
                    background-color: $theme-color-yellow;
                }
            }
        }
    }
}

.contact-info-area {
    margin-left: 25px;
    @media #{$md-layout} {
        margin-top: 50px;
        margin-left: 0px;
    }
    @media #{$xs-layout} {
        margin-top: 30px;
        margin-left: 0px;
    }
    ul {
        li {
            display: flex;
            align-items: center;
            margin: 0 0 50px;
            @media #{$md-layout} {
                margin: 0 0 30px;
            }
            @media #{$xs-layout} {
                margin: 0 0 20px;
            }
            &:last-child {
                margin: 0 0 0px;
            }
            i {
                font-size: 29px;
                margin-right: 30px;
                color: #DD6B33;
                @media #{$xs-layout} {
                    font-size: 25px;
                    margin-right: 20px;
                }
            }
            span {
                font-size: 16px;
                color: #6d6d6d;
                line-height: 20px;
            }
            a {
                display: flex;
                align-items: center;
                font-size: 16px;
                color: #6d6d6d;
                span {
                    color: #6d6d6d;
                    &:hover {
                        color: #DD6B33;
                    }
                }
            }
        }
    }
}

.contact-info-social {
    margin: 62px 0 0;
    @media #{$md-layout} {
        margin: 30px 0 0;
    }
    @media #{$xs-layout} {
        margin: 20px 0 0;
    }
    a {
        font-size: 18px;
        color: #000;
        margin: 0 20px 0px 0;
        display: inline-block;
        &:last-child {
            margin: 0 0px 0px 0;
        }
        &:hover {
            color: #DD6B33;
        }
    }
}

.contact-form-wrap-3 {
	padding: 130px 100px 150px;
    @media #{$xx-layout} {
        padding: 80px 50px 100px;
    }
    @media #{$xl-layout} {
        padding: 70px 40px 90px;
    }
    @media #{$lg-layout} {
        padding: 50px 30px 80px;
    }
    @media #{$md-layout} {
        padding: 50px 30px 80px;
    }
    @media #{$xs-layout} {
        padding: 60px 15px 60px;
    }
    .contact-form-style-3 {
        input , select , textarea {
            height: 54px;
            border: none;
            border-bottom: 2px solid #181818;
            font-size: 18px;
            color: #6d6d6d;
            padding: 0;
            background-color: transparent;
            margin: 0 0 30px;
            @media #{$xs-layout} {
                margin: 0 0 20px;
                font-size: 15px;
                height: 45px;
            }
        }
        textarea {
            height: 150px;
            @media #{$lg-layout} {
                height: 120px;
            }
            @media #{$md-layout} {
                height: 120px;
            }
            @media #{$xs-layout} {
                height: 100px;
            }
        }
        button {
            &.submit-2 {
                border: none;
                background-color: #181818;
                font-size: 18px;
                color: #fff;
                text-align: center;
                width: 100%;
                padding: 28px 10px;
                transition: all .35s ease 0s;
                @media #{$lg-layout} {
                    padding: 20px 10px;
                }
                @media #{$md-layout} {
                    padding: 20px 10px;
                }
                @media #{$xs-layout} {
                    padding: 18px 10px;
                    font-size: 16px;
                }
                &:hover {
                    background-color: $theme-color-yellow;
                }
            }
        }
    }
    .contact-info-social-2 {
        display: flex;
        justify-content: center;
        margin: 80px 0 0;
        @media #{$xx-layout} {
            margin: 40px 0 0;
        }
        @media #{$xl-layout} {
            margin: 40px 0 0;
        }
        @media #{$lg-layout} {
            margin: 40px 0 0;
        }
        @media #{$md-layout} {
            margin: 30px 0 0;
        }
        @media #{$xs-layout} {
            margin: 30px 0 0;
        }
        a {
            font-size: 24px;
            margin-right: 30px;
            color: #000;
            &:last-child {
                margin-right: 0;
            }
            &:hover {
                color: #DD6B33;
            }
        }
    }
}

.contact-info-area-2 {
	padding: 120px 0 87px;
    @media #{$md-layout} {
        padding: 80px 0 47px;
    }
    @media #{$xs-layout} {
        padding: 60px 0 27px;
    }
}
.single-contact-info-2 {
    .contact-info-2-icon {
        margin: 0 0 25px;
        @media #{$md-layout} {
            margin: 0 0 12px;
        }
        @media #{$xs-layout} {
            margin: 0 0 7px;
        }
        i {
            font-size: 40px;
            color: $theme-color-yellow;
            @media #{$xs-layout} {
                font-size: 30px;
            }
        }
    }
    .contact-info-2-content {
        h4 {
            font-size: 18px;
            margin: 0 0 25px;
            @media #{$md-layout} {
                margin: 0 0 12px;
            }
            @media #{$xs-layout} {
                margin: 0 0 7px;
            }
        }
        p {
            font-size: 16px;
            line-height: 28px;
            margin: 0 0 0px;
        }
    }
}





@media #{$xs-layout} {
    .contact-area {
        &.pt-85 {
            padding-top: 60px;
        }
        &.pb-100 {
            padding-bottom: 60px;
        }
    }
    .contact-us-area {
        &.pt-120 {
            padding-top: 60px;
        }
    }
    .contact-page-map {
        &.pt-120 {
            padding-top: 60px;
        }
    }
}


