
/*-------- 16. Brand logo style ---------*/

.single-brand-logo {
    display: flex;
    justify-content: center;
    img {
        width: 160px;
        opacity: .5;
        transition: all .25s ease-out;
        filter: grayscale(100);
        @media #{$lg-layout} {
            width: 120px;
        }
        @media #{$xs-layout} {
            width: 100px;
        }
    }
    &.sb-logo-modify {
        a {
            img {
                opacity: inherit;
                filter: grayscale(60);
            }
        }
    }
    &:hover {
        img {
            opacity: 1;
            filter: grayscale(0);
        }
    }
}

.single-brand-logo-2 {
    display: flex;
    justify-content: center;
    display: block;
    img {
        max-width: 100%;
    }
}

.single-brand-logo-3 {
    text-align: center;
    img {
        max-width: 100%;
    }
}

.single-brand-logo-4 {
    text-align: center;
    img {
        width: 140px;
        @media #{$lg-layout} {
            width: 120px;
        }
    }
}
.brand-logo-ptb-1 {
    padding: 108px 0 85px;
    @media #{$md-layout} {
        padding: 70px 0 50px;
    }
    @media #{$xs-layout} {
        padding: 50px 0 30px;
    }
}

.single-brand-logo-5 {
    display: flex;
    justify-content: center;
    a {
        display: block; 
        img {
            max-width: 100%;
            transition: all .25s ease-out;
            opacity: .6;
        }
    }
    &:hover {
        a {
            img {
                opacity: 1; 
            }
        }
    }
}

@media #{$md-layout} {
    .brand-logo-area {
        &.pb-60 {
            padding-bottom: 20px;
        }
        &.pb-90 {
            padding-bottom: 50px;
        }
    }
}
   

@media #{$xs-layout} {
    .brand-logo-area {
        &.pb-85 {
            padding-bottom: 30px;
        }
        &.pb-60 {
            padding-bottom: 0px;
        }
        &.pb-90 {
            padding-bottom: 30px;
        }
        &.pb-110 {
            padding-bottom: 30px; 
        }
        &.pt-100 {
            padding-top: 50px;
        }
        &.pt-110 {
            padding-top: 50px;
        }
    }
}
   



