
/*-------- 22. Shop style ---------*/

.shop-pl-35 {
    padding-left: 35px;
    @media #{$xx-layout} {
        padding-left: 0px;
    }
    @media #{$xl-layout} {
        padding-left: 0px;
    }
    @media #{$lg-layout} {
        padding-left: 0px;
    }
    @media #{$md-layout} {
        padding-left: 0px;
    }
    @media #{$xs-layout} {
        padding-left: 0px;
    }
}
.shop-pr-35 {
    padding-right: 35px;
    @media #{$xx-layout} {
        padding-right: 0px;
    }
    @media #{$xl-layout} {
        padding-right: 0px;
    }
    @media #{$lg-layout} {
        padding-right: 0px;
    }
    @media #{$md-layout} {
        padding-right: 0px;
    }
    @media #{$xs-layout} {
        padding-right: 0px;
    }
}

.shop-top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0px 0 40px;
    @media #{$xs-layout} {
        display: block;
        text-align: center;
    }
    .shop-filter {
        line-height: 1;
        position: relative;
        @media #{$xs-layout} {
            display: inline-block;
        }
        a {
            color: #181818;
            i {
                font-size: 12px;
                position: relative;
                top: 3px;
                color: #6d6d6d;
                margin-left: 12px;
                transition: all .4s ease 0s;
                @media #{$xs-layout} {
                    font-size: 10px;
                    top: 1px;
                    margin-left: 3px;
                }
                &.angle-up {
                    position: absolute;
                    right: 1px;
                    top: 3px;
                    opacity: 0;
                    visibility: hidden;
                }
            }
            &.active {
                color: $theme-color-yellow;
                i {
                    color: $theme-color-yellow;
                    &.angle-down {
                        opacity: 0;
                        visibility: hidden
                    }
                    &.angle-up {
                        opacity: 1;
                        visibility: visible
                    }
                }
            }
        }
        @media #{$xs-layout} {
            &.ml-50 {
                margin-left: 0px;
            }
        }
    }
    .shop-top-bar-left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media #{$xs-layout} {
            display: block;
            padding-bottom: 16px;
        }
        @media #{$sm-layout} {
            padding-bottom: 0px;
        }
        .shop-top-show {
            line-height: 1;
            span {
                display: block;
                line-height: 1;
                color: #181818;
                @media #{$sm-layout} {
                    margin: 0 0 5px;
                }
            }
        }
    }
    .shop-top-bar-right {
        display: flex;
        align-items: center;
        @media #{$xs-layout} {
            display: block;
        }
        @media #{$sm-layout} {
            display: flex;
            justify-content: space-between;
        }
        .shop-short-by {
            position: relative;
            padding: 0px 0 5px;
            line-height: 1;
            > span {
                color: #181818;
                position: relative;
                cursor: pointer;
                i {
                    color: #6d6d6d;
                    margin-left: 12px;
                    transition: all .4s ease 0s;
                    font-size: 12px;
                    position: relative;
                    top: 2px;
                    @media #{$xs-layout} {
                        margin-left: 3px;
                        font-size: 10px;
                    }
                    &.angle-up {
                        position: absolute;
                        right: 2px;
                        top: 4px;
                        opacity: 0;
                        visibility: hidden;
                        @media #{$xs-layout} {
                            top: 5px;
                        }
                    }
                }
            }
            ul {
                left: 50%;
                transform: translateX(-50%);
                top: 100%;
                position: absolute;
                background-color: #fff;
                z-index: 11;
                visibility: hidden;
                opacity: 0;
                transition: all 250ms ease-out;
                margin-top: 30px;
                border: 1px solid #ebebeb;
                &.wd-1 {
                    width: 112px;
                }
                &.wd-2 {
                    width: 160px;
                }
                li {
                    display: block;
                    border-bottom: 1px solid #ebebeb;
                    &:last-child {
                        border-bottom: none;
                    }
                    a {
                        padding: 8px 15px;
                        white-space: pre;
                        display: block;
                        color: #181818;
                        line-height: 24px;
                        font-size: .875em;
                        &:hover {
                            color: $theme-color-yellow;
                            background-color: #f1f0f0;
                        }
                    }
                    &.active {
                        a {
                            color: $theme-color-yellow;
                            background-color: #f1f0f0;
                        }
                    }
                }
            }
            &:hover {
                span i {
                    &.angle-down {
                        opacity: 0;
                        visibility: hidden
                    }
                    &.angle-up {
                        opacity: 1;
                        visibility: visible
                    }
                }
            }
            &:hover ul {
                opacity: 1;
                visibility: visible;
                margin-top: 0px;
            }
            @media #{$xs-layout} {
                padding: 0px 12px 16px 0;
                display: inline-block;
                &.ml-50 {
                    margin-left: 0px;
                }
            }
            @media #{$sm-layout} {
                padding: 16px 20px 16px 0;
            }
        }
        .shop-tab {
            a {
                color: #181818;
                font-size: 20px;
                margin-right: 20px;
                &:last-child {
                    margin-right: 0;
                }
                &.active {
                    color: $theme-color-yellow;
                }
            }
            @media #{$xs-layout} {
                justify-content: center;
                &.ml-50 {
                    margin-left: 0px;
                }
            }
        }
    }
}

.shop-list-wrap {
    .shop-list-content {
        margin-left: -10px;
        @media #{$xs-layout} {
            margin-left: 0px;
            margin-top: 15px;
        }
        @media #{$sm-layout} {
            margin-left: 0px;
            margin-top: 0px;
        }
        @media #{$esm-layout} {
            margin-left: 0px;
            margin-top: 0px;
        }
        h3 {
            margin: 0;
            font-size: 18px;
            a {
                color: #211E1C;
                &:hover {
                    color: $theme-color-yellow;
                }
            }
        }
        .pro-list-price {
            margin: 10px 0 14px;
            span {
                font-size: 16px;
                color: #181818; 
                display: block;
            }
        }
        p {
            font-size: 16px;
            line-height: 28px;
            width: 98%;
            margin: 0 0 20px;
            @media #{$md-layout} {
                font-size: 15px;
            }
            @media #{$xs-layout} {
                font-size: 14px;
                width: 100%;
            }
            @media #{$sm-layout} {
                width: 100%;
            }
        }
        .product-list-action {
            display: flex;
            align-items: center;
            .pro-list-actioncart {
                a {
                    display: inline-block;
                    text-transform: uppercase;
                    background-color: #181818;
                    color: $white;
                    line-height: 1;
                    padding: 17px 30px;
                    @media #{$md-layout} {
                        padding: 14px 25px;
                        font-size: 13px;
                    }
                    @media #{$xs-layout} {
                        font-size: 12px;
                        padding: 14px 15px;
                    }
                    &:hover {
                        background-color: $theme-color-yellow;
                    }
                }
            }
            > a {
                font-size: 18px;
                color: #181818;
                display: inline-block;
                line-height: 1;
                margin-left: 25px;
                @media #{$xs-layout} {
                    margin-left: 20px;
                }
                &:hover {
                    color: $theme-color-yellow;
                }
            }
        }
    }
    &:hover {
        .product-wrap {
            .product-img {
                .shop-list-quickview {
                    margin-top: 0;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

.pro-pagination-style {
    ul {
        li {
            display: inline-block;
            margin: 0 3px;
            @media #{$xs-layout} {
                margin: 0 4px;
            }
            a {
                border: 1px solid #ebebeb;
                color: #979797;
                font-size: 16px;
                text-align: center;
                display: inline-block;
                width: 38px;
                height: 38px;
                line-height: 38px;
                border-radius: 50%;
                @media #{$xs-layout} {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                }
                &.active , &:hover {
                    background-color: #181818;
                    color: #fff;
                    border: 1px solid #181818;
                }
                i {
                    line-height: 38px;
                    @media #{$xs-layout} {
                        line-height: 40px;
                    }
                }
            }
            &:last-child {
                position: relative;
                top: 2px;
            }
        }
    }
}
.filter-widget-mrg-1 {
	padding-right: 80px;
    @media #{$xx-layout} {
        padding-right: 0px;
    }
    @media #{$xl-layout} {
        padding-right: 0px;
    }
    @media #{$lg-layout} {
        padding-right: 0px;
    }
    @media #{$md-layout} {
        padding-right: 0px;
    }
    @media #{$xs-layout} {
        padding-right: 0px;
    }
}
.product-filter-wrapper {
	box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.05);
	margin-bottom: 30px;
    padding: 48px 40px 18px;
    display: none;
    width: 100%;
    @media #{$md-layout} {
        padding: 40px 25px 34px;
    }
    @media #{$xs-layout} {
        padding: 40px 25px 34px;
    }
}

.shop-collections-padding {
    padding: 50px 110px 50px 140px;
    @media #{$xx-layout} {
        padding: 50px 80px 50px 80px;
    }
    @media #{$xl-layout} {
        padding: 50px 60px 50px 60px;
    }
    @media #{$lg-layout} {
        padding: 20px 30px 20px 30px;
    }
    @media #{$md-layout} {
        padding: 50px 30px 50px 30px;
    }
    @media #{$xs-layout} {
        padding: 25px 20px 50px 20px;
    }
}
.shop-collections-padding-2 {
    padding: 50px 140px 50px 110px;
    @media #{$xx-layout} {
        padding: 50px 80px 50px 80px;
    }
    @media #{$xl-layout} {
        padding: 50px 60px 50px 60px;
    }
    @media #{$lg-layout} {
        padding: 20px 30px 20px 30px;
    }
    @media #{$md-layout} {
        padding: 50px 30px 50px 30px;
    }
    @media #{$xs-layout} {
        padding: 25px 20px 50px 20px;
    }
}

.shop-collections-content {
    h1 {
        font-size: 34px;
        letter-spacing: 2px;
        margin: 0;
        text-transform: uppercase;
        line-height: 1;
        @media #{$lg-layout} {
            font-size: 30px;
        }
        @media #{$xs-layout} {
            font-size: 21px;
            letter-spacing: 0px;
        }
        @media #{$sm-layout} {
            font-size: 26px;
        }
    }
    p {
        font-size: 18px;
        line-height: 36px;
        margin: 22px 0 30px;
        &.mrg-2 {
            margin: 22px 0 30px;
            @media #{$lg-layout} {
                margin: 18px 0 26px;
            }
            @media #{$xs-layout} {
                margin: 13px 0 20px;
            }
        }
        @media #{$lg-layout} {
            font-size: 16px;
            line-height: 34px;
            margin: 15px 0 24px;
        }
        @media #{$md-layout} {
            font-size: 16px;
            line-height: 34px;
            margin: 15px 0 24px;
        }
        @media #{$xs-layout} {
            font-size: 15px;
            line-height: 28px;
            margin: 15px 0 20px;
        }
    }
}

.shop-collections-img {
    a {
        display: block;
        img {
            max-width: 100%;
        }
    }
    @media #{$xs-layout} {
        &.mb-35 {
            margin-bottom: 20px;
        }
    }
}

.btn-style-16 {
    a {
        display: inline-block;
        line-height: 1;
        color: $white;
        background-color: #181818;
        font-size: 14px;
        padding: 13px 32px 16px;
        &:hover {
            background-color: $theme-color-yellow;
        }
    }
}

.shop-parallax-content-1 {
    h1 {
        font-size: 46px;
        text-transform: uppercase;
        line-height: 1.2em;
        letter-spacing: 5px;
        margin: 0 0 45px;
        line-height: 1;
        color: $white;
        @media #{$xs-layout} {
            margin: 0 0 25px;
            font-size: 28px;
            letter-spacing: 3px;
            line-height: 1.3;
        }
    }
    @media #{$xs-layout} {
        padding: 150px 0;
    }
}

.vendor-content {
    position: relative;
    padding: 35px 0 35px 350px;
    @media #{$xx-layout} {
        padding: 25px 0 35px 250px;
    }
    @media #{$xl-layout} {
        padding: 25px 0 35px 220px;
    }
    @media #{$lg-layout} {
        padding: 15px 0 35px 220px;
    }
    @media #{$md-layout} {
        padding: 15px 0 35px 200px;
    }
    @media #{$xs-layout} {
        padding: 40px 0 35px 0px;
    }
    .vendor-img {
        position: absolute;
        left: 0;
        top: -87px;
        @media #{$xx-layout} {
            top: -30px;
        }
        @media #{$xl-layout} {
            top: -30px;
        }
        @media #{$lg-layout} {
            top: -30px;
        }
        @media #{$md-layout} {
            top: -30px;
        }
        @media #{$xs-layout} {
            position: static;
            margin-top: 20px;
        }
        img {
            width: 270px;
            @media #{$xx-layout} {
                width: 200px;
            }
            @media #{$xl-layout} {
                width: 200px;
            }
            @media #{$lg-layout} {
                width: 200px;
            }
            @media #{$md-layout} {
                width: 180px;
            }
            @media #{$xs-layout} {
                width: 100%;
            }
        }
    }
    h1 {
        font-size: 36px;
        font-family: $playfair;
        margin: 0;
        @media #{$lg-layout} {
            font-size: 32px;
        }
        @media #{$md-layout} {
            font-size: 28px;
        }
        @media #{$xs-layout} {
            font-size: 28px;
        }
    }
    .shop-vendor-info {
        margin: 11px 0 12px;
        @media #{$lg-layout} {
            margin: 9px 0 12px;
        }
        @media #{$md-layout} {
            margin: 9px 0 12px;
        }
        ul {
            li {
                margin-right: 25px;
                display: inline-block;
                align-items: center;
                @media #{$xx-layout} {
                    margin-right: 15px;
                }
                @media #{$xl-layout} {
                    margin-right: 15px;
                }
                @media #{$lg-layout} {
                    margin-right: 15px;
                }
                @media #{$md-layout} {
                    margin-right: 10px;
                }
                > i {
                    margin-right: 8px;
                    color: #6d6d6d;
                }
                span {
                    font-size: 16px;
                    color: #6d6d6d;
                    display: inline-block;
                    @media #{$xx-layout} {
                        font-size: 14px;
                    }
                    @media #{$xl-layout} {
                        font-size: 14px;
                    }
                    @media #{$lg-layout} {
                        font-size: 14px;
                    }
                    @media #{$md-layout} {
                        font-size: 14px;
                    }
                    @media #{$xs-layout} {
                        font-size: 14px;
                    }
                }
                a {
                    font-size: 16px;
                    color: #6d6d6d;
                    display: inline-block;
                    @media #{$xx-layout} {
                        font-size: 14px;
                    }
                    @media #{$xl-layout} {
                        font-size: 14px;
                    }
                    @media #{$lg-layout} {
                        font-size: 14px;
                    }
                    @media #{$md-layout} {
                        font-size: 14px;
                    }
                    @media #{$xs-layout} {
                        font-size: 14px;
                    }
                    &:hover {
                        color: $theme-color-yellow;
                    }
                }
                .ratting {
                    display: inline-block;
                    font-size: 12px;
                    color: $theme-color-yellow;
                    margin-right: 3px;
                }
            }
        }
    }
    .shop-vendor-search-wrap {
        display: flex;
        .shop-vendor-btn {
            a {
                display: inline-block;
                border-right: 1px solid rgba(0,0,0,.1);
                background-color: #F7F7F7;
                color: #6d6d6d;
                padding: 16px 30px;
                @media #{$xs-layout} {
                    padding: 16px 20px;
                }
                &:hover {
                    background-color: $theme-color-yellow;
                    color: $white;
                }
            }
        }
        .shop-vendor-search {
            width: 100%;
            form {
                position: relative;
                input {
                    height: 56px;
                    color: #6d6d6d;
                    background-color: #F7F7F7;
                    border: none;
                    padding: 2px 50px 2px 20px;
                }
                button {
                    font-size: 18px;
                    color: #6d6d6d;
                    padding: 0;
                    border: none;
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    line-height: 1;
                    transition: all .3s ease 0s;
                    &:hover {
                        color: $theme-color-yellow;
                    }
                }
            }
        }
    }
}

.shop-instagram-top {
    margin-bottom: 50px;
    @media #{$lg-layout} {
        margin-bottom: 30px;
    }
    @media #{$md-layout} {
        margin-bottom: 30px;
    }
    @media #{$xs-layout} {
        margin-bottom: 20px;
    }
    p {
        font-size: 18px;
        line-height: 36px;
        margin: 0 auto;
        width: 48%;
        @media #{$xl-layout} {
            width: 60%;
        }
        @media #{$lg-layout} {
            width: 70%;
        }
        @media #{$md-layout} {
            width: 98%;
        }
        @media #{$xs-layout} {
            width: 98%;
            line-height: 34px;
            font-size: 16px;
        }
    }
}

.shop-carousel-title {
    h1 {
        margin: 0;
        font-size: 32px;
        @media #{$xx-layout} {
            font-size: 28px;
        }
        @media #{$xl-layout} {
            font-size: 25px;
        }
        @media #{$lg-layout} {
            font-size: 20px;
        }
        @media #{$md-layout} {
            font-size: 30px;
            margin: 0 0 5px;
        }
        @media #{$xs-layout} {
            font-size: 26px;
            margin: 0 0 10px;
        }
    }
    p {
        font-size: 14px;
        line-height: 30px;
        @media #{$xx-layout} {
            font-size: 15px;
            line-height: 30px;
        }
        @media #{$xl-layout} {
            font-size: 15px;
            line-height: 30px;
        }
        @media #{$lg-layout} {
            font-size: 15px;
            line-height: 30px;
        }
        @media #{$md-layout} {
            font-size: 16px;
            line-height: 32px;
        }
        @media #{$xs-layout} {
            font-size: 16px;
            line-height: 30px;
        }
    }
    @media #{$md-layout} {
        &.mb-50 {
            margin-bottom: 30px;
        }
    }
    @media #{$xs-layout} {
        &.mb-50 {
            margin-bottom: 20px;
        }
    }
}

.shop-carousel-active {
    .slick-list {
        margin: 0 -30px;
        @media #{$xx-layout} {
            margin: 0 -10px;
        }
        @media #{$xl-layout} {
            margin: 0 -10px;
        }
        @media #{$lg-layout} {
            margin: 0 -10px;
        }
        @media #{$md-layout} {
            margin: 0 -15px;
        }
        @media #{$xs-layout} {
            margin: 0 -15px;
        }
        .shop-product-plr {
            padding: 0 30px;
            @media #{$xx-layout} {
                padding: 0 10px;
            }
            @media #{$xl-layout} {
                padding: 0 10px;
            }
            @media #{$lg-layout} {
                padding: 0 10px;
            }
            @media #{$md-layout} {
                padding: 0 15px;
            }
            @media #{$xs-layout} {
                padding: 0 15px;
            }
        }
    }
}
.shop-carousel-wrap {
	border-bottom: 1px solid #D8D8D8;
	padding-bottom: 55px;
	margin-bottom: 60px;
    @media #{$xs-layout} {
        padding-bottom: 25px;
	    margin-bottom: 30px;
    }
    &:last-child {
        margin-bottom: 0px;
    }
}

.product-tab-list-5 {
    justify-content: center;
    a {
        display: inline-block;
        font-size: 24px;
        color: #6d6d6d;
        margin: 0 28px;
        padding: 0 22px 20px;
        border-bottom: 1px solid transparent;
        @media #{$lg-layout} {
            margin: 0 15px;
            padding: 0 17px 15px;
        }
        @media #{$md-layout} {
            margin: 0 12px;
            padding: 0 10px 10px;
            font-size: 20px;
        }
        @media #{$xs-layout} {
            margin: 0 8px 8px;
            font-size: 18px;
            padding: 0 10px 8px;
        }
        &.active {
            color: #181818;
            border-bottom: 1px solid #D8D8D8;
        }
    }
    @media #{$xs-layout} {
        &.mb-60 {
            margin-bottom: 12px;
        }
    }
}

.shop-custom-layout-active {
    .slick-list {
        padding: 0 330px;
        @media #{$xx-layout} {
            padding: 0 230px;
        }
        @media #{$xl-layout} {
            padding: 0 140px;
        }
        @media #{$lg-layout} {
            padding: 0 120px;
        }
        @media #{$md-layout} {
            padding: 0 80px;
        }
        @media #{$xs-layout} {
            padding: 0 30px;
        }
        .shop-product-mlr-2 {
            margin: 0 30px;
            @media #{$lg-layout} {
                margin: 0 15px;
            }
            @media #{$md-layout} {
                margin: 0 15px;
            }
            @media #{$xs-layout} {
                margin: 0 10px;
            }
            @media #{$sm-layout} {
                margin: 0 15px;
            }
        }
    }
}

.nav-style-7 {
    > span {
        color: #181818;
        position: absolute;
        left: 17%;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        line-height: 20px;
        background-color: $white;
        font-size: 10px;
        text-align: center;
        box-shadow: 0 0 1px 0 #020101;
        border-radius: 10px 10px 10px 10px;
        transition: all .3s ease 0s;
        z-index: 9;
        cursor: pointer;
        @media #{$xl-layout} {
            left: 11%;
        }
        @media #{$lg-layout} {
            left: 11%;
        }
        @media #{$md-layout} {
            left: 9%;
        }
        @media #{$xs-layout} {
            left: 6%;
        }
        @media #{$sm-layout} {
            left: 4%;
        }
        &.shop-next {
            right: 17%;
            left: auto;
            @media #{$xl-layout} {
                right: 11%;
            }
            @media #{$lg-layout} {
                right: 11%;
            }
            @media #{$md-layout} {
                right: 9%;
            }
            @media #{$xs-layout} {
                right: 6%;
            }
            @media #{$sm-layout} {
                right: 4%;
            }
        }
        &:hover {
            color: $white;
            background-color: #181818;
        }
    }
}

.shop-collections-wrap-2 {
    position: relative;
    &:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        content: "";
        z-index: 5;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease 0s;
        pointer-events: none;
    }
    a {
        display: block;
        img {
            width: 100%;
        }
    }
    .shop-collections-content-2 {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        z-index: 9;
        h3 {
            margin: 0px 0 0;
            color: $white;
            font-size: 28px;
            font-family: $playfair;
            opacity: 0;
            visibility: hidden;
            transition: all .35s ease 0s;
            transform: translate(0,50px);
            a {
                color: $white;
                &:hover {
                    color: $theme-color-yellow;
                }
            }
        }
    }
    &:hover {
        &:before {
            opacity: .4;
            visibility: visible;
        }
        .shop-collections-content-2 {
            h3 {
                transform: translate(0,0px);
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.custom-layout-2-title {
    margin: -93px 0 0;
    @media #{$md-layout} {
        text-align: center;
        margin: 0px 0 40px;
    }
    @media #{$xs-layout} {
        text-align: center;
        margin: 0px 0 30px;
    }
    h1 {
        font-size: 46px;
        color: $white;
        letter-spacing: 5px;
        text-transform: uppercase;
        line-height: 64px;
        margin: 0 0 50px;
        @media #{$lg-layout} {
            margin: 0 0 30px;
            font-size: 40px;
            letter-spacing: 2px;
        }
        @media #{$md-layout} {
            margin: 0 0 20px;
            font-size: 35px;
            letter-spacing: 1px;
            line-height: 35px;
        }
        @media #{$xs-layout} {
            margin: 0 0 12px;
            font-size: 28px;
            letter-spacing: 1px;
            line-height: 35px;
        }
        &.width-1 {
            width: 70%;
            @media #{$lg-layout} {
                width: 100%;
            }
            @media #{$md-layout} {
                width: 100%;
            }
            @media #{$xs-layout} {
                width: 100%;
            }
        }
    }
    &.cl-2-title-padding {
        padding: 0 0 0px 100px;
        @media #{$lg-layout} {
            padding: 0 0 0px 0px;
        }
        @media #{$md-layout} {
            padding: 0 0 0px 0px;
        }
        @media #{$xs-layout} {
            padding: 0 0 0px 0px;
        }
    }
}
.custom-layout-2-ptb {
	padding: 205px 0 80px;
    @media #{$md-layout} {
        padding: 165px 0 60px;
    }
    @media #{$xs-layout} {
        padding: 125px 0 20px;
    }
}

.custom-layout-2-ptb-2 {
	padding: 120px 0 80px;
    @media #{$lg-layout} {
        padding: 100px 0 60px;
    }
    @media #{$md-layout} {
        padding: 100px 0 60px;
    }
    @media #{$xs-layout} {
        padding: 60px 0 20px;
    }
}

.shop-vendor-pt-85 {
    padding-top: 85px;
    @media #{$md-layout} {
        padding-top: 55px;
    }
    @media #{$xs-layout} {
        padding-top: 25px;
    }
}



@media #{$lg-layout} {
    .shop-area {
        &.pt-110 {
            padding-top: 70px; 
        }
    }
    .shop-area {
        &.pb-120 {
            padding-bottom: 80px;
        }
    }
}
@media #{$md-layout} {
    .shop-area {
        &.pt-110 {
            padding-top: 70px; 
        }
    }
    .shop-area {
        &.pb-120 {
            padding-bottom: 80px;
        }
        &.pb-90 {
            padding-bottom: 50px;
        }
        &.pb-80 {
            padding-bottom: 40px;
        }
    }
    .shop-collections-wrap {
        &.mb-100 {
            margin-bottom: 80px;
        }
    }
    .shop-banner-2 {
        display: inline-block;
    }
}

@media #{$xs-layout} {
    .shop-area {
        &.pt-110 {
            padding-top: 50px; 
        }
        &.pb-120 {
            padding-bottom: 60px;
        }
        &.pb-90 {
            padding-bottom: 30px;
        }
        &.pb-80 {
            padding-bottom: 20px;
        }
    }
    .shop-banner-2 {
        display: inline-block;
    }
    .shop-collections-area {
        &.pt-120 {
            padding-top: 60px;
        }
        &.pt-115 {
            padding-top: 55px;
        }
        &.pb-90 {
            padding-bottom: 30px;
        }
    }
    .shop-collections-wrap {
        &.mb-100 {
            margin-bottom: 60px;
        }
    }
    .single-shop-parallax {
        &.height-100vh {
            height: auto !important;
        }
    }
}



