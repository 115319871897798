
/*-------- 24. Product details style ---------*/

.product-details-content {
    @media #{$md-layout} {
        padding-top: 20px;
    }
    @media #{$xs-layout} {
        padding-top: 20px;
    }
    .pro-details-next-prev {
        a {
            color: #6D6D6D;
            font-size: 14PX;
            display: inline-block;
            &:hover {
                color: $theme-color-yellow;
            }
        }
    }
    .pro-details-next-prev-2 {
        text-align: right;
        margin: -43px 3px 20px;
        @media #{$md-layout} {
            margin: 0px 3px 15px;
            text-align: left;
        }
        @media #{$xs-layout} {
            margin: 0px 3px 15px;
            text-align: left;
        }
        a {
            font-size: 20px;
            color: #6D6D6D;
            display: inline-block;
            &:hover {
                color: $theme-color-yellow;
            }
        }
    }
    h3 {
        font-size: 24px; 
        margin: 0 0 23px;
        @media #{$lg-layout} {
            margin: 0 0 18px; 
        }
        @media #{$xs-layout} {
            margin: 0 0 15px; 
            font-size: 22px; 
        }
    }
    h4 {
        font-size: 20px;
        margin: 0 0 0px;
        color: #181818;
    }
    .pro-details-price-rating-wrap {
        display: flex;
        justify-content: space-between;
        @media #{$lg-layout} {
            display: block;
        }
        @media #{$xs-layout} {
            display: block;
        }
        @media #{$sm-layout} {
            display: flex;
        }
        .pro-details-price {
            @media #{$xs-layout} {
                margin: 0 0 10px;
            }
            @media #{$sm-layout} {
                margin: 0 0 0px;
            }
            h4 {
                font-size: 20px;
                margin: 0 0 0px;
                color: #181818;
                font-weight: 600;
            }
        }
        .pro-details-rating-stock-wrap {
            display: flex;
            flex-wrap: wrap;
            @media #{$lg-layout} {
                margin: 10px 0 0;
            }
            @media #{$xs-layout} {
                display: block;
            }
            @media #{$sm-layout} {
                display: flex;
                margin: 0px 0 0;
            }
            .product-details-rating-wrap {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin: 0 15px 0 0;
                @media #{$xs-layout} {
                    margin: 10px 15px 10px 0;
                }
                @media #{$sm-layout} {
                    margin: 0px 15px 0px 0;
                }
                .product-details-ratting {
                    display: flex;
                    i {
                        color: #F5A623;
                        font-size: 12px;
                        margin: 1px 0;
                    }
                }
                a {
                    margin-left: 5px;
                    color: #6d6d6d;
                }
            }
            .product-details-stock {
                span {
                    font-weight: bold;
                    color: #181818;
                    i {
                        color: #86BA45;
                        font-size: 15px;
                        margin-right: 2px;
                    }
                }
            }
        }
    }
    .product-details-peragraph {
        margin: 30px 0 33px;
        @media #{$lg-layout} {
            margin: 15px 0 22px;
        }
        @media #{$xs-layout} {
            margin: 15px 0 20px;
        }
        p {
            line-height: 2;
            width: 94%;
            &.font-inc {
                line-height: 28px;
                font-size: 14px;
            }
            &.width-100 {
                width: 100%;
            }
        }
    }
    .product-details-action-top {
        display: flex;
        align-items: center;
        .product-details-cart {
            margin-left: 15px;
            &.mrg-none {
                margin-left: 0;
            }
            a  {
                display: inline-block;
                font-size: 16px;
                color: #181818;
                line-height: 1;
                padding: 21px 77px 20px;
                border: 1px solid #181818;
                @media #{$xs-layout} {
                    padding: 21px 30px 20px;
                }
                &:hover {
                    border: 1px solid $theme-color-yellow;
                    color: $white;
                    background-color: $theme-color-yellow;
                }
            }
            &.affiliate-btn {
                a {
                    padding: 21px 44px 20px;
                }
            }
        }
    }
    .product-details-action-bottom {
        display: flex;
        align-items: center;
        margin: 20px 0 0;
        .pro-details-wishlist-compare {
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
            a {
                line-height: 1;
                color: #181818;
                display: flex;
                align-items: center;
                i {
                    font-size: 16px;
                    color: #181818;
                    margin-right: 10px;
                    transition: all .3s ease 0s;
                }
                &:hover {
                    color: $theme-color-yellow;
                    i {
                        color: $theme-color-yellow;
                    }
                }
            }
        }
    }
    .product-details-meta {
        margin: 37px 0 34px;
        @media #{$lg-layout} {
            margin: 25px 0 20px;
        }
        @media #{$xs-layout} {
            margin: 25px 0 20px;
        }
        span {
            color: #6D6D6D;
            display: block;
            line-height: 1;
            margin: 0 0 17px;
            a {
                color: #181818;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .social-icon-style-2 {
        a {
            font-size: 16px;
            border: 1px solid #ebebeb;
            width: 32px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            border-radius: 100%;
            color: #6D6D6D;
            display: inline-block;
            margin-right: 10px;
            i {
                line-height: 32px;
            }
            &:last-child {
                margin-right: 0;
            }
            &:hover {
                color: $white;
                background-color: $theme-color-yellow;
                border: 1px solid $theme-color-yellow;
            }
        }
    }
    &.quickview-content-padding {
        h3 {
            margin: 11px 0 23px;
            @media #{$xs-layout} {
                margin: 11px 0 23px;
                font-size: 22px; 
            }
        }
    }
}

.product-details-quality {
    .cart-plus-minus {
        width: 83px;
        height: 60px;
        position: relative;
        .qtybutton {
            position: absolute;
            font-size: 18px;
            color: #6D6D6D;
            top: 50%;
            transform: translateY(-50%);
            &.dec {
                left: 8px;
            }
            &.inc {
                right: 8px;
            }
        }
        input {
            height: 60px;
            text-align: center;
            border: 1px solid rgba(0,0,0,.1);
            background-color: transparent;
            padding: 2px 25px;
        }
    }
    &.quality-border-none {
        @media #{$xs-layout}{
            display: flex;
            justify-content: center;
        }
        .cart-plus-minus {
            input {
                border: none;
            }
        }
    }
}

.easyzoom-style {
    position: relative;
    a.easyzoom-pop-up {
        position: absolute;
        right: 25px;
        bottom: 20px;
        width: 50px;
        height: 50px;
        line-height: 50px;
        display:inline-block;
        background-color: #fff;
        color: #000;
        font-size: 16px;
        text-align: center;
        border-radius: 100%;
        z-index: 999;
        i {
            line-height: 50px;
        }
        &:hover {
            color: $theme-color-yellow;
        }
    }
    .easyzoom {
        display: block;
        > a {
            transition: none;
            img {
                width: 100%;
                transition: none;
            } 
        }
    }
    .easyzoom-popup > a img {
        width: 100%;
    }
}

.product-dec-small-style1 {
    width: 420px;
    margin: 13px auto 0;
    @media #{$xl-layout} {
        width: 400px;
    }
    @media #{$lg-layout} {
        width: 100%;
    }
    @media #{$md-layout} {
        width: 450px;
    }
    @media #{$xs-layout} {
        width: 100%;
    }
    .product-dec-small {
        margin: 0 5px 0px 5px;
        cursor: pointer;
        img {
            width: 100%;
            opacity: .8;
        }
        &.slick-active {
            border: 1px solid transparent;
            &.slick-current {
                border: 1px solid $theme-color-yellow;
                img {
                    opacity: 1;
                }
            }
        }
        &:last-child {
            margin: 0 0 0px;
        }
    }
    span {
        color: #262626;
        font-size: 10px;
        position: absolute;
        line-height: 1;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 9;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border: 1px solid #979797;
        color: #1E1E1E;
        text-align: center;
        border-radius: 100%;
        &:hover {
            color: $white;
            border: 1px solid $theme-color-yellow;
            background-color: $theme-color-yellow;
        }
        &.pro-dec-prev {
            left: -5%;
            @media #{$lg-layout} {
                left: -4%;
            }
            @media #{$md-layout} {
                left: -4%;
            }
            @media #{$xs-layout} {
                left: -2%;
            }
        }
        &.pro-dec-next {
            left: auto;
            right: -4.7%;
            @media #{$lg-layout} {
                right: -4%;
            }
            @media #{$md-layout} {
                right: -4%;
            }
            @media #{$xs-layout} {
                right: -2%;
            }
        }
    }
}

.product-dec-small-style2 {
    text-align: center;
    .product-dec-small {
        margin: 5px 0px 5px 0px;
        cursor: pointer;
        img {
            width: 100%;
            opacity: .8;
        }
        &.slick-active {
            border: 1px solid transparent;
            &.slick-current {
                border: 1px solid $theme-color-yellow;
                img {
                    opacity: 1;
                }
            }
        }
        &:last-child {
            margin: 0 0 0px;
        }
    }
    span {
        color: #262626;
        font-size: 10px;
        line-height: 1;
        cursor: pointer;
        z-index: 9;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border: 1px solid #979797;
        color: #1E1E1E;
        text-align: center;
        border-radius: 100%;
        display: inline-block;
        &:hover {
            color: $white;
            border: 1px solid $theme-color-yellow;
            background-color: $theme-color-yellow;
        }
    }
}

.product-dec-small-style1 .slick-slide , .product-dec-small-style2 .slick-slide {
	border: 1px solid transparent;
}

.description-review-topbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 0 18px;
    border-bottom: 1px solid #ebebeb;
    margin: 0 0 65px;
    @media #{$xs-layout} {
        margin: 0 0 35px;
        padding: 0 0 13px;
    }
    a {
        font-size: 16px;
        color: #6d6d6d;
        display: inline-block;
        margin: 0 25px;
        @media #{$lg-layout} {
            margin: 0 15px;
        }
        @media #{$md-layout} {
            margin: 0 15px 5px;
        }
        @media #{$xs-layout} {
            margin: 0 6px 7px;
        }
        &.active {
            color: #181818;
        }
    }
}

.pro-details-banner {
    img {
        max-width: 100%;
    }
}

.product-description-content {
    margin: 0 37px 0 -37px;
    @media #{$lg-layout} {
        margin: 0 0px 0 0px;
    }
    @media #{$md-layout} {
        margin: 0 0px 0 0px;
    }
    @media #{$xs-layout} {
        margin: 30px 0px 0 0px;
    }
    h2 {
        font-size: 28px;
        margin: 0 0 20px;
        @media #{$md-layout} {
            margin: 0 0 12px;
        }
        @media #{$xs-layout} {
            margin: 0 0 12px;
            font-size: 23px;
        }
    }
    p {
        font-size: 15px;
        line-height: 28px;
        margin: 0 0 22px;
        &:last-child {
            margin: 0 0 0px;
        }
        @media #{$md-layout} {
            font-size: 15px;
            line-height: 27px;
            margin: 0 0 15px;
        }
        @media #{$xs-layout} {
            font-size: 15px;
            line-height: 27px;
            margin: 0 0 15px;
        }
    }
}

.specification-wrap {
    table {
        width: 100%;
        tbody {
            border: 1px solid #ebebeb;
            width: 100%;
            tr {
                border-bottom: 1px solid #ebebeb;
                td {
                    border-right: 1px solid #ebebeb;
                    font-size: 16px;
                    padding: 12px 10px;
                    @media #{$xs-layout} {
                        padding: 12px 15px;
                    }
                }
                td.width1 {
                    width: 215px;
                    @media #{$xs-layout} {
                        width: auto;
                    }
                }
            }
        }
    }
}

.review-wrapper {
    h3 {
        font-size: 24px;
        margin: 0 0 37px;
        line-height: 20px;
        @media #{$xs-layout} {
            font-size: 18px;
            margin: 0 0 18px;
        }
    }
}

.single-review {
	margin: 0 0 60px;
    display: flex;
    border-bottom: 1px solid #ebebeb;
    padding: 0 0 34px;
    @media #{$xs-layout} {
        display: block;
        margin: 0 0 20px;
        padding: 0 0 20px;
    }
    .review-img {
        img {
            width: 100px;
        }
    }
    .review-content {
        margin-left: 30px;
        @media #{$xs-layout} {
            margin-left: 0px;
        }
        h5 {
            font-size: 12px;
            margin: 11px 0 8px;
            color: #6d6d6d;
            span {
                font-weight: bold;
                font-size: 14px;
                color: #6d6d6d;
            }
        }
        .review-rating {
            line-height: 1;
            @media #{$xs-layout} {
                margin: 10px 0 0px;
            }
            i {
                color: #F5A623;
                font-size: 12px;
                margin: 0px 1px;
            }
        }
        p {
            line-height: 25px;
            margin: 0;
            @media #{$xs-layout} {
                font-size: 15px;
            }
        }
    }
}

.ratting-form-wrapper {
    > h3 {
        display: block;
        line-height: 1;
        font-size: 24px;
        color: #211E1C;
    }
    > p {
        margin: 35px 0 0;
        padding-bottom: 12px;
        border-bottom: 1px solid rgba(0,0,0,.1);
        @media #{$xs-layout} {
            margin: 15px 0 0;
        }
    }
    .your-rating-wrap {
        display: flex;
        align-items: center;
        margin: 52px 0 0px;
        @media #{$xs-layout} {
            margin: 20px 0 0px;
        }
        span {
            font-size: 16px; 
            color: #6d6d6d;
            display: inline-block;
            margin-right: 20px;
        }
        .your-rating {
            i {
                color: #F5A623;
                font-size: 14px;
            }
        }
    }
    .ratting-form {
        margin: 19px 0 0;
        .rating-form-style {
            label {
                margin: 0 0 14px;
                color: #6d6d6d;
                font-size: 16px;
                display: block;
            }
            input , textarea {
                background: transparent;
                border: 1px solid #ebebeb;
                height: 50px;
                padding: 5px 10px;
                &:focus {
                    border: 1px solid #e9322d;
                }
            }
            textarea {
                height: 150px;
                @media #{$xs-layout} {
                    height: 100px;
                }
            }
        }
        .save-email-option {
            > p {
                display: flex;
                align-items: center;
                margin: 0 0 5px;
                input {
                    width: auto;
                    height: auto;
                }
            }
            label {
                margin: 0 0 0 16px;
            }
        }
        .form-submit {
            margin-top: 9px;
            input {
                padding: 15px 65px;
                color: #fff;
                display: inline-block;
                width: auto;
                height: auto;
                font-size: 14px;
                text-transform: uppercase;
                background: #181818;
                &:hover {
                    background: $theme-color-yellow;
                }
            }
        }
    }
}

.about-brand-wrap {
    p {
        font-size: 15px;
        line-height: 28px;
        margin: 0 0 11px;
        @media #{$xs-layout} {
            font-size: 15px;
            line-height: 27px;
        }
        &:last-child {
            margin: 0 0 0;
        }
    }
}

.vendor-info-content {
    h3 {
        font-size: 24px;
        margin: 0 0 33px;
        @media #{$xs-layout} {
            margin: 0 0 15px;
        }
    }
    ul {
        li {
            font-size: 15px;
            color: #6d6d6d;
            margin: 0 0 6px;
            line-height: 28px;
            @media #{$xs-layout} {
                font-size: 14px;
                line-height: 25px;
            }
            span {
                display: inline-block;
                margin-right: 5px;
                line-height: 1;
                i {
                    color: #F5A623;
                    font-size: 12px;
                    margin: 0px 1px;
                }
            }
            &.rating {
                font-size: 15px;
            }
        }
    }
}

.related-product-slider {
    .slick-list {
        margin: 0 -15px;
        .related-product-plr {
            padding: 0 15px;
        }
    }
}

.configurable-wrap {
    .configurable-color {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 10px 0 17px;
        > span {
            color: #181818;
            display: inline-block;
            min-width: 80px;
            margin-right: 20px;
        }
        &.sidebar-widge-color {
            ul {
                li {
                    margin-right: 8px;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        .swatch-anchor {
                            width: 22px;
                            height: 22px;
                            line-height: 22px;
                        }
                        &:hover {
                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .configurable-size  {
        display: flex;
        align-items: center;
        > span {
            color: #181818;
            display: inline-block;
            min-width: 80px;
            margin-right: 20px;
            @media #{$xs-layout} {
                margin-right: 10px;
            }
        }
        select {
            padding: 0 20px 5px 0;
            border-bottom: 1px solid #ebebeb;
        }
    }
    .configurable-brand-img  {
        display: flex;
        align-items: center;
        margin: 0 0 25px;
        > span {
            color: #181818;
            display: inline-block;
            min-width: 80px;
            margin-right: 20px;
        }
        a {
            display: block;
            img {
                width: 60px;
            }
        }
    }
}

.pro-details-group {
    margin-bottom: 30px;
    .single-group {
        display: flex;
        border-bottom: 1px solid #ebebeb;
        padding: 0 0 14px;
        &:first-child {
            border-top: 1px solid #ebebeb;
        }
        @media #{$xs-layout} {
            display: block;
        }
        @media #{$sm-layout} {
            display: flex;
        }
        .product-details-quality {
            flex: 0 0 13%;
            .cart-plus-minus {
                input {
                    border: none;
                }
            }
        }
        .pro-dec-group-details {
            flex: 0 0 87%;
            margin: 18px 0 0;
            @media #{$xx-layout} {
                flex: 0 0 85%;
            }
            @media #{$xl-layout} {
                flex: 0 0 80%;
            }
            @media #{$lg-layout} {
                flex: 0 0 80%;
            }
            @media #{$xs-layout} {
                margin: 0px 0 0;
            }
            @media #{$sm-layout} {
                flex: 0 0 80%;
                margin: 18px 0 0;
            }
            .pro-group-title {
                h5 {
                    font-size: 14px;
                    margin: 0 0 5px;
                    a {
                        color: #181818;
                        &:hover {
                            color: $theme-color-yellow;
                        }
                    }
                }
            }
            .pro-dec-group-stock {
                display: flex;
                justify-content: space-between;
                .pro-dec-group-price {
                    span {
                        display: inline-block;
                        line-height: 1;
                        &.old-price {
                            text-decoration: line-through;
                            margin-right: 3px;
                            color: #666;
                        }
                    }
                }
                .pro-dec-group-stock {
                    span {
                        i {
                            color: #86BA45;
                            font-size: 15px;
                            margin-right: 5px;
                        }
                        display: flex;
                        align-items: center;
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

.sidebar-active {
    &.col-lg-6 {
        padding-left: 15px;
        padding-right: 15px;
        &.is-affixed {
            padding-left: 0px;
            padding-right: 0px;
            & .inner-wrapper-sticky {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

.product-dec-left {
	position: relative;
	float: left;
	width: 160px;
	padding-right: 20px;
    @media #{$xx-layout} {
        width: 125px;
    }
    @media #{$xl-layout} {
        width: 140px;
    }
    @media #{$lg-layout} {
        width: 110px;
    }
    @media #{$md-layout} {
        width: 135px;
    }
    @media #{$xs-layout} {
        width: 90px;
    }
    @media #{$sm-layout} {
        width: 100px;
    }
}
.product-dec-right {
	float: right;
	position: relative;
	width: calc(100% - 160px);
    @media #{$xx-layout} {
        width: calc(100% - 125px);
    }
    @media #{$xl-layout} {
        width: calc(100% - 140px);
    }
    @media #{$lg-layout} {
        width: calc(100% - 110px);
    }
    @media #{$md-layout} {
        width: calc(100% - 135px);
    }
    @media #{$xs-layout} {
        width: calc(100% - 90px);
    }
    @media #{$sm-layout} {
        width: calc(100% - 100px);
    }
}

.product-details-custom-slider {
    margin-bottom: 115px;
    overflow: hidden;
    @media #{$md-layout} {
        margin-bottom: 50px;
    }
    @media #{$xs-layout} {
        margin-bottom: 20px;
    }
    .slick-list {
        padding: 0 130px;
        @media #{$md-layout} {
            padding: 0 60px;
        }
        @media #{$xs-layout} {
            padding: 0 50px;
        }
        .pd-custom-slider-mlr {
            margin: 0 20px;
            @media #{$md-layout} {
                margin: 0 10px;
            }
            @media #{$xs-layout} {
                margin: 0 10px;
            }
        }
    }
    > span {
        &.pro-dec-custom-icon {
            color: #181818;
            position: absolute;
            left: 6%;
            top: 50%;
            transform: translateY(-50%);
            width: 30px;
            height: 30px;
            line-height: 30px;
            background-color: $white;
            font-size: 10px;
            text-align: center;
            border: 1px solid #ebebeb;
            border-radius: 100%;
            transition: all .3s ease 0s;
            z-index: 9;
            cursor: pointer;
            @media #{$xx-layout} {
                left: 8%;
            }
            @media #{$xl-layout} {
                left: 9%;
            }
            @media #{$lg-layout} {
                left: 12%;
            }
            @media #{$xs-layout} {
                left: 10%;
            }
            @media #{$sm-layout} {
                left: 5%;
            }
            &.pro-details-next {
                right: 6%;
                left: auto;
                @media #{$xx-layout} {
                    right: 8%;
                }
                @media #{$xl-layout} {
                    right: 9%;
                }
                @media #{$lg-layout} {
                    right: 12%;
                }
                @media #{$xs-layout} {
                    right: 10%;
                }
                @media #{$sm-layout} {
                    right: 5%;
                }
            }
            &:hover {
                color: $white;
                background-color: #181818;
            }
        }
    }
}



@media #{$md-layout} {
    .product-details-tab {
        margin-bottom: 50px;
    }
    .description-review-area {
        &.pb-120 {
            padding-bottom: 80px;
        }
    }
}

@media #{$xs-layout} {
    .product-details-tab {
        margin-bottom: 30px;
    }
    .related-product , .product-details-area {
        &.pt-120 {
            padding-top: 60px;
        }
        &.pb-115 {
            padding-bottom: 55px;
        }
    }
    .description-review-area {
        &.pb-120 {
            padding-bottom: 60px;
        }
    }
}




