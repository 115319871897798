
/*-------- 5. Banner style ---------*/

.new-collections-banner {
    img {
        width: 100%;
    }
    @media #{$xs-layout} {
        margin-top: 20px;
    }
}

.banner-wrap {
    position: relative;
    .banner-img {
        a {
            display: block;
            img {
                width: 100%;
            }
        }
        &.banner-img-overflow {
            overflow: hidden;
        }
        &.banner-zoom {
            a {
                img {
                    transition: all 2s;
                    transform: scale(1);
                }
            }
        }
    }
    .banner-position-1 {
        position: absolute;
        left: 60px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
        @media #{$xx-layout} {
            left: 40px;
        }
        @media #{$xl-layout} {
            left: 40px;
        }
        @media #{$lg-layout} {
            left: 40px;
        }
        @media #{$md-layout} {
            left: 40px;
        }
        @media #{$xs-layout} {
            left: 15px;
        }
    }
    .banner-position-2 {
        position: absolute;
        left: 380px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
        @media #{$xx-layout} {
            left: 240px;
        }
        @media #{$xl-layout} {
            left: 240px;
        }
        @media #{$lg-layout} {
            left: 240px;
        }
        @media #{$md-layout} {
            left: 370px; 
        }
        @media #{$xs-layout} {
            left: 15px;
        }
        @media #{$sm-layout} {
            left: 300px;
        }
    }
    .banner-position-3 {
        position: absolute;
        left: 40px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
        @media #{$lg-layout} {
            left: 20px;
        }
        @media #{$xs-layout} {
            left: 15px;
        }
    }
    .banner-content-1 {
        position: absolute;
        left: 95px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
        @media #{$xx-layout} {
            left: 40px;
        }
        @media #{$xl-layout} {
            left: 40px; 
        }
        @media #{$lg-layout} {
            left: 40px;
        }
        @media #{$md-layout} {
            left: 40px;
        }
        @media #{$xs-layout} {
            left: 15px;
        }
        h3 {
            font-size: 46px;
            color: $white;
            line-height: 1.4em;
            font-weight: 600;
            width: 55%;
            margin: 0;
            @media #{$xx-layout} {
                font-size: 35px;
                width: 70%;
            }
            @media #{$xl-layout} {
                font-size: 32px;
                width: 70%;
            }
            @media #{$lg-layout} {
                font-size: 32px;
                width: 70%;
            }
            @media #{$md-layout} {
                font-size: 32px;
                width: 70%;
            }
            @media #{$xs-layout} {
                font-size: 18px;
                width: 92%;
            }
            @media #{$sm-layout} {
                font-size: 28px;
            }
        }
        p {
            font-size: 16px;
            color: $white;
            line-height: 2em;
            width: 52%;
            margin: 20px 0 42px;
            @media #{$xx-layout} {
                width: 60%;
            }
            @media #{$xl-layout} {
                width: 70%;
            }
            @media #{$lg-layout} {
                width: 70%;
            }
            @media #{$md-layout} {
                width: 70%;
            }
            @media #{$xs-layout} {
                width: 100%;
                font-size: 15px;
                line-height: 24px;
                margin: 0px 0 12px;
            }
            @media #{$sm-layout} {
                width: 85%;
                margin: 12px 0 22px;
            }
        }
    }
    .banner-content-2 {
        h3 {
            line-height: 1.4em;;
            font-size: 28px;
            font-weight: 600;
            color: $white;
            margin: 0;
            @media #{$xx-layout} {
                font-size: 23px;
            }
            @media #{$xl-layout} {
                font-size: 20px;
            }
            @media #{$lg-layout} {
                font-size: 23px;
            }
            @media #{$xs-layout} {
                font-size: 20px;
            }
            @media #{$sm-layout} {
                font-size: 23px;
            }
            &.banner-text-width1 {
                width: 54%;
                @media #{$xx-layout} {
                    width: 60%;
                }
                @media #{$xl-layout} {
                    width: 60%;
                }
                @media #{$lg-layout} {
                    width: 60%;
                }
                @media #{$xs-layout} {
                    width: 98%;
                }
                @media #{$sm-layout} {
                    width: 76%;
                }
            }
            &.banner-text-width2 {
                width: 54%;
                @media #{$xx-layout} {
                    width: 60%;
                }
                @media #{$xl-layout} {
                    width: 60%;
                }
                @media #{$lg-layout} {
                    width: 60%;
                }
                @media #{$xs-layout} {
                    width: 100%;
                }
            }
        }
        h4 {
            line-height: 1.4em;
            font-size: 28px;
            color: $theme-color-yellow-2;
            margin: 10px 0 25px;
            @media #{$xx-layout} {
                margin: 8px 0 15px;
            }
            @media #{$xl-layout} {
                margin: 8px 0 10px;
            }
            @media #{$lg-layout} {
                margin: 8px 0 15px;
            }
            @media #{$xs-layout} {
                margin: 2px 0 10px;
                font-size: 24px;
            }
            @media #{$sm-layout} {
                margin: 5px 0 20px;
            }
        }
    }
    .banner-content-3 {
        h3 {
            font-size: 46px;
            font-weight: 700;
            line-height: 1.4em;
            color: $white;
            @media #{$xx-layout} {
                font-size: 30px;
                line-height: 1.2em;
            }
            @media #{$xl-layout} {
                font-size: 30px;
                line-height: 1.2em;
            }
            @media #{$lg-layout} {
                font-size: 18px;
            }
            @media #{$md-layout} {
                font-size: 40px;
            }
            @media #{$xs-layout} {
                font-size: 19px;
            }
            @media #{$sm-layout} {
                font-size: 30px;
            }
            &.banner-text-width3 {
                width: 90%;
                @media #{$lg-layout} {
                    width: 100%;
                }
                @media #{$xs-layout} {
                    width: 98%;
                }
            }
            span {
                &.sale-off {
                    font-size: 37px;
                    @media #{$lg-layout} {
                        font-size: 18px;
                    }
                    @media #{$xs-layout} {
                        font-size: 19px;
                    }
                    @media #{$sm-layout} {
                        font-size: 30px;
                    }
                }
                &.percentage {
                    font-weight: 600;
                }
            }
        }
        p {
            line-height: 28px;
            color: $white;
            font-size: 16px;
            width: 80%;
            margin: 7px 0 0;
            @media #{$lg-layout} {
                line-height: 24px;
                font-size: 14px;
                width: 90%;
            }
            @media #{$xs-layout} {
                line-height: 24px;
                font-size: 14px;
                width: 98%;
            }
        }
    }
    .banner-content-4 {
        position: absolute;
        left: 40px;
        bottom: 55px;
        z-index: 9;
        @media #{$xl-layout} {
            bottom: 25px;
        }
        @media #{$lg-layout} {
            left: 20px;
            bottom: 25px;
        }
        @media #{$xs-layout} {
            left: 15px;
            bottom: 30px;
        }
        h3 {
            font-size: 26px;
            font-weight: bold;
            color: $white;
            text-transform: uppercase;
            margin: 0 0 25px;
            width: 50%;
            line-height: 36px;
            @media #{$lg-layout} {
                font-size: 22px;
                line-height: 30px;
            }
            @media #{$xs-layout} {
                font-size: 20px;
                line-height: 18px;
            }
        }
        h2 {
            font-size: 100px;
            font-weight: bold;
            color: $white;
            text-transform: uppercase;
            margin: 0 0 0px;
            font-family: $robotocondensed;
            font-style: italic;
            line-height: 80px;
            @media #{$xx-layout} {
                font-size: 80px;
            }
            @media #{$xl-layout} {
                font-size: 60px;
            }
            @media #{$lg-layout} {
                font-size: 50px;
                line-height: 40px;
            }
            @media #{$md-layout} {
                font-size: 80px;
            }
            @media #{$xs-layout} {
                font-size: 50px;
                line-height: 40px;
            }
        }
    }
    .banner-content-5 {
        padding: 32px 70px;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        z-index: 99;
        @media #{$xl-layout} {
            padding: 30px 60px;
        }
        @media #{$lg-layout} {
            padding: 20px 35px;
        }
        @media #{$md-layout} {
            padding: 27px 45px;
        }
        @media #{$xs-layout} {
            padding: 25px 40px;
        }
        @media #{$sm-layout} {
            padding: 22px 30px;
        }
        &::before {
            content: '';
            width: 17px;
            height: 17px;
            position: absolute;
            left: 0;
            top: 0;
            border-left: 3px solid #fff;
            border-top: 3px solid #fff;
        }
        &::after {
            content: '';
            width: 17px;
            height: 17px;
            position: absolute;
            right: 0;
            top: 0;
            border-right: 3px solid #fff;
            border-top: 3px solid #fff;
        }
        h3 {
            color: $white;
            margin: 0;
            font-size: 28px;
            font-weight: 700;
            letter-spacing: 2px;
            font-family: $montserrat;
            line-height: 1;
            @media #{$lg-layout} {
                font-size: 22px;
            }
            @media #{$md-layout} {
                font-size: 22px;
            }
            @media #{$xs-layout} {
                font-size: 20px;
            }
            @media #{$sm-layout} {
                font-size: 18px;
            }
            &::before {
                content: '';
                width: 17px;
                height: 17px;
                position: absolute;
                left: 0;
                bottom: 0;
                border-left: 3px solid #fff;
                border-bottom: 3px solid #fff;
            }
            &::after {
                content: '';
                width: 17px;
                height: 17px;
                position: absolute;
                right: 0;
                bottom: 0;
                border-right: 3px solid #fff;
                border-bottom: 3px solid #fff;
            }
        }
    }
    &.overly-point-4-2-hover {
        &:before {
            opacity: .3;
            pointer-events: none;
            background-color: #181818;
            transition: all .35s ease 0s;
        }
    }
    .banner-content-9 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 52%;
        z-index: 9;
        @media #{$xs-layout} {
            width: 100%;
        }
        h3 {
            font-family: $satisfy;
            font-size: 46px;
            margin: 0 0 8px;
            @media #{$xs-layout} {
                font-size: 30px;
                margin: 0 0 5px;
            }
        }
        h2 {
            font-family: $satisfy;
            font-size: 56px;
            margin: 0 0 0px;
            color: #181818;
            @media #{$lg-layout} {
                font-size: 45px;
            }
            @media #{$xs-layout} {
                font-size: 30px;
            }
        }
    }
    .banner-content-10 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        text-align: center;
        z-index: 9;
        h3 {
            font-family: $satisfy;
            font-size: 46px;
            margin: 0 0 0px;
            color: $white;
            @media #{$xs-layout} {
                font-size: 35px;
            }
        }
    }
    .banner-content-11 {
        position: absolute;
        bottom: 20%;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 9;
        padding: 0 10px;
        h3 {
            font-family: $satisfy;
            font-size: 46px;
            margin: 0 0 0px;
            line-height: 1.4em;
            @media #{$lg-layout} {
                font-size: 35px;
            }
            @media #{$xs-layout} {
                font-size: 35px;
            }
        }
    }
    .banner-content-12 {
        position: absolute;
        top: 9%;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 9;
        padding: 0 90px;
        @media #{$xx-layout} {
            padding: 0 30px;
        }
        @media #{$xl-layout} {
            padding: 0 10px;
        }
        @media #{$lg-layout} {
            padding: 0 10px;
        }
        @media #{$md-layout} {
            padding: 0 50px;
        }
        @media #{$xs-layout} {
            padding: 0 40px;
        }
        h3 {
            font-family: $satisfy;
            font-size: 46px;
            margin: 0 0 0px;
            line-height: 1.4em;
            color: $white;
            @media #{$lg-layout} {
                font-size: 35px;
            }
            @media #{$xs-layout} {
                font-size: 35px;
            }
        }
    }
    .sidebar-banner-content {
        position: absolute;
        left: 25px;
        bottom: 32px;
        z-index: 9;
        @media #{$lg-layout} {
            left: 15px;
        }
        h4 {
            font-size: 32px;
            color: $white;
            margin: 0 0 5px;
        }
        a {
            font-size: 16px;
            color: $white;
            display: block;
            @media #{$lg-layout} {
                font-size: 15px;
            }
            &:hover {
                color: $theme-color-yellow;
            }
        }
    }
    &:hover {
        .banner-zoom {
            a {
                img {
                    transform: scale(1.1);
                }
            }
        }
        .btn-style-4 {
            a {
                background-color: $theme-color-red;
                color: $white;
            }
        }
        &.default-overlay {
            &.overly-point-4-hover {
                &:before {
                    opacity: .4;
                    pointer-events: none;
                    background-color: $black;
                    transition: all .35s ease 0s;
                }
            }
            &.overly-point-4-2-hover {
                &:before {
                    opacity: .4;
                }
            }
        }
        &.default-overlay-white {
            &.overly-point-4-hover-white {
                &:before {
                    opacity: .2;
                    pointer-events: none;
                    background-color: $white;
                    transition: all .35s ease 0s;
                }
            }
        }
    }
}

.banner-wrap-2 {
    .banner-img-2 {
        a {
            display: block;
            img {
                max-width: 100%;
                &:hover {
                    animation-name: elementor-animation-bob-float,elementor-animation-bob;
                    animation-duration: .3s,1.5s;
                    animation-delay: 0s,.3s;
                    animation-timing-function: ease-out,ease-in-out;
                    animation-iteration-count: 1,infinite;
                    animation-fill-mode: forwards;
                    animation-direction: normal,alternate;
                }
            }
        }
    }
}
@keyframes elementor-animation-bob {
    0% {
        transform:translateY(-8px)
    }
    50% {
        transform:translateY(-4px)
    }
    100% {
        transform:translateY(-8px)
    }
}
@keyframes elementor-animation-bob-float {
    100% {
        transform:translateY(-8px)
    }
}

.banner-area-mrg-top {
    margin-top: -80px;
}

.furniture-categori-padding-10-1 {
    padding-right: 10px;
    @media #{$md-layout} {
        padding-right: 0px;
    }
    @media #{$xs-layout} {
        padding-right: 0px;
    }
}
.furniture-categori-padding-10-4 {
    padding-right: 10px;
    @media #{$xs-layout} {
        padding-right: 0px;
    }
}

.furniture-categori-padding-10-2 {
    padding-left: 10px;
    @media #{$md-layout} {
        padding-left: 0px;
    }
    @media #{$xs-layout} {
        padding-left: 0px;
    }
}

.furniture-categori-padding-10-3 {
    padding-left: 20px;
    @media #{$md-layout} {
        padding-left: 0px;
    }
    @media #{$xs-layout} {
        padding-left: 0px;
    }
}

.banner-content-6-position-1 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 21%;
    @media #{$xl-layout} {
        right: 12%;
    }
    @media #{$lg-layout} {
        right: 6%;
    }
    @media #{$md-layout} {
        right: 15%;
    }
    @media #{$xs-layout} {
        right: 13%;
    }
}
.banner-content-6-position-2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15%;
    @media #{$xl-layout} {
        left: 10%; 
    }
    @media #{$lg-layout} {
        left: 6%; 
    }
    @media #{$xs-layout} {
        left: 10%; 
    }
}
.banner-content-6-position-3 {
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	top: 15%;
}
.banner-content-6-position-4 {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 50%;
}
.banner-content-6 {
    z-index: 9;
    span {
        display: block;
        font-size: 18px;
        color: #333;
        font-family: $playfair;
        font-style: italic;
        @media #{$xs-layout} {
            font-size: 16px;
            color: #333;
        }
    }
    h3 {
        color: #181818; 
        font-size: 24px;
        line-height: 33px;
        margin: 14px 0 0;
        @media #{$xx-layout} {
            font-size: 22px;
        }
        @media #{$xl-layout} {
            font-size: 20px;
            line-height: 30px;
        }
        @media #{$lg-layout} {
            font-size: 17px;
            line-height: 28px;
            margin: 10px 0 0;
        }
        @media #{$xs-layout} {
            font-size: 17px;
            line-height: 24px;
            margin: 2px 0 0;
        }
        @media #{$sm-layout} {
            font-size: 20px;
            line-height: 30px;
            margin: 10px 0 0;
        }
        a {
            color: #181818; 
        }
    }
}

.banner-wrap-3 {
    position: relative;
    &.banner-wrap-3-padding-1 {
        padding: 78px 0 88px;
    }
    &.banner-wrap-3-padding-2 {
        padding: 53px 0 53px;
        @media #{$lg-layout} {
            padding: 0px 0 106px;
        }
        @media #{$md-layout} {
            padding: 0px 0 106px;
        }
    }
    &.banner-wrap-3-border-1 {
        position: relative;
        a {
            z-index: 99;
            position: relative;
            img {
                width: 188px;
                @media #{$xs-layout} {
                    width: 120px;
                }
            }
        }
        &:before {
            position: absolute;
            content: "";
            left: 48%;
            top: 0;
            height: 100%;
            width: 1px;
            background-color: #D8D8D8;
            z-index: 8;
            @media #{$xx-layout} {
                left: 58%;
            }
            @media #{$xl-layout} {
                left: 58%;
            }
            @media #{$md-layout} {
                left: 58%;
            }
        }
    }
    &.banner-wrap-3-border-2 {
        position: relative;
        &:after {
            position: absolute;
            content: "";
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            height: 45px;
            width: 1px;
            background-color: #D8D8D8;
            z-index: 8;
            @media #{$lg-layout} {
                height: 91px;
            }
            @media #{$md-layout} {
                height: 91px;
            }
        }
    }
    .banner-content-7 {
        h3 {
            font-size: 28px;
            font-weight: 500;
            font-family: $noto;
            letter-spacing: -1px;
            margin: 0;
            text-transform: uppercase;
            @media #{$xs-layout} {
                font-size: 18px;
                letter-spacing: 0px;
            }
        }
        span {
            font-size: 20px;
            letter-spacing: -1px;
            margin: 0;
            color: #333;
            text-transform: uppercase;
            margin: 12px 0 16px;
            display: block;
        }
        h2 {
            font-size: 80px;
            color: $theme-color-pink;
            letter-spacing: 5px;
            margin: 10px 0 28px;
            line-height: 62px;
            @media #{$xs-layout} {
                font-size: 50px;
                letter-spacing: 3px;
                margin: 10px 0 18px;
            }
        }
    }
    .banner-content-8-position-1 {
        position: absolute;
        top: 21%;
        left: 48%;
        padding: 15px 0;
        @media #{$xx-layout} {
            left: 57%;
        }
        @media #{$xl-layout} {
            left: 57%;
        }
        @media #{$md-layout} {
            left: 58%;
        }
    }
    .banner-content-8-position-2 {
        margin: 18px 0 0;
    }
    .banner-content-8 {
        background-color: #F9F9F9;
        z-index: 9;
        span {
            font-size: 14px;
            text-transform: uppercase;
            color: #333;
        }
        h4 {
            font-size: 16px;
            line-height: 28px;
            color: #181818;
            margin: 0px 0 9px;
            text-transform: uppercase;
            letter-spacing: 1px;
            &.b-content-8-width {
                margin: 0px auto 12px;
                width: 55%;
                @media #{$xx-layout} {
                    width: 75%;
                }
                @media #{$xl-layout} {
                    width: 75%;
                }
                @media #{$md-layout} {
                    width: 75%;
                }
                @media #{$xs-layout} {
                    width: 80%;
                }
            }
        }
        h3 {
            font-size: 24px;
            color: $theme-color-pink;
            margin: 0;
        }
    }
}

.btn-style-13 {
    a {
        display: inline-block;
        text-transform: uppercase;
        color: $white;
        padding: 12px 38px;
        background-color: #181818;
        &:hover {
            background-color: $theme-color-pink;
        }
    }
}

.shop-banner-area {
	padding: 90px 0 100px;
    @media #{$xs-layout} {
        padding: 60px 0 60px;
    }
}
.shop-banner-content {
    margin: 0 0 0 -50px;
    @media #{$xl-layout} {
        margin: 0 0 0 0px;
    }
    @media #{$lg-layout} {
        margin: 0 0 0 0px;
    }
    @media #{$md-layout} {
        margin: 0 0 0 0px;
    }
    @media #{$xs-layout} {
        margin: 0 0 0 0px;
    }
    h1 {
        font-size: 66px;
        color: $white;
        text-transform: uppercase;
        line-height: 1;
        margin: 0 0 13px;
        @media #{$xx-layout} {
            font-size: 55px;
        }
        @media #{$xl-layout} {
            font-size: 55px;
        }
        @media #{$lg-layout} {
            font-size: 50px;
        }
        @media #{$md-layout} {
            font-size: 50px;
        }
        @media #{$xs-layout} {
            font-size: 36px;
        }
    }
}

@media #{$md-layout} {
    .banner-area {
        &.pb-90 {
            padding-bottom: 50px;
        }
        &.pb-95 {
            padding-bottom: 55px;
        }
    }
}

@media #{$xs-layout} {
    .banner-area {
        &.pb-90 {
            padding-bottom: 25px;
        }
        &.pb-95 {
            padding-bottom: 30px;
        }
    }
}





