
/*-------- 13. Others style ---------*/


.support-lists {
	position: fixed;
	top: 70%;
	right: 50px;
	list-style: none;
	width: 60px;
	background: #FFFFFF;
	box-shadow: 0 0 14px 0 rgba(0,0,0,0.16);
	border-radius: 30px;
	text-align: center;
	padding-top: 20px;
	padding-bottom: 15px;
	z-index: 99;
    @media #{$md-layout} {
        right: 15px;
        width: 40px;
        top: 75%;
    }
    @media #{$xs-layout} {
        right: 15px;
        width: 50px;
        top: 75%;
        display: none;
    }
    ul {
        li {
            display: block;
            a {
                font-size: 20px;
                height: 40px;
                line-height: 40px;
                color: #6D6D6D;
                &:hover {
                    color: $theme-color-yellow;
                }
            }
        }
    }
}

.view-demo-area {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	width: 0;
	z-index: 9999;
    > a {
        color: #fff;
        background: #282828;
        font-size: 14px;
        line-height: 18px;
        border: none;
        width: 76px;
        height: 76px;
        text-align: left;
        padding: 20px 10px 10px;
        position: absolute;
        right: 520px;
        top: 60%;
        transition: all 400ms ease-out;
        transform: translate(520px, 0);
        @media #{$xs-layout} {
            right: 200px;
            transform: translate(200px, 0);
            font-size: 12px;
            width: 60px;
            height: 60px;
            padding: 10px 10px 10px;
            top: 52%;
        }
        @media #{$sm-layout} {
            right: 400px;
            transform: translate(400px, 0);
        }
    }
    .view-demo-aside-active {
        position: absolute;
        width: 520px;
        background: #fff;
        top: 0;
        right: 0;
        height: 100%;
        padding: 50px 50px;
        box-shadow: 0 1px 26px 0 rgba(0,0,0,0.13);
        visibility: hidden;
        opacity: 0;
        transition: all 400ms ease-out;
        transform: translate(520px, 0);
        overflow: auto;
        @media #{$xs-layout} {
            transform: translate(200px, 0);
            width: 200px;
            padding: 40px 15px;
        }
        @media #{$sm-layout} {
            transform: translate(400px, 0);
            width: 400px;
        }
    }
    &.demo-visible {
        > a {
            transform: translate(0px, 0);
        }
        .view-demo-aside-active {
            visibility: visible;
            opacity: 1;
            transform: translate(0, 0);
        }
    }
}

.demo-item {
    transition: all .3s ease 0s;
    .demo-item-img {
        position: relative;
        a {
            display: block;
            img {
                width: 100%;
            }
        }
    }
    .demo-item-content {
        h4 {
            font-size: 13px;
            margin: 0;
            color: #282828;
            padding: 8px 0 15px 0;
            transition: all .3s ease 0s;
            a {
                color: #282828;
            }
        }
    }
    &:hover {
        box-shadow: 0 1px 26px 0 rgba(0,0,0,.13);
        .demo-item-content {
            h4 {
                padding: 8px 0 15px 12px;
                font-weight: bold;
            }
        }
    }
}

.discover-more-btn {
    a {
        display: inline-block;
        color: #fff;
        background-color: #181818;
        line-height: 1;
	    padding: 18px 30px;
        &:hover {
            background-color: $theme-color-yellow;
        }
    }
}

.modal-backdrop.show {
	opacity: .7;
}
.modal-dialog {
	margin: 7% auto 8%;
	max-width: 1220px;
	width: 1220px;
    @media #{$xl-layout} {
        width: 1120px;
        max-width: 1120px;
    }
    @media #{$lg-layout} {
        width: 920px;
        max-width: 920px;
    }
    @media #{$md-layout} {
        width: 700px;
        max-width: 700px;
    }
    @media #{$xs-layout} {
        width: 100%;
        max-width: 100%;
        padding: 35px 0;
    }
    @media #{$sm-layout} {
        width: 80%;
        max-width: 80%;
    }
    .modal-content {
        border-radius: 0;
        .modal-header {
            border: none;
            padding: 0;
            right: -22px;
            top: -24px;
            position: absolute;
            z-index: 9999;
            @media #{$xs-layout} {
                right: 0px;
                top: -34px;
            }
            @media #{$sm-layout} {
                right: -22px;
                top: -24px;
            }
            .close {
                color: #fff;
                cursor: pointer;
                opacity: 1;
                padding: 0;
                margin: 0;
                width: 50px;
                height: 50px;
                line-height: 53px;
                background-color: #292929;
                border-radius: 100%;
                font-size: 18px;
                text-shadow: none;
                font-weight: 300;
                transition: all .3s ease 0s;
                @media #{$xs-layout} {
                    width: 34px;
                    height: 34px;
                    line-height: 34px;
                    font-size: 15px;
                }
                &:hover {
                    color: $theme-color-yellow;
                    transform: rotate(90deg);
                }
            }
        }
        .modal-body {
            padding: 0px;
            @media #{$md-layout} {
                overflow-y: auto;
                max-height: 500px;
                height: 500px;
            }
            @media #{$xs-layout} {
                overflow-y: auto;
                max-height: 462px;
                height: 462px;
            }
        }
    }
}

.quickview-slider-active {
    .single-quickview-slider {
        a {
            img {
                width: 100%;
                height: 100%;
                display: block;
            }
        }
    }
}

.quickview-content-padding {
    padding: 40px 35px 20px;
    @media #{$lg-layout} {
        padding: 35px 20px 20px;
    }
    @media #{$md-layout} {
        padding: 40px 35px 45px;
    }
    @media #{$xs-layout} {
        padding: 30px 15px 35px;
    }
}

.social-border {
    position: relative;
    display: flex;
    justify-content: center;
    &:after {
        position: absolute;
        left: 0;
        top: 12px;
        content: "";
        width: 100%;
        height: 1px;
        background-color: #D8D8D8;
        z-index: 5;
    }
}

.social-icon-style {
    a {
        font-size: 18px;
        color: #B4B4B4;
        display: inline-block;
        margin-right: 32px;
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            color: #fff;
        }
    }
    @media #{$xs-layout} {
        &.mt-35 {
            margin-top: 20px;
        }
    }
    &.social-icon-black {
        a {
            color: #181818;
            &:hover {
                color: $theme-color-yellow;
            }
        }
    }
    &.social-icon-leather {
        margin: 0px 0 12px;
        padding: 0 20px;
        display: inline-block;
        background-color: #fff;
        position: relative;
        z-index: 9;
    }
}

.order-wrap {
    form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media #{$md-layout} {
            justify-content: center;
        }
        select {
            margin-right: 20px;
            border: 1px solid #C1C1C1;
            height: 80px;
            font-size: 18px;
            color: #181818;
            padding: 0 25px 0 20px;
            flex: 0 0 20.5%;
            @media #{$xx-layout} {
                flex: 0 0 19%;
            }
            @media #{$xl-layout} {
                flex: 0 0 19%;
            }
            @media #{$lg-layout} {
                flex: 0 0 17.5%;
            }
            @media #{$md-layout} {
                flex: 0 0 30%;
                margin-bottom: 20px;
                margin-right: 10px;
                margin-left: 10px;
            }
            @media #{$xs-layout} {
                flex: 0 0 100%;
                margin-bottom: 20px;
            }
            @media #{$lg-layout} {
                padding: 0 25px 0 10px;
            }
        }
        button {
            &.order-btn {
                color: $white;
                background-color: $theme-color-red;
                font-size: 18px;
                font-weight: 600;
                line-height: 1;
                padding: 10px 60px;
                border: none;
                display: inline-block;
                transition: all .3s ease 0s;
                height: 80px;
                &:hover {
                    background-color: #181818;
                }
            }
        }
    }
}

.app-area {
    position: relative;
    padding: 75px 0 70px;
    @media #{$md-layout} {
        padding: 60px 0 60px;
    }
    @media #{$xs-layout} {
        padding: 50px 0 40px;
    }
    .organic-app-shape {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        img {
            max-width: 100%;
        }
    }
}

.app-content {
    h2 {
        font-size: 32px;
        font-weight: 600;
        line-height: 44px;
        margin: 0;
        width: 70%;
        @media #{$md-layout} {
            font-size: 28px;
            width: 100%;
        }
        @media #{$xs-layout} {
            font-size: 25px;
            width: 100%;
        }
    }
    p {
        line-height: 28px;
        width:77%;
        margin: 15px 0 30px;
        @media #{$md-layout} {
            width:100%;
            margin: 15px 0 20px;
        }
        @media #{$xs-layout} {
            width:100%;
            margin: 10px 0 20px;
        }
    }
}

.app-img {
    position: relative;
    z-index: 9;
	float: right;
    margin-right: -70px;
    @media #{$xl-layout} {
        margin-right: 0px;
    }
    @media #{$lg-layout} {
        margin-right: 0px;
    }
    @media #{$md-layout} {
        margin-right: 0px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
        margin-top: 10px;
    }
    img {
        max-width: 100%;
    }
}

.team-wrap-3 {
    .team-img-3 {
        > a {
            display: block;
            img {
                width: 100%;
            }
        }
        .team-social-wrap {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            text-align: center;
            margin-top: 20px;
            z-index: 99;
            transition: all .35s ease 0s;
            opacity: 0;
            visibility: hidden;
        }
    }
    .team-content-3 {
        .team-info-3 {
            display: flex;
            @media #{$xs-layout} {
                display: block;
            }
            h4 {
                margin: 0 10px 0 0px;
                font-size: 18px;
                font-weight: 600;
                @media #{$xs-layout} {
                    margin: 0px 10px 5px 0px;
                }
                a {
                    color: #211e1c;
                    &:hover {
                        color: $theme-color-yellow;
                    }
                }
            }
            span {
                font-size: 14px;
                color: #6d6d6d;
                display: inline-block;
                font-family: $playfair;
                font-style: italic;
            }
        }
        p {
            line-height: 25px;
            margin: 10px 0 15px;
        }
        &.team-content-3-bg {
            background-color: #F9F9F9;
            padding: 40px 40px 37px 40px;
            @media #{$xl-layout} {
                padding: 30px 10px 27px 20px;
            }
            @media #{$lg-layout} {
                padding: 30px 10px 27px 20px;
            }
            @media #{$md-layout} {
                padding: 30px 20px 27px 20px;
            }
            @media #{$xs-layout} {
                padding: 20px 15px 17px 15px;
            }
            .team-info-3 {
                h4 {
                    font-size: 20px;
                    @media #{$lg-layout} {
                        font-size: 16px;
                    }
                    @media #{$md-layout} {
                        font-size: 17px;
                    }
                }
                span {
                    font-size: 16px;
                    @media #{$lg-layout} {
                        font-size: 14px;
                    }
                    @media #{$md-layout} {
                        font-size: 15px;
                    }
                }
            }
            p {
                margin: 10px 0 0px;
            }
        }
    }
    &:hover {
        .team-img-3 {
            &.hover-overly-point-4 {
                &:before {
                    opacity: .4;
                    background-color: #212121;
                    pointer-events: none;
                }
            }
            .team-social-wrap {
                margin-top: 0px;
                opacity: 1;
                visibility: visible;
            }
        }
    }
    @media #{$lg-layout} {
        &.mb-80 {
            margin-bottom: 30px;
        }
    }
    @media #{$md-layout} {
        &.mb-80 {
            margin-bottom: 30px;
        }
    }
    @media #{$xs-layout} {
        &.mb-65 {
            margin-bottom: 35px;
        }
        &.mb-80 {
            margin-bottom: 30px;
        }
    }
}

.team-social {
    a {
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border-radius: 100%;
        display: inline-block;
        color: #3E3E3E;
        border: 1px solid #E1E1E1;
        margin-right: 8px; 
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            background-color: $theme-color-yellow;
            color: $white;
            border: 1px solid $theme-color-yellow;
        }
    }
    &.team-social-2 {
        a {
            color: #fff;
            border: 1px solid #fff;
            &:last-child {
                margin-right: 0;
            }
            &:hover {
                background-color: $theme-color-yellow;
                color: $white;
                border: 1px solid $theme-color-yellow;
            }
        }
    }
}

.error-area {
    padding: 120px 0;
    @media #{$xs-layout} {
        padding: 60px 0;
    }
}
.error-content {
    img {
        max-width: 100%;
        @media #{$lg-layout} {
            max-width: 500px;
        }
        @media #{$md-layout} {
            max-width: 400px;
        }
        @media #{$xs-layout} {
            max-width: 280px;
        }
    }
    h1 {
        font-size: 46px;
        font-family: $playfair;
        font-style: italic;
        margin: 10px 0 36px;
        @media #{$xs-layout} {
            font-size: 35px;
            margin: 10px 0 25px;
        }
    }
    .error-btn {
        a {
            display: inline-block;
            color: #444;
            line-height: 1;
            border: 1px solid #aaa;
            padding: 22px 55px;
            @media #{$xs-layout} {
                padding: 15px 25px;
            }
            &:hover {
                color: $white;
                background-color: $theme-color-yellow;
                border: 1px solid $theme-color-yellow;
            }
        }
    }
}

.coming-soon-area {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
    position: relative;
    overflow: hidden;
    @media #{$xs-layout} {
        padding: 100px 0;
    }
    .coming-soon-content {
        width: 100%;
        position: relative;
        z-index: 99;
        @media #{$xx-layout} {
            margin-top: 50px;
        }
        @media #{$xl-layout} {
            margin-top: 50px;
        }
        @media #{$lg-layout} {
            margin-top: 50px;
        }
        @media #{$md-layout} {
            margin-top: 50px;
        }
        h1 {
            font-size: 130px;
            letter-spacing: 8px;
            color: #6d6d6d;
            text-transform: uppercase;
            margin: 0;
            line-height: 97px;
            @media #{$xx-layout} {
                font-size: 80px;
                line-height: 80px;
                letter-spacing: 6px;
            }
            @media #{$xl-layout} {
                font-size: 80px;
                line-height: 80px;
                letter-spacing: 6px;
            }
            @media #{$lg-layout} {
                font-size: 70px;
                line-height: 60px;
                letter-spacing: 6px;
            }
            @media #{$md-layout} {
                font-size: 65px;
                line-height: 60px;
                letter-spacing: 4px;
            }
            @media #{$xs-layout} {
                font-size: 30px;
                line-height: 35px;
                letter-spacing: 3px;
            }
        }
        p {
            font-size: 23px;
            letter-spacing: 2px;
            color: #6d6d6d;
            text-transform: uppercase;
            margin: 51px 0 44px;
            @media #{$xx-layout} {
                margin: 25px 0 25px;
            }
            @media #{$xl-layout} {
                margin: 25px 0 25px;
            }
            @media #{$lg-layout} {
                margin: 20px 0 20px;
                font-size: 20px;
            }
            @media #{$md-layout} {
                margin: 20px 0 20px;
                font-size: 20px;
            }
            @media #{$xs-layout} {
                font-size: 20px;
                letter-spacing: 1px;
                margin: 12px 0 10px;
            }
        }
        form {
            .coming-soon-input {
                margin: 0 15px 30px;
                @media #{$xs-layout} {
                    margin: 0 0px 15px;
                }
                input {
                    background-color: transparent;
                    border: none;
                    border-bottom: 2px solid #6d6d6d;
                    color: #6d6d6d;
                    font-weight: 600;
                    font-size: 18px;
                    padding: 2px 0;
                    height: 55px;
                    @media #{$xs-layout} {
                        font-size: 16px;
                        height: 50px;
                    }
                }
            }
            .coming-soon-btn {
                button {
                    border: 1px solid #6D6D6D;
                    color: #6D6D6D;
                    font-size: 24px;
                    background-color: transparent;
                    padding: 32px 100px 36px;
                    transition: all .35s ease 0s;
                    @media #{$xx-layout} {
                        padding: 25px 60px 29px;
                    }
                    @media #{$xl-layout} {
                        padding: 25px 60px 29px;
                    }
                    @media #{$lg-layout} {
                        padding: 20px 50px 24px;
                    }
                    @media #{$md-layout} {
                        padding: 20px 50px 24px;
                    }
                    @media #{$xs-layout} {
                        padding: 12px 40px 16px;
                        font-size: 20px;
                    }
                    &:hover {
                        background-color: #181818;
                        color: $white;
                        border: 1px solid #181818;
                    }
                }
            }
            @media #{$lg-layout} {
                margin: 0 30px;
            }
        }
    }
    .coming-soon-img-1 {
        position: absolute;
        top: 5% !important;
        left: 66% !important;
        z-index: 9;
        @media #{$xs-layout} {
            top: 15% !important;
            left: 50% !important;
        }
        @media #{$sm-layout} {
            left: 65% !important;
        }
        img {
            width: 480px;
            @media #{$xx-layout} {
                width: 320px;
            }
            @media #{$xl-layout} {
                width: 320px;
            }
            @media #{$lg-layout} {
                width: 300px;
            }
            @media #{$md-layout} {
                width: 280px;
            }
            @media #{$xs-layout} {
                width: 150px;
            }
        }
    }
}

.coming-soon-social {
    margin: 80px 0 0;
    @media #{$xx-layout} {
        margin: 50px 0 0;
    }
    @media #{$xl-layout} {
        margin: 50px 0 0;
    }
    @media #{$lg-layout} {
        margin: 40px 0 0;
    }
    @media #{$md-layout} {
        margin: 30px 0 0;
    }
    @media #{$xs-layout} {
        margin: 30px 0 0;
    }
    a {
        border: 1px solid #E1E1E1;
        font-size: 30px;
        margin-right: 30px;
        width: 62px;
        height: 62px;
        line-height: 62px;
        display: inline-block;
        color: #6D6D6D;
        border-radius: 50%;
        text-align: center;
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            background-color: #181818;
            color: $white;
            border: 1px solid #181818;
        }
        @media #{$lg-layout} {
            font-size: 20px;
            margin-right: 20px;
            width: 52px;
            height: 52px;
            line-height: 52px;
        }
        @media #{$md-layout} {
            font-size: 20px;
            margin-right: 20px;
            width: 52px;
            height: 52px;
            line-height: 52px;
        }
        @media #{$xs-layout} {
            font-size: 18px;
            margin-right: 10px;
            width: 45px;
            height: 45px;
            line-height: 45px;
        }
    }
    &.cs-social-white {
        margin: 105px 0 80px;
        @media #{$xx-layout} {
            margin: 50px 0 50px;
        }
        @media #{$xl-layout} {
            margin: 40px 0 40px;
        }
        @media #{$lg-layout} {
            margin: 40px 0 40px;
        }
        @media #{$md-layout} {
            margin: 30px 0 30px;
        }
        @media #{$xs-layout} {
            margin: 30px 0 20px;
        }
        a {
            border: 1px solid #fff;
            color: #fff;
            &:hover {
                background-color: #181818;
                color: $white;
                border: 1px solid #181818;
            }
        }
    }
}

.coming-soon-area-2 {
    height: 100vh;
	display: flex;
	align-items: center;
    .coming-soon-content-2 {
        width: 100%;
        h1 {
            font-size: 66px;
            letter-spacing: 4px;
            margin: 0 0 0px;
            color: $white;
            line-height: 60px;
            @media #{$xx-layout} {
                font-size: 48px;
                line-height: 44px;
                letter-spacing: 2px;
            }
            @media #{$xl-layout} {
                font-size: 46px;
                line-height: 44px;
                letter-spacing: 2px;
            }
            @media #{$lg-layout} {
                font-size: 42px;
                line-height: 40px;
                letter-spacing: 2px;
            }
            @media #{$md-layout} {
                font-size: 42px;
                line-height: 40px;
                letter-spacing: 2px;
            }
            @media #{$xs-layout} {
                font-size: 25px;
                line-height: 28px;
                letter-spacing: 0px;
            }
        }
        p {
            font-size: 18px;
            line-height: 36px;
            margin: 23px 0 75px;
            color: $white;
            @media #{$xx-layout} {
                margin: 20px 0 30px;
            }
            @media #{$xl-layout} {
                margin: 20px 0 30px;
            }
            @media #{$lg-layout} {
                margin: 20px 0 30px;
            }
            @media #{$md-layout} {
                margin: 20px 0 30px;
            }
            @media #{$xs-layout} {
                margin: 10px 0 25px;
                line-height: 28px;
                font-size: 15px;
            }
        }
        .coming-soon-btn-2 {
            button {
                display: inline-block;
                font-size: 24px;
                color: $white;
                background-color: rgba(255,255,255,.25);
                border: none;
                padding: 35px 110px 36px;
                transition: all .35s ease 0s;
                @media #{$xx-layout} {
                    padding: 30px 80px 31px;
                }
                @media #{$xl-layout} {
                    padding: 30px 80px 31px;
                }
                @media #{$lg-layout} {
                    padding: 30px 80px 31px;
                }
                @media #{$md-layout} {
                    padding: 22px 50px 23px;
                    font-size: 20px;
                }
                @media #{$xs-layout} {
                    padding: 12px 30px 13px;
                    font-size: 18px;
                }
                &:hover {
                    background-color: #181818;
                }
            }
        }
        .cs-copyright {
            > p {
                color: #ffffff;
                font-size: 14px;
                margin: 0;
                line-height: 24px;
                a {
                    color: $white;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.coming-soon-logo {
    position: absolute;
    left: 120px;
    top: 60px;
    @media #{$xx-layout} {
        top: 40px;
    }
    @media #{$xl-layout} {
        top: 40px;
        left: 45px;
    }
    @media #{$lg-layout} {
        top: 40px;
        left: 60px;
    }
    @media #{$md-layout} {
        top: 30px;
        left: 50px;
    }
    @media #{$xs-layout} {
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
    }
    a {
        display: block;
        img {
            width: 144px;
            @media #{$lg-layout} {
                width: 150px;
            }
            @media #{$md-layout} {
                width: 150px;
            }
            @media #{$xs-layout} {
                width: 120px;
            }
        }
    }
}

.coming-soon-logo-2 {
    margin-bottom: 145px;
    @media #{$xx-layout} {
        margin-bottom: 70px;
    }
    @media #{$xl-layout} {
        margin-bottom: 70px;
    }
    @media #{$lg-layout} {
        margin-bottom: 50px;
    }
    @media #{$md-layout} {
        margin-bottom: 50px;
    }
    @media #{$xs-layout} {
        margin-bottom: 30px;
    }
    a {
        display: block;
        img {
            width: 144px;
            @media #{$lg-layout} {
                width: 150px;
            }
            @media #{$md-layout} {
                width: 150px;
            }
            @media #{$xs-layout} {
                width: 120px;
            }
        }
    }
}

.coming-soon-area-3 {
    height: 100vh;
    display: flex;
    align-items: center;
    @media #{$xs-layout} {
        .coming-soon-logo-2 {
            text-align: center;
        }
    }
}

.coming-soon-content-3 {
    h1 {
        font-size: 100px;
        color: #fff;
        margin: 0;
        @media #{$xx-layout} {
            font-size: 70px;
        }
        @media #{$xl-layout} {
            font-size: 70px;
        }
        @media #{$lg-layout} {
            font-size: 60px;
        }
        @media #{$md-layout} {
            font-size: 50px;
        }
        @media #{$xs-layout} {
            font-size: 27px;
        }
        @media #{$sm-layout} {
            font-size: 35px;
        }
    }
    .timer-style-1 {
        margin: 45px 0 39px;
        @media #{$xx-layout} {
            margin: 35px 0 39px;
        }
        @media #{$xl-layout} {
            margin: 35px 0 39px;
        }
        @media #{$lg-layout} {
            margin: 30px 0 30px;
        }
        @media #{$md-layout} {
            margin: 30px 0 30px;
        }
        @media #{$xs-layout} {
            margin: 20px 0 30px;
        }
        div {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            > span {
                margin: 0 38px;
                @media #{$lg-layout} {
                    margin: 0 25px;
                }
                @media #{$md-layout} {
                    margin: 0 20px;
                }
                @media #{$xs-layout} {
                    margin: 0 8px;
                }
                @media #{$sm-layout} {
                    margin: 0 15px;
                }
                > span {
                    font-size: 49px;
                    color: #fff;
                    display: block;
                    line-height: 40px;
                    @media #{$lg-layout} {
                        font-size: 40px;
                    }
                    @media #{$md-layout} {
                        font-size: 40px;
                    }
                    @media #{$xs-layout} {
                        font-size: 30px;
                    }
                }
                > p {
                    font-size: 18px;
                    color: #fff;
                    margin: 10px 0 0;
                    line-height: 1;
                    @media #{$xs-layout} {
                        font-size: 16px;
                        margin: 7px 0 0;
                    }
                }
            }
        }
    }
    .coming-soon-btn-3 {
        button {
            border: 1px solid #fff;
            background-color: transparent;
            font-size: 24px;
            padding: 37px 110px;
            color: #fff;
            transition: all .3s ease 0s;
            @media #{$xx-layout} {
                padding: 30px 80px;
            }
            @media #{$xl-layout} {
                padding: 30px 80px;
            }
            @media #{$lg-layout} {
                padding: 25px 70px;
                font-size: 22px;
            }
            @media #{$md-layout} {
                padding: 22px 60px;
                font-size: 22px;
            }
            @media #{$xs-layout} {
                padding: 12px 30px;
                font-size: 17px;
            }
            &:hover {
                background-color: #181818;
                border: 1px solid #181818;
            }
        }
    }
}
.coming-soon-3-footer {
	display: flex;
	justify-content: space-between;
    align-items: center;
    margin: 185px 0 0 0;
    @media #{$xx-layout} {
        margin: 80px 0 0 0;
    }
    @media #{$xl-layout} {
        margin: 80px 0 0 0;
    }
    @media #{$lg-layout} {
        margin: 60px 0 0 0;
    }
    @media #{$md-layout} {
        margin: 50px 0 0 0;
    }
    @media #{$xs-layout} {
        display: block;
        text-align: center;
        margin: 30px 0 0 0;
    }
    .cs-copyright-2 {
        > p {
            color: #ffffff;
            font-size: 14px;
            margin: 0;
            line-height: 24px;
            a {
                color: $white;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .coming-soon-social-2 {
        @media #{$xs-layout} {
            margin: 15px 0 0;
        }
        a {
            border: 1px solid #fff;
            font-size: 20px;
            margin-right: 12px;
            width: 42px;
            height: 42px;
            line-height: 42px;
            display: inline-block;
            color: #fff; 
            border-radius: 50%;
            text-align: center;
            &:last-child {
                margin-right: 0;
            }
            @media #{$xs-layout} {
                font-size: 18px;
            }
            &:hover {
                background-color: #181818;
                color: $white;
                border: 1px solid #181818;
            }
        }
    }
}

.shop-locator-area {
	padding: 115px 0 70px;
    @media #{$xs-layout} {
        padding: 55px 0 10px;
    }
}
.single-store {
    h3 {
        font-size: 24px;
        font-weight: 500;
        text-transform: capitalize;
        line-height: 28px;
        margin-bottom: 15px;
        @media #{$xs-layout} {
            font-size: 20px;
            margin-bottom: 12px;
        }
    }
    ul {
        li {
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0;
            }
            a {
                
            }
        }
    }
    @media #{$md-layout} {
        &.mb-50 {
            margin-bottom: 40px;
        }
    }
    @media #{$xs-layout} {
        &.mb-50 {
            margin-bottom: 30px;
        }
    }
}
.team-details-img {
    img {
        width: 100%;
    }
}

.team-details-content {
    margin: 0 0 0 30px;
    @media #{$lg-layout} {
        margin: 0 0 0 0px;
    }
    @media #{$md-layout} {
        margin: 0 0 0 0px;
    }
    @media #{$xs-layout} {
        margin: 23px 0 0 0px;
    }
    h2 {
        font-size: 20px;
        margin: 0 0 0px;
        font-weight: 600;
    }
    > span {
        font-size: 16px;
        color: #333;
        font-family: $playfair;
        font-style:italic;
        display: block;
        margin: 10px 0 25px;
        @media #{$xs-layout} {
            margin: 5px 0 15px;
        }
    }
    p {
        line-height: 25px;
        margin: 0 0 15px;
    }
    .team-details-icon {
        position: relative;
        overflow: hidden;
        margin: 40px 0 42px;
        @media #{$lg-layout} {
            margin: 20px 0 22px;
        }
        @media #{$md-layout} {
            margin: 20px 0 22px;
        }
        @media #{$xs-layout} {
            margin: 20px 0 22px;
        }
        &::before {
            background: #eaeaea none repeat scroll 0 0;
            top: 14px;
            content: "";
            height: 1px;
            left: 0;
            position: absolute;
            width: 100%;
        }
        ul {
            float: right;
            display: inline-block;
            background-color: #fff;
            position: relative;
            li {
                margin-left: 10px;
                display: inline-block;
                a {
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    color: #fff;
                    border-radius: 100%;
                    border: 1px solid transparent;
                    &.facebook {
                        background-color: #3b5999;
                    }
                    &.twitter {
                        background-color: #55acee;
                    }
                    &.instagram {
                        background-color: #e4405f;
                    }
                    &.google-plus {
                        background-color: #dd4b39;
                    }
                    &:hover {
                        &.facebook {
                            color: #3b5999;
                            border: 1px solid #3b5999;
                            background-color: transparent;
                        }
                        &.twitter {
                            color: #55acee;
                            border: 1px solid #55acee;
                            background-color: transparent;
                        }
                        &.instagram {
                            color: #e4405f;
                            border: 1px solid #e4405f;
                            background-color: transparent;
                        }
                        &.google-plus {
                            color: #dd4b39;
                            border: 1px solid #dd4b39;
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
    .team-details-info {
        ul {
            li {
                margin: 0 0 15px;
                color: #333;
                &:last-child {
                    margin-bottom: 0;
                }
                i {
                    font-size: 18px;
                    color: $theme-color-yellow;
                    min-width: 30px;
                    @media #{$xs-layout} {
                        font-size: 16px;
                        min-width: 25px;
                    }
                }
                span {
                    color: #211E1C;
                    font-weight: 500;
                    min-width: 70px;
                    display: inline-block;
                    @media #{$xs-layout} {
                        min-width: 60px;
                    }
                }
            }
        }
    }
}





@media #{$lg-layout} {
    .team-area {
        &.pb-120 {
            padding-bottom: 120px; 
        }
    }
}
@media #{$md-layout} {
    .team-area {
        &.pb-120 {
            padding-bottom: 120px; 
        }
    }
}


@media #{$xs-layout} {
    .team-area {
        &.pt-120 {
            padding-top: 60px; 
        }
        &.pb-55 {
            padding-bottom: 25px; 
        }
        &.pb-120 {
            padding-bottom: 55px; 
        }
    }
}




