
/*-------- 8. Testimonial style ---------*/

.quote-img {
    text-align: center;
    svg {
        width: 42px;
        display: inline-block;
        @media #{$xs-layout} {
            width: 38px;
        }
        img {
            width: 100%;
        }
    }
}

.single-testimonial {
    p {
        color: #181818;
        font-family: $playfair;
        font-size: 36px;
        font-style: italic;
        line-height: 1.4em;
        letter-spacing: -1px;
        width: 63%;
        margin: 22px auto 30px;
        @media #{$lg-layout} {
            width: 80%;
        }
        @media #{$md-layout} {
            width: 85%;
            font-size: 30px;
            margin: 30px auto 30px;
        }
        @media #{$xs-layout} {
            width: 100%;
            font-size: 22px;
            line-height: 1.6em;
            margin: 20px auto 25px;
            letter-spacing: 0px;
        }
    }
    .client-info {
        margin: 0 0 26px;
        img {
            display: inline-block;
            width: 70px;
            height: 70px;
        }
        h3 {
            font-size: 18px;
            color: #6D6D6D;
            margin: 20px 0 0;
        }
    }
}

.single-testimonial-2 {
    .testimonial-rating {
        i {
            color: #D98719;
            font-size: 16px;
        }
    }
    p {
        color: #fff;
        font-size: 20px;
        line-height: 40px;
        margin: 4px auto 0;
        width: 63%;
        @media #{$md-layout} {
            width: 75%;
        }
        @media #{$xs-layout} {
            width: 100%;
            font-size: 14px;
            line-height: 30px;
        }
    }
    .client-info-2 {
        text-align: center;
        margin: 21px 0 0;
        img {
            display: inline-block;
            width: 50px;
        }
        h3 {
            font-size: 22px;
            color: $white;
            margin: 15px 0 0;
        }
    }
}


.dot-style-1 {
    ul {
        display: flex;
        justify-content: center;
        li {
            button {
                width: 12px;
                height: 12px;
                border: none;
                padding: 0;
                font-size: 0;
                border-radius: 100%;
                margin: 0 10px;
                background-color: #d8d8d8;
                transition: all .3s ease 0s;
            }
            &.slick-active {
                button {
                    background-color: #3E3E3E;
                }
            }
            &:hover {
                button {
                    background-color: #3E3E3E;
                }
            }
        }
    }
    &.dot-active-yellow {
        ul {
            li {
                &.slick-active {
                    button {
                        background-color: $theme-color-yellow;
                    }
                }
                &:hover {
                    button {
                        background-color: $theme-color-yellow;
                    }
                }
            }
        }
    }
    &.dot-style-1-white {
        ul {
            margin-top: 28px;
            li {
                button {
                    background-color: #fff;
                }
                &.slick-active {
                    button {
                        background-color: $theme-color-red;
                    }
                }
                &:hover {
                    button {
                        background-color: $theme-color-red;
                    }
                }
            }
        }
    }
    &.dot-style-1-plants {
        ul {
            margin-top: 18px;
            li {
                button {
                    background-color: rgba(255,255,255,.32);
                }
                &.slick-active {
                    button {
                        background-color: $white;
                    }
                }
                &:hover {
                    button {
                        background-color: $white;
                    }
                }
            }
        }
    }
    &.dot-style-1-black {
        ul {
            li {
                button {
                    background-color: #181818;
                }
                &.slick-active {
                    button {
                        background-color: $theme-color-pink;
                    }
                }
                &:hover {
                    button {
                        background-color: $theme-color-pink;
                    }
                }
            }
        }
    }
}

.testimonial-active {
    position: relative;
    z-index: 9;
}

.testimonial-active-2 {
    .slick-list {
        margin: 0 -15px;
        .testimonial-wrap-lr-1 {
            padding: 30px 15px;
        }
    }
}

.single-testimonial-3 {
    background: $white;
    padding: 60px 45px 60px 45px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
    @media #{$xx-layout} {
        padding: 60px 35px 60px 35px;
    }
    @media #{$xl-layout} {
        padding: 40px 35px 40px 35px;
    }
    @media #{$lg-layout} {
        padding: 25px 20px 35px 20px;
    }
    @media #{$md-layout} {
        padding: 40px 20px 40px 20px;
    }
    @media #{$xs-layout} {
        padding: 40px 25px 40px 25px;
    }
    @media #{$sm-layout} {
        padding: 30px 25px 30px 25px;
    }
    p {
        color: #181818;
        font-size: 18px;
        line-height: 2em;
        font-weight: 500;
        font-family: $noto;
        margin: 0;
        @media #{$xx-layout} {
            font-size: 17px;
        }
        @media #{$xl-layout} {
            font-size: 17px;
        }
        @media #{$lg-layout} {
            font-size: 14px;
        }
        @media #{$md-layout} {
            font-size: 16px;
        }
        @media #{$xs-layout} {
            font-size: 16px;
        }
        @media #{$sm-layout} {
            font-size: 14px;
        }
        &.playfair {
            color: #181818;
            font-size: 18px;
            line-height: 33px;
            font-family: $playfair;
            font-weight: 400;
            @media #{$lg-layout} {
                font-size: 18px; 
            }
            @media #{$xs-layout} {
                font-size: 17px; 
                line-height: 30px;
            }      
            @media #{$sm-layout} {
                font-size: 15px; 
            }
        }
    }
    .testimonial-rating-2 {
        margin: 21px 0 16px;
        i {
            color: #F5A623;
            font-size: 13px;
        }
    }
    .client-info-2 {
        display: flex;
        align-items: center;
        img {
            width: 50px;
        }
        h3 {
            font-size: 18px;
            color: #6D6D6D;
            margin: 0 0 0px 20px;
            @media #{$lg-layout} {
                font-size: 15px;
            }
            @media #{$md-layout} {
                font-size: 16px;
            }
            @media #{$xs-layout} {
                font-size: 16px;
            }
            &.inc-font-size {
                color: #6D6D6D; 
                font-size: 20px;
                @media #{$xs-layout} {
                    font-size: 16px; 
                }
            }
        }
    }
}

.single-testimonial-4 {
    .testimonial-rating-3 {
        i {
            color: $theme-color-yellow;
        }
    }
    p {
        font-size: 24px;
        line-height: 40px;
        color: $white;
        font-weight: 500;
        font-family: $noto;
        margin: 20px auto 37px;
        width: 95%;
        @media #{$xx-layout} {
            width: 96%;
        }
        @media #{$lg-layout} {
            font-size: 22px;
        }
        @media #{$md-layout} {
            width: 80%;
            font-size: 22px;
            margin: 15px auto 25px;
        }
        @media #{$xs-layout} {
            width: 98%;
            font-size: 19px;
            line-height: 33px;
            margin: 14px auto 25px;
        }
        @media #{$sm-layout} {
            width: 87%;
        }
    }
    .client-info-3 {
        h4 {
            color: $white;
            font-family: $montserrat;
            font-size: 18px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 6px;
            margin: 0 0 8px;
        }
        span {
            color: rgba(255,255,255,.72);
            font-family: $montserrat;
            font-size: 16px;
            letter-spacing: 3px;
            display: block;
        }
    }
}


.testimonial-ptb {
    padding: 255px 0 207px;
    @media #{$xx-layout} {
        padding: 205px 0 157px;
    }
    @media #{$xl-layout} {
        padding: 205px 0 157px;
    }
    @media #{$lg-layout} {
        padding: 100px 0 48px;
    }
    @media #{$md-layout} {
        padding: 70px 0 22px;
    }
    @media #{$xs-layout} {
        padding: 55px 0 2px;
    }
}

.single-testimonial-5 {
    .quote-img-2 {
        svg {
            fill: $theme-color-pink;
        }
    }
    p {
        font-size: 20px;
        line-height: 38px;
        color: #6d6d6d;
        margin: 19px auto 20px;
        width: 53%;
        @media #{$lg-layout} {
            font-size: 20px;
            line-height: 46px;
            width: 83%;
        }
        @media #{$md-layout} {
            width: 86%;
            font-size: 18px;
            line-height: 40px;
        }
        @media #{$xs-layout} {
            width: 98%;
            font-size: 15px;
            line-height: 30px;
        }
    }
    .client-info-4 {
        h3 {
            font-size: 20px;
            font-family: $pacifico;
            color: #181818;
            margin: 0 0 23px;
        }
    }
}

.testimonial-ptb-2 {
    padding: 220px 0 220px;
    @media #{$xx-layout} {
        padding: 180px 0 180px;
    }
    @media #{$xl-layout} {
        padding: 150px 0 150px;
    }
    @media #{$lg-layout} {
        padding: 100px 0 100px;
    }
    @media #{$md-layout} {
        padding: 100px 0 100px;
    }
    @media #{$xs-layout} {
        padding: 90px 0 90px;
    }
}

.quote-img-3 {
    text-align: center;
    svg {
        fill: $white;
        width: 4.3em;
        height: 4.3em;
        display: inline-block;
    }
}

.single-testimonial-6 {
    position: relative;
    z-index: 999;
    p {
        font-size: 35px;
        line-height: 56px;
        font-family: $playfair;
        color: $white;
        font-style: italic;
        width: 73%;
        margin: 30px auto 51px;
        @media #{$lg-layout} {
            font-size: 32px;
            width: 83%;
            margin: 25px auto 30px;
        }
        @media #{$md-layout} {
            font-size: 28px;
            line-height: 50px;
            width: 100%;
            margin: 25px auto 35px;
        }
        @media #{$xs-layout} {
            font-size: 23px;
            line-height: 40px;
            width: 98%;
            margin: 15px auto 20px;
        }
    }
    .client-info-5 {
        h5 {
            font-size: 18px;
            color: $white;
            margin: 0 0 9px;
            color: #ddd;
        }
        span {
            font-size: 16px;
            color: rgba(255,255,255,.46);
            display: block;
        }
    }
}

.single-testimonial-7 {
    .testimonial-icon {
        text-align: center;
        img {
            display: inline-block;
        }
        svg {
            fill: $white;
            color: $white;
        }
    }
    p {
        font-size: 20px;
        line-height: 36px;
        color: #ffffff;
        margin: 25px auto 35px;
        width: 69%;
        @media #{$md-layout} {
            width: 75%;
        }
        @media #{$xs-layout} {
            font-size: 16px;
            line-height: 32px;
            margin: 15px auto 25px;
            width: 88%;
        }
    }
    .client-info-7 {
        img {
            display: inline-block;
            width: 50px;
        }
        h3 {
            font-size: 16px;
            color: $white;
            margin: 17px 0 0;
        }
    }
}





@media #{$xs-layout} {
    .testimonial-area {
        &.pb-100 {
            padding-bottom: 40px;
        }
        &.pb-115 {
            padding-bottom: 60px;
        }
        &.pt-115 {
            padding-top: 60px;
        }
    }
}













