/*-------- 30. Login register style ---------*/

.login-register-wrap {
    margin: 0 0 31px;
    &:last-child {
        margin-bottom: 0;
    }
    h3 {
        i {
            margin-right: 5px;
            position: relative;
            top: 2px;
        }
        font-size: 24px;
        margin: 0;
        font-family: $playfair;
        cursor: pointer;
    }
    .login-register-form {
        margin: 15px 0 0;
        @media #{$xs-layout} {
            margin: 10px 0 0;
        }
        .sin-login-register {
            margin: 0 0 16px;
            label {
                font-size: 16px;
                color: #6d6d6d;
                margin: 0 0 10px;
                display: block;
            }
            input {
                background: transparent;
                border: 1px solid #ebebeb;
                height: 50px;
                padding: 5px 10px;
                &:focus {
                    border: 1px solid #bbb;
                }
            }
        }
        .login-register-remember {
            input {
                width: 16px;
                height: auto;
                position: relative;
                top: 1px;
            }
            label {
                line-height: 1;
                margin: 0 0 0 9px;
                font-size: 16px;
                color: #6d6d6d;
            }
        }
        .lost-password {
            > a {
                font-size: 16px;
                color: $theme-color-yellow;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        p {
            color: #989898;
            font-size: 12px;
            margin: 0 0 16px;
            a {
                color: $theme-color-yellow;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.login-register-btn {
    margin: 10px 0 18px;
    button {
        border: none;
        background-color: #181818;
        color: #fff;
        font-size: 14px;
        padding: 18px 40px 18px;
        line-height: 1;
        display: block;
        padding: 18px 40px 20px;
        width: 100%;
        @media #{$xs-layout} {
            padding: 14px 30px 17px;
        }
        &:hover {
            background-color: $theme-color-yellow;
        }
    }
}

.vendor-customar-wrap {
    margin: 0 0 23px;
    label {
        display: flex;
        margin: 0 0 22px;
        color: #6d6d6d;
        font-size: 16px;
        line-height: 1;
        input {
            width: auto;
            height: auto;
            margin-right: 5px;
        }
    }
}

.register-wrap {
    .login-register-form {
        display: none;
        p {
            font-size: 14px;
            line-height: 28px;
            margin: 30px 0 15px;
            color: #6d6d6d;
            a {
                color: #6d6d6d;
                &:hover {
                    color: $theme-color-yellow;
                }
            }
        }
    }
}

.login-register-area {
	padding: 120px 0;
    @media #{$xs-layout} {
        padding: 60px 0;
    }
}
.login-register-mrg {
    margin: 0 35px;
    @media #{$lg-layout} {
        margin: 0 0px;
    }
    @media #{$md-layout} {
        margin: 0 0px;
    }
    @media #{$xs-layout} {
        margin: 0 0px;
    }
}

.vendor-customar-active {
	display: none;
}

