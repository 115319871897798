/*-------- 25. Order tracking style ---------*/

.order-tracking-area {
    position: relative;
    .order-tracking-img {
        height: 100%;
        padding: 0;
        position: absolute;
        top: 0;
        width: 50%;
        z-index: 2;
        @media #{$md-layout}{
            width: 100%;
            position: relative;
        }
        @media #{$xs-layout}{
            width: 100%;
            position: relative;
        }
        .order-tracking-banner-content {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            text-align: center;
            h2 {
                color: $white;
                font-size: 110px;
                margin: 0 0 35px;
                font-family: $playfair;
                font-style: italic;
                @media #{$xx-layout}{
                    font-size: 90px;
                    margin: 0 0 25px;
                }
                @media #{$xl-layout}{
                    font-size: 85px;
                    margin: 0 0 25px;
                }
                @media #{$lg-layout}{
                    font-size: 65px;
                    margin: 0 0 20px;
                }
                @media #{$md-layout}{
                    font-size: 85px;
                    margin: 0 0 25px;
                }
                @media #{$xs-layout}{
                    font-size: 45px;
                    margin: 0 0 15px;
                }
                @media #{$sm-layout}{
                    font-size: 55px;
                    margin: 0 0 15px;
                }
            }
            .order-track-btn-2 {
                a {
                    display: inline-block;
                    color: $white;
                    font-size: 16px;
                    background-color: #181818;
                    padding: 18px 40px 18px;
                    &:hover {
                        background-color: $theme-color-yellow;
                    }
                    @media #{$xs-layout}{
                        padding: 13px 35px 15px;
                        font-size: 15px;
                    }
                }
            }
        }
        img {
            height: 100%;
            width: 100%;
            transition: all 0.3s linear 0s;
            will-change: transform, top;
            z-index: 0;
            object-fit: cover;
        }
    }
}

.order-tracking-content {
    padding: 173px 220px 180px 70px;
    @media #{$xx-layout}{
        padding: 127px 110px 135px 0px;
    }
    @media #{$xl-layout}{
        padding: 102px 100px 108px 0px;
    }
    @media #{$lg-layout}{
        padding: 80px 50px 80px 50px;
    }
    @media #{$md-layout}{
        padding: 50px 30px 60px;
    }
    @media #{$xs-layout}{
        padding: 40px 15px 60px;
    }
    p {
        line-height: 25px;
        margin: 0;
        text-align: center;
        width: 98%;
        color: #6d6d6d;
    }
    .order-tracking-form {
        margin: 30px 0 0;
        text-align: left;
        .sin-order-tracking {
            margin: 0 0 30px;
            @media #{$xs-layout}{
                margin: 0 0 20px;
            }
            label {
                font-size: 14px;
                font-weight: bold;
                color: #181818;
                margin: 0 0 9px;
                display: block;
            }
            input {
                background: transparent;
                border: 1px solid #ebebeb;
                height: 70px;
                padding: 10px 20px;
                color: #6d6d6d;
                font-size: 14px;
                &:focus {
                    border: 1px solid #bbb;
                }
                @media #{$xs-layout}{
                    height: 60px;
                }
            }
        }
        .order-track-btn {
            text-align: center;
            a {
                display: block;
                color: #fff;
                background-color: #181818;
                line-height: 1;
                padding: 22px 40px 23px;
                text-transform: uppercase;
                &:hover {
                    background-color: $theme-color-yellow;
                }
            }
        }
    }
}

