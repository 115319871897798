
/*-------- 9. Video style ---------*/


.video-bg-ptb {
    padding: 200px 0 200px;
    @media #{$md-layout} {
        padding: 150px 0 150px;
    }
    @media #{$xs-layout} {
        padding: 120px 0 120px;
    }
}

.video-content {
    position: relative;
    z-index: 9;
    .video-icon {
        a {
            display: inline-block;
            svg {
                color: #fff;
                width: 160px;
                height: 160px;
                transform: scale(1);
                transition: all .3s ease;
                @media #{$md-layout} {
                    width: 150px;
                    height: 150px;
                }
                @media #{$xs-layout} {
                    width: 100px;
                    height: 100px;
                }
                &:hover {
                    transform: scale(.9);
                    color: $theme-color-yellow;
                }
            }
        }
    }
    h3 {
        margin: 30px 0 0;
        font-size: 26px;
        font-style: italic;
        color: #fff;
        font-family: $playfair;
        @media #{$xs-layout} {
            margin: 25px 0 0;
            font-size: 24px;
        }
    }
}

.video-content-2 {
    position: relative;
    z-index: 9;
    h2 {
        color: $white;
        font-size: 80px;
        line-height: 1.3em;
        letter-spacing: -3px;
        font-family: $playfair;
        margin: 0 0 48px;
        @media #{$xx-layout} {
            font-size: 55px;
            margin: 0 0 28px;
        }
        @media #{$xl-layout} {
            font-size: 55px;
            margin: 0 0 28px;
        }
        @media #{$lg-layout} {
            font-size: 40px;
            letter-spacing: 0px;
        }
        @media #{$md-layout} {
            font-size: 47px;
            letter-spacing: 0px;
        }
        @media #{$xs-layout} {
            font-size: 25px;
            letter-spacing: 0px;
            margin: 0 0 30px;
        }
    }
    .video-icon-2 {
        a {
            svg {
                width: 130px;
                height: 130px;
                color: $white;
                transition: all .3s ease 0s;
                @media #{$xs-layout} {
                    width: 80px;
                    height: 80px;
                }
                &:hover {
                    color: $theme-color-green;
                    animation-name: elementor-animation-bob-float,elementor-animation-bob;
                    animation-duration: .3s,1.5s;
                    animation-delay: 0s,.3s;
                    animation-timing-function: ease-out,ease-in-out;
                    animation-iteration-count: 1,infinite;
                    animation-fill-mode: forwards;
                    animation-direction: normal,alternate;
                }
            }
        }
    }
}
.video-content-3 {
    position: relative;
    z-index: 9;
    .video-icon-3 {
        a {
            svg {
                width: 150px;
                height: 150px;
                color: $white;
                transition: all .3s ease 0s;
                @media #{$xl-layout} {
                    width: 180px;
                    height: 180px;
                }
                @media #{$lg-layout} {
                    width: 150px;
                    height: 150px;
                }
                @media #{$md-layout} {
                    width: 120px;
                    height: 120px;
                }
                @media #{$xs-layout} {
                    width: 80px;
                    height: 80px;
                }
                &:hover {
                    color: $theme-color-pink;
                    animation-name: elementor-animation-bob-float,elementor-animation-bob;
                    animation-duration: .3s,1.5s;
                    animation-delay: 0s,.3s;
                    animation-timing-function: ease-out,ease-in-out;
                    animation-iteration-count: 1,infinite;
                    animation-fill-mode: forwards;
                    animation-direction: normal,alternate;
                }
            }
        }
    }
    h3 {
        color: $white;
        font-family: $noto;
        font-size: 28px;
        margin: 42px 0 0;
        @media #{$xs-layout} {
            margin: 22px 0 0;
        }
    }
}

.video-bg-ptb-2 {
    padding: 201px 0 226px;
    @media #{$lg-layout} {
        padding: 150px 0 150px;
    }
    @media #{$md-layout} {
        padding: 150px 0 150px;
    }
    @media #{$xs-layout} {
        padding: 80px 0 80px;
    }
}

.video-bg-ptb-3 {
    padding: 166px 0 166px; 
    @media #{$md-layout} {
        padding: 100px 0 100px; 
    }
    @media #{$xs-layout} {
        padding: 80px 0 80px; 
    }
}

.video-content-4 {
    padding: 224px 0 244px;
    @media #{$lg-layout} {
        padding: 174px 0 194px;
    }
    @media #{$md-layout} {
        padding: 124px 0 144px;
    }
    @media #{$xs-layout} {
        padding: 100px 0 100px;
    }
    a { 
        color: $white;
        font-size: 40px;
        position: relative;
        z-index: 9;
        &:hover {
            color: $theme-color-yellow;
        }
    }
    h3 {
        margin: 15px 0 0;
        font-size: 26px;
        font-family: $playfair;
        font-style: italic;
        color: $white;
        position: relative;
        z-index: 9;
    }
}

























