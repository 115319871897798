
/*-------- 10. Blog style ---------*/


.blog-wrap {
    span {
        a {
            letter-spacing: 2px;
            text-transform: uppercase;
            color: $theme-color-yellow;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    h3 {
        font-size: 32px;
        line-height: 42px;
        font-family: $playfair;
        letter-spacing: -1px;
        margin: 4px 0 22px;
        @media #{$lg-layout} {
            font-size: 23px;
            line-height: 35px;
            margin: 4px 0 20px;
            margin: 4px 0 12px;
        }
        @media #{$md-layout} {
            font-size: 22px;
            margin: 4px 0 10px;
            line-height: 33px;
        }
        @media #{$xs-layout} {
            font-size: 22px;
            line-height: 33px;
            margin: 4px 0 10px;
        }
        a {
            color: #211E1C;
            &:hover {
                color: $theme-color-yellow;
            }
        }
    }
    p {
        font-size: 16px;
        line-height: 32px;
        color: #6D6D6D;
        margin: 0 0 20px;
        @media #{$lg-layout} {
            margin: 0 0 10px;
            font-size: 15px;
            line-height: 30px;
        }
        @media #{$md-layout} {
            font-size: 14px;
            line-height: 28px;
            margin: 0 0 8px;
        }
        @media #{$xs-layout} {
            font-size: 14px;
            line-height: 28px;
            margin: 0 0 18px;
        }
    }
    .blog-btn {
        a {
            display: inline-block;
            color: $theme-color-yellow;
            font-size: 16px;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &.blog-transform {
        transition: transform .3s;
        &:hover {
            transform: translateY(-20px);
        }
    }
}

.blog-wrap-2 {
    display: flex;
    flex-wrap: wrap;
    .blog-img {
        flex: 0 0 44%;
        overflow: hidden;
        @media #{$xs-layout} {
            flex: 0 0 100%;
        }
        @media #{$sm-layout} {
            flex: 0 0 44%;
        }
        a {
            display: block;
            img {
                width: 100%;
                transform: scale(1);
                transition: all 2s ease-out;
            }
        }
        &:hover {
            a {
                img {
                    transform: scale(1.2);
                }
            }
        }
    }
    .blog-content-2 {
        flex: 0 0 56%;
        padding: 0 50px;
        @media #{$xx-layout} {
            padding: 0 20px;
        }
        @media #{$xl-layout} {
            padding: 0 15px;
        }
        @media #{$md-layout} {
            padding: 0 20px;
        }
        @media #{$xs-layout} {
            flex: 0 0 100%;
            padding: 20px 0px 0;
        }
        @media #{$sm-layout} {
            flex: 0 0 56%;
            padding: 0px 20px;
        }
        span {
            a {
                color: #181818;
                &:hover {
                    color: $theme-color-yellow-2;
                    text-decoration: underline;
                }
            }
        }
        h3 {
            font-size: 24px;
            font-weight: 600;
            margin: 4px 0 13px;
            @media #{$xx-layout} {
                font-size: 21px;
            }
            @media #{$xl-layout} {
                font-size: 21px;
            }
            @media #{$xs-layout} {
                font-size: 21px;
            }
            @media #{$sm-layout} {
                font-size: 19px;
            }
            a {
                color: #211E1C;
                &:hover {
                    color: $theme-color-yellow-2;
                }
            }
        }
        .post-meta {
            ul {
                display: flex;
                li {
                    color: #181818;
                    &:not(:first-child)::before {
                        content: "|";
                        display: inline-block;
                        margin-left: 10px;
                        margin-right: 10px;
                        opacity: .5;
                        position: relative;
                        top: -1px;
                    }
                    a {
                        color: #181818;
                    }
                }
            }
        }
        p {
            line-height: 28px;
            color: #6D6D6D;
            margin: 11px 0 28px;
            @media #{$xx-layout} {
                margin: 11px 0 20px;
            }
            @media #{$xl-layout} {
                margin: 11px 0 20px;
            }
            @media #{$xs-layout} {
                margin: 11px 0 18px;
            }
        }
        .blog-btn-2 {
            a {
                display: inline-block;
                line-height: 1;
                color: #181818;
                border: 1px solid #ebebeb;
                padding: 18px 30px;
                font-weight: 600;
                &:hover {
                    border: 1px solid $theme-color-yellow-2;
                    background-color: $theme-color-yellow-2;
                    color: $white;
                }
            }
            &.blog-btn-2-red {
                a {
                    &:hover {
                        border: 1px solid $theme-color-red;
                        background-color: $theme-color-red;
                    }
                }
            }
        }
    }
}

.blog-all-mrg {
    margin: 0 -25px;
    @media #{$xl-layout} {
        margin: 0 0px;
    }
    @media #{$lg-layout} {
        margin: 0 0px;
    }
    @media #{$md-layout} {
        margin: 0 0px;
    }
    @media #{$xs-layout} {
        margin: 0 0px;
    }
}

.blog-wrap-3 {
    transition: all .3s ease 0s;
    .blog-img-2 {
        overflow: hidden;
        margin: 0 0 21px;
        box-shadow: 5px 5px 15px 0 rgba(0,0,0,.2);
        a {
            display: block;
            img {
                width: 100%;
                transition: all 2s ease-out;
            }
        }
        &:hover {
            a {
                img {
                    transform: scale(1.2);
                }
            }
        }
    }
    .blog-content-3 {
        span {
            a {
                color: #6D6D6D;
                text-transform: uppercase;
                display: inline-block;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        h4 {
            font-size: 20px;
            margin: 6px 0 0;
            line-height: 30px;
            @media #{$lg-layout} {
                font-size: 18px;
            }
            @media #{$md-layout} {
                font-size: 18px;
                line-height: 27px;
            }
            @media #{$xs-layout} {
                font-size: 18px;
                line-height: 27px;
            }
            @media #{$esm-layout} {
                font-size: 17px;
            }
            a {
                color: #211E1C;
                &:hover {
                    color: $theme-color-pink;
                }
            }
            &.green {
                a {
                    color: #211E1C;
                    &:hover {
                        color: $theme-color-green;
                    }
                }
            }
        }
        span {
            color: #181818;
            display: block;
            margin: 16px 0 0;
        }
    }
    &.blog-wrap-3-mrg {
        margin: 0 25px;
        @media #{$xl-layout} {
            margin: 0 15px;
        }
        @media #{$lg-layout} {
            margin: 0 15px;
        }
        @media #{$md-layout} {
            margin: 0 0px;
        }
        @media #{$xs-layout} {
            margin: 0 0px 30px;
        }
    }
    &:hover {
        transform: translateY(-20px);
        @media #{$xs-layout} {
            transform: translateY(-10px);
        }
    }
}

.blog-wrap-4 {
    position: relative;
    .blog-img-3 {
        position: relative;
        &::after {
            content: '';
            background-image: linear-gradient(180deg,transparent 42%,#181818 100%);
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
        }
        a {
            img {
                width: 100%;
            }
        }
    }
    .blog-content-4 {
        position: absolute;
        left: 30px;
        bottom: 32px;
        @media #{$xs-layout} {
            left: 15px;
            bottom: 15px;
        }
        > a {
            span {
                background-color: #fff;
                color: #181818;
                padding: 5px 20px;
                display: inline-block;
                text-transform: uppercase;
                font-size: 12px;
                transition: all .3s ease 0s;
                @media #{$xs-layout} {
                    padding: 2px 15px;
                }
                &:hover {
                    text-decoration: underline;
                    color: $theme-color-red;
                }
            }
        }
        h3 {
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            margin: 20px 0 0;
            width: 70%;
            @media #{$xx-layout} {
                width: 90%;
            }
            @media #{$xl-layout} {
                width: 90%;
                font-size: 20px;
                line-height: 34px;
            }
            @media #{$lg-layout} {
                width: 90%;
                font-size: 20px;
                line-height: 34px;
            }
            @media #{$md-layout} {
                width: 80%;
                font-size: 22px;
            }
            @media #{$xs-layout} {
                font-size: 15px;
                line-height: 26px;
                margin: 15px 0 0;
                width: 98%;
            }
            @media #{$sm-layout} {
                font-size: 17px;
            }
            a {
                color: $white;
                &:hover {
                    color: $theme-color-red; 
                }
            }
        }
    }
}

.blog-slider-active {
    .slick-list {
        padding: 0 377px;
        @media #{$xx-layout} {
            padding: 0 220px;
        }
        @media #{$xl-layout} {
            padding: 0 200px;
        }
        @media #{$lg-layout} {
            padding: 0 100px;
        }
        @media #{$md-layout} {
            padding: 0 150px;
        }
        @media #{$xs-layout} {
            padding: 0 20px;
        }
        @media #{$sm-layout} {
            padding: 0 100px;
        }
    }
}

.blog-sidebar-search {
    .blog-search-form {
        position: relative;
        input {
            background-color: transparent;
            border: 1px solid #ebebeb;
            height: 60px;
            padding: 2px 50px 2px 20px;
            color: #181818;
            &:focus {
                border: 1px solid #bbb;
            }
        }
        button {
            border: none;
            padding: 0;
            background-color: transparent;
            font-size: 18px;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            &:hover {
                color: $theme-color-yellow;
            }
        }
    }
}

.blog-page-area {
    padding: 120px 0;
    @media #{$md-layout} {
        padding: 80px 0;
    }
    @media #{$xs-layout} {
        padding: 60px 0;
    }
}

.blog-wrap-5 {
    padding-bottom: 60px;
    margin-bottom: 60px;
    border-bottom: 1px solid #ebebeb;
    @media #{$xs-layout} {
        padding-bottom: 30px;
        margin-bottom: 30px;
    }
    .blog-img-5 {
        margin: 0 0 18px;
        overflow: hidden;
        a {
            display: block;
            img {
                width: 100%;
                transform: scale(1);
                transition: all 2s ease-out;
            }
        }
        &:hover {
            a {
                img {
                    transform: scale(1.2);
                }
            }
        }
    }
    .blog-content-5 {
        .blog-category-5 {
            > a {
                letter-spacing: .5px;
                display: inline-block;
                color: $theme-color-yellow;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        h1 {
            font-size: 28px;
            font-family: $playfair;
            margin: 7px 0 12px;
            color: #211e1c;
            @media #{$xs-layout} {
                font-size: 24px;
            }
            a {
                color: #211e1c;
                &:hover {
                    color: $theme-color-yellow;
                }
            }
        }
        .blog-meta-5 {
            ul {
                display: flex;
                li {
                    color: #6d6d6d;
                    text-transform: uppercase;
                    a {
                        color: #6d6d6d;
                        &:hover {
                            color: $theme-color-yellow;
                        }
                    }
                    &:not(:first-child)::before {
                        content: "|";
                        display: inline-block;
                        margin-left: 10px;
                        margin-right: 10px;
                        opacity: .5;
                        position: relative;
                        top: 1px;
                    }
                }
            }
        }
        p {
            line-height: 28px;
            margin: 17px 0 20px;
            @media #{$lg-layout} {
                margin: 10px 0 13px;
            }
            @media #{$xs-layout} {
                margin: 10px 0 15px;
            }
        }
        .blog-btn-5 {
            a {
                font-size: 14px;
                display: inline-block;
                color: #181818;
                border: 1px solid #ebebeb;
                padding: 9px 22px 10px; 
                &:hover {
                    background-color: $theme-color-yellow;
                    border: 1px solid $theme-color-yellow;
                    color: $white;
                }
            }
        }
    }
    &.blog-custom-padding {
        border: none;
        margin: 0;
        padding: 50px 140px 50px 140px;
        @media #{$xx-layout} {
            padding: 50px 80px 50px 80px;
        }
        @media #{$xl-layout} {
            padding: 50px 60px 50px 60px;
        }
        @media #{$lg-layout} {
            padding: 10px 30px 10px 30px;
        }
        @media #{$md-layout} {
            padding: 40px 30px 50px 30px;
        }
        @media #{$xs-layout} {
            padding: 30px 20px 40px 20px;
        }
    }
}

.blog-sidebar-mrg {
    margin-right: -10px;
    @media #{$lg-layout} {
        margin-right: 0px;
    }
    @media #{$md-layout} {
        margin-right: 0px;
        margin-top: 50px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
        margin-top: 50px;
    }
}

.blog-page-wrap-padding {
    padding-left: 100px;
    @media #{$lg-layout} {
        padding-left: 0px;
    }
    @media #{$md-layout} {
        padding-left: 0px;
    }
    @media #{$xs-layout} {
        padding-left: 0px;
    }
}

.blog-sidebar-mrg-2 {
    margin-left: -10px;
    @media #{$lg-layout} {
        margin-left: 0px;
    }
    @media #{$md-layout} {
        margin-left: 0px;
        margin-top: 50px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
        margin-top: 50px;
    }
}

.blog-page-wrap-padding-2 {
    padding-right: 100px;
    @media #{$lg-layout} {
        padding-right: 0px;
    }
    @media #{$md-layout} {
        padding-right: 0px;
    }
    @media #{$xs-layout} {
        padding-right: 0px;
    }
}

.pro-pagination-style-2 {
    ul {
        display: flex;
        li {
            margin-right: 8px;
            &:last-child {
                margin-right: 0;
            }
            a {
                display: inline-block;
                width: 38px;
                height: 38px;
                line-height: 38px;
                text-align: center;
                color: #979797;
                border: 1px solid #ebebeb;
                border-radius: 100%;
                &.active , &:hover {
                    border: 1px solid #181818;
                    background-color: #181818;
                    color: $white;
                }
            }
        }
    }
    &.pagination-style-2-center {
        ul {
            justify-content: center;
        }
    }
}

.blog-details-wrap {
    .blog-details-img {
        margin: 0 0 18px;
        overflow: hidden;
        img {
            width: 100%;
            transform: scale(1);
            transition: all 2s ease-out;
        }
        &:hover {
            img {
                transform: scale(1.2);
            }
        }
    }
    .blog-details-category {
        > a {
            font-size: 12px;
            display: inline-block;
            color: $theme-color-yellow;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    > h1 {
        font-size: 28px;
        font-family: $playfair;
        margin: 7px 0 12px;
        color: #211e1c;
        @media #{$xs-layout} {
            font-size: 20px;
        }
    }
    .blog-details-meta {
        margin: 0 0 18px;
        ul {
            display: flex;
            li {
                font-size: 12px;
                color: #6d6d6d;
                text-transform: uppercase;
                a {
                    color: #6d6d6d;
                    &:hover {
                        color: $theme-color-yellow;
                    }
                }
                &:not(:first-child)::before {
                    content: "|";
                    display: inline-block;
                    margin-left: 10px;
                    margin-right: 10px;
                    opacity: .5;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }
    .blog-details-peragraph {
        p {
            font-size: 14px;
            line-height: 30px;
            margin: 0 0 20px;
            @media #{$xs-layout} {
                font-size: 14px;
                line-height: 30px;
                margin: 0 0 15px;
            }
            &:last-child {
                margin: 0 0 0;
            }
        }
        h2 {
            font-size: 28px;
            font-family: $playfair;
            margin: 0px 0 20px;
            color: #211e1c;
            @media #{$xs-layout} {
                font-size: 20px;
                line-height: 30px;
            }
        }
    }
    .blog-details-video {
        margin: 41px 0 35px;
        @media #{$xs-layout} {
            margin: 21px 0 15px;
        }
        a {
            display: block;
            position: relative;
            &::before {
                font-family: 'LaStudioIcons';
                display: inline-block;
                vertical-align: middle;
                text-rendering: auto;
                content: "\ea45";
                position: absolute;
                width: 68px;
                height: 68px;
                line-height: 68px;
                background: #fff;
                text-align: center;
                border-radius: 50%;
                left: 50%;
                top: 50%;
                margin-left: -34px;
                margin-top: -34px;
                transition: all .5s;
                z-index: 2;
                font-size: 18px;
                color: #6d6d6d;
            }
            img {
                width: 100%;
            }
        }
        &:hover {
            a {
                &::before {
                    transform: scale(1.2);
                    color: $theme-color-yellow;
                }
            }
        }
    }
    blockquote {
        background-color: #F9F9F9;
        border-width: 0;
        text-align: center;
        padding: 60px 40px;
        margin: 40px 0;
        position: relative;
        @media #{$xs-layout} {
            padding: 60px 20px;
            margin: 20px 0;
        }
        &::before {
            content: '\ea7a';
            font-family: LaStudioIcons;
            font-size: 200px;
            line-height: 1;
            color: #3E3E3E;
            opacity: .1;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            pointer-events: none;
            @media #{$xs-layout} {
                font-size: 150px;
            }
        }
        h3 {
            font-size: 26px;
            color: #3E3E3E;
            line-height: 1.4;
            font-family: $playfair;
            width: 357px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 10px;
            max-width: 100%;
            font-weight: 700;
            font-style: italic;
            @media #{$xs-layout} {
                font-size: 20px;
            }
        }
        span {
            font-family: $playfair;
            font-style: italic;
            font-size: 16px;
            position: relative;
            &:before {
                position: absolute;
                content: "";
                width: 50px;
                height: 2px;
                background-color: #D8D8D8;
                left: 0;
                right: 0;
                margin: 0 auto;
                top: -9px;
            }
        }
    }
    .blog-details-tag-social {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        border-top: 1px solid rgba(0,0,0,0.1);
        padding: 14px 0;
        margin: 68px 0 27px;
        .blog-details-tag {
            @media #{$xs-layout}{
                margin-bottom: 10px;
            }
            @media #{$sm-layout}{
                margin-bottom: 0px;
            }
            ul {
                display: flex;
                li {
                    margin-right: 4px;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        color: #181818;
                        font-style: italic;
                        &:hover {
                            color: $theme-color-yellow;
                        }
                    }
                }
            }
        }
        .blog-details-social {
            ul {
                display: flex;
                li {
                    margin-left: 10px;
                    &:first-child {
                        margin-left: 0;
                    }
                    a {
                        display: inline-block;
                        width: 27px;
                        height: 27px;
                        line-height: 27px;
                        border-radius: 100%;
                        color: #fff;
                        text-align: center;
                        &.facebook {
                            background-color: #272EAE;
                        }
                        &.twitter {
                            background-color: #1590D8;
                        }
                        &.pinterest {
                            background-color: #D0021B;
                        }
                    }
                    i {
                        line-height: 27px;
                    }
                }
            }
        }
    }
    .next-wrap {
        display: flex;
        justify-content: flex-end;
        text-align: right;
        margin: 0 0 51px;
        .next-conent {
            h5 {
                font-size: 18px;
                line-height: 21px;
                margin: 0 0 13px;
                font-family: $playfair;
                > a {
                    color: #181818;
                    &:hover {
                        color: $theme-color-yellow;
                    }
                }
            }
            a {
                color: #6d6d6d;
                &:hover {
                    color: $theme-color-yellow;
                }
            }
        }
    }
    .blog-details-comment {
        > h4 {
            margin: 0;
            font-size: 24px;
            @media #{$xs-layout}{
                font-size: 20px;
            }
        }
        .single-blog-comment {
            display: flex;
            margin: 32px 0 0;
            .blog-comment-img {
                flex: 0 0 75px;
                margin-right: 25px;
                @media #{$xs-layout}{
                    margin-right: 15px;
                }
                img {
                    width: 100%;
                }
            }
            .blog-comment-content {
                border: 1px solid rgba(0,0,0,0.1);
                padding: 25px 30px;
                box-shadow: 0 0 6px 0 rgba(226,226,226,0.5);
                @media #{$xs-layout}{
                    padding: 25px 15px;
                }
                .blog-comment-top {
                    border-bottom: 1px solid rgba(0,0,0,0.1);
                    margin-bottom: 18px;
                    padding-bottom: 18px;
                    @media #{$xs-layout}{
                        margin-bottom: 10px;
                        padding-bottom: 10px;
                    }
                    .comment-author {
                        h3 {
                            font-weight: 600;
                            font-size: 14px;
                            margin: 0 0 16px;
                            a {
                                color: #211e1c;
                                &:hover {
                                    color: $theme-color-yellow;
                                }
                            }
                            span {
                                font-size: 12px;
                                color: #979797;
                                margin-left: 5px;
                                display: inline-block;
                            }
                        }
                    }
                    p {
                        line-height: 25px;
                        a {
                            color: #6d6d6d;
                            &:hover {
                                color: $theme-color-yellow;
                            }
                        }
                    }
                }
                .blog-comment-bottom {
                    a {
                        font-size: 12px;
                        font-weight: bold;
                        color: #6d6d6d;
                        i {
                            font-size: 12px;
                            margin-right: 3px;
                            position: relative;
                            top: 3px;
                        }
                        &:hover {
                            color: $theme-color-yellow;
                        }
                    }
                }
            }
        }
    }
    .comment-form {
        margin: 51px 0 0;
        h4 {
            font-size: 24px;
            margin: 0 0 33px;
        }
        form {
            .leave-form {
                margin-bottom: 28px;
                input , textarea {
                    height: 60px;
                    border: 1px solid #ebebeb;
                    color: #6d6d6d;
                    padding: 2px 25px;
                    background-color: transparent;
                    &:focus {
                        border: 1px solid #bbb;
                    }
                }
                textarea {
                    height: 150px;
                    padding: 20px 25px;
                }
            }
            .save-comment-info {
                display: flex;
                align-items: center;
                input {
                    width: auto;
                    height: auto;
                }
                span {
                    margin-left: 14px;
                    color: #6d6d6d;
                    display: inline-block;
                }
            }
            .text-submit {
                margin: 30px 0 0;
                input {
                    width: auto;
                    height: auto;
                    background-color: transparent;
                    background-color: #181818;
                    color: #fff;
                    text-transform: uppercase;
                    border: none;
                    padding: 16px 37px;
                    &:hover {
                        background-color: $theme-color-yellow;
                        color: #fff;
                    }
                }
            }
        }
    }
}

@media #{$md-layout} {
    .blog-area {
        &.pb-105 {
            padding-bottom: 35px;
        }
        &.pb-115 {
            padding-bottom: 50px;
        }
        &.pb-120 {
            padding-bottom: 50px;
        }
        &.pb-75 {
            padding-bottom: 35px;
        }
        &.pb-85 {
            padding-bottom: 45px;
        }
        &.pb-90 {
            padding-bottom: 50px;
        }
    }
}
@media #{$xs-layout} {
    .blog-area {
        &.pb-105 {
            padding-bottom: 20px;
        }
        &.pb-140 {
            padding-bottom: 30px;
        }
        &.pb-115 {
            padding-bottom: 30px; 
        }
        &.pb-120 {
            padding-bottom: 30px; 
        }
        &.pt-135 {
            padding-top: 50px;
        }
        &.pb-75 {
            padding-bottom: 15px;
        }
        &.pb-85 {
            padding-bottom: 25px;
        }
        &.pb-90 {
            padding-bottom: 30px;
        }
    }
}




