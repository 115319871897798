/*-------- 31. Wishlist style ---------*/


.wishlist-table-content {
    table {
        width: 100%;
        thead > tr {
            border-bottom: 1px solid #BEBEBE;
            th {
                border-top: medium none;
                font-size: 16px;
                text-transform: capitalize;
                vertical-align: middle; 
                white-space: nowrap;
                font-weight: 600;
                text-transform: capitalize;
                padding: 0 0 9px 0;
                @media #{$xs-layout}{
                    padding: 0 50px 12px 50px;
                }
            }
        }
        tbody > tr {
            border-bottom: 1px solid #BEBEBE;
            td {
                font-size: 14px;
                padding: 40px 0px 40px;
                @media #{$xs-layout}{
                    padding: 20px 0px 20px; 
                    text-align: center; 
                }
            }
            td.product-remove-2 {
                width: 30px;
                a {
                    color: #181818;
                    &:hover {
                        color: $theme-color-yellow;
                    }
                }
            }
            td.product-img-2 {
                width: 90px;
                a {
                    display: block;
                    padding-right: 20px;
                    @media #{$xs-layout}{
                        padding-right: 0px;
                    }
                    img {
                        max-width: 100%; 
                    }
                }
            }
            td.product-name-2 {
                width: 340px;
                @media #{$lg-layout}{
                    width: 240px;
                }
                @media #{$md-layout}{
                    width: 180px;
                }
                a {
                    font-size: 16px;
                    display: block;
                    &:hover {
                        color: $theme-color-yellow;
                    }
                }
                span {
                    display: block;
                    color: #181818;
                }
            }
            td.product-stock {
                width: 242px;
                @media #{$md-layout}{
                    width: 140px;;
                }
                span {
                    color: #181818;
                    font-size: 16px;
                }
            }
            td.product-price-2 {
                width: 123px;
                @media #{$md-layout}{
                    width: 100px;;
                }
                span {
                    color: #181818;
                    font-size: 16px;
                }
            }
            td.product-wishlist-cart {
                text-align: right;
                > a {
                    background-color: #181818;
                    color: #fff;
                    font-size: 14px;
                    line-height: 1;
                    padding: 11px 17px;
                    text-transform: capitalize;
                    display: inline-block;
                    @media #{$md-layout}{
                        font-size: 12px;
                    }
                    @media #{$xs-layout}{
                        font-size: 12px;
                        padding: 11px 10px;
                    }
                    &:hover {
                        background-color: $theme-color-yellow;
                    }
                }
            }
        }
    }
}


.wishlist-area {
    padding: 112px 0 120px;
    @media #{$xs-layout}{
        padding: 60px 0 60px;
    }
}


























