/*-------- 23. Faq style ---------*/

.faq-accordion {
    border: 1px solid #e3e3e3;
    background-color: #fff;
    .panel-heading {
        h4 {
            font-size: 16px;
            font-weight: 500;
            margin: 0;
            background-color: #fff;
            a {
                color: $theme-color-yellow;
                display: block;
                position: relative;
                padding: 22px 30px 23px 30px;
                @media #{$xs-layout} {
                    padding: 22px 30px 23px 10px;
                    line-height: 25px;
                }
                @media #{$sm-layout} {
                    padding: 22px 30px 23px 30px;
                    line-height: inherit;
                }
                &.collapsed {
                    position: relative;
                    color: #181818;
                    &::after {
                        content: "\ea67";
                        position: absolute;
                        color: #6d6d6d;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 30px;
                        transition: all .3s ease 0s;
                        font-family:LaStudioIcons;
                        font-size: 16px;
                        font-weight: 400;
                        @media #{$xs-layout} {
                            right: 10px;
                        }
                        @media #{$sm-layout} {
                            right: 10px;
                        }
                    }
                    &::before {
                        position: absolute;
                        right: 30px;
                        color: #6d6d6d; 
                        top: 50%;
                        transform: translateY(-50%);
                        content: '\ea68';
                        transition: all .3s ease 0s;
                        font-family:LaStudioIcons;
                        font-size: 16px;
                        font-weight: 400;
                        @media #{$xs-layout} {
                            right: 10px;
                        }
                        @media #{$sm-layout} {
                            right: 10px;
                        }
                    }
                }
                &::before {
                    position: absolute;
                    right: 30px;
                    color: $theme-color-yellow;
                    top: 50%;
                    transform: translateY(-50%);
                    content: '\ea68';
                    transition: all .3s ease 0s;
                    font-family:LaStudioIcons;
                    font-size: 16px;
                    font-weight: 400;
                    @media #{$xs-layout} {
                        right: 10px;
                    }
                    @media #{$sm-layout} {
                        right: 10px;
                    }
                }
            }
        }
    }
    &.actives {
        box-shadow: 0 0 87px 0 rgba(41,44,58,.1);
    }
    .panel-collapse {
        .panel-body {
            p {
                line-height: 28px;
                font-size: 14px;
                margin: 0;
                padding: 0px 30px 30px 30px;
                @media #{$xs-layout} {
                    padding: 0px 10px 30px 10px;
                }
                @media #{$sm-layout} {
                    padding: 0px 30px 30px 30px;
                }
            }
        }
    }
}

.single-faq-wrap {
    h3 {
        font-size: 18px;
        font-weight: 600;
        margin: 0 0 23px;
        @media #{$xs-layout} {
            margin: 0 0 15px;
            line-height: 28px; 
        }
    }
    p {
        line-height: 28px;
        margin: 0;
    }
    @media #{$xs-layout} {
        &.mb-50 {
            margin-bottom: 30px;
        }
    }
}

@media #{$xs-layout} {
    .faq-wrap-1 {
        &.mb-75 {
            margin-bottom: 30px;
        }
        &.mb-55 {
            margin-bottom: 20px;
        }
    }
    .faq-area {
        &.pt-120 {
            padding-top: 60px;
        }
        &.pt-110 {
            padding-top: 50px;
        }
        &.pb-100 {
            padding-bottom: 40px;
        }
        &.pb-65 {
            padding-bottom: 30px;
        }
    }
}

