/*-------- 28. Compare style ---------*/

.compare-page-area {
    padding: 120px 0;
    @media #{$xs-layout} {
        padding: 60px 0;
    }
}
.compare-table {
    & .table {
        & tbody {
            & tr {
                & th {
                    vertical-align: middle;
                    &.first-column {
                        min-width: 205px;
                        margin: 0;
                        padding: 15px 15px;
                        font-size: 15px;
                        font-weight: 600;
                        @media #{$md-layout} {
                            min-width: 200px;
                        }
                        @media #{$xs-layout} {
                            min-width: 150px;
                            padding: 10px 20px;
                        }
                    }
                }
                &:first-child th {
                    vertical-align: bottom;
                }
                & td {
                    text-align: center;
                    padding: 10px 15px;
                    vertical-align: middle;
                    border-color: #ebebeb;
                    &.product-image-title {
                        min-width: 293px;
                        vertical-align: bottom;
                        padding: 10px 10px 19px;
                        @media #{$xs-layout} {
                            min-width: 220px;
                        }
                        .compare-remove {
                            a {
                                font-size: 12px;
                                color: #181818;
                                text-transform: capitalize;
                                i {
                                    padding-right: 2px;
                                    position: relative;
                                    top: 2px;
                                }
                                &:hover {
                                    color: $theme-color-yellow;
                                }
                            }
                        }
                        & .image {
                            clear: both;
                            width: 100%;
                            margin: 10px 0 6px;
                            display: block;
                            img {
                                max-width: 100%;
                            }
                        }
                        & .category {
                            float: left;
                            clear: both;
                            color: $theme-color-yellow;
                            text-transform: capitalize;
                            letter-spacing: 0.5px;
                        }
                        & .pro-title {
                            display: block;
                            overflow: hidden;
                            > a {
                                float: left;
                                clear: both;
                                font-size: 14px;
                                text-transform: capitalize;
                                margin-top: 5px;
                                display: block;
                                width: 100%;
                                line-height: 1;
                                &:hover {
                                    color: $theme-color-yellow;
                                }
                            }
                        }
                        .compare-btn {
                            margin: 11px 0 0;
                            a {
                                background-color: #181818;
                                padding: 5px 14px 6px;
                                color: #fff;
                                display: inline-block;
                                font-size: 14px;
                                font-weight: 500;
                                &:hover {
                                    background-color: $theme-color-yellow;
                                }
                            }
                        }
                    }
                    &.pro-desc {
                        & p {
                            margin: 0;
                            font-size: 14px;
                            line-height: 25px;
                            color: #181818;
                        }
                    }
                    &.pro-price {
                        font-size: 14px;
                        font-weight: 400;
                        color: #181818;
                    }
                    &.pro-sku , &.pro-weight {
                        font-size: 14px;
                        font-weight: 400;
                        color: #181818;
                    }
                    &.pro-stock {
                        p {
                            font-size: 14px;
                            font-weight: 400;
                            color: #181818;
                            display: inline-block;
                            line-height: 1;
                        }
                    }
                    &.pro-dimensions {
                        font-size: 14px; 
                        font-weight: 400;
                        color: #181818;
                    }
                }
            }
        }
    }
}
/*------ end Compare Page Wrapper -----*/