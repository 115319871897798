
/*-------- 12. Instafeed style ---------*/


.footer-instagram {
    .insta-icon {
        margin: 0 0 16px;
        i {
            font-size: 18px;
            color: #fff;
        }
    }
}

.instagram-wrap-1 {
	display: flex;
	flex-wrap: wrap;
	margin-left: -5px;
	margin-right: -5px;
    .single-instafeed-wrap {
        flex: 0 0 25%;
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 10px;
    }
}

.single-instafeed {
    overflow: hidden;
    position: relative;
    &:before {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        content: '';
        pointer-events: none;
        z-index: 8;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease 0s;
        background-color: rgba(84,89,95,.6);
    }
    > a {
        display: block;
        img {
            width: 100%;
        }
    }
    .insta-icon-digit {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease 0s;
        > a {
            color: #fff;
            font-size: 14px;
            margin-right: 3px;
            &:last-child {
                margin-right: 0;
            }
            i {
                position: relative;
                top: 3px;
            }
        }
    }
    &:hover {
        &:before {
            opacity: 1;
            visibility: visible;
        }
        .insta-icon-digit {
            opacity: 1;
            visibility: visible;
        }
    }
}

.single-instafeed-wrap-2 {
	margin: 0 15px;
}

.instagram-active {
    .slick-list {
        padding: 0 260px;
        margin: 0 0 33px;
        @media #{$xx-layout} {
            padding: 0 160px;
        }
        @media #{$xl-layout} {
            padding: 0 130px;
        }
        @media #{$lg-layout} {
            padding: 0 90px;
        }
        @media #{$md-layout} {
            padding: 0 110px;
        }
        @media #{$xs-layout} {
            padding: 0 60px;
        }
    }
} 


