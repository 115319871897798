/*-------- 27. Checkout style ---------*/

.customer-zone {
    margin: 0 0 55px;
    @media #{$xs-layout} {
        margin: 0 0 35px;
    }
    > h4 {
        margin: 0;
        color: #181818;
        font-size: 24px;
        font-family: $playfair;
        border-bottom: 1px solid #ebebeb;
        padding: 0 0 22px;
        text-align: center;
        @media #{$xs-layout} {
            font-size: 20px;
        }
        a {
            color: #181818;
            font-style: italic;
            &:hover {
                color: $theme-color-yellow;
            }
        }
    }
}
.checkout-login-info-2 {
    p {
        font-size: 16px;
        color: #6d6d6d;
        margin: 0 0 13px;
    }
    display: block;
    margin-top: 30px;
    margin-bottom: 55px;
    margin: 21px 260px 0;
    text-align: center;
    @media #{$lg-layout} {
        margin: 21px 100px 0;
    }
    @media #{$md-layout} {
        margin: 21px 50px 0;
    }
    @media #{$xs-layout} {
        margin: 21px 0px 0;
    }
    form {
        input[type="text"] {
            border: 1px solid #ebebeb;
            height: 50px;
            background-color: transparent;
            width: 54%;
            color: #6d6d6d;
            font-size: 14px;
            padding: 0 20px;
            &:focus {
                border: 1px solid #262626;
            }
        }
        input[type="submit"] {
            background: #181818 none repeat scroll 0 0;
            border: medium none;
            color: #fff;
            height: 50px;
            margin-left: 3px;
            padding: 5px 30px 7px;
            text-transform: capitalize;
            width: inherit;
            font-size: 14px; 
            @media #{$lg-layout} {
                padding: 5px 15px;
            }
            @media #{$xs-layout} {
                padding: 5px 10px;
                margin-left: 5px;
            }
            &:hover {
                background-color: $theme-color-yellow;
            }
        }
    }
}

.billing-info-wrap {
    h3 {
        font-size: 28px;
        position: relative;
        margin: 0 0 33px;
        @media #{$xs-layout} {
            font-size: 24px;
            margin: 0 0 20px;
        }
    }
    .billing-info {
        label {
            display: block;
            margin: 0 0 10px;
            @media #{$xs-layout} {
                margin: 0 0 5px;
            }
        }
        input {
            border: 1px solid #ebebeb;
            height: 50px;
            background-color: transparent;
            padding: 2px 20px;
            color: #6d6d6d;
            &:focus {
                border: 1px solid #bbb;
            }
            &.billing-address {
                margin-bottom: 20px;
            }
        }
        @media #{$xs-layout} {
            &.mb-25 {
                margin-bottom: 15px;
            }
        }
    }
    .billing-select {
        label {
            display: block;
            margin: 0 0 10px;
            color: #181818;
        }
        .select2-container {
            display: block;
            .select2-choice {
                border: 1px solid #ebebeb;
                -moz-appearance: none;
                height: 50px;
                padding: 2px 20px;
                color: #6d6d6d;
                cursor: pointer; 
                .select2-chosen {
                    height: 50px;
                }
            }
        }
    }
    .additional-info-wrap {
        margin: 26px 0 0;
        @media #{$xs-layout}{
            margin: 16px 0 0;
        }
        label {
            display: block;
            margin: 0 0 10px;
            padding: 5px 0 0;
        }
        textarea {
            min-height: 150px;
            background-color: transparent;
            border: 1px solid #ebebeb;
            padding: 20px;
            color: #6d6d6d; 
        }
    }
}

.select2-container--default {
    .select2-selection--single {
        border: 1px solid #e6e6e6;
        border-radius: 0px;
        cursor: pointer;
        display: block;
        height: 50px;
        user-select: none;
        -webkit-user-select: none;
        line-height: 50px;
        .select2-selection__rendered {
            height: 50px;
            color: #262626;
            line-height: 50px;
            padding: 0px 20px;
        }
        .select2-selection__arrow {
            height: 50px;
        }
    }
}
.select2-container--default .select2-search--dropdown .select2-search__field {
    height: 34px;
    background-color: transparent;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #0073aa;
	color: #fff;
}

.your-order-area {
	margin: 68px 0 0;
	position: relative;
    @media #{$md-layout} {
        margin: 30px 0 0;
    }
    @media #{$xs-layout} {
        margin: 30px 0 0;
    }
    h3 {
        font-size: 28px;
        position: relative;
        margin: 0 0 23px;
        @media #{$xs-layout} {
            margin: 0 0 20px;
        }
    }
    .your-order-wrap {
        .your-order-info {
            border-bottom: 1px solid #e7e7e7;
            ul {
                li {
                    color: #181818;
                    font-size: 16px;
                    &.order-info-product {
                        padding: 0 0 10px;
                    }
                    span {
                        float: right;
                        color: #181818;
                    }
                }
            }
            &.order-subtotal {
                padding: 15px 0 10px;
                ul {
                    li {
                        span {
                            font-size: 16px;
                        }
                    }
                }
            }
            &.order-total {
                padding: 12px 0 12px;
                ul {
                    li {
                        span {
                            font-size: 24px;
                            color: #181818;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        .your-order-middle {
            border-bottom: 1px solid #e7e7e7;
            padding: 16px 0 17px;
            .single-order-middle {
                display: flex;
                justify-content: space-between;
                padding: 0 0 16px;
                margin: 0 0 14px;
                border-bottom: 1px solid #ebebeb;
                &:last-child {
                    margin: 0 0 0px;
                    border-bottom: none;
                    padding: 0 0 0px;
                }
                .single-order-content {
                    h5 {
                        margin: 0;
                        color: #181818;
                        font-size: 16px;
                        > span {
                            color: #181818;
                            font-size: 14px;
                        }
                    }
                    > span {
                        color: #181818;
                        font-size: 12px;
                        display: block;
                        line-height: 1;
                        margin: 6px 0 0;
                    }
                }
                .single-order-price {
                    span {
                        color: #181818;
                        font-size: 16px;
                    }
                }
            }
        }
        .checkout-shipping-content {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #e7e7e7;
            margin-top: 13px;
            padding-bottom: 7px;
            .shipping-content-left {
                margin-right: 34px;
            }
            .shipping-content-right {
                ul {
                    li {
                        display: flex;
                        color: #262626;
                        margin: 0 0 5px;
                        text-transform: uppercase;
                        justify-content: flex-end;
                        input {
                            width: auto;
                            height: auto;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
        .payment-method {
            margin: 34px 0 0;
            .sin-payment {
                margin-bottom: 18px;
                &:last-child {
                    margin-bottom: 0;
                }
                input {
                    width: auto;
                    display: inline-block;
                    float: left;
                    height: auto;
                    margin-top: 5px;
                }
                label {
                    font-size: 16px;
                    text-transform: capitalize;
                    color: #181818;
                    margin: 0px 0 0 21px;
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    @media #{$xs-layout} {
                        display: block;
                    }
                    img {
                        max-width: 140px;
                        display: inline-block;
                        margin: 0 5px
                    }
                    a {
                        text-decoration: underline;
                    }
                }
                .payment-box {
                    margin: 6px 0 0;
                    display: none;
                    p {
                        font-size: 13px;
                        margin: 0;
                        color: #181818;
                    }
                }
                &.sin-payment-3 {
                    input {
                        margin-top: 18px;
                    }
                }
            }
        }
        .condition-wrap {
            margin: 30px 0 0;
            p {
                color: #181818;
                line-height: 25px;
                margin: 0;
                a {
                    color: #181818;
                    &:hover {
                        color: $theme-color-yellow;
                    }
                }
            }
        }
    }
    .Place-order {
        margin-top: 27px;
        a {
            background-color: #181818;
            display: block;
            cursor: pointer;
            padding: 17px 50px 17px;
            width: 100%;
            color: #fff;
            text-align: center;
            text-transform: uppercase;
            &:hover {
                background-color: $theme-color-yellow;
            }
        }
    }
}

.checkout-account {
    margin: 25px 0 0;
    @media #{$xs-layout}{
        margin: 10px 0 0;
    }
    input {
        border: 1px solid #bbb;
        display: inline-block;
        float: left;
        height: 10px;
        width: 10px;
        position: relative;
        top: 6px;
    }
    span {
        color: #181818;
        font-weight: 400;
        margin: 0 0 0 12px;
    }
}

.different-address {
    display: none;
}

.checkout-main-area {
    padding: 120px 0;
    @media #{$md-layout} {
        padding: 80px 0;
    }
    @media #{$xs-layout} {
        padding: 60px 0;
    }
}




